import { Segmented, Select, Table } from 'antd'
import _ from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { modelsConfigRequest } from 'src/store/actions/formulate'
import { StoreState } from 'src/store/configureStore'
import { useQuery } from 'src/utils/useQuery'
import useTranslate from 'src/utils/useTranslate'
import Fuse from 'fuse.js'
import { PARAMETER_NAME, PARAMETER_TYPE } from './UploadComponent'

const MapParametersStep = ({
  selectedStages,
  sheetToStageMap,
  isMultiStageModel,
  rowToParameterMap,
  setRowToParameterMap,
  ingredientsInputs,
  processingInputs,
  trialDisplayNameList,
  hasCategories,
  groupedIngredientOptions,
  currentSelectedStage,
  setCurrentSelectedStage,
  setTrialDisplayNameList,
  fileJson
}: {
  selectedStages: [number, number]
  sheetToStageMap: any
  isMultiStageModel: boolean
  rowToParameterMap: any
  setRowToParameterMap: any
  ingredientsInputs: any
  processingInputs: any
  trialDisplayNameList: any
  hasCategories: boolean
  groupedIngredientOptions: any
  currentSelectedStage: number
  setCurrentSelectedStage: any
  setTrialDisplayNameList: any
  fileJson: any
}) => {
  let query = useQuery()
  let modelVersion = query?.get('version')
  const dispatch = useDispatch()

  const modelConfigData = useSelector((state: StoreState) => state.formulate.modelConfigData)
  const configData = useSelector((state: StoreState) => state.formulate.configData)
  const displayNames = useSelector((state: StoreState) => state.displayNames.data ?? {})

  const stagesOptions: any[] = useMemo(() => {
    const options = new Array(selectedStages?.[selectedStages?.length - 1] - selectedStages?.[0] + 1).fill(null)
    return options.map((res, index) => {
      const stageName = modelConfigData[0]?.all_stages?.[selectedStages[0] + index - 1]

      const sheetName = _.keys(sheetToStageMap).find((key: any) => sheetToStageMap[key].stageName === stageName)

      return {
        value: selectedStages[0] + index,
        label: `${sheetName} → ${stageName ?? `Stage ${selectedStages[0] + index}`}`,
        title: `${stageName ?? `Stage ${selectedStages[0] + index}`}`
      }
    })
  }, [selectedStages, modelConfigData, sheetToStageMap])

  const versionData = configData.find((res: any) => res?.version === Number(modelVersion))

  const currentStageSheetName = useMemo(() => {
    const stageName = stagesOptions.find((option) => option.value === currentSelectedStage)?.title
    return _.keys(sheetToStageMap).find((key: any) => sheetToStageMap[key].stageName === stageName)
  }, [currentSelectedStage, sheetToStageMap, stagesOptions])

  // setting trial display name list on stage change
  useEffect(() => {
    if (currentStageSheetName) {
      const trialKeysForThisStage = Object.keys(fileJson[currentStageSheetName][0]).filter((key) => key !== PARAMETER_TYPE && key !== PARAMETER_NAME)

      setTrialDisplayNameList((prev: any) => {
        const stageName = stagesOptions.find((option) => option.value === currentSelectedStage)?.title
        const stage = currentSelectedStage
        return {
          ...prev,
          [stage]: trialKeysForThisStage.map((trialKey: any, idx: number) => {
            const trialNumber = idx + 1
            return {
              label: `${stageName}: ${trialKey}`,
              value: `FR_Trial_${trialNumber}`
            }
          })
        }
      })
    }
  }, [currentSelectedStage, currentStageSheetName, fileJson, setTrialDisplayNameList, stagesOptions])

  const currentStageSheetData = useMemo(() => {
    return currentStageSheetName ? rowToParameterMap[currentStageSheetName] : {}
  }, [currentStageSheetName, rowToParameterMap])

  const selectedIngredients = Object.values(currentStageSheetData.ingredients || [])

  const selectedProcessing = Object.values(currentStageSheetData.processing || [])

  const ingredientOptions = useMemo(() => {
    return Object.keys(ingredientsInputs?.[currentSelectedStage] || {})
      .filter((ingredient: any) => !selectedIngredients.includes(ingredient))
      .map((ingredient: any) => {
        const multstageIngName =
          trialDisplayNameList?.[currentSelectedStage - 1]?.find((ele: any) => ele?.value === ingredient)?.label ||
          modelConfigData?.[currentSelectedStage - 1]?.display_names?.ingredients?.[ingredient]
        const label = multstageIngName ?? versionData?.display_names?.ingredients?.[ingredient] ?? ingredient
        return {
          value: ingredient,
          label: label
        }
      })
  }, [selectedIngredients, ingredientsInputs, currentSelectedStage, trialDisplayNameList, modelConfigData, versionData?.display_names?.ingredients])

  const ingredientsCategoryOptions = useMemo(() => {
    return (groupedIngredientOptions || []).map((group: any) => {
      return {
        label: group.label,
        options: group.options
          .map((option: any) => {
            const ingredient = option.value
            const multstageIngName =
              trialDisplayNameList?.[currentSelectedStage - 1]?.find((ele: any) => ele?.value === ingredient)?.label ||
              modelConfigData?.[currentSelectedStage - 1]?.display_names?.ingredients?.[ingredient]
            const label = multstageIngName ?? versionData?.display_names?.ingredients?.[ingredient] ?? ingredient

            return {
              value: option.value,
              label: label
            }
          })
          .filter((option: any) => !selectedIngredients.includes(option.value))
      }
    })
  }, [
    currentSelectedStage,
    groupedIngredientOptions,
    modelConfigData,
    selectedIngredients,
    trialDisplayNameList,
    versionData?.display_names?.ingredients
  ])

  const processingOptions = useMemo(() => {
    return Object.keys(processingInputs?.[currentSelectedStage] || {})
      .map((processing: any) => {
        const label = displayNames?.processing?.[processing]?.name ?? processing
        return {
          value: processing,
          label: label
        }
      })
      .filter((option: any) => !selectedProcessing.includes(option.value))
  }, [currentSelectedStage, displayNames?.processing, processingInputs, selectedProcessing])

  // Fuzzy mapping parameters

  useEffect(() => {
    const ingredientsOptionToFuse = Object.keys(ingredientsInputs?.[currentSelectedStage] || {}).map((ingredient: any) => {
      const multstageIngName =
        trialDisplayNameList?.[currentSelectedStage - 1]?.find((ele: any) => ele?.value === ingredient)?.label ||
        modelConfigData?.[currentSelectedStage - 1]?.display_names?.ingredients?.[ingredient]
      const label = multstageIngName ?? versionData?.display_names?.ingredients?.[ingredient] ?? ingredient
      return {
        value: ingredient,
        label: label
      }
    })

    const categoryIngredientsOptionToFuse = (groupedIngredientOptions || [])
      .map((group: any) => {
        return group.options.map((option: any) => {
          const ingredient = option.value
          const multstageIngName =
            trialDisplayNameList?.[currentSelectedStage - 1]?.find((ele: any) => ele?.value === ingredient)?.label ||
            modelConfigData?.[currentSelectedStage - 1]?.display_names?.ingredients?.[ingredient]
          const label = multstageIngName ?? versionData?.display_names?.ingredients?.[ingredient] ?? ingredient

          return {
            value: option.value,
            label: label
          }
        })
      })
      .flat()

    const processingOptionToFuse = Object.keys(processingInputs?.[currentSelectedStage] || {}).map((processing: any) => {
      const label = displayNames?.processing?.[processing]?.name ?? processing
      return {
        value: processing,
        label: label
      }
    })

    const fuse = new Fuse([...(hasCategories ? categoryIngredientsOptionToFuse : ingredientsOptionToFuse), ...processingOptionToFuse], {
      keys: ['label'],
      threshold: 0.1
    })

    setRowToParameterMap((prev: any) => {
      const newState = { ...prev }
      if (currentStageSheetName) {
        const currentStageSheetData = newState[currentStageSheetName]
        Object.keys(currentStageSheetData).forEach((type) => {
          const parameters = currentStageSheetData[type]

          let fuzzyMappedIngredientsCount = 0
          let fuzzyMappedProcessingCount = 0

          const totalCategoryIngredients = (groupedIngredientOptions || [])
            .map((group: any) => group.options.length)
            .reduce((acc: number, val: number) => acc + val, 0)
          const totalIngredients = hasCategories ? totalCategoryIngredients : Object.keys(ingredientsInputs?.[currentSelectedStage] || {}).length
          const totalProcessing = Object.keys(processingInputs?.[currentSelectedStage] || {}).length

          const usedParameterValues:string[] = []

          Object.keys(parameters).forEach((parameter) => {
            const doFuzzyMapping = parameters[parameter] === ''
            if (doFuzzyMapping) {
              if (type === 'ingredients' && totalIngredients>0 && fuzzyMappedIngredientsCount === totalIngredients) {
                newState[currentStageSheetName][type][parameter] = 'ignore'
                return
              }

              if (type === 'processing' && totalProcessing>0 && fuzzyMappedProcessingCount === totalProcessing) {
                newState[currentStageSheetName][type][parameter] = 'ignore'
                return
              }

              let result = fuse.search(parameter)

              result = result.filter((res) => !usedParameterValues.includes(res.item.value))

              if (result.length > 0) {
                newState[currentStageSheetName][type][parameter] = result[0].item.value

                usedParameterValues.push(result[0].item.value)

                if (type === 'ingredients') {
                  fuzzyMappedIngredientsCount += 1
                }

                if (type === 'processing') {
                  fuzzyMappedProcessingCount += 1
                }
              }
            }
          })
        })
      }

      return newState
    })
  }, [
    currentSelectedStage,
    currentStageSheetName,
    displayNames?.processing,
    groupedIngredientOptions,
    ingredientsInputs,
    modelConfigData,
    processingInputs,
    setRowToParameterMap,
    trialDisplayNameList,
    versionData?.display_names?.ingredients,
    hasCategories
  ])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        width: '100%',
        height: '100%'
      }}
    >
      {isMultiStageModel && (
        <Segmented
          options={stagesOptions}
          value={currentSelectedStage}
          onChange={(value: any) => {
            if (!Object.keys(modelConfigData[value - 1] || {}).length) {
              dispatch(
                modelsConfigRequest({
                  version: modelVersion,
                  isMultiStage: isMultiStageModel,
                  stage_name: modelConfigData?.[0]?.all_stages?.[value - 1]
                })
              )
            }
            setCurrentSelectedStage(value as number)
          }}
          style={{
            width: 'fit-content'
          }}
        />
      )}
      <div
        style={{
          display: 'flex',
          width: '100%',
          gap: '4%'
        }}
      >
        {Object.keys(currentStageSheetData).map((type: string) => {
          const parameters = currentStageSheetData[type]

          return (
            <IngredientTable
              type={type}
              parameters={parameters}
              currentSelectedStage={currentSelectedStage}
              setRowToParameterMap={setRowToParameterMap}
              trialDisplayNameList={trialDisplayNameList}
              modelConfigData={modelConfigData}
              versionData={versionData}
              displayNames={displayNames}
              hasCategories={hasCategories}
              ingredientsCategoryOptions={ingredientsCategoryOptions}
              ingredientOptions={ingredientOptions}
              currentStageSheetName={currentStageSheetName}
              key={type}
              processingOptions={processingOptions}
            />
          )
        })}
      </div>
    </div>
  )
}

export default MapParametersStep

const IngredientTable = ({
  type,
  parameters,
  currentSelectedStage,
  setRowToParameterMap,
  trialDisplayNameList,
  modelConfigData,
  versionData,
  displayNames,
  hasCategories,
  ingredientsCategoryOptions,
  ingredientOptions,
  currentStageSheetName,
  processingOptions
}: {
  type: string
  parameters: any
  currentSelectedStage: number
  setRowToParameterMap: any
  trialDisplayNameList: any
  modelConfigData: any
  versionData: any
  displayNames: any
  hasCategories: boolean
  ingredientsCategoryOptions: any
  ingredientOptions: any
  currentStageSheetName: string | undefined
  processingOptions: any
}) => {
  const [t] = useTranslate()

  const columns = [
    {
      title: type === 'ingredients' ? t('common.ingredients') : t('common.processing'),
      dataIndex: 'parameterName',
      key: 'parameterName',
      width: '40%'
    },
    {
      title: t('mapcolumn.columnsMappedTo'),
      dataIndex: 'parameterMappedTo',
      key: 'parameterMappedTo',
      width: '60%',
      render: (text: any, record: any) => {
        const parameter = record.key

        return (
          <Select
            style={{ width: '100%' }}
            value={
              type === 'ingredients'
                ? trialDisplayNameList?.[currentSelectedStage - 1]?.find((ele: any) => ele?.value === parameters[parameter])?.label ||
                  modelConfigData?.[currentSelectedStage - 1]?.display_names?.ingredients?.[parameters[parameter]] ||
                  versionData?.display_names?.ingredients?.[parameters[parameter]] ||
                  displayNames?.ingredients?.[parameters[parameter]]?.name ||
                  parameters[parameter]
                : displayNames?.processing?.[parameters[parameter]]?.name || parameters[parameter]
            }
            {...(parameters[`polymerize_custom_error_${parameter}`] ? { status: 'error' } : {})}
            options={[
              ...(type === 'ingredients' ? (hasCategories ? ingredientsCategoryOptions : ingredientOptions) : processingOptions),

              {
                value: 'ignore',
                label: t('common.Ignore')
              }
            ]}
            allowClear
            onChange={(value) => {
              setRowToParameterMap((prevState: any) => {
                const newState = { ...prevState }
                if (currentStageSheetName) {
                  newState[currentStageSheetName][type][parameter] = value
                  delete newState[currentStageSheetName][type][`polymerize_custom_error_${parameter}`]
                }
                return newState
              })
            }}
            onClear={()=>{
              setRowToParameterMap((prevState: any) => {
                const newState = { ...prevState }
                if (currentStageSheetName) {
                  newState[currentStageSheetName][type][parameter] = ''
                  delete newState[currentStageSheetName][type][`polymerize_custom_error_${parameter}`]
                }
                return newState
              })
            }}
          />
        )
      }
    }
  ]

  const data = Object.keys(parameters)
    .map((parameter: string) => {
      return {
        key: parameter,
        parameterName: parameter,
        parameterMappedTo: parameters[parameter]
      }
    })
    .filter((item) => !item.parameterName.includes('polymerize_custom_error_'))

  return (
    <Table
      columns={columns}
      className="forward_prediction_upload_flow_table"
      dataSource={data}
      pagination={false}
      bordered={false}
      style={{ width: '36%' }}
    />
  )
}
