import { useEffect, useState } from "react"
import { Col, Divider, Form, Modal, Radio, Row, Select, Space, Typography } from "antd";
import { useForm } from "antd/lib/form/Form"
import useTranslate from "src/utils/useTranslate"
import { ModelConfig } from "src/typings"
import { useHistory } from "react-router-dom"
import { useQuery } from "src/utils/useQuery"
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import {
	modelsConfigCleanup,
	modelsConfigRequest,
} from "src/store/actions/formulate"
import { StyledButton } from "src/styled_components/StyledButton"
import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import './use-custom-model-form.scss'
const { Text } = Typography

type P = {
	data: ModelConfig[]
	ingredientsParameterList: string[]
	ingredientsDataList: any[]
	selectionState?: any
	setIsMultiStageModel: any
	isMultiStageModel: boolean
	isUploadMode: boolean
	setIsUploadMode: any
	clearState: any
}

export const UseCustomForm = ({
	data,
	ingredientsParameterList,
	ingredientsDataList,
	selectionState,
	setIsMultiStageModel,
	isMultiStageModel,
	isUploadMode,
	setIsUploadMode,
	clearState
}: P) => {
	const [t] = useTranslate()
	const [form] = useForm()
	const [focused, setFocused] = useState(false)
	const { push } = useHistory()
	const dispatch = useDispatch()

	const companyId = useSelector(
		(state: StoreState) => state.login.loginResponse.company_id,
	)
	const workOrders = useSelector((state: StoreState) => state.workOrders.data)

	let query = useQuery()
	let modelVersion = query?.get("version")
	const configData = useSelector(
		(state: StoreState) => state.formulate.configData,
	) as ModelConfig[]

	const [userSelection, setUserSelection] = selectionState

	const [isMultistage, setIsMultistage] = useState<boolean>(false)

	useEffect(() => {
		setIsMultistage(
			configData?.find(
				(modelData: any) => modelData?.version === Number(modelVersion),
			)?.is_multistage || false,
		)
	}, [configData, isMultistage, modelVersion])

	const onCheck = (e: any) => {
		setUserSelection(e.target.value)
		if (e.target.value === "version") {
			if (
				ingredientsParameterList.some((res) => !!res) ||
				ingredientsDataList.some((res) =>
					Object.values(res).some((value) => !!value),
				)
			) {
				Modal.confirm({
					okText: t("common.ok"),
					cancelText: t("common.cancel"),
					title: `${t("common.unsavedChangesLost")}`,
					onOk: () =>
						!e.target.checked
							? push("/ai-engine/predict/properties_prediction")
							: push(
								`/ai-engine/predict/properties_prediction?version=${configData[0].version}`,
							),
				})
			} else {
				if (!e.target.checked) push("/ai-engine/predict/properties_prediction")
				else
					push(
						`/ai-engine/predict/properties_prediction?version=${configData[0].version}`,
					)
			}
		} else {
			push("/ai-engine/predict/properties_prediction")
		}
	}

	useEffect(() => {
		if (!Boolean(modelVersion) && !!configData?.length)
			push(
				`/ai-engine/predict/properties_prediction?version=${configData[0].version}`,
			)
	}, [configData, modelVersion, push])

	useEffect(() => {
		if (!!data.length && Number(modelVersion)) {
			const currentModelData = data.find((config) => config.version === Number(modelVersion))
			dispatch(
				modelsConfigRequest({
					version: modelVersion,
					...(currentModelData?.is_multistage && {
						isMultiStage: currentModelData?.is_multistage,
						stage_name: currentModelData?.all_stages?.[0] ?? "Stage 1",
					}),
				}),
			)
		}
	}, [dispatch, data, modelVersion])

	return (
		<>
			{companyId === "COMP140MGVSEQE2023" ? (
				<Form form={form}>
					{data.filter((res) => Boolean(res.is_custom)).length > 0 && (
						<Form.Item className="use-custom-form-item">
							<Radio.Group onChange={onCheck} value={userSelection}>
								{/* <Radio value="workOrder">Use Domain Model</Radio>
								<Radio value="version">{t("aiEngine.useCustomModel")}</Radio> */}
							</Radio.Group>
						</Form.Item>
					)}

					{Boolean(modelVersion) && (
						<Form.Item
							className="use-custom-form-item"
							label={t("aiEngine.selectModel")}
						>
							<Select
								value={Number(modelVersion)}
								style={{ maxWidth: 400, minWidth: 200 }}
								onDropdownVisibleChange={setFocused}
								onSelect={(version: number) =>
									push(
										`/ai-engine/predict/properties_prediction?version=${version}`,
									)
								}
								showSearch
								optionFilterProp="children"
								open={focused}
								dropdownRender={(menu) => {
									return (
										<>
											{menu}
											<Divider />
											<Row justify="center" style={{ paddingBottom: 10 }}>
												<Col>
													<StyledButton
														onClick={() => {
															push("/ai-engine/new_model/custom_ml")
															setFocused(false)
														}}
														type="primary"
													>
														{t("forwadModal.createCustomMl")}
													</StyledButton>
												</Col>
											</Row>
										</>
									)
								}}
							>
								{data
									.filter((res) => Boolean(res.is_custom))
									.map((res) => (
										<Select.Option key={res.version} value={res.version}>
											{`${res.objective} (${res.comments})`}
										</Select.Option>
									))}
							</Select>
						</Form.Item>
					)}
					{userSelection === "workOrder" && (
						<Form.Item
							className="use-custom-form-item"
							label="Select WorkOrder"
						>
							<Select
								style={{ maxWidth: 400, minWidth: 200 }}
								onDropdownVisibleChange={setFocused}
								showSearch
								placeholder="Select WorkOrder"
								optionFilterProp="children"
								mode="multiple"
								onChange={(work_order_id) => {
									if (work_order_id?.length)
										dispatch(
											modelsConfigRequest({ work_order_ids: work_order_id }),
										)
									else dispatch(modelsConfigCleanup())
								}}
								open={focused}
								options={workOrders.map(
									({ work_order_name, work_order_id }) => ({
										label: work_order_name,
										value: work_order_id,
									}),
								)}
							/>
						</Form.Item>
					)}
				</Form>
			) : (
				<Form form={form} style={{
					width:'100%',
					display:'flex',
					justifyContent:'space-between',
					alignItems:'center',
				}} >
					<Space
						direction="horizontal"
						style={{ alignItems: "normal" }}
						size={"middle"}
					>
						{Boolean(modelVersion) && (
							<>
								<Space >
									<Radio.Group
										value={isMultistage}
										onChange={(e) => {
											setIsMultistage(Boolean(e.target.value))
											push(
												`/ai-engine/predict/properties_prediction?version=${configData.filter(
													({ is_multistage, is_custom }: any) =>
														is_custom &&
														(e.target.value ? is_multistage : !is_multistage),
												)?.[0].version
												}`,
											)
										}}
										style={{height:'2rem'}}
									>
										<Radio
											value={false}
											disabled={
												!Boolean(
													configData.filter(
														(modelData: any) =>
															modelData.is_custom && !modelData.is_multistage,
													).length,
												)
											}
										>
											{t("aiEngine.singleStage")}
										</Radio>
										<Radio
											value={true}
											disabled={
												!Boolean(
													configData.filter(
														(modelData: any) =>
															modelData.is_custom && modelData.is_multistage,
													).length,
												)
											}
										>
											{t("aiEngine.multiStage")}
										</Radio>
									</Radio.Group>
								</Space>

								<Form.Item className="use-custom-form-item">
									<Space>
										<Text strong style={{ wordBreak: "normal" }}>
											{t("aiEngine.selectModel")}:{" "}
										</Text>
										<Select
											value={Number(modelVersion)}
											style={{ maxWidth: 250, minWidth: 250 }}
											onDropdownVisibleChange={setFocused}
											onSelect={(version: number) => {
												push(
													`/ai-engine/predict/properties_prediction?version=${version}`,
												)
											}}
											showSearch
											optionFilterProp="label"
											filterOption={true}
											open={focused}
											dropdownRender={(menu) => {
												return (
													<>
														{menu}
														<Divider />
														<Row justify="center" style={{ paddingBottom: 10 }}>
															<Col>
																<StyledButton
																	onClick={() => {
																		push("/ai-engine/new_model/custom_ml")
																		setFocused(false)
																	}}
																	type="primary"
																>
																	{t("forwadModal.createCustomMl")}
																</StyledButton>
															</Col>
														</Row>
													</>
												)
											}}
										>
											{data
												.filter(
													(modelData: any) =>
														modelData.is_custom &&
														(isMultistage
															? modelData.is_multistage
															: !modelData.is_multistage),
												)
												.map((res) => (
													<Select.Option
														key={res.version}
														value={res.version}
														label={`${res.objective} (${res.comments})`}
													>
														<div
															style={{
																display: "flex",
																gap: "0.25rem",
																justifyContent: "space-between",
																alignItems: "center",
																height: "100%",
															}}
														>
															<Text
																ellipsis={{ tooltip: true }}
																style={{ margin: 0, maxWidth: "95%" }}
															>
																{`${res.objective} (${res.comments})`}
															</Text>
														</div>
													</Select.Option>
												))}
										</Select>
									</Space>
								</Form.Item>
							</>
						)}

						
					</Space>
					<StyledButton
							type="primary"
							onClick={() => {
								if(isUploadMode){
									Modal.confirm({
										title: t('common.unsavedChanges'),
										content: t('common.modal.unsavedwarning'),
										cancelText: t('common.no'),
										okText: t('common.yes'),
										okCancel: true,
										onOk: () => {
											clearState()
											setIsUploadMode(false)
											Modal.destroyAll()
										},
										onCancel: () => {
											Modal.destroyAll()
										},
										okButtonProps: {
											style: {
												outline: 'none',
												flexGrow: 1
											}
										},
										cancelButtonProps: {
											style: {
												outline: 'none',
												flexGrow: 1
											}
										},
										styles: {
											mask: {
												backgroundColor: 'rgba(0, 0, 0, 0.85)'
											},
											content: {
												padding: '1.5rem'
											}
										},
										footer: (_, { OkBtn, CancelBtn }) => (
											<div
												style={{
													display: 'flex',
													width: '100%'
												}}
											>
												<CancelBtn />
												<OkBtn />
											</div>
										),
										width: '350px',
										maskClosable: true,
										className: 'forward-upload-close-modal',
										icon: null
									})
								}else{
									setIsUploadMode(true)
								}
							}}
							danger={isUploadMode}
							icon={isUploadMode ? <CloseOutlined/> : <UploadOutlined/>}
						>
							{isUploadMode ? t("common.cancelImport") : t("dataManagement.importData")}
							</StyledButton>
				</Form>
			)}
		</>
	)
}
