import { Layout, Space, Typography, Alert } from 'antd';
import { useDispatch, useSelector, } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { forgotPasswordClear, forgotPasswordRequest } from 'src/store/actions/forgotPassword';
import LeftSideOnboarding from "../SignUp/LeftSideOnboarding";
import "../SignUp/SignUp.scss";
import AllRightsReserved from '../SignUp/AllRightsReserved';
import { StoreState } from 'src/store/configureStore';
import { useEffect } from 'react';
import { StyledButton } from 'src/styled_components/StyledButton';
import useTranslate from 'src/utils/useTranslate';


const { Content: LayoutContent } = Layout
const { Title } = Typography


export const AfterForgotPassword = () => {
  const [t] = useTranslate()
  const { state: email_id }: any = useLocation()
  const errorMsg = useSelector<StoreState, string>((state) => state.forgotPassword.error)
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(forgotPasswordClear())
    }
  }, [dispatch])

  return (
    <Layout
      style={{ textAlign: "center", minHeight: "100%", background: "white" }}
      className="fontWorkSans"
    >
      <LayoutContent style={{ overflow: "auto", position: "relative" }}>
        <Space style={{ width: "100%" }} direction="horizontal" size="large">
          <LeftSideOnboarding minWidth="55vw" />
          <Space direction="vertical" style={{ maxWidth: "40vw" }}>
            <Space direction="vertical" style={{ margin: "48px", textAlign: "left" }} size={'large'} >
              <Title
                level={1}
                style={{
                  fontSize: "2.5rem",
                  fontWeight: 500,
                  color: "#1753E2",
                  marginBottom: 40,
                  textAlign: "center",
                }}
              >
                {t('afterForgorPassword.title')}
              </Title>
                
                  <Title
                    level={4}
                  >
                    {t('afterForgorPassword.sentence1')}
                    <strong style={{ color: "#EF8129" }}>
                      {email_id?.user_email}
                    </strong>
                    {t('afterForgorPassword.sentence2')}
                  </Title>
                  <Title
                    level={4}
                  >
                    {t('afterForgorPassword.sentence3')}
                  </Title>

                  {!!errorMsg && !!email_id && (
                    <Alert
                      message={errorMsg}
                      type="error"
                      style={{
                        backgroundColor: "#CF1322",
                        color: "white",
                        textAlign: "left",
                        display: "block",
                      }}
                      className="error-alert-msg"
                    />
                  )}

                  <StyledButton
                    style={{
                      backgroundColor: !(!email_id || (errorMsg && email_id)) ? "#1B55CE" : undefined,
                      width: "100%",
                      borderRadius: "7.5px",
                      minHeight: "50px",
                    }}
                    disabled={!email_id || (!!errorMsg && !!email_id)}
                    size="large"
                    type="primary"
                    htmlType="submit"
                    onClick={() => dispatch(forgotPasswordRequest(email_id))}
                  >
                    {t('afterForgorPassword.resendInstructions')}
                  </StyledButton>
                <Space
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Title
                    level={4}
                    style={{
                      textAlign: "center",
                      display: "block",
                    }}
                  >
                    <Link to="/login" style={{ color: "#0062FF" }}>
                      {t('common.backToLogin')}
                    </Link>
                  </Title>
                  <Title
                    level={4}
                    style={{
                      textAlign: "left",
                      display: "block",
                    }}
                  >
                    {t('userVerified.needHelp')}
                    <a
                      style={{ color: "#007AFF" }}
                      href="mailto: contact@polymerize.io"
                    >
                      {t('common.contactSupport')}
                    </a>
                  </Title>
                </Space>
            </Space>
            <AllRightsReserved />
          </Space>
        </Space>
      </LayoutContent>
    </Layout>
  );
}
