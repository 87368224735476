import { Space, Tag } from "antd"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import useTranslate from "src/utils/useTranslate"

const DetailsBar = () => {
  const [t] = useTranslate()

  const { getMetadataResponse } = useSelector((state: StoreState) => state.qualityCheck)

  const details = useMemo(
    () => [
      {
        key: t('common.Supplier'),
        value: getMetadataResponse?.supplier_name
      },
      {
        key: t('qc.productName'),
        value: getMetadataResponse?.product_name
      },
      {
        key: t('common.Category'),
        value: getMetadataResponse?.category_name
      }
    ],
    [getMetadataResponse?.category_name, getMetadataResponse?.product_name, getMetadataResponse?.supplier_name, t]
  )

  return (
    <Space
      style={{
        padding: '0rem 1.5rem'
      }}
    >
      {details.map((detail: any, idx: number) => {
        return (
          <Tag
            key={idx}
            style={{
              padding: '0.5rem 1rem',
              backgroundColor: '#F5F5F5',
              border: 'none',
              fontWeight: 500
            }}
          >
            {`${detail.key}: `}
            <span
              style={{
                fontWeight: 700
              }}
            >
              {detail.value}
            </span>
          </Tag>
        )
      })}
    </Space>
  )
}
export default DetailsBar