import { useState, useEffect } from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Space, Spin, Modal, Row, Typography, Input, Col, Table, Badge, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { notificationReadRequest, notificationsRequest } from "src/store/actions/notifications";
import { StoreState } from "src/store/configureStore";
import "./Notifications.scss";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import useTranslate from "src/utils/useTranslate";
import { inverseNotificationClick } from "src/store/actions/inverseModel";
import { AsyncStates } from "src/constants";
import { LoadingOutlined } from "@ant-design/icons";
import { resetWorkOrder } from "src/store/actions/workOrderDetails";
import { verifyOtpTrueRequest } from "src/store/actions/login";
import QRCode from "react-qr-code";
import { StyledPageHeader } from "src/styled_components/StyledPageHeader";
import { StyledCard } from "src/styled_components/StyledCard";
import { StyledButton } from "src/styled_components/StyledButton";
import { MarkAllRead } from "./mark-all-read";
import relativeTime from "dayjs/plugin/relativeTime";
import { useNotifySocket } from "src/utils/hooks/useNotifySocket";
import { useToken } from "src/utils/hooks/useToken";
import { AvatarComponent } from "../DashboardUpdated/Dashboard";

dayjs.extend(relativeTime);
const { Title, Text } = Typography;

export const Notifications = () => {
  const [t] = useTranslate();
  const dispatch = useDispatch();
  const notificationsData = useSelector(
    (state: StoreState) => state.notifications.data.notifications
  );
  const userId = useSelector(
    (state: StoreState) => state.login.loginResponse.user_id
  );
  const status = useSelector((state: StoreState) => state.notifications.status);
  const notificationData = useSelector((state: StoreState) => state.notifications.data.notifications);
  const {
    verifyOtpTrueStatus,
    loginResponse: { two_fa, otp },
  } = useSelector((state: StoreState) => state.login);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const { push } = useHistory();
  const [notifications, setNotifications] = useState<any>(notificationsData);

  const [selectedFilter, setSelectedFilter] = useState<'all' | 'unread'>("all");

  useEffect(() => {
    if (selectedFilter === "all") {
      setNotifications(notificationData)
    } else if (selectedFilter === "unread") {
      setNotifications(notificationData.filter(({ is_read }: any) => !is_read))
    } else {
      setNotifications(notificationData)
    }
  }, [notificationData, selectedFilter])

  useEffect(() => {
    dispatch(notificationsRequest());
  }, [dispatch])

  const setRead = (key: string | string[]) => {
    const currentMessage = notifications.find(
      ({ notification_id }: any) => key.includes(notification_id)
    );
    if (currentMessage === undefined) {
      return;
    }
    if (!currentMessage?.is_read) {
      dispatch(notificationReadRequest(key));
    }
    if(currentMessage?.model_version) {
      push(`/model-details?version=${currentMessage.model_version}`);
    }
    if(currentMessage?.work_order_id) {
      dispatch(resetWorkOrder());
      push(`/work-orders/details/${currentMessage.work_order_id}/`);
    }
    if(currentMessage?.download_link) {
      window.open(currentMessage.download_link, "_blank");
    }
    if (currentMessage?.notification_type === "inverse_prediction") {
      dispatch(inverseNotificationClick(true));
      push(`/ai-engine/history/formulation_prediction?predId=${currentMessage.ref_id}`);
    }
    if (currentMessage?.notification_type === "forward_prediction") {
      dispatch(inverseNotificationClick(true));
      push(`/ai-engine/history/properties_prediction?predId=${currentMessage.ref_id}`);
    }
    if (currentMessage?.notification_type === "customML") {
      push("/ai-engine/new_model/custom_ml");
    }
    if (currentMessage?.notification_type === "2fa_status" && two_fa && !otp) {
      setModalVisible(true);
    }
  };

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    if (verifyOtpTrueStatus === AsyncStates.SUCCESS) {
      setModalVisible(false);
    }
  }, [verifyOtpTrueStatus]);

  useEffect(() => {
    const interval = setInterval(() => {
      setNotifications([...notifications]);
    }, 60000);
    return () => clearInterval(interval);
  }, [notifications]);

  const tokenData = useToken()
  const socket = useNotifySocket("notify", tokenData)

  useEffect(() => {
    const onNewNotification = (notification: any) => {
      setNotifications((state: any) => [notification?.data, ...state])
    }
    socket?.on("notification", onNewNotification)

    return () => {
      socket?.off("notification", onNewNotification)
      socket?.disconnect()
    }
  }, [socket])

  const tableColumns = [
    {
      dataIndex: 'message',
      key: 'message',
      render: (text: any, record: any) => {
        return (
          <div
            style={{
              display: 'flex',
              gap:'2rem',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div style={{
              flexGrow:1,
              display: 'flex',
              gap:'1rem',
              alignItems: 'center',
            }} >
              <Badge
                dot={!record?.is_read}
                styles={{
                  root: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }
                }}
              >
                <AvatarComponent id={record.sender} />
              </Badge>

              <Text
                ellipsis={{
                  tooltip: true
                }}
                strong={!record?.is_read}
                style={{
                 maxWidth: '600px',
                  ...(!record?.is_read ? { fontWeight: 'bold' } : {})
                }}
              >
                {
                  userId === record?.sender
                  ? record?.message?.senders_message ?? record?.message?.receivers_message
                  : record?.message?.receivers_message
                }
                {record.message?.receivers_message}
              </Text>

              <Text italic type="secondary" style={{
                maxWidth: '100px',
                paddingRight:'0.5rem'
              }}
              ellipsis={{
                tooltip: true
              }}
              >
                {record.message?.body}
              </Text>
            </div>

            <div style={{
              display: 'flex',
              gap:'1rem',
              alignItems: 'center',
              width: '12rem',
            }} >
              <Text
              style={{
                ...(!record?.is_read ? { fontWeight: 'bold' } : {}),
                width:'8rem'
              }}
            >
              {dayjs(record.created).fromNow()}
            </Text>

            <StyledButton
              style={{
                border: 'none',
                display: !record?.is_read ? 'block' : 'none',
                width:'3rem'
              }}
              icon={<CheckCircleOutlined />}
              onClick={(e) => {
                e.stopPropagation()
                dispatch(notificationReadRequest(record.notification_id))
              }}
              type="text"
              className="notification-read-button"
            />
            </div>
          </div>
        )
      },
      width:'100%'
    },
  ]

  return (
    <Space direction="vertical" style={{ width: "100%", gap:0 }}>
      <StyledPageHeader
        title={t("navBar.notifications")}
        extra={
          <MarkAllRead
            notifications={notifications}
            notificationStatus={status}
          />
        }
        ghost={false}
      />

      <Spin
        indicator={<LoadingOutlined />}
        spinning={status === AsyncStates.LOADING}
      >
        <StyledCard>
          <Space size={'small'} direction="vertical" style={{width:'100%'}} >
            <Tabs 
              defaultActiveKey="all"
              style={{
                width: '100%',
              }}
              items={[
                {
                  key: 'all',
                  label: t("common.all"),
                },
                {
                  key: 'unread',
                  label: t('common.unread'),
                }
              ]}
              activeKey={selectedFilter}
              onChange={(key) => setSelectedFilter(key as 'all' | 'unread')}
            />
          
          <Table
            columns={tableColumns}
            dataSource={notifications}
            rowKey="notification_id"
            pagination={{
              pageSize: 10,
              showSizeChanger: false,
              style:{
                position:'absolute',
                margin: '1rem 0rem',
                top: `${10*3+10*0.75}rem`,
                right: '0rem',
              },
            }}
            showHeader={false}
            onRow={(record) => {
              return {
                onClick: () => {
                  setRead(record.notification_id)
                },
              }
            }}
            virtual
            className="notification-table"
            bordered={false}
            style={{
              width: '100%',
            }}
          />
          <TwoFactorAuthModal modalVisible={modalVisible} setModalVisible={setModalVisible} />
          </Space>
        </StyledCard>
      </Spin>
    </Space>
  );
};


export const TwoFactorAuthModal = ({
  modalVisible,
  setModalVisible,
}: {
  modalVisible: boolean;
  setModalVisible: (value: boolean) => void;
}) => {
  const [t] = useTranslate();
  const dispatch = useDispatch();
  const {
    requestOtpData,
    verifyOtpTrueStatus,
  } = useSelector((state: StoreState) => state.login);
  const [otpValue, setOtpValue] = useState<string>("");

  const codeChange = (e: any) => {
    if (e.target.value?.length < 7) {
      setOtpValue(e.target.value);
    }
  };

  return (
    <Modal
    okText={t("common.ok")}
    cancelText={t("common.cancel")}
    visible={modalVisible}
    onCancel={() => setModalVisible(false)}
    footer={null}
    width={800}
  >
    <Spin
      indicator={<LoadingOutlined />}
      spinning={verifyOtpTrueStatus === AsyncStates.LOADING}
    >
      <Row justify="space-between" style={{ padding: 30 }}>
        <Col span={12} style={{ minWidth: 300, marginBottom: 50 }}>
          <Space direction="vertical">
            <QRCode value={requestOtpData} />
            <Input
              addonBefore={<Text strong>{t("common.code")}</Text>}
              value={otpValue}
              onChange={codeChange}
              placeholder={t("common.enterCode")}
              size="large"
              type="number"
              maxLength={6}
            />
            <StyledButton
              style={{ width: "100%" }}
              disabled={otpValue?.length !== 6}
              type="primary"
              onClick={() => {
                setOtpValue("");
                dispatch(verifyOtpTrueRequest({ otp: otpValue }));
              }}
            >
              {"Verify"}
            </StyledButton>
          </Space>
        </Col>
        <Col span={12}>
          <Space direction="vertical">
            <Title level={5}>{t("common.steps")}</Title>
            <Text type="secondary" strong>
              {t("common.authenticatorApp")}
            </Text>
            <Text type="secondary" strong>
              {t("common.QRCodeverification")}
            </Text>
          </Space>
        </Col>
      </Row>
    </Spin>
  </Modal>
  )
}