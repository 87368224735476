import React, { Dispatch } from "react"
import { StyledCard } from "src/styled_components/StyledCard"
import { ScrollToTopInDrawer } from "src/components/Common"
import { Checkbox, Descriptions, List as AntdList, Popconfirm, Typography, Tooltip } from "antd"
import { GetSavedFiltersResponse } from "src/store/sagas/saveFormulationsFilters"
import { StyledButton } from "src/styled_components/StyledButton"
import useTranslate from "src/utils/useTranslate"
import { useMemberName } from "src/utils/useMemberName"
import dayjs from "dayjs"
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

type P = {
	data: GetSavedFiltersResponse["result"]["data"]
	applyFilter: (item: GetSavedFiltersResponse["result"]["data"][0]) => void
	drawerRef: React.RefObject<HTMLDivElement>
	selectedFilters: string[]
	setSelectedFilters: Dispatch<React.SetStateAction<string[]>>
}

export const List = ({
	data,
	applyFilter,
	drawerRef,
	selectedFilters,
	setSelectedFilters,
}: P) => {
	const [t] = useTranslate()
	const { getName } = useMemberName()

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				gap: "25px",
			}}
		>
			<AntdList
				pagination={{
					pageSize: 10,
					showSizeChanger: false,
					responsive: true,
					position: "top",
					total: data.length,
				}}
				size="small"
				grid={{ column: 1, gutter: 16 }}
				dataSource={data}
				renderItem={(item) => (
					<AntdList.Item style={{ marginTop: 10 }}>
						<StyledCard
							size="small"
							title={
								<div
									style={{
										display: "flex",
										alignItems: "center",
										gap: "10px",
									}}
								>
									<Checkbox
										onChange={(e) => {
											if (e.target.checked) {
												setSelectedFilters((prev) => [...prev, item.filter_id])
											} else {
												setSelectedFilters((prev) =>
													prev.filter((id) => id !== item.filter_id)
												)
											}
										}}
										checked={selectedFilters.includes(item.filter_id)}
									>
										<Typography.Title level={4} style={{ userSelect: "none" }}>
											{String(item?.name || "").length > 40 ? <Tooltip title={item.name}>{String(item?.name || "").substring(0, 40)}...</Tooltip> : <>{item.name}</>}
										</Typography.Title>
									</Checkbox>
								</div>
							}
							extra={
								<Popconfirm
									okText={t("common.ok")}
									cancelText={t("common.cancel")}
									title={t('common.applyThisFilter')}
									onConfirm={() => applyFilter(item)}
								>
									<StyledButton
										type="link"
										size="middle"
										style={{
											cursor: "pointer",
										}}
									>
										{t('common.applyFilter')}
									</StyledButton>
								</Popconfirm>
							}
						>
							<Descriptions
								column={1}
								bordered
								size="small"
								style={{ background: "white" }}
							>
								<Descriptions.Item label={t("common.description")}>
									{item.description}
								</Descriptions.Item>
								<Descriptions.Item label={t("aiEngine.user")}>
									{getName(item.user_id)}
								</Descriptions.Item>
								{item.created && (
									<Descriptions.Item label={t("common.createdOn")}>
										{dayjs.utc(item.created).format("MMMM Do YYYY, h:mm A") +
											" (UTC)"}
									</Descriptions.Item>
								)}
							</Descriptions>
						</StyledCard>
					</AntdList.Item>
				)}
			/>
			{data.length > 5 && <ScrollToTopInDrawer sectionRef={drawerRef} />}
		</div>
	)
}
