import { Card, Col, Collapse, Form, Input, message, Row, Space, Typography } from "antd";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Font,
  Image,
  pdf,
} from "@react-pdf/renderer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { CheckOutlined, CloseOutlined, EditOutlined, MinusCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { StyledButton } from "src/styled_components/StyledButton";
import { ReportSimpleEditor } from "./components/ReportSimpleEditor";
import { antdTheme, AsyncStates } from "src/constants";
import { reportUploadRequest, templateCreateCleanup, templateCreateRequest, templateDeleteRequest, templateUpdateRequest } from "src/store/actions/compareFormulations";
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import useTranslate from "src/utils/useTranslate";
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon";
dayjs.extend(isSameOrAfter);

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    margin: 10,
    padding: 10,
  },
  header: {
    textAlign: "center",
    marginTop: 40,
  },
  section: {
    paddingRight: 25,
    marginTop: 40,
  },
  index: {
    fontFamily: "Open Sans",
    fontWeight: "bold",
    fontSize: antdTheme.fontSize,
    marginRight: 5,
  },
  title: {
    fontFamily: "Open Sans",
    fontWeight: "bold",
    fontSize: antdTheme.fontSizeHeading4,
  },
  subtitle: {
    fontFamily: "Open Sans",
    fontWeight: "bold",
    fontSize: antdTheme.fontSize,
  },
  text: {
    fontFamily: "Open Sans",
    fontWeight: "thin",
    fontSize: antdTheme.fontSize,
    marginLeft: 10,
  },
  image: { position: "absolute", top: 2, right: 16, height: 100, width: 100 },
  listItem: {
    flexDirection: "row",
    marginLeft: 10,
    marginBottom: 5,
    fontFamily: "Open Sans",
    fontWeight: "thin",
    fontSize: antdTheme.fontSize,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#000",
    borderWidth: 1,
    marginBottom: 10,
  },
  row: {
    flexDirection: "row",
  },
  cell: {
    flex: 1,
    borderColor: "#000",
    borderWidth: 1,
    padding: 5,
    fontFamily: "Open Sans",
    fontWeight: "thin",
    fontSize: antdTheme.fontSize,
  },
});

export interface ReportElement {
  type: "section" | "heading" | "text" | "list" | "table";
  label: string;
  editor_content?: any[];
  heading_level: 1 | 2 | 5 | 3 | 4 | undefined;
  key: string;
  children?: ReportElement[]
}

export const Design = ({ templateType, templateCreation }: any) => {
  const dispatch = useDispatch()
  const [t] = useTranslate()
  const logo = useSelector((state: StoreState) => state.login.loginResponse.logo)
  const reportUploadStatus = useSelector((state: StoreState) => state.compareFormulations.uploadStatus)
  const [generate, setGenerate] = useState<boolean>(false);
  const [editorModalOpened, setEditorModalOpened] = useState<boolean>(false);
  const [currentElement, setCurrentElement] = useState<ReportElement | undefined>();
  const [newTemplate, setNewTemplate] = useState<any>({})
  const [elementIndex, setElementIndex] = useState({ parentIndex: -1, childIndex: -1 })
  const { lists, templateDeleteStatus, updateStatus, createStatus, listStatus } = useSelector((state: StoreState) => state.compareFormulations)
  const [templateName, setTemplateName] = useState({ name: "New Template", editFlag: false })
  const [editStatus, setEditStatus] = useState(false);
  const [form] = Form.useForm();
  const [createTemplate, setCreateTemplate] = templateCreation
  const [editTitle, SetEditTitle] = useState(false)
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);


  useEffect(() => {
    if (templateType !== "Create New Template") {
      let template = lists.find(({ template_id }) => template_id === templateType)
      setTemplateName({ name: template?.name, editFlag: false })
      setNewTemplate(template)
      setCreateTemplate(false)
    }
  }, [lists, templateType, listStatus, setCreateTemplate])

  useEffect(() => {
    if (createStatus === AsyncStates.SUCCESS) {
      setEditStatus(false)
      setCreateTemplate(true)
      setTemplateName({ name: "New Template", editFlag: false })
      dispatch(templateCreateCleanup())
    }
  }, [createStatus, createTemplate, dispatch, lists, setCreateTemplate])

  useEffect(() => {
    if (templateType === "Create New Template") {
      setTemplateName({ name: "New Template", editFlag: false })
    }
  }, [templateType, createTemplate])

  useEffect(() => {
    if (newTemplate?.template_id) {
      setCreateTemplate(false)
    }
  }, [newTemplate?.template_id, setCreateTemplate])

  useEffect(() => {
    if (updateStatus === AsyncStates.SUCCESS) {
      setEditStatus(false)
    }
  }, [updateStatus])

  useEffect(() => {
    if (templateDeleteStatus === AsyncStates.SUCCESS) {
      setTemplateName({ name: "New Template", editFlag: false })
      setNewTemplate({})
      setCreateTemplate(true)
      setDeleteModalVisible(false);
    }
  }, [setCreateTemplate, templateDeleteStatus])


  const [fileName, setFileName] = useState<string>("")

  const getPdfRenderElement = (block: any) => {
    switch (block.type) {
      case "paragraph":
        return <Text style={styles.text}>{block.data?.text?.replace("/(|<([^>]+)>)/", '').replace('&nbsp;', '')}</Text>;
      case "table":
        return (
          <View style={styles.table}>
            {block.data.content?.map((row: any, rowIndex: number) => (
              <View key={rowIndex} style={styles.row}>
                {row.map((cell: any, cellIndex: number) => (
                  <View key={cellIndex} style={styles.cell}>
                    <Text>{cell?.replace("/(|<([^>]+)>)/", '').replace('&nbsp;', '')}</Text>
                  </View>
                ))}
              </View>
            ))}
          </View>
        );
      case "list":
        return (
          <View>
            {block.data.items.map((item: string, itemIndex: number) => (
              <View key={itemIndex} style={styles.listItem}>
                <Text>{item?.replace("/(|<([^>]+)>)/", '').replace('&nbsp;', '')}</Text>
              </View>
            ))}
          </View>
        );

      default:
        return null; // Handle other block types as needed
    }
  };

  const handleContentUpdate = ({ label, editor_content }: any) => {
    let updatedData = newTemplate?.data
    if (elementIndex.childIndex === -1) {
      updatedData[elementIndex?.parentIndex]!.label = label
      updatedData[elementIndex?.parentIndex]!.editor_content = editor_content
    } else {
      updatedData[elementIndex?.parentIndex]!.children.find((value: ReportElement, index: number) => index === elementIndex.childIndex).editor_content = editor_content
      updatedData[elementIndex?.parentIndex]!.children.find((value: ReportElement, index: number) => index === elementIndex.childIndex).editor_content = editor_content
    }
    setNewTemplate({ ...newTemplate, name: templateName.name, title: newTemplate?.title, data: [...updatedData] })
    setCurrentElement(undefined);
    setEditorModalOpened(false);
    setTimeout(() => {
      setGenerate(true)
    }, 100);
    setElementIndex({ parentIndex: -1, childIndex: -1 });
  }

  const document = (dataArg: any) => (
    <Document title={"Design Project Plan"}>
      <Page
        size="A4"
        style={styles.page}
        orientation="portrait"
        key={"page_"}
        wrap
      >
        <View style={styles.image}>
          <Image
            fixed={true}
            cache
            src={logo + "?not-from-cache-please"}
          />
        </View>
        <View style={styles.header}>
          <Text style={styles.title}>{`Design Project Plan ${newTemplate?.title || ""}`}</Text>
        </View>
        {dataArg?.data?.map((reportElement: ReportElement, parentIndex: number) => {
          return <>{reportElement.type === "section" ? (
            <View style={styles.section}>
              <Text style={styles.section}>
                {`${parentIndex + 1}.${reportElement.label}`}
              </Text>
              <Text style={{ paddingLeft: "2rem" }}>
                {reportElement?.editor_content?.map((block: any) =>
                  getPdfRenderElement(block)
                )}
              </Text>
            </View>
          ) : (
            <>
              <View style={styles.section}>
                <Text style={styles.subtitle}>
                  {`${parentIndex + 1}.${reportElement.label}`}
                </Text>
                {reportElement.editor_content?.map((block: any) =>
                  getPdfRenderElement(block)
                )}
              </View>

            </>
          )}
            {reportElement?.children?.map((subReportElement: ReportElement, childIndex) => {
              return subReportElement.type === "section" ? (
                <View style={styles.section}>
                  <Text style={styles.section}>
                    {`${parentIndex + 1}.${childIndex + 1}.${subReportElement.label}`}
                  </Text>
                </View>
              ) : (
                <>
                  <View style={styles.section}>
                    <Text style={styles.subtitle}>
                      {`${parentIndex + 1}.${childIndex + 1}.${subReportElement.label}`}
                    </Text>
                    {subReportElement.editor_content?.map((block: any) =>
                      getPdfRenderElement(block)
                    )}
                  </View>

                </>
              )
            })}</>
        })}
        {/* {Object.entries(formState || {})
        .filter(([key, value]: any) => !!value)
        .map(([key, value]: any, index: number) => (
          <View style={styles.section}>
            <Text style={styles.subtitle}>{`${
              index + 1
            }. `}</Text>
            <Text style={styles.subtitle}>{`${String(key)
              .split("_")
              .map(
                (res: string) =>
                  res?.[0]?.toUpperCase() + res?.slice(1)
              )
              .join(" ")}:`}</Text>
          </View>
        ))} */}
      </Page>
    </Document>
  )

  const onFinish = ({ title, templates }: any) => {
    setNewTemplate({
      name: templateName.name,
      title,
      data: [...templates?.map((template: any, index: number) => ({
        label: template.label,
        type: "section",
        key: index + 1,
        heading_level: 4,
        editor_content: [
          {
            type: "paragraph",
            data: {
              text: "",
            },
          },
        ],
        ...(template.hasOwnProperty("children") ? {
          children: template?.children?.map((subsStage: any, subIndex: number) => (
            {
              label: subsStage,
              type: "text",
              heading_level: 5,
              editor_content: [
                {
                  type: "paragraph",
                  data: {
                    text: "",
                  },
                },
              ],
              key: `${index + 1}.${subIndex + 1}`,
            }),
          )
        } : {
          editor_content: [
            {
              type: "paragraph",
              data: {
                text: "",
              },
            },
          ],
        })
      }))]
    })
    setCreateTemplate(false)
    if (templateName.editFlag) setTemplateName({ ...templateName, editFlag: false })
    form.resetFields()
  };


  return (
    <>
      <Card title={!templateName.editFlag ? <>
        <span>{templateName.name}</span>
        <EditOutlined onClick={() => {
          setTemplateName({ ...templateName, editFlag: true })
        }} style={{ color: "#1677ff", marginLeft: "0.5rem" }} />
        {newTemplate?.hasOwnProperty("template_id") && !createTemplate && <StyledDeleteIcon style={{ color: antdTheme.colorError, fontSize:antdTheme.fontSizeLG, marginLeft: "0.5rem" }} onClick={() => {
          setDeleteModalVisible(true)
        }} />}
      </> :
        <><Input value={templateName.name} style={{ width: '20%' }} onPressEnter={() => {
          setTemplateName({ ...templateName, editFlag: false })
        }} onChange={(e) => {
          if (!editStatus) setEditStatus(true)
          setTemplateName({ ...templateName, name: e.target.value })
        }
        } /><CheckOutlined style={{ color: "#1677ff", marginLeft: "0.5rem" }} onClick={() => setTemplateName({ ...templateName, editFlag: false })} /></>}>
        <Row>
          <Col span={8} >
            {createTemplate ? <Form
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 18 }}
              form={form}
              name="template_form"
              style={{ maxWidth: 600 }}
              autoComplete="off"
              initialValues={{ templates: [] }}
              onFinish={onFinish}
            >
              <Form.Item label={t('common.title')} name={'title'}>
                <Input placeholder={t('common.title')} />
              </Form.Item>
              <Form.List name="templates">
                {(fields, { add, remove }) => (
                  <div style={{ display: 'flex', rowGap: 16, flexDirection: 'column' }}>
                    {fields.map((field, index) => (
                      <Card
                        size="small"
                        title={`${t('report.header')} ${field.name + 1}`}
                        key={field.key}
                        extra={
                          <CloseOutlined
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        }
                      >
                        <Form.Item label={index + 1} name={[field.name, 'label']}>
                          <Input placeholder={`${t('report.header')} ${index + 1}`} />
                        </Form.Item>
                        {/* Nest Form.List */}
                        <label></label>
                        <Form.Item >
                          <Form.List name={[field.name, 'children']}>
                            {(subFields, subOpt) => (
                              <div style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
                                {subFields.map((subField, subIndex) => (
                                  <Space key={subField.key}>
                                    <Form.Item label={`${index + 1}.${subIndex + 1}`} name={[subField.name]}>
                                      <Input placeholder={`${t('report.subHeader')} ${subIndex + 1}`} />
                                    </Form.Item>
                                    <MinusCircleOutlined
                                      onClick={() => {
                                        subOpt.remove(subField.name);
                                      }}
                                    />
                                  </Space>
                                ))}
                                <StyledButton type="dashed" style={{ width: "fit-content" }} onClick={() => subOpt.add()} block>
                                  {t('report.addSubItem')}
                                </StyledButton>
                              </div>
                            )}
                          </Form.List>
                        </Form.Item>
                      </Card>
                    ))}
                    <StyledButton type="dashed" style={{ width: "fit-content", marginInline: "auto" }} onClick={() => add()} block>
                      {t('report.addItem')}
                    </StyledButton>
                  </div>
                )}
              </Form.List>
              <Form.Item style={{ float: "right" }}>
                <StyledButton disabled={templateName.editFlag} style={{ marginTop: "1rem" }} type="primary" htmlType="submit">
                  {t('report.createTemplate')}
                </StyledButton>
              </Form.Item>
            </Form> : <div style={{ border: "1px solid #f0f0f0", padding: "0.5rem" }}>
              <Row>
                {!editTitle ? <><Typography.Title style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "85%"
                }} level={3}>
                  {newTemplate?.title || "Title"}
                </Typography.Title>
                  <StyledButton
                    style={{ marginLeft: "5px" }}
                    type="link"
                    icon={<EditOutlined />}
                    onClick={() => {
                      SetEditTitle(true)
                    }}
                  /></> : <>
                  <Input placeholder="Title" value={newTemplate?.title} style={{ width: '80%' }} onPressEnter={() => {
                    if (!editStatus) setEditStatus(true)
                    setNewTemplate({ ...newTemplate, title: newTemplate.title })
                    SetEditTitle(false)
                  }} onChange={(e) => {
                    if (!editStatus) setEditStatus(true)
                    setNewTemplate({ ...newTemplate, title: e.target.value })
                  }
                  } /><CheckOutlined style={{ color: "#1677ff", marginLeft: "0.5rem" }} onClick={() => SetEditTitle(false)} />
                </>}
              </Row>
              {newTemplate?.data?.map((reportElement: ReportElement, parentIndex: number) => (
                <>
                  <Row>
                    <Typography.Title level={reportElement.heading_level} style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "85%"
                    }}>
                      {`${parentIndex + 1}.${reportElement.label}`}
                    </Typography.Title>

                    <StyledButton
                      style={{ marginLeft: "5px" }}
                      type="link"
                      icon={<EditOutlined />}
                      onClick={() => {
                        setGenerate(false)
                        setElementIndex({ ...elementIndex, parentIndex })
                        setCurrentElement(reportElement);
                        setEditorModalOpened(true);
                      }}
                    />
                  </Row>
                  {reportElement?.children?.map((subReportElement: ReportElement, childIndex) => (
                    <Row style={{ paddingLeft: "2rem" }}>
                      <Typography.Title level={subReportElement.heading_level} style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "85%"
                      }}>
                        {`${parentIndex + 1}.${childIndex + 1}.${subReportElement.label}`}
                      </Typography.Title>
                      {["text", "list"].includes(subReportElement.type) ? (
                        <StyledButton
                          style={{ marginLeft: "5px" }}
                          type="link"
                          icon={<EditOutlined />}
                          onClick={() => {
                            setGenerate(false)
                            setElementIndex({ parentIndex, childIndex })
                            setCurrentElement(subReportElement);
                            setEditorModalOpened(true);
                          }}
                        />
                      ) : null}
                    </Row>
                  ))}
                </>
              )
              )}
              <Row justify={"center"} style={{ marginTop: "0.5rem" }}>
                <StyledButton loading={createStatus === AsyncStates.LOADING || updateStatus === AsyncStates.LOADING} disabled={!editStatus} onClick={() => {
                  if (!newTemplate?.hasOwnProperty("template_id")) {
                    dispatch(templateCreateRequest({ ...newTemplate, title: newTemplate.title || "", name: templateName.name || "" }))
                  } else {
                    dispatch(templateUpdateRequest({
                      template_id: templateType,
                      name: templateName.name,
                      title: newTemplate.title,
                      data: newTemplate.data
                    }))
                  }
                }} >{!newTemplate?.hasOwnProperty("template_id") ? "Save Template" : "Update Template"}</StyledButton>
              </Row>
            </div>}
            {currentElement && editorModalOpened && (
              <ReportSimpleEditor
                visible={editorModalOpened}
                onOk={handleContentUpdate}
                dataEditStatus={[editStatus, setEditStatus]}
                onCancel={() => {
                  setCurrentElement(undefined);
                  setEditorModalOpened(false);
                }}
                element={currentElement}
              />
            )}
          </Col>
          {!createTemplate && <Col span={15} style={{ marginInline: "auto" }}>
            <Collapse onChange={(e) => setGenerate(Boolean(e?.length))}>
              <Collapse.Panel
                header={"Preview"}
                key={"preview"}
                extra={
                  <Space onClick={(e) => e.stopPropagation()}>
                    <Input style={{ width: 250 }} value={fileName} onChange={e => setFileName(e.target.value)} />
                    <StyledButton loading={reportUploadStatus === AsyncStates.LOADING} onClick={() => {
                      if (!fileName) {
                        message.error("Please add a file name")
                        return
                      }
                      pdf(document(newTemplate)).toBlob().then((report) => {
                        dispatch(reportUploadRequest({
                          report,
                          type: "design",
                          file_name: fileName,
                          data: newTemplate,
                          from: "modularReports"
                        }))
                      })
                    }} type="primary">{"Save"}</StyledButton>
                  </Space>
                }
              >
                {generate && (
                  <PDFViewer style={{ width: "100%", height: 1280 }}>
                    {document(newTemplate)}
                  </PDFViewer>
                )}
              </Collapse.Panel>
            </Collapse>
          </Col>}
        </Row>
      </Card>
      <DeleteConfirmationModal
        title={`${t("common.confirmDelete")}?`}
        description={
          <Typography.Text>
            {t("common.deleteConfirmation")}{" "}
            <Typography.Text strong>{templateName?.name}</Typography.Text>
            ? {t("common.cannotUndoOperation")}
          </Typography.Text>
        }
        onCancel={() => setDeleteModalVisible(false)}
        visible={deleteModalVisible}
        confirmLoading={templateDeleteStatus === AsyncStates.LOADING}
        onConfirm={() => {
          dispatch(templateDeleteRequest({
            template_id: templateType
          }))
        }}
      />
    </>
  );
};
