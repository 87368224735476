import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeTitleForwardClear, favouriteModelCleanup, forwardPredIdRequest, forwardPredResultClear, forwardPredResultCurrentpageRequest, forwardPredResultFiltersRequest, forwardPredResultRequest, setMetric } from 'src/store/actions/formulate'
import { useQuery } from 'src/utils/useQuery'
import { PredictionResult } from './prediction-result'
import { StoreState } from 'src/store/configureStore'
import { clearSuggestedExpInsights, insightsSocketConnect } from 'src/store/actions/insights'
import jwtManager from 'src/utils/jwtManager'

const ForwardResult = ({ tab, experimentsCurrent, setExperimentsCurrent, selectedObjective, setSelectedObjective }: any) => {
  const dispatch = useDispatch()
  const query = useQuery()
  const predId = query?.get('predId')
  const userId = useSelector(
    (state: StoreState) => state.login.loginResponse.user_id,
  )

  useEffect(() => {
    return () => {
      dispatch(changeTitleForwardClear())
    }
  }, [dispatch])

  useEffect(() => {
    return () => {
      dispatch(forwardPredResultClear())
    }
  }, [dispatch])

  useEffect(() => {
    if (predId) {
      dispatch(setMetric({}))
      dispatch(favouriteModelCleanup())
      dispatch(
        forwardPredResultRequest({
          prediction_id: predId,
          type: "history"
        })
      )
      dispatch(forwardPredIdRequest(predId))
      dispatch(forwardPredResultCurrentpageRequest(1))
      dispatch(forwardPredResultFiltersRequest({}))
      setSelectedObjective(predId as string)

      dispatch(clearSuggestedExpInsights())
      dispatch(
        insightsSocketConnect({
          event: "custom_insights",
          type: "forward",
          action: "results",
          data: { prediction_id: predId },
          key: userId,
          token: jwtManager.getToken(),
        }),
      )
    }
  }, [dispatch, predId, userId, setSelectedObjective]);

  return (
    <PredictionResult 
      currentTab={tab} 
      experimentsCurrent={experimentsCurrent}
      setExperimentsCurrent={setExperimentsCurrent}
      selectedObjective={selectedObjective}
    />
  )
}

export default ForwardResult