import { CloseOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons'
import { Drawer, Tabs, Typography } from 'antd'
import React, { useMemo } from 'react'
import CustomTable from './CustomTable'
import useTranslate from 'src/utils/useTranslate'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { qcCreateNameDetailsRequest, qcDeleteNameDetailsRequest, qcUpdateNameDetailsRequest } from 'src/store/actions/qualityCheck'
import { StyledButton } from 'src/styled_components/StyledButton'
import './NameDetailsDrawer.scss'

const NameDetailsDrawer = ({ isOpen, setIsOpen }: { isOpen: boolean; setIsOpen: any }) => {
  const [t] = useTranslate()
  const [selectedTab, setSelectedTab] = React.useState('supplier')

  const { getNameDetailsResponse } = useSelector((state: StoreState) => state.qualityCheck)

  const dispatch = useDispatch()

  const tabsData = useMemo(() => {
    return [
      {
        tabName: t('qc.supplierName'),
        contentTitle: `${t('qc.supplierName')} ${t('common.List')}`,
        key: 'supplier',
        count: getNameDetailsResponse?.supplier?.length
      },
      {
        tabName: t('qc.productName'),
        contentTitle: `${t('qc.productName')} ${t('common.List')}`,
        key: 'product',
        count: getNameDetailsResponse?.product?.length
      },
      {
        tabName: t('common.Category'),
        contentTitle: `${t('common.Category')} ${t('common.List')}`,
        key: 'category',
        count: getNameDetailsResponse?.category?.length
      }
    ]
  }, [getNameDetailsResponse?.category?.length, getNameDetailsResponse?.product?.length, getNameDetailsResponse?.supplier?.length, t])

  return (
    <Drawer
      open={isOpen}
      onClose={() => setIsOpen(false)}
      closeIcon={false}
      width={'40%'}
      placement="right"
      destroyOnClose
      title={<Typography.Title level={4}>{t('qc.metadata.addOrEditNames')}</Typography.Title>}
      extra={
        <CloseOutlined
          onClick={() => {
            setIsOpen(false)
          }}
          style={{
            outline: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        />
      }
    >
      <Tabs
        defaultActiveKey={selectedTab}
        activeKey={selectedTab}
        onChange={(key) => {
          setSelectedTab(key)
        }}
        destroyInactiveTabPane
        style={{
          height: '100%',
          outline: 'none'
        }}
        moreIcon={<MoreOutlined/>}
        className='qc_metadat_name_details_drawer_tabs'
      >
        {tabsData?.map((tab, idx) => (
          <Tabs.TabPane
            tab={`${tab.tabName} (${tab.count})`}
            key={tab.key}
            style={{
              padding: '1rem 0',
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
              height: '100%'
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Typography.Title level={5}>{tab.contentTitle}</Typography.Title>
              <StyledButton
                icon={<PlusOutlined />}
                size="small"
                style={{
                  fontSize: '0.75rem',
                  fontWeight: 500
                }}
                type={'link'}
                onClick={() => {
                  dispatch(
                    qcCreateNameDetailsRequest({
                      name: `Untitled ${Date.now()}`,
                      type: selectedTab
                    })
                  )
                }}
              >
                {t('common.addNew')}
              </StyledButton>
            </div>
            <CustomTable
              data={getNameDetailsResponse?.[selectedTab]}
              onChange={(detail_id: string | number, value: string) => {
                dispatch(
                  qcUpdateNameDetailsRequest({
                    detail_id,
                    name: value,
                    type: selectedTab
                  })
                )
              }}
              onRemove={(detail_id: string | number) => {
                dispatch(
                  qcDeleteNameDetailsRequest({
                    detail_id,
                    type: selectedTab
                  })
                )
              }}
            />
          </Tabs.TabPane>
        ))}
      </Tabs>
    </Drawer>
  )
}

export default NameDetailsDrawer
