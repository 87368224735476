import { useDispatch, useSelector } from 'react-redux'
import { qcMetadataListRequest } from 'src/store/actions/qualityCheck';
import { StoreState } from 'src/store/configureStore'
import useTranslate from 'src/utils/useTranslate'
import { StyledText } from './MetadataTable'
import { Pagination } from 'antd'

const PaginationTable = () => {

  const [t] = useTranslate()
  const dispatch = useDispatch()

  const {
    metadataListTotal,
    metadataPageNumber
  } = useSelector((state: StoreState) => state.qualityCheck)

  return (
    <Pagination
        total={metadataListTotal}
        pageSize={20}
        onChange={(page) => {
          dispatch(qcMetadataListRequest({ page_num: page }))
        }}
        showTotal={(total) =>
          StyledText(`${t('common.total')} ${total} ${t('common.items')}`)
        }
        current={metadataPageNumber}
        style={{
          padding: 12,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          height: '10%'
        }}
        size="small"
        showSizeChanger={false}
        className="quality-check-files-table-pagination"
      />
  )
}

export default PaginationTable