import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./MyDetails.scss";
import { useSelector, useDispatch } from "react-redux";
import { StoreState } from "../../store/configureStore";
import {
  Descriptions,
  Tooltip,
  Typography,
  Select,
  Input,
  notification,
} from "antd";
import useTranslate from "src/utils/useTranslate";
import { StyledButton } from "src/styled_components/StyledButton";
import {
  updateCurrencyRequest,
  updateProfileRequest,
} from "src/store/actions/login";
import { AsyncStates } from "src/constants";
import { countryNames } from "src/constants/countries";
import {
  CheckOutlined,
  EditOutlined,
  InfoCircleFilled,
} from "@ant-design/icons";
import { Prompt } from "react-router-dom";
import { countryCurrencyCode } from "src/constants/countryCurrencyCode";
const { Paragraph, Text } = Typography;

const MyDetails = () => {
  const [t] = useTranslate();
  const userData = useSelector(
    (store: StoreState) => store.login.loginResponse
  );
  const { updateProfileStatus, updateCurrencyStatus } = useSelector(
    (store: StoreState) => store.login
  );
  const [editing, setEditing] = useState(false);
  const [editingCountryDisable, setEditingCountryDisable] = useState(true);
  const [editingCurrencyState, setEditingCurrencyState] = useState({
    allowEditingCurrency: true,
    currency: {
      currency_name: userData?.currency?.currency_name ?? "Singapore (SGD)",
      currency_code: userData?.currency?.currency_code ?? "SGD",
    },
    isCurrencyUpdated: false,
  });

  const [editingNameDisable, setEditingNameDisable] = useState(true);
  const [name, setName] = useState(userData.user_name);
  const initialProfileData = useMemo(
    () => ({
      user_name: userData.user_name,
      user_location: userData.user_location,
    }),
    [userData]
  );
  const [profileDetails, setProfileDetails] = useState(initialProfileData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (updateProfileStatus === AsyncStates.ERROR) {
      setProfileDetails(initialProfileData);
    }
  }, [updateProfileStatus, initialProfileData]);

  useEffect(() => {
    if (updateCurrencyStatus === AsyncStates.SUCCESS) {
      setEditing(false);
      setEditingCurrencyState((prev) => ({
        ...prev,
        allowEditingCurrency: true,
        isCurrencyUpdated: false,
      }));
    }
  }, [updateCurrencyStatus]);

  useEffect(() => {
    if (updateProfileStatus === AsyncStates.SUCCESS) {
      setEditing(false);
    }
  }, [updateProfileStatus, initialProfileData]);

  function handleProfileUpdate() {
    if (editingCurrencyState.isCurrencyUpdated) {
      dispatch(
        updateCurrencyRequest({ currency: editingCurrencyState.currency })
      );
    }
    if (editing) {
      if (!!profileDetails.user_name.length) {
        dispatch(updateProfileRequest(profileDetails));
        setEditingCountryDisable(true);
        setEditingNameDisable(true);
      } else {
        return notification.error({
          message: "User Name Cannot be Empty",
        });
      }
    }
  }

  const callback = useCallback(
    (e: any) => {
      e.preventDefault();
      e.returnValue = t("common.unsavedChanges");
    },
    [t]
  );

  useEffect(() => {
    editing && window.addEventListener("beforeunload", callback);
    return () => {
      window.removeEventListener("beforeunload", callback);
    };
  }, [editing, callback]);

  return (
    <>
      <Prompt when={editing} message={t("common.unsavedChanges")} />
      <Descriptions
        title={t("profile.userInfo")}
        bordered
        column={{ xxl: 2, xs: 1, sm: 1, md: 1, lg: 1, xl: 2 }}
      >
        <Descriptions.Item
          label={<Text className="fw-bold">{t("common.name")}</Text>}
        >
          <Paragraph style={{ width: "100%", marginBottom: 0 }}>
            <Input
              placeholder={t('common.enterName')}
              value={name}
              onChange={(e) => {
                const user_name = e.target.value;
                setName(user_name);
                if (user_name.trim() !== profileDetails.user_name.trim()) {
                  setProfileDetails((prevState) => ({
                    ...prevState,
                    user_name: user_name.trim(),
                  }));
                  setEditing(true);
                }
              }}
              style={{
                width: "80%",
                marginBottom: 0,
                outline: "none",
                padding: 0,
                marginRight: 10,
              }}
              disabled={editingNameDisable}
              bordered={false}
            />
            {editingNameDisable ? (
              <EditOutlined
                onClick={() => setEditingNameDisable(false)}
                style={{ color: "#1677ff", outline: "none" }}
              />
            ) : (
              <CheckOutlined
                onClick={() => setEditingNameDisable(true)}
                style={{ color: "#1677ff", outline: "none" }}
              />
            )}
          </Paragraph>
        </Descriptions.Item>
        <Descriptions.Item
          label={<Text className="fw-bold">{t("common.email")}</Text>}
        >
          {userData.user_email}
        </Descriptions.Item>
        <Descriptions.Item
          label={<Text className="fw-bold">{t("common.companyName")}</Text>}
        >
          {userData.company}
        </Descriptions.Item>
        <Descriptions.Item
          label={<Text className="fw-bold">{t("profile.country")}</Text>}
        >
          <Paragraph style={{ width: "100%", marginBottom: 0 }}>
            <Select
              showSearch
              placeholder="Select Country"
              style={{
                width: "80%",
                textAlign: "left",
                padding: 0,
                marginRight: 10,
              }}
              className="round-text country"
              value={profileDetails.user_location}
              onChange={(user_location: any) => {
                if (user_location !== profileDetails.user_location) {
                  setProfileDetails((prevState) => ({
                    ...prevState,
                    user_location: user_location,
                  }));
                  setEditing(true);
                }
              }}
              bordered={false}
              disabled={editingCountryDisable}
            >
              {countryNames.map((countryName) => (
                <Select.Option key={countryName} value={countryName}>
                  {countryName}
                </Select.Option>
              ))}
            </Select>
            {editingCountryDisable ? (
              <EditOutlined
                onClick={() => setEditingCountryDisable(false)}
                style={{ color: "#1677ff", outline: "none" }}
              />
            ) : (
              <CheckOutlined
                onClick={() => setEditingCountryDisable(true)}
                style={{ color: "#1677ff", outline: "none" }}
              />
            )}
          </Paragraph>
        </Descriptions.Item>
        <Descriptions.Item
          label={<Text className="fw-bold">{t("profile.publicIp")}</Text>}
        >
          {userData.user_loggedin_IP}
        </Descriptions.Item>
        <Descriptions.Item
          label={<Text className="fw-bold">{t("profile.role")}</Text>}
        >
          {userData.user_role}
        </Descriptions.Item>
        <Descriptions.Item
          label={<Text className="fw-bold">{t("profile.userVerified")}</Text>}
        >
          {userData.user_verified ? t("common.yes") : t("common.no")}
        </Descriptions.Item>

        <Descriptions.Item
          label={
            <>
              <Text className="fw-bold">{t("common.currency")}</Text>{" "}
              {userData?.currency?.is_default_value_updated &&
                userData.user_role === "admin" && (
                  <Tooltip title={t("common.currency.updateCurrencyNote")}>
                    <InfoCircleFilled />
                  </Tooltip>
                )}
            </>
          }
        >
          <Paragraph style={{ width: "100%", marginBottom: 0 }}>
            <Select
              showSearch
              filterOption={(input, option) => {
                return option?.label?.props?.children?.toLowerCase()?.includes(input?.toLowerCase())
              }}
              placeholder={t("common.selectCurrency")}
              style={{
                width: userData.user_role === "admin" ? "80%" : "100%",
                textAlign: "left",
                padding: 0,
                marginRight: 10,
              }}
              className="round-text country"
              value={
                editingCurrencyState.currency.currency_name ?? "Singapore (SGD)"
              }
              onChange={(value: any) => {
                const currencyCode = value.slice(-3);
                const currency = {
                  currency_code: currencyCode,
                  currency_name: `${value.slice(0, -3)}(${currencyCode})`,
                };
                if (
                  currency.currency_name !==
                  editingCurrencyState.currency.currency_name
                ) {
                  setEditingCurrencyState((prevState) => ({
                    ...prevState,
                    currency: currency,
                    isCurrencyUpdated: true,
                  }));
                }
              }}
              bordered={false}
              disabled={
                userData.user_role !== "admin" ||
                editingCurrencyState.allowEditingCurrency
              }
              showArrow={
                userData.user_role === "admin" &&
                !userData?.currency?.is_default_value_updated
              }
              options={countryCurrencyCode.map((countryDetail, index) => ({
                value:
                  countryDetail.country + " " + countryDetail.currency_code,
                label: (
                  <Text className="fw-bold">{`${countryDetail.country} (${countryDetail.currency_code})`}</Text>
                ),
                key: countryDetail.country + "_" + countryDetail.currency_code,
              }))}
            />

            {userData.user_role === "admin" &&
              !userData?.currency?.is_default_value_updated &&
              (editingCurrencyState.allowEditingCurrency ? (
                <EditOutlined
                  onClick={() =>
                    setEditingCurrencyState((prev) => ({
                      ...prev,
                      allowEditingCurrency: !prev.allowEditingCurrency,
                    }))
                  }
                  style={{ color: "#1677ff", outline: "none" }}
                />
              ) : (
                <CheckOutlined
                  onClick={() =>
                    setEditingCurrencyState((prev) => ({
                      ...prev,
                      allowEditingCurrency: !prev.allowEditingCurrency,
                    }))
                  }
                  style={{ color: "#1677ff", outline: "none" }}
                />
              ))}
          </Paragraph>
        </Descriptions.Item>

        {editing || editingCurrencyState.isCurrencyUpdated ? (
          <Descriptions.Item label={null}>
            <Tooltip title={t("form.updateProfile")}>
              <span className="profile__action">
                <StyledButton
                  onClick={handleProfileUpdate}
                  disabled={
                    updateProfileStatus === AsyncStates.LOADING ||
                    updateCurrencyStatus === AsyncStates.LOADING
                  }
                >
                  {t("form.saveDetails")}
                </StyledButton>
              </span>
            </Tooltip>
          </Descriptions.Item>
        ) : null}
      </Descriptions>
    </>
  );
};

export default MyDetails;
