import { Typography } from "antd"
import useTranslate from "src/utils/useTranslate"

const StepTitle = ({ step }: { step: number }) => {
  const [t] = useTranslate()

  const stepTitles: Record<string, string> = {
    0: t('qc.metadata.step1'),
    1: t('qc.metadata.step2'),
    2: t('qc.metadata.step3'),
    3: t('qc.metadata.step4')
  }

  return <Typography.Title level={5}>{stepTitles[step]}</Typography.Title>
}

export default StepTitle