import React, { useState, useEffect, useRef, memo } from "react";
import { usePopper } from "react-popper";
import { Constants } from "../utils";
import AddColumnHeader from "./AddColumnHeader";
import DataTypeIcon from "./DataTypeIcon";
import HeaderMenu from "./HeaderMenu";
import { Dropdown, Menu } from "antd/lib";
import { FilterOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useMemberName } from "src/utils/useMemberName";
import useTranslate from "src/utils/useTranslate";

export const predefinedWorkOrderStages = [
  { label: "Wo.workOrderInitiated", value: "work_order_initiated" },
  { label: "Wo.formulationStage", value: "formulation_stage" },
  { label: "Wo.processingStage", value: "processing_stage" },
  { label: "Wo.characterizationStage", value: "characterization_stage" },
  { label: "Wo.propertiesStage", value: "properties_stage" },
  { label: "Wo.workOrderPreview", value: "report_preview" },
  { label: "Wo.workOrderCompleted", value: "work_order_status" },
  { label: "stages.closedWorkOrders", value: "closed" },
];

const Header = ({
  column: {
    id,
    created,
    label,
    dataType,
    getResizerProps,
    getHeaderProps,
    filter,
    filterOptions,
    disableSortBy
  },
  setSortBy,
  dataDispatch,
  data,
  SetGlobalFilter,
  setWorkOrderFilters,
  Width,
  tabKey,
}) => {
  const [showHeaderMenu, setShowHeaderMenu] = useState(created || false);
  const [headerMenuAnchorRef, setHeaderMenuAnchorRef] = useState(null);
  const [headerMenuPopperRef, setHeaderMenuPopperRef] = useState(null);
  const [showFilterDropdown, setShowFilterDropdown] = useState(null);
  const [t] = useTranslate();
  const headerMenuPopper = usePopper(headerMenuAnchorRef, headerMenuPopperRef, {
    placement: "bottom",
    strategy: "absolute",
    style: { transform: "translate(8px, 93px)" },
  });
  const { getName } = useMemberName();

  /* when the column is newly created, set it to open */
  useEffect(() => {
    if (created) {
      setShowHeaderMenu(true);
    }
  }, [created]);

  const overlayRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (overlayRef.current && !overlayRef.current.contains(event.target)) {
        setShowHeaderMenu(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowHeaderMenu]);

  function getHeader() {
    if (id === Constants.ADD_COLUMN_ID) {
      return (
        <AddColumnHeader
          dataDispatch={dataDispatch}
          getHeaderProps={getHeaderProps}
        />
      );
    }

    function getUniqueColumnValues(data, columnName) {
      const uniqueValues = new Set(
        data.map((item) =>
          columnName === "created"
            ? dayjs(item[columnName]).format("YYYY-MM-DD")
            : item[columnName]
        )
      );
      return [...uniqueValues];
    }

    const columnsToFilter = [
      { name: "work_order_stage", id: "work_order_stage" },
      { name: "work_order_name", id: "work_order_name" },
      { name: "created_by", id: "created_by" },
      { name: "created", id: "created" },
      { name: "work_order_desc", id: "work_order_desc" },
      { name: "lab_location_display", id: "lab_location_display" },
    ];

    const filterOptionsByColumn = {};

    columnsToFilter.forEach((column) => {
      const colFilterOptions = [
        {
          label: t("common.reset") + " " + t("common.filters"),
          key: `${column.id}_reset`,
          value: "reset",
        },
      ];

      if (column.name === "work_order_stage") {
        colFilterOptions.push(
          ...predefinedWorkOrderStages.map((stage) => ({
            label: t(stage.label),
            key: `${column.id}_${stage.value}`,
            value: stage.value,
          }))
        );
      } else if (column.name !== "work_order_stage") {
        if (
          filterOptions &&
          Array.isArray(filterOptions) &&
          filterOptions.length > 0
        ) {
          colFilterOptions.push(
            ...filterOptions.map((value, index) => ({
              label: value,
              key: `${column.id}_${index}`,
              value: value,
            }))
          );
        } else {
          const uniqueValues = getUniqueColumnValues(data, column.name);
          colFilterOptions.push(
            ...uniqueValues.map((value, index) => ({
              label: value,
              key: `${column.id}_${index}`,
              value: value,
            }))
          );
        }
      }

      const removeNulls = colFilterOptions.filter(
        ({ label, value }) => label !== null || value !== null
      );
      filterOptionsByColumn[column.id] = removeNulls;
    });

    function FilterDropdown({
      setShowFilterDropdown,
      filterOptions,
      columnName,
    }) {
      const handleItemClick = (key) => {
        if (key.value === "reset") {
          if (setWorkOrderFilters) setWorkOrderFilters({});
          else SetGlobalFilter("");
        } else {
          if (setWorkOrderFilters) {
            let filter = {};
            if (columnName === "work_order_name") filter.name = [key.value];
            if (columnName === "work_order_stage") filter.stage = [key.value];
            if (columnName === "created_by") filter.created_by = [key.value];
            if (columnName === "created") filter.created = [key.value];
            if (columnName === "work_order_desc") filter.desc = [key.value];
            if (columnName === "lab_location_display")
              filter.lab_location = [key.value];
            setWorkOrderFilters(filter);
          } else SetGlobalFilter(key.value);
        }
        setShowFilterDropdown(false);
      };

      return (
        <Menu>
          {filterOptions.map((option) => (
            <Menu.Item
              key={option.key}
              onClick={() => handleItemClick(option)}
              style={{
                borderBottom:
                  option.value === "reset" ? "2px solid #1890ff" : "none",
                color: option.value === "reset" ? "#1890ff" : "black",
                fontWeight: option.value === "reset" ? "500" : "400",
              }}
            >
              {columnName === "work_order_stage"
                ? formatFilterOption(option, columnName, data)
                : option.label}
            </Menu.Item>
          ))}
        </Menu>
      );
    }

    function formatFilterOption(option, columnName, data) {
      switch (columnName) {
        case "work_order_name":
        case "work_order_desc":
          return option.label;
        case "work_order_stage":
          if (option?.label === "closed") {
            return t("stages.closedWorkOrders");
          } else if (option?.label === "work_order_status") {
            return "Work Order Completed";
          } else if (option?.label === "report_preview") {
            return t("workOrder.reportPreview");
          } else {
            return option.label
              .replace(/_/g, " ")
              .replace(/\b\w/g, (char) => char.toUpperCase());
          }
        case "created_by":
          if (option.value === "reset") return option.label;
          const createdBy = option.label;
          const userName = getName(createdBy);
          return userName;
        case "date":
          if (option.value === "reset") return option.label;
          const date = dayjs(option.label).format("YYYY-MM-DD");
          return date;
        default:
          return option.label;
      }
    }

    const currentColumnIdentifier = filter;

    return (
      <>
        <div {...getHeaderProps()} className="th noselect d-inline-block">
          <div className="th-content">
            <div
              style={{ display: "flex", minWidth: "85%" }}
              onClick={() => setShowHeaderMenu(true)}
              ref={setHeaderMenuAnchorRef}
            >
              <span
                className="svg-icon svg-gray icon-margin"
                style={{ display: "flex", alignItems: "center" }}
              >
                <DataTypeIcon dataType={dataType} />
              </span>
              {label}
            </div>
            {filter && (
              <span style={{ float: "right" }}>
                <Dropdown
                  dropdownRender={() => (
                    <FilterDropdown
                      setShowFilterDropdown={setShowFilterDropdown}
                      filterOptions={filterOptionsByColumn[
                        currentColumnIdentifier
                      ]?.map((option) => ({
                        ...option,
                        label: formatFilterOption(
                          option,
                          currentColumnIdentifier,
                          data
                        ),
                      }))}
                      columnName={currentColumnIdentifier}
                    />
                  )}
                  trigger={["click"]}
                  overlayClassName="custom-dropdown"
                >
                  <FilterOutlined
                    onClick={() => setShowFilterDropdown(!showFilterDropdown)}
                  />
                </Dropdown>
              </span>
            )}
          </div>
          <div {...getResizerProps()} className="resizer" />
        </div>
        {showHeaderMenu && <div className="overlay" ref={overlayRef} />}
        {showHeaderMenu && (
          <HeaderMenu
            label={label}
            dataType={dataType}
            popper={headerMenuPopper}
            popperRef={setHeaderMenuPopperRef}
            dataDispatch={dataDispatch}
            setSortBy={setSortBy}
            disableSortBy={disableSortBy}
            columnId={id}
            setShowHeaderMenu={setShowHeaderMenu}
          />
        )}
      </>
    );
  }

  return getHeader();
};

export default memo(Header);
