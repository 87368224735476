import { useEffect, useState } from "react";
import { Divider, Form, Input, Space, Typography, Layout, Row, Col, Select, Alert } from 'antd';
import "../Login/Login.scss";
import Sha1 from "js-sha1";
import { useDispatch, useSelector } from "react-redux";
import './SignUp.scss'
import LeftSideOnboarding from "./LeftSideOnboarding";
import Icon, { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import useTranslate from "src/utils/useTranslate";
import { Services, signInViaFirebase } from "src/services/firebase/firebase-auth";
import { getIpDetails } from "src/services/login";
import Title from "antd/lib/typography/Title";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { trackEvent } from "src/analytics";
import AllRightsReserved from "./AllRightsReserved";
import { useQuery } from "src/utils/useQuery";
import { StoreState } from "src/store/configureStore";
import { antdTheme, AsyncStates, currentPlatform } from "src/constants";
import { signupClearError, signupFailure, signupRequest } from "src/store/actions/signup";
import { loginFirebaseClear, loginFirebaseRequest } from "src/store/actions/firebase";
import { countryNames } from "src/constants/countries";
import Spinner from "src/components/Spinner/Spinner";
import { GsuiteIcon, MicrosoftIcon } from "../Login/Login";
import { useSessionStorage } from "src/utils/hooks/useSessionStorage";
import { useRequiredFieldStar } from "../Common/useRequiredFieldStar";
import { StyledButton } from "src/styled_components/StyledButton";

const { Content: LayoutContent } = Layout
const { Text } = Typography;
const { Option } = Select;

const SignUp = () => {
  const query = useQuery()
  const [t] = useTranslate()
  const dispatch = useDispatch();
  const requiredFieldStar = useRequiredFieldStar()

  const setSessionStorage = useSessionStorage().setSessionStorage
  const getSessionStorage = useSessionStorage().getSessionStorage

  const [ip, setIp] = useState<any>(undefined);
  const [disabledFieldsBasedOnQuery, setDisabledFieldsBasedOnQuery] = useState(false)
  const userId = useSelector((state: StoreState) => state.login.loginResponse.user_id)
  const [validatedPassword, setValidatedPassword] = useState<any>({
    password: "",
    error: false,
    passwordLength: false,
    passWordwithNoSpaces: false,
    containsUppercaseCharacters: false,
    containsLowercaseCharacters: false,
    containsOneNumber: false,
    containsOneSymbol: false,
    containsNoWhiteSpaces: false
  })
  const [recaptcha, setRecaptcha] = useState("")

  const submitForm = (values: any) => {
    const invite_token = query.get("token")
    let { first_name, last_name, pwd, user_email, user_location, user_loggedin_IP, company, user_name, job_title } = values
    pwd = Sha1(pwd.trim())
    user_loggedin_IP = ip;
    user_name = `${first_name} ${last_name}`
    trackEvent(userId, 'User Signed In through email', { "Sign in details": { user_name, company, user_location, user_email, user_loggedin_IP } })
    dispatch(signupRequest({ user_name, company, user_location, user_email, job_title, pwd, user_loggedin_IP, invite_token }));
  }

  const signInViaGsuite = () => {
    trackEvent(userId, 'User signed In through Gmail')
    setSessionStorage("isLoginWithFirebaseInitiated", true)
    signInViaFirebase(Services.gsuite)
  }

  const signInViaMicrosoft = async () => {
    trackEvent(userId, 'User signed In through Microsoft')
    setSessionStorage("isLoginWithFirebaseInitiated", true)
    signInViaFirebase(Services.microsoft)
  }

  const apiStatus = useSelector<StoreState, AsyncStates>
    (
      (state) => state.signup.status
    );
  const firebaseStatus = useSelector<StoreState, AsyncStates>(
    (state) => state.loginFirebase.status
  );
  const errorMsg = useSelector<StoreState, string>(
    (state) => state.signup.error
  );
  const errorMsgFB = useSelector<StoreState, string>(
    (state) => state.loginFirebase.error
  );

  useEffect(() => {
    return () => {
      dispatch(signupClearError())
      dispatch(loginFirebaseClear())
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(signupClearError())
    const parseIp = async () => {
      const {
        data: { ip },
      }: any = await getIpDetails();
      setIp(ip);
    };
    parseIp();
  }, [dispatch]);

  useEffect(() => {
    const isLoginWithFirebaseInitiated = getSessionStorage("isLoginWithFirebaseInitiated")
    if (isLoginWithFirebaseInitiated) {
      dispatch(loginFirebaseRequest())
    }
  }, [dispatch, getSessionStorage])

  useEffect(() => {
    if (AsyncStates.LOADING === "LOADING") {
      dispatch(signupFailure(""))
    }
  }, [dispatch])

  const checkPasswordStrength = (password: any) => {
    const passwordStrength = {
      password: password,
      error: false,
      passwordLength: false,
      containsUppercaseCharacters: false,
      containsLowercaseCharacters: false,
      containsOneNumber: false,
      containsOneSymbol: false,
      containsNoWhiteSpaces: false
    }
    if (password.match(/[a-z]/)) {
      passwordStrength.containsLowercaseCharacters = true
    }
    if (password.match(/[A-Z]/)) {
      passwordStrength.containsUppercaseCharacters = true
    }
    if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
      passwordStrength.containsOneSymbol = true
    }
    if (password.length >= 8) {
      passwordStrength.passwordLength = true
    }
    if (password.match(/([0-9])/)) {
      passwordStrength.containsOneNumber = true
    }
    passwordStrength.containsNoWhiteSpaces = !((/^(?=.*\s)/)).test(password)

    passwordStrength.error = passwordStrength.containsOneNumber && passwordStrength.passwordLength && passwordStrength.containsOneSymbol && passwordStrength.containsUppercaseCharacters && passwordStrength.containsLowercaseCharacters && passwordStrength.containsNoWhiteSpaces

    setValidatedPassword(passwordStrength)
  }
  const [form] = Form.useForm()

  // For Invited USER
  useEffect(() => {
    const user_email = query.get("email")
    const company = query.get("company")?.replaceAll("+", " ")
    const invite_token = query.get("token")
    if (!!user_email?.length && !!company?.length && !!invite_token?.length && currentPlatform === "connect") {
      form.setFieldsValue({ user_email, company })
      setDisabledFieldsBasedOnQuery(true)
    }
    if (!!user_email?.length && !!company?.length && currentPlatform === "labs") {
      form.setFieldsValue({ user_email, company })
      setDisabledFieldsBasedOnQuery(true)
    }
  }, [query, form])

  return (
    <Layout
      style={{ textAlign: "center", background: "white" }}
      className="fontWorkSans"
    >
      <LayoutContent style={{ overflow: "auto" }}>
        <Space style={{ width: "100%" }} direction="horizontal" size="large">
          <LeftSideOnboarding minWidth="45vw" maxWidth="50vw" />
          <Row
            align="middle"
            justify="center"
            style={{ flex: 1, width: "100%" }}
            className="login-card-container login-card-container-overflow"
          >
            <Space direction="vertical" className="mainLayoutMargin2">
              <Title
                level={1}
                style={{
                  marginBottom: "15px",
                  marginTop: "15px",
                  fontSize: "2.5rem",
                  color: "#1753E2",
                  fontWeight: 500,
                }}
              >
                {t('signUp.createAnAccount')}
              </Title>

              <Form
                name="normal_login"
                className="signup-form"
                layout="vertical"
                onFinish={submitForm}
                form={form}
                requiredMark={false}
              >
                <Row
                  style={{
                    gap: "35px",
                    justifyContent: "center",
                    fontWeight: "bold",
                  }}
                >
                  <Form.Item>
                    <StyledButton
                      size="large"
                      className="round-text social-btn"
                      onClick={signInViaGsuite}
                      style={{
                        whiteSpace: "normal",
                        display: "block",
                        height: "max-content",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      <Space style={{ padding: "3px 38px", fontWeight: 600 }}>
                        <Icon
                          component={GsuiteIcon}
                          style={{ fontSize: antdTheme.fontSizeHeading2 }}
                        />
                        &nbsp; {t("signup.gsuite")}
                      </Space>
                    </StyledButton>
                  </Form.Item>
                  <Form.Item>
                    <StyledButton
                      size="large"
                      className="round-text social-btn"
                      onClick={signInViaMicrosoft}
                      style={{
                        whiteSpace: "normal",
                        height: "max-content",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      <Space style={{ padding: "3px 38px", fontWeight: 600 }}>
                        <Icon
                          component={MicrosoftIcon}
                          style={{ fontSize: antdTheme.fontSizeHeading2 }}
                        />
                        &nbsp; {t('signup.microsoft')}
                      </Space>
                    </StyledButton>
                  </Form.Item>
                </Row>

                <Form.Item>
                  <Divider plain>
                    <Text type="secondary">{t("common.or")}</Text>
                  </Divider>
                </Form.Item>

                {!!errorMsg && (
                  <Alert
                    message={errorMsg}
                    type="error"
                    style={{
                      backgroundColor: "#CF1322",
                      color: "white",
                      textAlign: "left",
                      display: "block",
                      marginBottom: "1rem",
                    }}
                    className="error-alert-msg"
                  />
                )}
                {!!errorMsgFB && (
                  <Alert
                    message={
                      <div dangerouslySetInnerHTML={{ __html: errorMsgFB }}></div>
                    }
                    type="error"
                    style={{
                      backgroundColor: "#CF1322",
                      color: "white",
                      textAlign: "left",
                      display: "block",
                      marginBottom: "1rem",
                    }}
                    className="error-alert-msg"
                  />
                )}

                <Row gutter={[35, 35]}>
                  <Col span={12}>
                    <Form.Item
                      label={t('signUp.firstName')}
                      name="first_name"
                      validateTrigger="onChange"
                      required tooltip={requiredFieldStar}
                      rules={[
                        {
                          required: true,
                          message: (
                            <Text
                              style={{
                                color: "red",
                                display: "block",
                                textAlign: "left",
                                margin: "0rem",
                              }}
                            >
                              {t('signUp.inputFirstName')}
                            </Text>
                          ),
                        },
                        {
                          pattern: new RegExp(/^[^\s]+(\s+[^\s]+)*$/),
                          message: (
                            <Text style={{ float: "left", color: "red" }}>{t("signUp.inputFirstNameError")}</Text>
                          ),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={"John"}
                        className="round-text"
                        minLength={2}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={t('signUp.lastName')}
                      name="last_name"
                      validateTrigger="onChange"
                      required tooltip={requiredFieldStar}
                      rules={[
                        {
                          required: true,
                          message: (
                            <Text
                              style={{
                                color: "red",
                                display: "block",
                                textAlign: "left",
                                margin: "0rem",
                              }}
                            >
                              {t('signUp.inputLastName')}
                            </Text>
                          ),
                        },
                        {
                          pattern: new RegExp(/^[^\s]+(\s+[^\s]+)*$/),
                          message: (
                            <Text style={{ float: "left", color: "red" }}>
                              {t('signUp.inputLastNameError')}  
                            </Text>
                          ),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={"Doe"}
                        className="round-text"
                        minLength={2}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[35, 35]}>
                  <Col span={12}>
                    <Form.Item
                      label={t("common.companyName")}
                      name="company"
                      validateTrigger="onChange"
                      required tooltip={requiredFieldStar}
                      rules={[
                        {
                          required: true,
                          message: (
                            <Text
                              style={{
                                color: "red",
                                display: "block",
                                textAlign: "left",
                                margin: "0.2rem",
                              }}
                            >
                              {t("signup.warn.companyName")}
                            </Text>
                          ),
                        },
                        {
                          pattern: new RegExp(
                            /^(?!.*[-_]{2})(?=.*[a-zA-Z0-9.]$)[a-zA-Z0-9.][a-zA-Z0-9. _-]*$/
                          ),
                          message: (
                            <Text style={{ float: "left", color: "red" }}>
                              {t('signUp.inputCompanyNameError')}
                            </Text>
                          ),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t('common.companyName')}
                        className="round-text"
                        minLength={2}
                        disabled={disabledFieldsBasedOnQuery}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={t('signUp.jobTitle')}
                      name="job_title"
                      validateTrigger="onChange"
                      required tooltip={requiredFieldStar}
                      rules={[
                        {
                          required: true,
                          message: (
                            <Text
                              style={{
                                color: "red",
                                display: "block",
                                textAlign: "left",
                                margin: "0.2rem",
                              }}
                            >
                              {t('signUp.inputJobTitle')}
                            </Text>
                          ),
                        },
                        {
                          pattern: new RegExp(
                            /^(?!.*[-_]{2})(?=.*[a-zA-Z0-9.]$)[a-zA-Z0-9.][a-zA-Z0-9. _-]*$/
                          ),
                          message: (
                            <Text style={{ float: "left", color: "red" }}>
                              {t('signUp.inputCompanyNameError')}
                            </Text>
                          ),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={t('signUp.jobTitle')}
                        className="round-text"
                        minLength={2}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[35, 35]}>
                  <Col span={12}>
                    <Form.Item
                      label={t("common.email")}
                      name="user_email"
                      validateTrigger="onChange"
                      required tooltip={requiredFieldStar}
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp(
                            /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
                          ),
                          message: (
                            <Text
                              style={{
                                color: "red",
                                display: "block",
                                textAlign: "left",
                                margin: "0.2rem",
                              }}
                            >
                              {t('billing.modal.emailError')}
                            </Text>
                          ),
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder={"name@company.com"}
                        autoComplete="on"
                        className="round-text"
                        minLength={4}
                        disabled={disabledFieldsBasedOnQuery}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={t("login.password")}
                      name="pwd"
                      rules={[{ required: true, message: "" }]}
                      required tooltip={requiredFieldStar}
                    >
                      <Input.Password
                        size="large"
                        placeholder={t("login.password")}
                        className="round-text"
                        type="password"
                        minLength={8}
                        onChange={(e: any) =>
                          checkPasswordStrength(e.target.value)
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[35, 40]}>
                  <Col span={12}>
                    <Form.Item
                      label={t("signup.location")}
                      name="user_location"
                      required tooltip={requiredFieldStar}
                      rules={[
                        {
                          required: true,
                          message: (
                            <Text
                              style={{
                                color: "red",
                                display: "block",
                                textAlign: "left",
                                margin: "0.2rem",
                              }}
                            >
                              {t("signup.warn.location")}
                            </Text>
                          ),
                        },
                      ]}
                      validateTrigger="onChange"
                    >
                      <Select
                        showSearch
                        placeholder="Select location"
                        style={{ width: "100%", textAlign: "left" }}
                        className="round-text"
                      >
                        {countryNames.map((countryName) => (
                          <Option key={countryName} value={countryName}>
                            {countryName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <div className="password-validator-msg-container">
                      <ul
                        className={
                          !!validatedPassword.password
                            ? "listStyleDisabled"
                            : ""
                        }
                      >
                        <li>
                          {validatedPassword.passwordLength ? (
                            <Text type="secondary" style={{ color: "green" }}>
                              <CheckOutlined
                                className={
                                  !validatedPassword.password
                                    ? "hide-icons"
                                    : ""
                                }
                              />{" "}
                              {t('password.minCharacters')}
                            </Text>
                          ) : (
                            <Text
                              type="secondary"
                              style={{
                                color: !!validatedPassword.password
                                  ? "red"
                                  : "",
                              }}
                            >
                              <CloseOutlined
                                className={
                                  !validatedPassword.password
                                    ? "hide-icons"
                                    : ""
                                }
                              />{" "}
                              {t('password.minCharacters')}
                            </Text>
                          )}
                        </li>
                        <li>
                          {validatedPassword.containsNoWhiteSpaces ? (
                            <Text type="secondary" style={{ color: "green" }}>
                              <CheckOutlined
                                className={
                                  !validatedPassword.password
                                    ? "hide-icons"
                                    : ""
                                }
                              />{" "}
                              {t('password.cannotContainSpaces')}
                            </Text>
                          ) : (
                            <Text
                              type="secondary"
                              style={{
                                color: !!validatedPassword.password
                                  ? "red"
                                  : "",
                              }}
                            >
                              <CloseOutlined
                                className={
                                  !validatedPassword.password
                                    ? "hide-icons"
                                    : ""
                                }
                              />{" "}
                              {t('password.cannotContainSpaces')}
                            </Text>
                          )}
                        </li>

                        <li>
                          {validatedPassword.containsUppercaseCharacters ? (
                            <Text type="secondary" style={{ color: "green" }}>
                              <CheckOutlined
                                className={
                                  !validatedPassword.password
                                    ? "hide-icons"
                                    : ""
                                }
                              />{" "}
                              {t('password.oneUppercase')}
                            </Text>
                          ) : (
                            <Text
                              type="secondary"
                              style={{
                                color: !!validatedPassword.password
                                  ? "red"
                                  : "",
                              }}
                            >
                              <CloseOutlined
                                className={
                                  !validatedPassword.password
                                    ? "hide-icons"
                                    : ""
                                }
                              />{" "}
                              {t('password.oneUppercase')}
                            </Text>
                          )}
                        </li>
                        <li>
                          {validatedPassword.containsLowercaseCharacters ? (
                            <Text type="secondary" style={{ color: "green" }}>
                              <CheckOutlined
                                className={
                                  !validatedPassword.password
                                    ? "hide-icons"
                                    : ""
                                }
                              />{" "}
                              {t('password.oneLowercase')}
                            </Text>
                          ) : (
                            <Text
                              type="secondary"
                              style={{
                                color: !!validatedPassword.password
                                  ? "red"
                                  : "",
                              }}
                            >
                              <CloseOutlined
                                className={
                                  !validatedPassword.password
                                    ? "hide-icons"
                                    : ""
                                }
                              />{" "}
                              {t('password.oneLowercase')}
                            </Text>
                          )}
                        </li>
                        <li>
                          {validatedPassword.containsOneNumber ? (
                            <Text type="secondary" style={{ color: "green" }}>
                              <CheckOutlined
                                className={
                                  !validatedPassword.password
                                    ? "hide-icons"
                                    : ""
                                }
                              />{" "}
                              {t('password.oneNumber')}
                            </Text>
                          ) : (
                            <Text
                              type="secondary"
                              style={{
                                color: !!validatedPassword.password
                                  ? "red"
                                  : "",
                              }}
                            >
                              <CloseOutlined
                                className={
                                  !validatedPassword.password
                                    ? "hide-icons"
                                    : ""
                                }
                              />{" "}
                               {t('password.oneNumber')}
                            </Text>
                          )}
                        </li>
                        <li>
                          {validatedPassword.containsOneSymbol ? (
                            <Text type="secondary" style={{ color: "green" }}>
                              <CheckOutlined
                                className={
                                  !validatedPassword.password
                                    ? "hide-icons"
                                    : ""
                                }
                              />{" "}
                              {t('password.oneSpecialCharacter')}
                            </Text>
                          ) : (
                            <Text
                              type="secondary"
                              style={{
                                color: !!validatedPassword.password
                                  ? "red"
                                  : "",
                              }}
                            >
                              <CloseOutlined
                                className={
                                  !validatedPassword.password
                                    ? "hide-icons"
                                    : ""
                                }
                              />{" "}
                              {t('password.oneSpecialCharacter')}
                            </Text>
                          )}
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>

                <Form.Item
                  rules={[{ required: true }]}
                  required tooltip={requiredFieldStar}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "35px",
                  }}
                >
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_CAPTCHA ?? ""}
                    onChange={(value: any) => setRecaptcha(value)}
                  />
                </Form.Item>

                <Form.Item
                  style={{
                    height: 15,
                    marginTop: "25px",
                    textAlign: "center",
                  }}
                  shouldUpdate
                >
                  {() => (
                    <StyledButton
                      className="primary-btn"
                      style={{
                        background: "#1753E2",
                        width: "100%",
                        borderRadius: "4px",
                      }}
                      size="large"
                      type="primary"
                      htmlType="submit"
                      disabled={
                        !form.isFieldsTouched(true) ||
                        !!form
                          .getFieldsError()
                          .filter(({ errors }: any) => errors.length)
                          .length ||
                        !validatedPassword.error ||
                        !recaptcha
                      }
                    >
                      {t("login.signUp")}
                    </StyledButton>
                  )}
                </Form.Item>
              </Form>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <Text>
                  {t('signUp.youAgree')}{" "}
                  <span>
                    <a
                      href="https://polymerize.io/privacy-policy/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "#5F85EB",
                        textDecoration: "underline",
                      }}
                    >
                      {" "}
                      {t('common.privacyPolicy')}
                    </a>{" "}
                  </span>
                  <span>{t('common.and')}</span>
                  <span>
                    {" "}
                    <a
                      href="https://polymerize.io/terms-of-use/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "#5F85EB",
                        textDecoration: "underline",
                      }}
                    >
                      {t('common.termsOfService')}
                    </a>
                  </span>
                </Text>
              </Row>
              <Row justify="center">
                <Title
                  level={5}
                  className="linktoLogin"
                  style={{
                    marginTop: "25px",
                    marginBottom: "20px",
                  }}
                >
                  {t("signup.alreadyHaveAnAccount")}{" "}
                  <Link
                    style={{
                      color: "#007AFF",
                    }}
                    to="/login"
                  >{" "}{t("common.login")}</Link>
                </Title>
              </Row>
              <AllRightsReserved
                styleProp={{ position: "relative", marginTop: "5rem" }}
              />
            </Space>
          </Row>
        </Space>
      </LayoutContent>
      {(apiStatus === AsyncStates.LOADING ||
        firebaseStatus === AsyncStates.LOADING) && <Spinner />}
    </Layout>
  );
};

export default SignUp;
