import { Select, Table } from 'antd'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import useTranslate from 'src/utils/useTranslate'
import Fuse from 'fuse.js'

const MapStagesStep = ({
  selectedStages,
  sheetToStageMap,
  setSheetToStageMap
}: {
  selectedStages: [number, number]
  sheetToStageMap: Record<string, Record<string, any>>
  setSheetToStageMap: any
}) => {
  const [t] = useTranslate()
  const modelConfigData = useSelector((state: StoreState) => state.formulate.modelConfigData)

  const selectedStagesForMapping = Object.values(sheetToStageMap)
    .map((mapping) => mapping.stageNumber)
    .filter((stage) => stage !== -1)

  const stagesOptions = useMemo(() => {
    const options = new Array(selectedStages?.[selectedStages?.length - 1] - selectedStages?.[0] + 1).fill(null)
    return options
      .map((res, index) => {
        const stageName = modelConfigData[0]?.all_stages?.[selectedStages[0] + index - 1]
        return {
          value: selectedStages[0] + index,
          label: `${stageName ?? `Stage ${selectedStages[0] + index}`}`,
          title: `${stageName ?? `Stage ${selectedStages[0] + index}`}`
        }
      })
      .filter((option) => !selectedStagesForMapping.includes(option.value))
  }, [selectedStages, modelConfigData, selectedStagesForMapping])

  // Fuzzy mapping stages

  useEffect(() => {
    const options = new Array(selectedStages?.[selectedStages?.length - 1] - selectedStages?.[0] + 1).fill(null)
    const stages = options.map((res, index) => {
      const stageName = modelConfigData[0]?.all_stages?.[selectedStages[0] + index - 1]
      return {
        value: selectedStages[0] + index,
        label: `${stageName ?? `Stage ${selectedStages[0] + index}`}`,
        title: `${stageName ?? `Stage ${selectedStages[0] + index}`}`
      }
    })

    const fuse = new Fuse(stages, {
      keys: ['label', 'title'],
      threshold: 0.2
    })

    setSheetToStageMap((prev: any) => {
      const newState = { ...prev }

      let fuzzyMappedSheetCount = 0
      const totalStages = selectedStages?.[selectedStages?.length - 1] - selectedStages?.[0] + 1

      const usedStages:string[] = []

      Object.keys(prev).forEach((sheetName) => {
        const doFuzzyMapping = prev[sheetName].stageNumber === null && prev[sheetName].stageName === ''

        if (doFuzzyMapping) {
          if (fuzzyMappedSheetCount === totalStages) {
            newState[sheetName] = {
              ...prev[sheetName],
              stageNumber: -1,
              stageName: t('common.Ignore')
            }
            return
          }

          let result = fuse.search(sheetName)

          result = result.filter((item) => !usedStages.includes(item.item.title))

          if (result.length > 0) {
            newState[sheetName] = {
              ...prev[sheetName],
              stageNumber: result[0].item.value,
              stageName: result[0].item.title
            }

            usedStages.push(result[0].item.title)

            fuzzyMappedSheetCount += 1
          }
        }
      })

      return newState
    })
  }, [modelConfigData, selectedStages, setSheetToStageMap, t])

  const columns = [
    {
      title: t('mapSheets.sheetName'),
      dataIndex: 'sheetName',
      key: 'sheetName',
      width: '50%'
    },
    {
      title: t('common.selectStageName'),
      dataIndex: 'stageName',
      key: 'stageName',
      width: '50%',
      render: (val: any, record: any) => {
        const { sheetName } = record
        return (
          <Select
            value={sheetToStageMap[sheetName].stageName}
            style={{
              width: '100%'
            }}
            {...(sheetToStageMap[sheetName].isError ? { status: 'error' } : {})}
            allowClear
            options={[
              ...stagesOptions,

              ...(stagesOptions.length > 0
                ? []
                : [
                    {
                      value: -1,
                      label: t('common.Ignore'),
                      title: t('common.Ignore'),
                      disabled: stagesOptions.length > 0
                    }
                  ])
            ]}
            onChange={(value) => {
              setSheetToStageMap({
                ...sheetToStageMap,
                [sheetName]: {
                  stageNumber: value,
                  stageName: [
                    ...stagesOptions,
                    {
                      value: -1,
                      label: t('common.Ignore'),
                      title: t('common.Ignore')
                    }
                  ].find((option) => option.value === value)?.title
                }
              })
            }}
            onClear={() => {
              setSheetToStageMap({
                ...sheetToStageMap,
                [sheetName]: {
                  stageNumber: null,
                  stageName: ''
                }
              })
            }}
          />
        )
      }
    }
  ]

  const data = Object.keys(sheetToStageMap).map((sheetName: string) => {
    return {
      key: sheetName,
      sheetName
    }
  })

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        width: '100%',
        height: '100%'
      }}
    >
      <Table
        columns={columns}
        className="forward_prediction_upload_flow_table"
        dataSource={data}
        pagination={false}
        bordered={false}
        style={{ width: '50%' }}
      />
    </div>
  )
}

export default MapStagesStep
