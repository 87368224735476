import { Card, Checkbox, message, Modal, Row, Space, Spin, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react'
import { StyledButton } from 'src/styled_components/StyledButton'
import { ExclamationCircleOutlined, InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import "./WorkOrderDetails.module.scss"
import { AsyncStates, defaultStages } from 'src/constants';
import { useDispatch, useSelector } from 'react-redux';
import { editWorkOrderRequest, stageExistsCleanup, workOrderRequest } from 'src/store/actions/workOrderDetails';
import { setIsEditing } from 'src/store/actions/isEditing';
import { StoreState } from 'src/store/configureStore';
import useTranslate from 'src/utils/useTranslate';
import { colorCodes } from '../Formulation/Compare/ZeonCompare/zeon-chart/utils';
import StyledDeleteIcon from 'src/styled_components/StyledDeleteIcon';

const { Text } = Typography

export const CustomStagesModal = ({ stagesModalVisisble, setStagesModalVisible, setStages, stages, workOrder }: any) => {
    const [t] = useTranslate()
    const dispatch = useDispatch()
    const [localStages, setLocalStages] = useState<any[]>([])
    const [unsaved, setunSaved] = useState(false)
    const [current, setCurrent] = useState(0)
    const [deleteStageVisible, setDeleteSubStageVisible] = useState(false)
    const stageDataExist = useSelector((state: StoreState) => state.workOrderDetails.stageDataExist)
    const stageExistLoading = useSelector((state: StoreState) => state.workOrderDetails.stageExistLoading)


    useEffect(() => {
        setLocalStages(stages || JSON.parse(JSON.stringify(defaultStages)))
    }, [stages])

    const addStageGroup = () => {
        if (localStages.length === 10) {
            message.error("You can add only 10 stage groups in a Work Order")
            return
        }
        setLocalStages((prevState: any) => {
            setCurrent(prevState.length)
            return [...prevState, {
                name: `Stage ${prevState.length + 1}`,
                description: "",
                data: [
                    {
                        "type": "formulation_stage",
                        "name": "Formulation"
                    },
                    {
                        "type": "processing_stage",
                        "name": "Processing"
                    },
                    {
                        "type": "characterization_stage",
                        "name": "Characterization"
                    },
                    {
                        "type": "properties_stage",
                        "name": "Properties"
                    },
                ]
            }]
        })
        setunSaved(true)
        message.success(t("stages.addedNewStageGroup"))
    }

    const closeModal = () => {
        setStages(JSON.parse(JSON.stringify(defaultStages)))
        setLocalStages(JSON.parse(JSON.stringify(defaultStages)))
        setunSaved(false)
        setStagesModalVisible(false)
        setCurrent(0)
    }

    return (
        <Modal title={!!workOrder?.work_order_id ? t("Wo.editStages.Header") : t("common.AddCustomStages")} width={1000}
            bodyStyle={{ maxHeight: 600 }}
            maskClosable={false}
            okText={!!workOrder?.work_order_id ? t("stages.editStages") : t("common.add")}
            okButtonProps={{ style: { borderRadius: "12px" } }}
            cancelButtonProps={{ style: { borderRadius: "12px" } }}
            onOk={() => {
                if (!!workOrder?.work_order_id) {
                    dispatch(editWorkOrderRequest({
                        work_order_id: workOrder?.work_order_id,
                        data: {
                            stages: localStages.map(({ data, description, name, identifier }) => ({
                                identifier, name, description, data
                            }))
                        },
                        updateDisplayNames: true
                    }))
                }
                setStages(JSON.parse(JSON.stringify(localStages)))
                setCurrent(0)
                setunSaved(false)
                dispatch(stageExistsCleanup())
                setStagesModalVisible(false)
            }
            }
            visible={stagesModalVisisble}
            onCancel={() => {
                if (unsaved) {
                    Modal.confirm({
                        title: t('workOrderDetails.unsavedChangesPresent'),
                        icon: <ExclamationCircleOutlined />,
                        onOk: () => {
                            if (workOrder?.work_order_id) dispatch(workOrderRequest(workOrder.work_order_id))
                            dispatch(stageExistsCleanup())
                            closeModal()
                        }
                    })
                } else {
                    dispatch(stageExistsCleanup())
                    closeModal()
                }
            }}>
            <Space direction='horizontal' className='gap-0' style={{ width: "100%", overflowX: 'auto', alignItems: 'self-start' }}>
                {localStages.map((stage: any, index: number) => (
                    <>
                        <Space direction='vertical' style={{ minWidth: "200px" }}>
                            <Card style={{
                                borderTop: `3px solid ${colorCodes[index]}`,
                                background: "#FAFAFA"
                            }} bodyStyle={{ display: 'flex', padding: '15px' }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}>
                                    <Text strong style={{ marginLeft: 5 }} editable={{
                                        tooltip: t("common.clicktoeditstagename"),
                                        onChange: (e) => {
                                            if (!!e?.trim()) {
                                                if (localStages.some((res: any, stageIndex: number) => res?.name === e?.trim() && index !== stageIndex)) {
                                                    message.error(`${e} is already present in the stages`)
                                                    return
                                                }
                                                setLocalStages((prevState: any) => {
                                                    prevState.forEach((stage: any, stageIndex: number) => {
                                                        if (stageIndex === index) {
                                                            stage.name = e
                                                        }
                                                    })
                                                    return [...prevState]
                                                })
                                                setunSaved(true)
                                            }
                                        }
                                    }}>{stage?.name||stage?.actual_stage_name}</Text>
                                    <Text strong type="secondary" style={{ marginLeft: 5, marginTop: '0.2rem' }} editable={{
                                        tooltip: t("common.clicktoeditstagedescription"),
                                        onChange: (e) => {
                                            if (!!e?.trim()) {
                                                setLocalStages((prevState: any) => {
                                                    prevState.forEach((stage: any, stageIndex: number) => {
                                                        if (stageIndex === index) {
                                                            stage.description = e
                                                        }
                                                    })
                                                    return [...prevState]
                                                })
                                                setunSaved(true)
                                            }
                                        }
                                    }}>{`${stage?.description || t("common.description")}`}</Text>
                                </div>
                                {localStages.length > 1 && <Tooltip title={`Remove Stage ${index + 1} ?`}>
                                    <StyledDeleteIcon style={{ marginLeft: 'auto', color: '#ff4d4f' }} onClick={() => {
                                        setCurrent(index)
                                        setDeleteSubStageVisible(true)
                                    }} />
                                </Tooltip>}
                            </Card>
                            {stage.data.map((subStage: any, stageIndex: any) => <Card bodyStyle={{ padding: "15px" }}> <span>{subStage.name}</span>
                                <Tooltip title={(subStage.type === 'formulation_stage' && Array.isArray(stageDataExist) && stageDataExist?.find(({ identifier }: any) => identifier === stage.identifier)?.linked_trials_is_existing) && <Space>
                                    <InfoCircleOutlined />
                                    <Text style={{ color: "#ffffff" }}>{`Deletion of formulation stage from Stage ${index + 1} is restricted. Data of Stage ${index + 1} is used in the other stages of the Work Order.`}</Text>
                                </Space>}>
                                    <Checkbox disabled={subStage.type === 'formulation_stage' && Array.isArray(stageDataExist) && stageDataExist?.find(({ identifier }: any) => identifier === stage.identifier)?.linked_trials_is_existing} checked={stage.data.find(({ type }: any) => type === subStage.type)} onChange={(e) => {
                                        if (!e.target.checked) {
                                            setLocalStages((prevState: any) => {
                                                prevState[index].data = defaultStages[0].data.filter((res: any) => stage.data.map(({ type }: any) => type).filter((stageType: any) => stageType !== subStage.type)?.includes(res?.type))
                                                return [...prevState]
                                            })
                                        }
                                        setunSaved(true)
                                        dispatch(setIsEditing(false))
                                    }} style={{ float: "right" }} />
                                </Tooltip>
                            </Card>
                            )}
                            {defaultStages[0].data.filter(({ type }) => !stage?.data.find((subStage: any) => subStage.type === type)).length > 0 && <hr style={{ margin: 0 }} />}
                            {defaultStages[0].data.filter(({ type }) => !stage?.data.find((subStage: any) => subStage.type === type)).map((subStage: any,) => <Card bodyStyle={{ padding: "15px" }}> <span>{subStage.name}</span>
                                <Checkbox checked={stage.data.find(({ type }: any) => type === subStage.type)} onChange={(e) => {
                                    const getIndex = (typeArg: any) => {
                                        if (typeArg === "formulation_stage") return 0
                                        if (typeArg === "processing_stage") {
                                            if (localStages?.[index]?.data.map((dupStage: any) => dupStage.type).includes('characterization_stage')) return localStages?.[index]?.data.findIndex(({ type }: any) => type === "characterization_stage")
                                            else if (localStages?.[index]?.data.map((dupStage: any) => dupStage.type).includes('properties_stage')) return localStages?.[index]?.data.findIndex(({ type }: any) => type === "properties_stage")
                                            else return 1
                                        }
                                        if (typeArg === "characterization_stage") {
                                            if (localStages?.[index]?.data.map((dupStage: any) => dupStage.type).includes('properties_stage')) return localStages?.[index]?.data.findIndex(({ type }: any) => type === "properties_stage")
                                            else return 2
                                        }
                                        if (typeArg === "properties_stage") {
                                            return 3
                                        }
                                    }
                                    setLocalStages((prevState: any) => {
                                        prevState[index].data.splice(getIndex(subStage.type), 0, subStage);
                                        return [...prevState]
                                    })
                                    setunSaved(true)
                                    dispatch(setIsEditing(false))
                                }} style={{ float: "right" }} /></Card>
                            )}
                        </Space>
                        {index !== localStages?.length - 1 && <Space><span style={{ position: 'relative', top: '20px' }}><svg width="23" height="7" viewBox="0 0 23 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23 3.5L18 0.613249V6.38675L23 3.5ZM0.5 4H1.90625V3H0.5V4ZM4.71875 4H7.53125V3H4.71875V4ZM10.3438 4H13.1562V3H10.3438V4ZM15.9688 4H18.7812V3H15.9688V4Z" fill="#1F1F1F" />
                        </svg></span>
                        </Space>}
                    </>))
                }
                <Space><span style={{ position: 'relative', top: '20px' }}><svg width="23" height="7" viewBox="0 0 23 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23 3.5L18 0.613249V6.38675L23 3.5ZM0.5 4H1.90625V3H0.5V4ZM4.71875 4H7.53125V3H4.71875V4ZM10.3438 4H13.1562V3H10.3438V4ZM15.9688 4H18.7812V3H15.9688V4Z" fill="#1F1F1F" />
                </svg></span>
                </Space>
                <Space style={{ alignItems: 'start' }}>
                    <Card style={{
                        borderTop: "2px solid #d9d9d9",
                        background: "#FAFAFA"
                    }} bodyStyle={{
                        padding: "15px"
                    }}>
                        <Tooltip title={localStages.length === 10 && "You can add only 10 stage groups in a Work Order"}>
                            <StyledButton disabled={localStages.length === 10} type="link" block onClick={addStageGroup}>{t("Wo.details.addStageGroup")}</StyledButton>
                        </Tooltip>
                    </Card>
                </Space>
            </Space>
            <Modal
                title={t("common.DeleteStage?")}
                visible={deleteStageVisible}
                onCancel={() => { setDeleteSubStageVisible(false) }}
                footer={null}
            >
                <Spin spinning={stageExistLoading === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
                    <Space direction="vertical" size="large" style={{ width: "100%" }}>
                        {(!!workOrder?.work_order_id && Array.isArray(stageDataExist) && stageDataExist?.find(({ identifier }: any) => identifier === stages?.[current]?.identifier)?.linked_trials_is_existing) ?
                            <Text strong type="secondary">{`Deletion of Stage ${current + 1} is restricted. Data of Stage ${current + 1} is used in the other stages of the Work Order.`}</Text> :
                            <Text strong type="secondary">{`${t("stages.removingStage")} ${current + 1}. ${t("stages.areYouSure")}`}</Text>}
                        <Row justify="end">
                            <Space>
                                {/* {(!workOrder?.work_order_id || !(Array.isArray(stageDataExist) && stageDataExist?.find(({ identifier }: any) => identifier === stages?.[current]?.identifier)?.linked_trials_is_existing)) &&
                                    <StyledButton type="default" onClick={() => {
                                        setDeleteSubStageVisible(false)
                                    }}>
                                        {"Cancel"}
                                    </StyledButton>
                                } */}
                                <StyledButton type="primary" danger onClick={() => {
                                    if (!!workOrder?.work_order_id && (Array.isArray(stageDataExist) && stageDataExist?.find(({ identifier }: any) => identifier === stages?.[current]?.identifier)?.linked_trials_is_existing)) {
                                        setDeleteSubStageVisible(false)
                                    }
                                    else {
                                        setLocalStages([...localStages.filter((res: any, stageId: number) => stageId !== current)])
                                        setunSaved(true)
                                        setCurrent(0)
                                        setDeleteSubStageVisible(false)
                                    }
                                }}>
                                    {(!!workOrder?.work_order_id && (Array.isArray(stageDataExist) && stageDataExist?.find(({ identifier }: any) => identifier === stages?.[current]?.identifier)?.linked_trials_is_existing)) ? t("common.Ok") : t("common.Confirm")}
                                </StyledButton>
                            </Space>
                        </Row>
                    </Space>
                </Spin>
            </Modal>
        </Modal >
    )
}
