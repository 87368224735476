import { put, takeLatest, call, select, take } from "redux-saga/effects"
import { eventChannel, END } from "redux-saga"
import {
	insightsSocketConnect,
	insightsSuccess,
	insightsFailure,
	suggestedExpInsights,
	getCustomInsightsDataRequest,
	getCustomInsightsDataSuccess,
	getCustomInsightsDataFailure,
} from "../actions/insights"
import { insightsStream } from "src/services/insights/insights"
import { messages } from "src/utils/hooks"
import { message, notification } from "antd"
import { LanguageUnion } from "src/utils/useTranslate"
import { StoreState } from "../configureStore"
import jwtManager from "src/utils/jwtManager"
import { getCustomInsightsDataApi } from "src/services/explainableAI"

function createInsightsChannel(socket: WebSocket) {
	return eventChannel((emit) => {
		socket.onmessage = (event: any) => {
			emit(event.data)
		}

		socket.onclose = () => {
			emit(END)
		}

		const unsubscribe = () => {
			socket.onmessage = null
		}

		return unsubscribe
	})
}

function* insightsSaga({
	payload: inputPayload,
}: any): Generator<any, any, any> {
	const { user_id: key } = yield select(
		(state) => state.login.loginResponse
	)
	const socket = yield call(insightsStream, key)
	const socketChannel = yield call(createInsightsChannel, socket)
	inputPayload.key = key
	const token = jwtManager.getToken()
	inputPayload.token = token
	socket.send(JSON.stringify(inputPayload))

	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)

	try {
		const payload = JSON.parse(yield take(socketChannel))

		if (payload.status === "Success" && payload.type === "ack") {
			const { ack_id } = payload.data
			const results = JSON.parse(JSON.parse(yield take(socketChannel)))

			if (results.status === "Success" && results.data.ack_id === ack_id) {
				if (
					(results.action === "regression_stats" &&
						results.data.results[0].insights.length === 0) ||
					(results.action === "predicted_v_observed" &&
						(Object.keys(results.data.results?.[0]?.insights[0]?.data.x)
							?.length === 0 ||
							results.data.results[0].insights.length === 0 ||
							Object.keys(results.data.results?.[0]?.insights?.[0]?.data?.y)
								?.length === 0))
				) {
					if (!!token) {
						yield put(insightsFailure(messages[ln].internal_server_error))
						notification.error({
							message: messages[ln].could_not_fetch_results,
						})
					}
				} else {
					if (results.action === "visualize") {
						yield put(suggestedExpInsights(results.data?.results[0]?.insights))
					} else if (results.action === "predicted_properties_vs_ingredients") {
						yield put(suggestedExpInsights(results.data?.results))
					} else if (results.action === "predicted_properties_vs_processing") {
						yield put(suggestedExpInsights(results.data?.results))
					} else if (results.action === "predicted_properties_vs_costing") {
						yield put(suggestedExpInsights(results.data?.results))
					} else if (results.action === "clustering") {
						yield put(suggestedExpInsights(results.data?.results))
					} else if (results.action === "pareto") {
						yield put(suggestedExpInsights(results.data?.results.data))
					} else {
						if (!!token) {
							yield put(insightsSuccess(results))
							if (results.action !== "results") {
								notification.success({
									message: messages[ln].custom_insights_results,
								})
							}
						}
					}
				}
			} else {
				if (!!token) {
					yield put(insightsFailure(results.message))
					notification.info({ message: results.message })
				}
			}
		} else {
			if (!!token) {
				yield put(insightsFailure(payload.message))
				notification.info({ message: payload.message })
			}
		}
	} catch (err) {
		insightsFailure(messages[ln].internal_server_error)
		message.error(messages[ln].internal_server_error)
	} finally {
		socket.close()
		socketChannel.close()
	}
}

function* getCustomInsightsDataSaga({ payload }: any): Generator<any, any, any> {
	const ln: LanguageUnion = yield select((state: StoreState) => state.language.current)
	try {
		const { defaultHeaders } = yield select((state) => state)
		const headers = { ...defaultHeaders, token: jwtManager.getToken() }
		const {
			data: {
				data, status, message: errorMessage
			},
		} = yield call(getCustomInsightsDataApi, payload, headers)
		if (status === "Success") {
			yield put(getCustomInsightsDataSuccess(data))
		} else {
			yield put(getCustomInsightsDataFailure(errorMessage))
			notification.error({
				message: errorMessage
			})
		}
	} catch (error) {
		yield put(getCustomInsightsDataFailure(error))
		message.error(messages[ln].internal_server_error)
	}
}

export default function* rootSaga(): Generator<any, any, any> {
	yield takeLatest(insightsSocketConnect, insightsSaga)
	yield takeLatest(getCustomInsightsDataRequest, getCustomInsightsDataSaga)
}
