import { AliasToken } from "antd/lib/theme/internal";
import { Headers } from "src/services/interface";

const localPlatform = process.env.REACT_APP_CURRENT_APP ?? "labs";
export const currentPlatform =
  window.location.hostname === "localhost"
    ? localPlatform
    : window.location.href.includes("lab")
      ? "labs"
      : "connect";

export enum baseTypes {
  INGREDIENTS = "Ingredients",
  PROPERTIES = "Properties",
}

export const KuritaCompanyId = "COMP7FWUZ9UYI12023";
export const GulfCompanyId = "COMP140MGVSEQE2023";

export const molEnabledUnits = ["g", "kg", "mg"];

export enum AsyncStates {
  INITIAL = "INITIAL",
  LOADING = "LOADING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}

export const commonDefaultPageSize = 10;

export const permissions = {
  projectAdmin: "project_admin",
  editor: "editor",
  viewer: "viewer",
};

export const userPermissions: any = {
  project_admin: "Project Admin",
  editor: "Editor",
  viewer: "Viewer",
};

export const userRoles = {
  admin: "admin",
  member: "member",
};

export const projectStatus = {
  in_progress: "in_progress",
  archived: "archived",
  completed: "completed",
};

export const POLYMERIZE_EMAIL = "contact@polymerize.io"

export const defaultStages = [
  {
    name: "Stage 1",
    description: "",
    data: [
      {
        type: "formulation_stage",
        name: "Formulation",
      },
      {
        type: "processing_stage",
        name: "Processing",
      },
      {
        type: "characterization_stage",
        name: "Characterization",
      },
      {
        type: "properties_stage",
        name: "Properties",
      },
    ],
  },
];

export const platformInfo: any = {
  connect: {
    name: "Polymerize Connect",
    link: "https://polymerize.io/connect/",
  },
  labs: {
    name: "Polymerize Labs",
    link: "https://polymerize.io/polymerize-labs/",
  },
};

export const defaultHeaders: Headers = {
  "Content-Type": "application/json" || "multipart/form-data",
  "Access-Control-Allow-Origin": "*",
  platform: currentPlatform,
};

export const projectType = {
  celsure: "celsure",
};

export const maxellForwardVersion = [34, 37, 38, 39];

export const EXP_PAGE_SIZE = 20;

export const sendCredsHostnames = [
  "labs.polymerize.io",
  "connect.polymerize.io",
  "uat.labs.polymerize.io",
  "sit.labs.polymerize.io",
  "sit.connect.polymerize.io"
];

export const zeonFixedCategoryList = [
  "POLYMER",
  "FILLER",
  "COUPLING AGENT",
  "STABILIZER",
  "RETARDER",
  "PROCESS AID",
  "PLASTICIZER",
  "ACTIVATOR",
  "METAL OXIDE",
  "COLOR",
  "FLAME RETARDANT",
  "METAL SALTS",
  "ORGANIC ACID",
  "ACID SCAVENGER",
  "COAGENT",
  "CURATIVE",
];
export const sortingConditonKeys = [
  "ingredients",
  "processing",
  "characterizations",
  "properties",
  "stages",
  "material",
  "application",
  "order_type",
  "grade",
  "descriptor",
];

export const fileUploadtemplateUrl =
  "https://polymerize-misc.s3.ap-southeast-1.amazonaws.com/Data+Templates/Polymerize_data_upload_sample.xlsx";

export const nestleFileUploadTemplateUrl =
  "https://s3.ap-southeast-1.amazonaws.com/polymerize-uploads/COMPJ7X6Q4GMC42023/uploads/Lab Worksheet (Single Experiment).xlsx";

export const PLUSS_HS05_MODEL_CONFIG = {
  objective: "HS05",
  is_inverse: false,
  access: true,
  is_custom: true,
  comments: "Custom-trained",
  input_type: ["ingredients"],
  inputs_ingredients: [
    "attapulgite_clay_-_350",
    "bacl2_2h2o",
    "borax",
    "caso4",
    "fumed_silica",
    "kcl",
    "na2so4",
    "nacl",
    "nh4cl",
    "sodium_polyacrylate_ck-766",
    "water",
  ],
  inputs: [
    "attapulgite_clay_-_350",
    "bacl2_2h2o",
    "borax",
    "caso4",
    "fumed_silica",
    "kcl",
    "na2so4",
    "nacl",
    "nh4cl",
    "sodium_polyacrylate_ck-766",
    "water",
  ],
  inputs_range: {
    "sodium_polyacrylate_ck-766": {
      min: 1.18,
      max: 1.18,
    },
    nacl: {
      min: 3.78,
      max: 25,
    },
    water: {
      min: 27.82,
      max: 86.54,
    },
    "attapulgite_clay_-_350": {
      min: 5,
      max: 10.1,
    },
    fumed_silica: {
      min: 0.05,
      max: 1.84,
    },
    caso4: {
      min: 4.81,
      max: 14.77,
    },
    kcl: {
      min: 2.4,
      max: 25,
    },
    bacl2_2h2o: {
      min: 3.85,
      max: 15.72,
    },
    na2so4: {
      min: 1.92,
      max: 48.05,
    },
    nh4cl: {
      min: 1.84,
      max: 16.82,
    },
    borax: {
      min: 0,
      max: 2.84,
    },
  },
  output_type: "properties",
  version: -1,
  created_on: "2022-04-22T10:22:02.519000",
  model_display_type: "custom_ai_singlestage",
};

export const recipeDistributionColorCodes = [
  "#4169E1",
  "#32CD32",
  "#FF6347",
  "#FFD700",
  "#9370DB",
  "#00FA9A",
  "#4682B4",
  "#FF8C00",
  "#6A5ACD",
  "#66CDAA",
  "#CD5C5C",
  "#1E90FF",
  "#2E8B57",
  "#FF4500",
  "#00BFFF",
  "#7CFC00",
  "#BA55D3",
  "#00CED1",
  "#E9967A",
  "#C71585",
  "7FFF00",
  "6495ED",
  "FFA500",
  "F08080",
  "00FF7F",
];

export const allowedUnitCategories = ["weight", "ratio"]

export const inventoryUploadTemplateUrl = "https://polymerize-misc.s3.ap-southeast-1.amazonaws.com/Inventory+Upload+Template+-+V2.xlsx";

export const antdTheme: Partial<AliasToken> = {
  colorPrimary: '#1677ff',
  colorSuccess: '#52c41a',
  colorWarning: '#faad14',
  colorError: '#ff4d4f',
  colorLink: '#1677ff',

  fontSize: 13,
  fontSizeSM: 11,
  fontSizeLG: 14,
  fontSizeXL: 16,
  fontSizeHeading1: 32,
  fontSizeHeading2: 26,
  fontSizeHeading3: 20,
  fontSizeHeading4: 16,
  fontSizeHeading5: 14,

  lineHeight: 1.6153846153846154,
  lineHeightSM: 1.8,
  lineHeightLG: 1.5714285714285714,
  lineHeightHeading1: 1.2352941176470589,
  lineHeightHeading2: 1.2857142857142858,
  lineHeightHeading3: 1.3636363636363635,
  lineHeightHeading4: 1.4444444444444444,
  lineHeightHeading5: 1.5714285714285714,

  marginXXS: 4,
  marginXS: 8,
  marginSM: 12,
  margin: 16,
  marginMD: 20,
  marginLG: 24,
  marginXL: 32,
  marginXXL: 48,

  paddingXXS: 4,
  paddingXS: 8,
  paddingSM: 12,
  padding: 16,
  paddingMD: 20,
  paddingLG: 24,
  paddingXL: 32
}
