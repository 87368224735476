import { motion } from 'framer-motion'
import useTranslate from 'src/utils/useTranslate'
import { StyledPageHeader } from 'src/styled_components/StyledPageHeader'
import { Breadcrumb, Space, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { StyledButton } from 'src/styled_components/StyledButton'
import { Link } from 'react-router-dom'
import MetadataTable from './MetadataTable/MetadataTable'
import { useDispatch } from 'react-redux'
import { qcGetMetadataClear, qcGetNameDetailsRequest, qcMetadataEditDrawerVisible } from 'src/store/actions/qualityCheck'
import { useEffect, useState } from 'react'
import NameDetailsDrawer from './NameDetailsDrawer/NameDetailsDrawer'

const QualityCheckMetadata = () => {
  const [t] = useTranslate()
  const dispatch = useDispatch()

  const [nameDetailsDrawerVisible, setNameDetailsDrawerVisible] = useState(false)

  useEffect(() => {
    dispatch(qcGetNameDetailsRequest())
  }, [dispatch])

  return (
    <motion.div
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 20 }}
      transition={{ type: 'just' }}
      style={{
        height: 'calc(-2.5rem + 100vh)',
        overflowY: 'hidden',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '5%'
      }}
    >
      <StyledPageHeader
        ghost={false}
        title={
          <Space direction="vertical" size={'small'}>
            <Typography.Title
              level={3}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 8
              }}
            >
              {t(`qc.metadata.title`)}
            </Typography.Title>
            <Breadcrumb
              items={[
                {
                  title: (
                    <Link
                      to="/quality-check"
                      style={{
                        padding: '0 4px',
                        margin: '0 2px',
                        textDecoration: 'none'
                      }}
                    >
                      {t('common.qualityCheck')}
                    </Link>
                  )
                },
                {
                  title: (
                    <Typography.Title
                      level={5}
                      style={{
                        padding: '0 4px',
                        margin: '0 2px'
                      }}
                    >
                      {t('qc.metadata.title')}
                    </Typography.Title>
                  )
                }
              ]}
            />
          </Space>
        }
        style={{ height: '5%' }}
        extra={
        <Space>
          <AddSupplierProfileButton />
          <StyledButton onClick={()=>{
            setNameDetailsDrawerVisible(true)
          }} >
            {t('qc.metadata.addOrEditNames')}
          </StyledButton>
          </Space>}
      />
      <MetadataTable/>
      <NameDetailsDrawer isOpen={nameDetailsDrawerVisible} setIsOpen={setNameDetailsDrawerVisible} />
    </motion.div>
  )
}

export default QualityCheckMetadata

export const AddSupplierProfileButton = () => {
  const [t] = useTranslate()
  const dispatch = useDispatch()

  return (
    <StyledButton
      onClick={() => {
        dispatch(qcGetMetadataClear())
        dispatch(qcMetadataEditDrawerVisible(true))
      }}
      style={{
        outline: 'none'
      }}
      icon={<PlusOutlined />}
      type="primary"
    >
      {t('qc.metadata.add')}
    </StyledButton>
  )
}
