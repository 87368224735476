import { Skeleton, Space, Tabs } from "antd";
import { Suspense, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AsyncStates, projectType } from "src/constants";
import { StoreState } from "src/store/configureStore";
import useTranslate from "src/utils/useTranslate";
import { useLocation, useParams } from "react-router-dom";
import { setCelsureWo } from "src/store/actions/celsureWo";
import { workOrderRequest } from "src/store/actions/workOrderDetails";
import { WOPageHeader } from "./components/WOPageHeader";
import { CommentsSection } from "../CommentsSection";
import { history } from "src";
import "./WorkOrderContainer.scss"
import Text from 'antd/lib/typography/Text';
import { CustomPrompt } from "src/utils/CustomPrompts";
import { StyledButton } from "src/styled_components/StyledButton";

interface WorkOrderContainerProps {
  children: React.ReactNode;
}

export const WorkOrderContainer = (props: WorkOrderContainerProps) => {
  let { woId }: { woId: string } = useParams();
  const location = useLocation();

  const [t] = useTranslate();
  const { workOrder, workOrderStatus, editingState } = useSelector((state: StoreState) => state.workOrderDetails || {});
  const isEditing = useSelector((state: StoreState) => state.isEditing);

  const workOrderTabs = useMemo(() => [
    {
      key: "1",
      subRoute: "",
      label: t("common.stages"),
    },
    {
      key: "2",
      subRoute: "details",
      label: t("workOrderDetails.details"),
    },
    {
      key: "3",
      subRoute: "attachments",
      label: t("common.attachments"),
    },
    {
      key: "4",
      subRoute: "procedures",
      label: t("workOrderDetails.procedures"),
    },
    {
      key: "5",
      subRoute: "chemdraw",
      label: t("workOrder.chemicalDrawing"),
    },
  ], [t]);

  const project_type = useSelector(
    (state: StoreState) => state.login.loginResponse.project_type
  );

  const { celsureWoList, celsureWo } = useSelector(
    (state: StoreState) => state.celsureWo
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (project_type === projectType.celsure) {
      if (celsureWo?.work_order_id !== woId) {
        dispatch(
          setCelsureWo(celsureWoList.find((wo) => wo.work_order_id === woId))
        );
      }
    } else {
      if (woId !== workOrder?.work_order_id) dispatch(workOrderRequest(woId));
    }
  }, [
    celsureWo?.work_order_id,
    celsureWoList,
    dispatch,
    project_type,
    woId,
    workOrder?.work_order_id,
  ]);

  useEffect(() => {
    let timeoutID: any
    if (workOrder?.data_processing) {
      timeoutID = setTimeout(() => {
        dispatch(workOrderRequest(workOrder?.work_order_id ? workOrder?.work_order_id : woId))
      }, 3000);
    }
    return () => {
      if (timeoutID) {
        clearTimeout(timeoutID);
      }
    };
  }, [dispatch, woId, workOrder?.data_processing, workOrder?.work_order_id])


  const changeTab = (woId: string, event: any) => {
    history.push(
      `/work-orders/details/${woId}/${workOrderTabs[Number(event) - 1].subRoute
      }`
    );
    return
  }

  return (
    <>
      <div>
        <CustomPrompt isEditing={editingState || isEditing} message={`${t("common.unsavedChangesLost")}!.`} />
        {project_type !== projectType.celsure && (
          <>
            <WOPageHeader workOrder={workOrder} />
            {workOrderStatus !== AsyncStates.ERROR ?
              <Tabs
                defaultActiveKey="1"
                size="large"
                items={workOrderTabs}
                activeKey={workOrderTabs?.find(
                  (value: any) => value.subRoute === location.pathname.split("/").pop()
                )?.key || "1"}
                onChange={(event) => {
                  changeTab(woId, event)
                }}
              />
              : workOrder?.data_processing ?
                <div className="work__order__error">
                  <Text strong>{t('common.stillProcessingPleaseWait')}<StyledButton style={{ padding: 0, outline: "none" }} type="link" onClick={() => { dispatch(workOrderRequest(workOrder?.work_order_id ? workOrder?.work_order_id : woId)) }}>{t('common.reload')}</StyledButton></Text>
                </div> : null
            }
          </>)}
      </div>
      <div style={{ marginBottom: "1rem" }}>
        <Space
          size="large"
          direction="vertical"
          style={{
            width: "100%",
          }}
        >
          {props.children}
        </Space>
        <Suspense fallback={<Skeleton active avatar />}>
          <CommentsSection />
        </Suspense>
      </div>
    </>
  );
};