import React, { useEffect, useMemo, useState } from "react";
// import { useDebounce } from "src/utils/useDebounce"
import clsx from "clsx";
import { useTable, useFlexLayout, useResizeColumns, useSortBy, useGlobalFilter } from "react-table";
import Cell from "./cells/Cell";
import Header from "./header/Header";
import { PlusSquareFilled } from "@ant-design/icons";
import "./style.css";
// import { ProjectSelection } from "../ProjectSelection";
import { CreateProjectModal } from "../Project/CreateProjectModal";
import useTranslate from "src/utils/useTranslate";

const defaultColumn = {
    minWidth: 50,
    maxWidth: 400,
    Cell: Cell,
    Header: Header
};

const CustomTable = ({ columns, woSearchTerm, noDataText, data, dispatch: dataDispatch, skipReset, allowEditing = false, onRow, rowClassName = "table__row", headerClassName = "table__header", tableClassName = "custom__table", rowSelection = {}, isProjects = undefined, tabKey, setWorkOrderFilters, setSortBy }: any) => {
    // const {
    //     selectedRowKeys = [],
    //     onChange,
    // } = rowSelection
    const [t] = useTranslate()
    const [createProjectModal, setCreateProjectModal] = useState<boolean>(false)

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setGlobalFilter, state
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            dataDispatch,
            autoResetSortBy: !skipReset,
            autoResetFilters: !skipReset,
            autoResetRowState: !skipReset,
            manualSortBy: true,
            filterTypes: {
                // Custom filter function for exact match search
                text: (rows: any[], id: string | number, filterValue: string) => {
                    return rows.filter(row => {
                        const rowValue = row.values[id];
                        return rowValue !== undefined && rowValue.toLowerCase() === filterValue.toLowerCase();
                    });
                },
            },
            initialState: {
                sortBy: [
                    {
                        id: 'created',
                        desc: true,
                    },
                ],
            },
        },
        useFlexLayout,
        useResizeColumns,
        useGlobalFilter,
        useSortBy,
    );

    const isTableResizing = useMemo(() => {
        for (let headerGroup of headerGroups) {
            for (let column of headerGroup.headers) {
                if (column.isResizing) {
                    return true;
                }
            }
        }

        return false;
    }, [headerGroups])

    useEffect(() => {
        setGlobalFilter(woSearchTerm)
    }, [setGlobalFilter, woSearchTerm]);

    useEffect(() => {
        if(state?.sortBy && setSortBy) {
            setSortBy(state?.sortBy[0]);
        }
    }, [setSortBy, state?.sortBy])

    // const customHeaders = headerGroups?.[0]?.headers
    // const debouncedValue = useDebounce(searchInputState.searchTerm)

    // const columnKeys = columns?.map((col: any) => col?.accessor)

    // const handleFilterBasedOnSearch = useCallback(() => {
    //     const newRows = rows.filter((row: any) => {
    //         if (!!Object.keys(row?.values || {}).length) {
    //             const res = Object.values(row.values || {})
    //             console.log({ res, row, debouncedValue, headerGroups })

    //         }
    //     })

    // }, [debouncedValue, rows])

    // useEffect(() => {
    //     if (!!debouncedValue?.length) {
    //         handleFilterBasedOnSearch()
    //     }
    // }, [debouncedValue, handleFilterBasedOnSearch])


    // console.log({ state, searchInputState, columns, rows })

    return (
        <>
            <div {...getTableProps()}
                className={clsx("table", {
                    [tableClassName]: tableClassName ? tableClassName : "",
                    noselect: isTableResizing,
                })}
                style={{ width: "100%", overflowX:'scroll' }}>
                <div style={{overflowX:'visible', overflowY:'visible', width:'max-content', position:'sticky', top:0, left:0, zIndex:100}}>
                    {headerGroups.map((headerGroup: any, idx:number) => {
                        return (
                                <div {...headerGroup.getHeaderGroupProps()} className={headerClassName} key={idx} >
                                    {headerGroup.headers.map((column: any) => column.render("Header", {
                                        rowSelection: Object.keys(rowSelection || {}).length,
                                        SetGlobalFilter: setGlobalFilter,
                                        Width: column.width, tabKey: tabKey,
                                        setWorkOrderFilters: setWorkOrderFilters
                                    }))}
                                </div>
                        )
                    })}
                </div>
                <div {...getTableBodyProps()} style={{ maxHeight: "60vh", overflowY: "visible", width:'max-content' }}>
                    {
                        rows.length === 0 ?
                            <>
                                <div style={{ margin: "auto", width: "100%", padding: "10px" }}>
                                    <p>{noDataText}</p>
                                </div>
                            </> :
                            <>
                                {rows.map((row: any, i: any) => {
                                    prepareRow(row);
                                    return (
                                        <div {...row.getRowProps()} style={{ ...row.original.status === "closed" && { opacity: "0.8", backgroundColor: "#f1f1f1" }, ...row.getRowProps().style }} className={clsx({ "tr__disabled": allowEditing === false, [rowClassName]: true })} onClick={() => onRow(row)}>
                                            {row.cells.map((cell: any) => {
                                                return <div {...cell.getCellProps()} className='td table__row__data'>
                                                    {cell.render("Cell", { allowEditing })}
                                                </div>
                                            })}
                                        </div>
                                    );
                                })}
                            </>
                    }
                    {
                        allowEditing &&
                        <div className='add-row' onClick={() => dataDispatch({ type: "add_row" })}>
                            <span className='svg-icon svg-gray' style={{ marginRight: 4 }}>
                                <PlusSquareFilled />
                            </span>{t("common.New")}</div>
                    }
                </div>
            </div >
            <CreateProjectModal
                setCreateProjectModal={setCreateProjectModal}
                createProjectModal={createProjectModal}
            />
        </>
    );
}

export default React.memo(CustomTable);