import { EyeInvisibleOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Cascader, Col, Form, Input, InputNumber, Row, Select, Tag, Typography } from 'antd';
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux';
import { StoreState } from 'src/store/configureStore';
import { StyledButton } from 'src/styled_components/StyledButton';
import { StyledCard } from 'src/styled_components/StyledCard';
import useTranslate from 'src/utils/useTranslate';
import { OPS } from './RecipeDistribution';
import { recipeDistributionColorCodes as colorCodes } from "src/constants"
import { useRequiredFieldStar } from 'src/components/Common/useRequiredFieldStar';
import StyledDeleteIcon from 'src/styled_components/StyledDeleteIcon';
import { useValue } from 'src/utils/useValue';


type ReciepeDistributionFilterFormProps = any

const { Text } = Typography

export const ReciepeDistributionFilterForm = ({ reciepeExperiments, setVisibilityArray, visibilityArray, filterValues, setFilterValues, recipeDistributionFrom, metaInputIngredients }: ReciepeDistributionFilterFormProps) => {
  const [reciepeDistributionFilterForm] = Form.useForm()
  const [t] = useTranslate()
  const requiredFieldStar = useRequiredFieldStar()
  const { formatter, parser } = useValue()

  const configs = useSelector((state: StoreState) => state.configs.features)
  const {
    recipeDistributionData,
    recipeDistributionDisplayNames,
  } = useSelector((state: StoreState) => state.dataSummary)
  const {
    processingProfilesList
  } = useSelector((state: StoreState) => state.workOrderDetails)
  const displayNames = useSelector((state: StoreState) => state.displayNames.data)
  const collator = useMemo(() => new Intl.Collator([], { numeric: true }), [])
  const getstruct = (arg: any[]) => {
    let test = {}
    for (const i in arg) {
      if ((Number(i) || Number(i) === 0)) { test = { ...test, ...arg[i] } } else { test = { ...test, ...{ [i]: arg[i] } } }
    }
    return test
  }


  const getCascaderOptions = (parameter: string) => {
    const recipeData: any[] = recipeDistributionFrom === "data-summary" ? recipeDistributionDisplayNames?.[parameter] ?? [] : Object.keys(reciepeExperiments[0]?.[parameter] || {})

    if (['properties', 'processing'].includes(parameter) && Boolean(configs?.nestle_configs)) {
      const data = reciepeExperiments?.length > 0 ? reciepeExperiments?.flatMap(({ properties }: any) => properties?.flatMap((value: any) => value)) : []

      const variation_ids = [...new Set(reciepeExperiments.flatMap((distribution: any) => distribution.properties).map((recipe: any) => recipe?.variation_id))]

      if (parameter === 'processing' && Boolean(configs?.nestle_configs) && recipeDistributionFrom === "inverse-prediction") {
        return recipeData.map((processingParameter) => ({
          label: processingParameter,
          value: processingParameter,
          children: [...new Set(reciepeExperiments.map((reciepeExperiment: any) => reciepeExperiment.processing[processingParameter].value))].map((processingName: any) => ({
            label: processingName,
            value: processingName
          }))
        }))
      }

      return parameter === 'processing' ? [...new Set(recipeDistributionData?.flatMap(value => value.processing.flatMap((value: any) => value?.meta?.profile_id)))].map(value => ({ value, label: processingProfilesList.find(({ profile_id }: any) => profile_id === value).name }))
        : data.length > 0 ? variation_ids.map((var_id: any) => ({
          value: var_id,
          label: displayNames?.characterization_methods[var_id]?.name,
          children: Object.keys(getstruct(data.filter(((recipe: any) => recipe?.variation_id)).map(({ data }: any) => data))).map(pro_id => ({ value: pro_id, label: displayNames?.properties[pro_id].name })
          ).sort((a, b) =>
            a?.label?.toLowerCase() < b?.label?.toLowerCase() ? -1 : a?.label?.toLowerCase() > b?.label?.toLowerCase() ? 1 : 0
          )
        })
        ).sort((a, b) =>
          a?.label?.toLowerCase() < b?.label?.toLowerCase() ? -1 : a?.label?.toLowerCase() > b?.label?.toLowerCase() ? 1 : 0
        ) : []
    } else if (parameter === 'cost') {
      return ([
        { value: 'total_cost', label: 'Total Cost' },
        ...[...new Set(reciepeExperiments?.flatMap(({ cost }: any) => cost?.ingredients && Object.keys(cost?.ingredients)))].map((value: any) => ({ value, label: displayNames?.ingredients[value]?.name })).sort((a: any, b: any) => collator.compare(a.label, b.label))
      ])
    } else if(parameter === "ingredients") {
      return reciepeExperiments?.length > 0 ? [...recipeData?.map(value => ({ 
        value: value, 
        label: (
          <>
            {displayNames.hasOwnProperty(parameter) ? displayNames[parameter][value]?.name || reciepeExperiments[0]?.[parameter]?.[value]?.name || recipeDistributionDisplayNames?.linked_trials?.[value] || value : displayNames['properties'][value]?.name}
            &nbsp;&nbsp;{Object.keys(metaInputIngredients || {}).includes(String(value)) || Object.values(metaInputIngredients || {}).includes(String(value)) ? <Tag style={{ padding: '2px 6px', fontSize: '10px', lineHeight: "12px" }} color="processing">{t("common.New")}</Tag> : null}
          </>
        ),
        labelKey: displayNames.hasOwnProperty(parameter) ? displayNames[parameter][value]?.name || reciepeExperiments[0]?.[parameter]?.[value]?.name || recipeDistributionDisplayNames?.linked_trials?.[value] || value : displayNames['properties'][value]?.name
      }))
      ].sort((a, b) =>
        a?.labelKey?.toLowerCase() < b?.labelKey?.toLowerCase() ? -1 : a?.labelKey?.toLowerCase() > b?.labelKey?.toLowerCase() ? 1 : 0
      ) : []
    } else {
      return reciepeExperiments?.length > 0 ? [...recipeData?.map(value => ({ 
        value: value, 
        label: displayNames.hasOwnProperty(parameter) ? displayNames[parameter][value]?.name || reciepeExperiments[0]?.[parameter]?.[value]?.name || recipeDistributionDisplayNames?.linked_trials?.[value] || value : displayNames['properties'][value]?.name
      }))
      ].sort((a, b) =>
        a?.label?.toLowerCase() < b?.label?.toLowerCase() ? -1 : a?.label?.toLowerCase() > b?.label?.toLowerCase() ? 1 : 0
      ) : []
    }
  }

  const options: any = reciepeExperiments?.length > 0 ? [
    {
      value: 'ingredients',
      label: t('common.ingredients'),
      children: [...getCascaderOptions('ingredients')]
    },
    {
      ...((recipeDistributionFrom !== "data-summary" && reciepeExperiments[0]?.predicted_properties) && {
        value: 'properties',
        label: t('common.properties'),
        children: [...getCascaderOptions('predicted_properties')],
      })
    },
    {
      ...((recipeDistributionFrom === "data-summary" && !!recipeDistributionDisplayNames?.properties?.length && reciepeExperiments.flatMap(({ properties }: any) => properties).length) && {
        value: 'properties',
        label: t('common.properties'),
        children: [...getCascaderOptions('properties')],
      })
    },
    {
      ...((recipeDistributionFrom === "data-summary" ? Boolean(configs?.nestle_configs) ? [...new Set(recipeDistributionData.flatMap(value => value.processing.flatMap((value: any) => value?.meta?.profile_id)))] : !!recipeDistributionDisplayNames?.processing?.length : reciepeExperiments[0]?.processing && JSON.stringify(reciepeExperiments[0]?.processing) !== '{}') && {
        value: 'processing',
        label: t('common.processing'),
        children: [...getCascaderOptions('processing')]
      })
    },
    {
      ...(Boolean(configs?.work_order_costing) && reciepeExperiments[0].hasOwnProperty('cost') && ({
        value: 'cost',
        label: t('inventory.costing'),
        children: [...getCascaderOptions('cost')]
      }))
    },
    ...(reciepeExperiments?.some((wo: any) => wo.work_order_id) ? ['work_order'] : []).map(value => ({ label: t('common.workOrder'), value })),
    ...(reciepeExperiments?.some((wo: any) => wo.material) ? ['material'] : []).map(value => ({ label: t('common.material'), value })),
    ...(reciepeExperiments?.some((wo: any) => wo.grade) ? ['grade'] : []).map(value => ({ label: t('common.grade'), value }))
  ].filter(values => typeof values === 'object' && values !== null && Object.keys(values).length !== 0) : []

  const displayRender = (labels: string[]) => {
    return labels.map((label, idx) => (
      <>
        {label}
        {idx < labels.length - 1 && <span>/</span>}
      </>
    ))
  }    

  useEffect(() => {
    reciepeDistributionFilterForm.setFieldsValue({
      recipeFilters: [{
        op: "",
        param: [],
        val: null
      }]
    })

  }, [reciepeDistributionFilterForm])

  const getOperators = (values: any) => {
    const valueType = [...new Set(reciepeExperiments?.map((value: any) => value?.[values?.[0]]?.[values?.[1]]?.value))]?.find((value: any) => value !== '')
    const isCategorical = typeof (Number(valueType) || valueType) === "string"
    if (isCategorical) {
      return ["="]
    } else if (Boolean(configs?.nestle_configs) && values?.[0] === 'processing') {
      return ['exists']
    } else if (values?.[0] === 'cost') {
      return ['=', '<', '<=', '>', '>=']
    } else {
      return OPS
    }
  }

  const getOptions = (type: string, index: number) => {
    if (type === "work_order") {
      const selectedWoIds = filterValues.filter((filterElement: any) => filterElement?.param?.[0] === type).map((workOrder: any) => workOrder.type)
      const options = Array.from(new Set(reciepeExperiments.map((exp: any) => exp.work_order_id))).map((woId: any) => ({ label: reciepeExperiments.find((exp: any) => exp.work_order_id === woId).work_order_name ?? woId, value: woId })).filter((workOrder) => !selectedWoIds.includes(workOrder.value))
      return options
    } else if (type === "grade") {
      const selectedGrades = filterValues.filter((filterElement: any) => filterElement?.param?.[0] === type).map((grade: any) => grade.type)
      const options = Array.from(new Set(reciepeExperiments.map((exp: any) => exp.grade))).map((grade: any) => ({ label: reciepeExperiments.find((exp: any) => exp.grade === grade).grade ?? grade, value: grade })).filter((grades) => !selectedGrades.includes(grades.value))
      return options
    } else if (type === "material") {
      const selectedMaterials = filterValues.filter((filterElement: any) => filterElement?.param?.[0] === type).map((material: any) => material.type)
      const options = Array.from(new Set(reciepeExperiments.map((exp: any) => exp.material))).map((material: any) => ({ label: displayNames?.material?.[material].name, value: material })).filter((materials) => !selectedMaterials.includes(materials.value))
      return options
    }
    return []
  }



  const getFormItemBasedOnOperators = (field: any, operatorType: string, name: number) => {
    if (operatorType === "range") {
      const { recipeFilters } = reciepeDistributionFilterForm.getFieldsValue()
      return (
        <div style={{ display: "flex", gap: "4px", alignItems: "baseline" }}>
          <Form.Item
            rules={[
              {
                transform: (val: any) => val?.trim(),
              },
            ]}
            {...field}
            name={[name, "val"]}
          >
            <InputNumber style={{ minWidth: 70 }} placeholder={t("common.minimum")}
              formatter={formatter} parser={parser}
              onChange={(value) => {
                setFilterValues((prevState: any) => {
                  const values = [...prevState]
                  values[name].val = value
                  return values
                })
              }}
            />
          </Form.Item>
          <Text>{"~"}</Text>
          <Form.Item
            {...field}
            rules={[
              {
                transform: (val: any) => val?.trim(),
              },
            ]}
            validateStatus={((recipeFilters[name].val && recipeFilters[name].val2 && recipeFilters[name].val > recipeFilters[name].val2) || (recipeFilters[name].val && recipeFilters[name].val2 === 0 && recipeFilters[name].val > recipeFilters[name].val2) || (recipeFilters[name].val === 0 && recipeFilters[name].val2 && recipeFilters[name].val > recipeFilters[name].val2)) && "error"}
            help={((recipeFilters[name].val && recipeFilters[name].val2 && recipeFilters[name].val > recipeFilters[name].val2) || (recipeFilters[name].val && recipeFilters[name].val2 === 0 && recipeFilters[name].val > recipeFilters[name].val2) || (recipeFilters[name].val === 0 && recipeFilters[name].val2 && recipeFilters[name].val > recipeFilters[name].val2)) && "Max should be greater than Min!"}
            name={[name, "val2"]}
          >
            <InputNumber style={{ minWidth: 70 }} placeholder={t("formulations.placeholder.maximum")}
              formatter={formatter} parser={parser}
              onChange={(value) => {
                setFilterValues((prevState: any) => {
                  const values = [...prevState]
                  values[name].val2 = value
                  return values
                })
              }}
            />
          </Form.Item>
        </div>
      )
    } else if (operatorType === "exists") {
      return (
        <Form.Item
          rules={[{ required: true, message: t("common.required") }]}
          {...field}
          name={[name, "val"]}
          required
          tooltip={requiredFieldStar}
        >
          <Select style={{ minWidth: 200 }} onChange={(value) => {
            setFilterValues((prevState: any) => {
              const values = [...prevState]
              values[name].val = value
              return values
            })
          }}>
            <Select.Option value={"yes"}>{t("common.yes")}</Select.Option>
            <Select.Option value={"no"}>{t("common.no")}</Select.Option>
          </Select>
        </Form.Item>
      )
    } else {
      return (
        <Form.Item
          {...field}
          name={[name, 'val']}
        >
          <Input placeholder={t('common.enterValue')}
            onChange={(e) => {
              setFilterValues((prevState: any) => {
                const values = [...prevState]
                values[name].val = e.target.value
                return values
              })
            }}
          />
        </Form.Item>
      )
    }
  }


  return (
    <Form
      name="recipe-filter-form"
      form={reciepeDistributionFilterForm}
      style={{ width: "100%" }}
      autoComplete="off"
      requiredMark={false}
    >
      <Form.List name="recipeFilters">
        {(fields, { add, remove }) => (
          <>
            <Form.Item>
              <StyledButton
                disabled={colorCodes.length === filterValues.length}
                type="primary"
                style={{ width: "8rem" }}
                onClick={() => {
                  add();
                  setFilterValues((prev: any) => [
                    ...prev,
                    { param: [], op: "", val: null },
                  ]);
                }}
                block
                icon={<PlusOutlined />}
              >
                {t("common.addFilters")}
              </StyledButton>
            </Form.Item>
            {fields.map(({ key, name, ...restField }, index) => (
              <StyledCard
                style={{
                  padding: "0.5rem",
                  borderLeft: `8px solid ${colorCodes[index]}`,
                }}
              >
                <Row style={{ alignItems: "baseline" }}>
                  <Col span={1}>
                    {visibilityArray[index] ? (
                      <EyeOutlined
                        onClick={(e) => {
                          e.stopPropagation();
                          setVisibilityArray((arr: any) =>
                            arr.map((val: any, idx: number) =>
                              idx === index ? false : val
                            )
                          );
                        }}
                      />
                    ) : (
                      <EyeInvisibleOutlined
                        onClick={(e) => {
                          e.stopPropagation();
                          setVisibilityArray((arr: any) =>
                            arr.map((val: any, idx: number) =>
                              idx === index ? true : val
                            )
                          );
                        }}
                      />
                    )}
                  </Col>
                  <Col
                    span={
                      filterValues[name]?.param?.length === 1 &&
                        ["work_order", "material", "grade"].includes(
                          filterValues[name]?.param?.[0]
                        )
                        ? 11
                        : 8
                    }
                    style={{ paddingInline: "0.5rem" }}
                  >
                    <Form.Item {...restField} name={[name, "param"]}>
                      <Cascader
                        options={options}
                        displayRender={displayRender}
                        showSearch
                        onChange={(value) => {
                          const { recipeFilters } =
                            reciepeDistributionFilterForm.getFieldsValue();
                          if (Boolean(configs?.nestle_configs) && value && value[0] === 'processing') {
                            setFilterValues((prevState: any) => {
                              const values = [...prevState];
                              values[name] = recipeFilters[name];
                              values[name].op = "exists";
                              values[name].val = "";
                              return values;
                            });
                          } else {
                            setFilterValues((prevState: any) => {
                              const values = [...prevState];
                              values[name] = recipeFilters[name];
                              values[name].op = "";
                              values[name].val = "";
                              return values;
                            });
                          }
                        }}
                        placeholder={t("common.pleaseSelect")}
                      />
                    </Form.Item>
                  </Col>
                  {filterValues[name]?.param?.length === 1 &&
                    ["work_order", "material", "grade"].includes(
                      filterValues[name]?.param?.[0]
                    ) ? (
                    <Col span={11} style={{ paddingInline: "0.5rem" }}>
                      <Form.Item {...restField} name={[name, "val"]}>
                        <Select
                          optionFilterProp="children"
                          value={
                            reciepeExperiments.find(
                              (exp: any) =>
                                exp.work_order_id ===
                                filterValues?.[name]?.val
                            )?.work_order_name || ""
                          }
                          onChange={(value) => {
                            setFilterValues((prevState: any) => {
                              const values = [...prevState];
                              values[name][filterValues[name]?.param?.[0]] =
                                value;
                              return values;
                            });
                          }}
                          placeholder="Select From Options"
                          options={getOptions(
                            filterValues[name]?.param?.[0],
                            name
                          )}
                        />
                      </Form.Item>
                    </Col>
                  ) : (
                    <>
                      <Col span={7} style={{ paddingInline: "0.5rem" }}>
                        <Form.Item {...restField} name={[name, "op"]}>
                          <Select
                            onChange={(value) => {
                              const { recipeFilters } =
                                reciepeDistributionFilterForm.getFieldsValue();

                              setFilterValues((prevState: any) => {
                                const values = [...prevState];
                                values[name].op = value;
                                values[name].val = "";
                                if (
                                  values[name].op !== "range" &&
                                  values[name].hasOwnProperty("val2")
                                ) {
                                  delete values[name].val2;
                                }
                                return values;
                              });
                              if (recipeFilters?.[key]) {
                                Object.assign(recipeFilters?.[key], {
                                  val: "",
                                  val2: "",
                                });
                              }
                              reciepeDistributionFilterForm.setFieldsValue({
                                recipeFilters,
                              });
                            }}
                            optionFilterProp="children"
                            placeholder={t("common.selectOperator")}
                            options={getOperators(
                              filterValues[name]?.param
                            )?.map((opValue: any) => ({
                              value: opValue,
                              label: opValue === 'exists' ? t('common.exists') : opValue === 'range' ? t('common.range') : opValue,
                            }))}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={7} style={{ paddingInline: "0.5rem" }}>
                        {getFormItemBasedOnOperators(
                          restField,
                          filterValues?.[name]?.op,
                          name
                        )}
                      </Col>
                    </>
                  )}
                  <Col span={1} style={{ textAlign: "end" }}>
                    <StyledDeleteIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setFilterValues((prevState: any) =>
                          prevState.filter(
                            (state: any, index: number) => index !== name
                          )
                        );
                        remove(name);
                      }}
                    />
                  </Col>
                </Row>
              </StyledCard>
            ))}
          </>
        )}
      </Form.List>
    </Form>
  );
}
