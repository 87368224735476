import React from 'react'

const Connector = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="68" height="12" viewBox="0 0 68 12" fill="none">
<path d="M1.30078 5.2C0.858953 5.2 0.500781 5.55817 0.500781 6C0.500781 6.44183 0.858953 6.8 1.30078 6.8V5.2ZM67.2665 6.56569C67.5789 6.25327 67.5789 5.74673 67.2665 5.43431L62.1753 0.343146C61.8629 0.0307264 61.3563 0.0307264 61.0439 0.343146C60.7315 0.655565 60.7315 1.1621 61.0439 1.47452L65.5694 6L61.0439 10.5255C60.7315 10.8379 60.7315 11.3444 61.0439 11.6569C61.3563 11.9693 61.8629 11.9693 62.1753 11.6569L67.2665 6.56569ZM1.30078 6.8H66.7008V5.2H1.30078V6.8Z" fill="#1F1F1F"/>
</svg>
  )
}

export default Connector