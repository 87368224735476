import React, { memo, useEffect, useState, useMemo, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./AppSkeleton.scss";
import PolymerizeLogo from 'src/assets/imgs/logo-white.png'
import styles from "./AppSkeleton.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { sidebarToggle } from "../../store/actions";
import {
  Menu,
  Layout,
  Space,
  Row,
  Tooltip,
  Select,
  Divider,
  Popover,
  Modal,
  Spin,
  Input,
  // Tour,
  // TourProps,
  // Steps,
  Segmented,
  Checkbox,
  Empty,
  Tag,
} from "antd";
import {
  BellOutlined, // CopyrightOutlined,
  DashboardOutlined, DeploymentUnitOutlined, ExperimentOutlined, HeatMapOutlined, ContainerOutlined, LoadingOutlined, LogoutOutlined, NotificationOutlined, TeamOutlined, UserOutlined, FolderOutlined, DiffOutlined, AreaChartOutlined, SettingOutlined, // SearchOutlined,
  AuditOutlined, PlusOutlined, ProfileOutlined, RightOutlined, // QuestionOutlined,
  InfoCircleFilled, WechatOutlined, FundOutlined, FilterOutlined, CheckCircleOutlined,
  FileAddOutlined
} from "@ant-design/icons";
import Avatar from "antd/lib/avatar/avatar";
import Text from "antd/lib/typography/Text";
import { StoreState } from "../../store/configureStore";
import useTranslate from "src/utils/useTranslate";
import { orange } from "@ant-design/colors";
import { setProject, selectAllProjects } from "src/store/actions/projects";
import { antdTheme, AsyncStates, currentPlatform, projectType } from "src/constants";
import {
  setEditingProceduresState,
  setEditingState,
} from "src/store/actions/workOrderDetails";
import { logoutRequest, setProjectTypeRequest } from "src/store/actions/login";
import {
  setCelsureEditingTrialsState,
  setCelsureEditingWoState,
} from "src/store/actions/celsure";
import {
  KBarProvider,
  KBarPortal,
  KBarPositioner,
  KBarAnimator,
  KBarSearch,
  useMatches,
  KBarResults,
  // useKBar,
} from "kbar";
import CheckableTag from "antd/lib/tag/CheckableTag";
import { StyledButton } from "src/styled_components/StyledButton";
import { trackEvent } from "src/analytics";
import ConnectTrialEndsIn from "../Settings/tabs/ConnectBilling/ConnectTrialEndsIn";
// import { GuideTooltip } from "src/components/product-guide"

import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
// import { preferencesUpdateRequest } from "src/store/actions/preferences";
// import introImg from "../../assets/svgs/intro.svg";
// import outroImg from "../../assets/imgs/video_guides.gif";
import { SegmentedValue } from "antd/es/segmented";
import { setIsEditing } from "src/store/actions/isEditing";
import { CreateProjectModal } from "../Project/CreateProjectModal";
import { VersionUpdate } from "../VersionUpdate";
import { useVariableValue } from "@devcycle/devcycle-react-sdk"
import StyledBadge from "src/styled_components/StyledBadge";
import { TbLayoutSidebarLeftCollapseFilled } from "react-icons/tb";
import { TbLayoutSidebarLeftExpandFilled } from "react-icons/tb";


dayjs.extend(isSameOrAfter);

// const { Title } = Typography;
const { Sider } = Layout;
const { Option, OptGroup } = Select;

const HeadwayWidget = memo(() => {
  const [t] = useTranslate();

  useEffect(() => {
    (window as any)?.Headway?.init({
      selector: "." + styles[`headway-badge`], // CSS selector where to inject the badge
      account: "7gbPXx", // your account ID, you can find it in Settings->Widget
      position: {
        y: "top",
        x: "right",
      },
      trigger: "." + styles[`headway-badge`],
      translations: {
        title: "Updates",
        footer: "",
      },
    });
  }, []);

  return (window as any).Headway ? (
    <div className={styles[`headway-badge`]}>
      <Text type="warning">{t("navbar.whatsnew")}</Text>
    </div>
  ) : null;
});

const RenderResults = memo(() => {
  const { results } = useMatches();

  return (
    <KBarResults
      items={results}
      onRender={({ item, active }) =>
        typeof item === "string" ? (
          <div
            className={styles[`search-item`]}
            style={{ background: "#e6f7ff", color: "#1890ff" }}
          >
            {item}
          </div>
        ) : (
          <div
            className={styles[`search-item`]}
            style={{
              background: active ? "#1890ff" : "white",
              color: active ? "white" : "#000",
            }}
          >
            {item.name}
          </div>
        )
      }
    />
  );
});

const ProjectSelectionDropdown = memo(() => {
  const [t] = useTranslate();
  const dispatch = useDispatch();
  const { push } = useHistory();
  const userId = useSelector(
    (state: StoreState) => state.login.loginResponse.user_id
  );
  const collapsed = useSelector((state: StoreState) => state.sidebar.collapsed);
  const {
    projectList: projects,
    projectListStatus,
    current: currentProject,
  } = useSelector((state: StoreState) => state.projects);
  const labLocationsList = useSelector(
    (state: StoreState) => state.settings.labsLocationList
  );
  const [searchText, setSearchText] = useState("");
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const [labLocationFilterList, setLabLocationFilterList] = useState<string[]>(
    []
  );
  const projectOptions = useMemo(() => {
    const filteredProjects = projects.filter((project) => {
      if (labLocationFilterList.length === 0) return true;

      const projectLabLocations = project.lab_locations;
      if (projectLabLocations && Array.isArray(projectLabLocations)) {
        if (
          projectLabLocations.filter((p) =>
            labLocationFilterList.includes(p.lab_location_id)
          ).length > 0
        )
          return true;
      }
      return false;
    });

    return [
      ...new Set(filteredProjects.map((project) => project.category)),
    ].map((category) => (
      <OptGroup label={category} key={category}>
        {filteredProjects
          .filter((project) => project.category === category)
          .filter((project) =>
            project?.name.toLowerCase().includes(searchText.toLowerCase())
          )
          .map((project) => (
            <Option
              value={project.project_id}
              className="projects-title-menu"
              style={{ width: "17em" }}
              key={project.project_id}
            >
              {project.name}
            </Option>
          ))}
      </OptGroup>
    ));
  }, [projects, searchText, labLocationFilterList]);
  const changeProject = useCallback(
    (value: string) => {
      trackEvent(userId, "User changed project", {
        "Project details": {
          "current project": currentProject,
          "new selected project": value,
        },
      });
      if (window.location.pathname.includes("/work-orders/details")) {
        dispatch(setEditingProceduresState(false));
        dispatch(setEditingState(false));
        dispatch(setProject(value));
        push("/work-orders");
      } else {
        dispatch(selectAllProjects(false));
        dispatch(setProject(value));
      }
    },
    [currentProject, dispatch, push, userId]
  );

  const selectAllLabLocationsForFilter = useCallback(() => {
    if (labLocationFilterList.length !== labLocationsList.length) {
      const allLabLocationIds = labLocationsList.map((l) => l.lab_location_id);
      setLabLocationFilterList(allLabLocationIds);
    } else setLabLocationFilterList([]);
  }, [labLocationFilterList.length, labLocationsList]);

  const updateLabLocationsFilter = useCallback(
    (lab_location_id: string) => {
      if (labLocationFilterList.includes(lab_location_id)) {
        // Remove
        setLabLocationFilterList((prev) =>
          prev.filter((id) => id !== lab_location_id)
        );
      } else {
        // Add
        setLabLocationFilterList((prev) => [...prev, lab_location_id]);
      }
    },
    [labLocationFilterList]
  );

  const labLocationsFilter = useCallback(() => {
    if (labLocationsList.length === 0) return <Empty />;

    return (
      <div className="lab-location-filter-list">
        <div className="list-item">
          <Checkbox
            onChange={selectAllLabLocationsForFilter}
            checked={labLocationFilterList.length === labLocationsList.length}
          >
            {t("common.selectAll")}
          </Checkbox>
        </div>
        <Divider style={{ marginTop: 2, marginBottom: 2 }} />
        {labLocationsList.map((l) => (
          <div className="list-item">
            <Checkbox
              onChange={() => updateLabLocationsFilter(l.lab_location_id)}
              checked={labLocationFilterList.includes(l.lab_location_id)}
            >
              {l.city}, {l.country}
            </Checkbox>
          </div>
        ))}
      </div>
    );
  }, [
    labLocationFilterList,
    labLocationsList,
    selectAllLabLocationsForFilter,
    t,
    updateLabLocationsFilter,
  ]);

  const projectFilters = useCallback(
    (trigger: any = "click") => {
      return (
        <div
          onMouseDown={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Popover
            content={labLocationsFilter()}
            trigger={trigger}
            placement="rightTop"
          >
            <Row
              justify="space-between"
              style={{ gap: "20px", cursor: "pointer" }}
              onMouseDown={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Text>
                {t("common.labLocations")}{" "}
                {labLocationFilterList.length > 0 &&
                  `(${labLocationFilterList.length})`}
              </Text>
              <RightOutlined />
            </Row>
          </Popover>
        </div>
      );
    },
    [labLocationFilterList.length, labLocationsFilter, t]
  );

  const getProjectFilterCount = useCallback(() => {
    // Expand for future filters
    if (labLocationFilterList.length > 0) return "(1)";
    else return null;
  }, [labLocationFilterList.length]);

  useEffect(() => {
    if (document) {
      const projectLabel =
        document.querySelector(".project-selection")?.firstChild;
      if (projectLabel) {
        (projectLabel as HTMLDivElement).dataset.label = t("common.project");
      }
    }
  }, [t, collapsed, popoverVisible]);

  return (
    <>
      {collapsed ? (
        <Popover
          onOpenChange={() => {
            setPopoverVisible(true);
          }}
          style={{ margin: "32px auto" }}
          overlayStyle={{ width: 250 }}
          placement="right"
          content={
            <Select
              loading={projectListStatus === AsyncStates.LOADING}
              style={{ width: "100%", margin: "12px 0" }}
              className="project-selection"
              popupMatchSelectWidth={true}
              size="large"
              getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
              value={currentProject}
              onSelect={changeProject}
              dropdownRender={(menu) => (
                <div>
                  <Row justify="space-between">
                    <Text strong style={{ paddingLeft: 12 }}>
                      {t("projects.header.title")}
                    </Text>
                    <Popover
                      content={projectFilters("hover")}
                      trigger="hover"
                      placement="bottom"
                      overlayStyle={{ zIndex: 1070 }}
                    >
                      <Text className="project-selection-filter">
                        <FilterOutlined /> {getProjectFilterCount()}
                      </Text>
                    </Popover>
                  </Row>
                  <Divider style={{ margin: 8 }} />
                  <Input
                    placeholder={t("projects.searchProjects")}
                    onChange={(event) => setSearchText(event.target.value)}
                  />
                  <Divider style={{ margin: 0 }} />
                  {menu}
                </div>
              )}
            >
              {projectOptions}
            </Select>
          }
        >
          <StyledButton type="primary" shape="round" icon={<FolderOutlined />} />
        </Popover>
      ) : (
        <Select
          loading={projectListStatus === AsyncStates.LOADING}
          className="project-selection"
          popupMatchSelectWidth={false}
          value={currentProject}
          onSelect={changeProject}
          getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
          dropdownRender={(menu) => (
            <div style={{ maxWidth: 250 }}>
              <Row justify="space-between">
                <Text strong style={{ paddingLeft: 12 }}>
                  {t("projects.header.title")}
                </Text>
                <Popover
                  content={projectFilters()}
                  trigger="click"
                  placement="bottom"
                  overlayStyle={{ zIndex: 1070 }}
                >
                  <Text className="project-selection-filter">
                    <FilterOutlined /> {getProjectFilterCount()}
                  </Text>
                </Popover>
              </Row>
              <Divider style={{ margin: 8 }} />
              <Input
                placeholder={t("projects.searchProjects")}
                onChange={(event) => setSearchText(event.target.value)}
              />
              <Divider style={{ margin: 0 }} />
              {menu}
            </div>
          )}
        >
          {projectOptions}
        </Select>
      )}
    </>
  );
});

export const SideBar = memo(({ siderWidth }: { siderWidth: number }) => {
  const [t] = useTranslate();
  const location = useLocation();
  const qc_filter = useVariableValue("qc-filter", false);
  const asset_library = useVariableValue("asset-library", false);
  const sem_flag = useVariableValue("sem", false);

  const [version] = useState(() => localStorage.getItem('version') || "2.0.0");
  const [createProjectModal, setCreateProjectModal] = useState<boolean>(false);


  const {
    features: featuresConfigs,
    modules: navbarConfigs,
    ai_engine,
  } = useSelector((state: StoreState) => state.configs);

  const {
    loginResponse: { user_name, image_url, user_role, project_type },
    setProjectTypeStatus,
    logoutStatus,
  } = useSelector((state: StoreState) => state.login);
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { unread = 0 } = useSelector(
    (state: StoreState) => state.notifications.data
  );
  const collapsed = useSelector((state: StoreState) => state.sidebar.collapsed);
  const [projectModalvisible, setProjectModalvisible] = useState(false);
  const currentProject = useSelector(
    (state: StoreState) => state.projects.current
  );
  const { celsureEditingTrialsState, celsureEditingWoState } = useSelector(
    (state: StoreState) => state.celsure
  );
  const [navMenuStatus, setNavMenuStatus] = useState<string[]>();
  const userId = useSelector(
    (state: StoreState) => state.login.loginResponse.user_id
  );

  const userInfo = useSelector(
    (state: StoreState) => state.login.loginResponse
  );
  const isEditing = useSelector((state: StoreState) => state.isEditing);

  useEffect(() => {
    const getLocation = () => {
      if (collapsed) return [];
      else if (location.pathname.includes("experiment-history"))
        return ["doe", "experiment-history"];
      else if (location.pathname.includes("generate-new-experiments"))
        return ["doe", "generate-new-experiments"];
      else if (location.pathname.includes("ingredients"))
        return ["inventory", "ingredients"];
      else if (location.pathname.includes("library"))
        return ["inventory", "library"];
      else if (location.pathname.includes("import_data"))
        return ["data-management", "import_data"];
      else if (location.pathname.includes("import_history"))
        return ["data-management", "import_history"];
      else if (location.pathname.includes("data-analytics"))
        return ["analytics", "data-analytics"];
      else return location.pathname.split("/");
    };

    setNavMenuStatus(getLocation());
  }, [collapsed, location.pathname]);

  useEffect(() => {
    if (
      currentPlatform === "connect" &&
      userInfo.user_role === "admin" &&
      (userInfo?.subscription_status === "canceled" ||
        dayjs().isAfter(userInfo.license_period_end))
    ) {
      push("/settings?tab=billing");
    }
  }, [userInfo, push]);

  const menuItems = useMemo(() => {
    return [
      {
        key: "dashboard",
        label: t("navBar.menuItem.dashboard"),
        route: "/dashboard",
        icon: <DashboardOutlined />,
        enable: navbarConfigs.dashboard,
      },
      {
        key: "work-orders",
        label: (
          <div>
            <span>{t("common.workOrders")}</span>
            {/* <GuideTooltip module="work_orders" /> */}
          </div>
        ),
        route: "/work-orders",
        icon: <ContainerOutlined />,
        enable: navbarConfigs.work_orders,
      },
      {
        key: "formulations",
        label: (
          <div>
            <span>{t("navBar.menuItem.formulations")}</span>
            {/* <GuideTooltip module="formulations" /> */}
          </div>
        ),
        route: "/formulations",
        icon: <ExperimentOutlined />,
        enable: navbarConfigs.formulations,
      },
      {
        key: "poly-gpt",
        label: <div style={{ display: 'flex', alignItems: 'center', gap: 8 }} >
          {t("navBar.menuItem.aiEngine.polyGPT")}
          <Tooltip title={t('common.betaTooltip')} >
            <Tag color="processing" className='sidebar-background' style={{
              color: '#fff',
              borderColor: '#fff'
            }} >
              {t('common.beta')}
            </Tag>
          </Tooltip>
        </div>,
        route: "/poly-gpt",
        icon: <WechatOutlined />,
        enable: true,
      },
      {
        key: "ai-engine",
        label: t("navBar.menuItem.aiEngine"),
        icon: <HeatMapOutlined />,
        enable: !!Object.keys(navbarConfigs.ai_engine || {}).length,
        ...(project_type === projectType.celsure
          ? { route: "/ai-engine/predict/properties_prediction" }
          : {
            children: [
              {
                key: "predict",
                label: t("aiEngine.predict"),
                onClick: () => {
                  trackEvent(
                    userId,
                    "AI Engine -> Properties prediction was viewed"
                  );
                  push(`/ai-engine/predict/properties_prediction`);
                },
                enable: navbarConfigs.ai_engine?.predict,
              },
              {
                key: "history",
                label: (
                  <Row justify={"space-between"} align={"middle"} >
                    {t("aiEngine.predictionHistory")}
                    {ai_engine?.forward?.total_unread_count +
                      ai_engine?.inverse?.total_unread_count >
                      0 ? (
                      <StyledBadge
                        count={
                          ai_engine?.forward?.total_unread_count +
                          ai_engine?.inverse?.total_unread_count
                        }
                        overflowCount={10}
                        style={{ boxShadow: "none" }}
                        showZero={false}
                      />
                    ) : null}
                  </Row>
                ),
                onClick: () => {
                  trackEvent(
                    userId,
                    "AI Engine -> Formulation prediction history was viewed"
                  );
                  push(`/ai-engine/history/properties_prediction`);
                },
                enable: navbarConfigs.ai_engine?.predict,
              },

              ...(!Boolean(featuresConfigs?.ai_engine_with_methods)
                ? [
                  {
                    key: "new_model",
                    label: t("navBar.menuItem.aiEngine.newModel"),
                    onClick: () => {
                      trackEvent(
                        userId,
                        "AI Engine -> New Model was viewed"
                      );
                      push(`/ai-engine/new_model/custom_ml`);
                    },
                    enable: navbarConfigs.ai_engine?.new_model,
                  },
                ]
                : []),

              ...(Boolean(featuresConfigs?.custom_insights)
                ? [
                  {
                    key: "insights",
                    label: t("aiEngine.tab.customInsights"),
                    onClick: () => {
                      trackEvent(
                        userId,
                        "AI Engine -> Analytics was viewed"
                      );
                      push(`/ai-engine/insights/custom_insights`);
                    },
                    enable: navbarConfigs.ai_engine?.analytics,
                  },
                ]
                : []),
              {
                key: "model_analysis",
                label: t("aiEngine.tab.model_analysis"),
                onClick: () => {
                  trackEvent(
                    userId,
                    "AI Engine -> Modal Analysis was viewed"
                  );
                  push(`/ai-engine/model_analysis`);
                },
                enable: true,
              },
            ],
          }),
      },
      project_type !== projectType.celsure
        ? {
          key: "doe",
          label: t("navBar.menuItem.DOE"),
          icon: <AreaChartOutlined />,
          children: [
            {
              key: "experiment-history",
              label: t("navBar.menuItem.experimentHistory"),
              onClick: () => {
                trackEvent(userId, "DOE -> Experiment History was viewed");
                push("/experiment-history");
              },
              enable: navbarConfigs.doe?.experiment_history,
            },
            {
              key: "generate-new-experiments",
              label: t("common.generateNewExperiments"),
              onClick: () => {
                trackEvent(
                  userId,
                  "DOE -> Generate new Experiments was viewed"
                );
                push("/generate-new-experiments");
              },
              enable: navbarConfigs.doe?.generate_new_experiments,
            },
          ],
          enable: !!Object.keys(navbarConfigs.doe || {}).length,
        }
        : {},
      project_type !== projectType.celsure
        ? {
          key: "analytics",
          label: t("navBar.menuItem.analytics"),
          icon: <FundOutlined />,
          children: [
            {
              key: "data-analytics",
              label: t("navBar.menuItem.dataAnalytics"),
              onClick: () => {
                trackEvent(userId, "Analytics -> Data Summary was viewed");
                push("/data-analytics");
              },
              enable: true,
            },
            {
              key: "smile-analytics",
              label: t("smiles.analytics"),
              onClick: () => {
                trackEvent(userId, "Analytics -> Smile Analytics was viewed");
                push("/smile-analytics");
              },
              enable: true,
            },
            {
              key: "multistage-heatmap",
              label: `${t("aiEngine.multiStage")} ${t("common.heatmap")}`,
              onClick: () => {
                trackEvent(userId, "Analytics -> multistage-heatmap was viewed");
                push("/multistage-heatmap");
              },
              enable: true,
            },
            {
              key: "sem-analysis",
              label: `${t("sem.imageAnalysis")}`,
              onClick: () => {
                trackEvent(userId, "Analytics -> SEM Analysis was viewed");
                push("/sem-analysis");
              },
              enable: sem_flag,
            },
          ],
          enable: true,
        }
        : {},
      {
        type: "divider",
        dashed: true,
        enable: true,
        style: { borderColor: "#A9A9A9", width: "80%", margin: "15px auto" },
      },
      {
        key: "projects",
        label: (
          <div>
            <span>{t("common.allProjects")}</span>
            {/* <GuideTooltip module="projects" /> */}
          </div>
        ),
        route: "/projects",
        icon: <FolderOutlined />,
        enable: navbarConfigs.projects,
      },
      {
        key: "quality-check",
        label: (
          <div>
            <span>{t("common.qualityCheck")}</span>
          </div>
        ),
        route: "/quality-check",
        icon: <CheckCircleOutlined />,
        enable: qc_filter
      },
      {
        key: 'asset-library',
        label: <div style={{ display: 'flex', alignItems: 'center', gap: 8 }} >
          {`Asset Library`}
          <Tooltip title={t('common.betaTooltip')} >
            <Tag color="processing" className='sidebar-background' style={{
              color: '#fff',
              borderColor: '#fff'
            }} >
              {t('common.beta')}
            </Tag>
          </Tooltip>
        </div>,
        route: '/asset-library/?reset',
        icon: <FileAddOutlined />,
        enable: asset_library
      },
      {
        key: "data-management",
        label: (
          <div>
            <span>{t("navBar.menuItem.dataManagement")}</span>
            {/* <GuideTooltip module="data_management_import" /> */}
          </div>
        ),
        icon: <DeploymentUnitOutlined />,
        children: [
          {
            key: "import_data",
            label: t("dataManagement.importData"),
            onClick: () => {
              trackEvent(userId, "Data Management -> Import Data was viewed");
              push("/import_data");
            },
            enable: navbarConfigs.data_management?.import_data,
          },
          {
            key: "import_history",
            label: t("dataManagement.importHistory"),
            onClick: () => {
              trackEvent(
                userId,
                "Data Management -> Import History was viewed"
              );
              push("/import_history");
            },
            enable: navbarConfigs.data_management?.import_history,
          },
        ],
        enable: !!Object.keys(navbarConfigs.data_management || {}).length,
      },
      {
        key: "inventory",
        label: (
          <div>
            <span>{t("common.title.inventory")}</span>
            {/* <GuideTooltip module="inventory" /> */}
          </div>
        ),
        icon: <DiffOutlined />,
        enable: navbarConfigs.inventory,
        children: [
          {
            key: "ingredients",
            label: t("inventory.Ingredients"),
            enable: true,
            onClick: () => {
              trackEvent(userId, "Inventory -> Ingredients was viewed");
              push("/inventory/ingredients");
            },
          },
          // {
          //   key: "family",
          //   label: `${t("common.ingredient")} ${t("common.family")}`,
          //   enable: true,
          //   onClick: () => {
          //     trackEvent(userId, "Inventory -> Inventory Family was viewed");
          //     push("/inventory/family");
          //   },
          // },
          // {
          //   key: "library",
          //   label: t("inventory.Library"),
          //   enable: true,
          //   onClick: () => {
          //     trackEvent(userId, "Inventory -> Inventory Library was viewed");
          //     push("/inventory/library");
          //   },
          // },
          {
            key: "repository",
            label: t("common.repository"),
            enable: true,
            onClick: () => {
              trackEvent(userId, "Inventory -> Repository was viewed");
              push("/inventory/repository/properties");
            },
          },
        ],
      },
      {
        key: "teams",
        label: t("navBar.menuItem.teams"),
        route: "/teams",
        icon: <TeamOutlined />,
        enable: navbarConfigs.teams,
      },
      {
        key: "templates",
        label: (
          <>
            <Space
              size="small"
              style={{ cursor: "pointer" }}
              onClick={() => {
                trackEvent(userId, "User viewed templates");
              }}
            >
              {t("common.templateLibrary")}
            </Space>
          </>
        ),
        route: "/templates",
        icon: <ProfileOutlined />,
        enable: navbarConfigs.templates,
      },
      //   {
      //     key: "notifications",
      //     label: (
      //       <>
      //         <Space
      //           size="small"
      //           style={{ cursor: "pointer" }}
      //           onClick={() => {
      //             trackEvent(userId, "User viewed notifications");
      //           }}
      //         >
      //           {!collapsed && t("navBar.notifications")}
      //           <StyledButton
      //             size="small"
      //             shape="round"
      //             style={{ background: blue.primary, color: "white" }}
      //           >
      //             {unread}
      //           </StyledButton>
      //         </Space>
      //       </>
      //     ),
      //     route: "/notifications",
      //     icon: <BellOutlined />,
      //     // enable: navbarConfigs.notifications,
      // enable: true
      //   },
      // {
      //   key: "feedback",
      //   label: t("navBar.menuItem.feedback"),
      //   route: "/feedback",
      //   icon: <NotificationOutlined />,
      //   enable: navbarConfigs.feedback,
      // },
    ].reduce((acc: any[], res: any) => {
      if (res?.enable) {
        if (!!res?.children?.length) {
          const children = res.children.filter((route: any) => {
            if (route.enable) return true;
            return false;
          });
          acc.push({ ...res, children });
        } else if (res.enable) {
          acc.push(res);
        }
      }
      return acc;
    }, []);
  }, [t, navbarConfigs.dashboard, navbarConfigs.work_orders, navbarConfigs.formulations, navbarConfigs.ai_engine, navbarConfigs.doe, navbarConfigs.projects, navbarConfigs.data_management, navbarConfigs.inventory, navbarConfigs.teams, navbarConfigs.templates, project_type, ai_engine?.forward?.total_unread_count, ai_engine?.inverse?.total_unread_count, featuresConfigs?.ai_engine_with_methods, featuresConfigs?.custom_insights, sem_flag, qc_filter, asset_library, userId, push]);


  const logout = () => {
    dispatch(logoutRequest());
  };

  const onCollapse = (collapsed: any) => {
    dispatch(sidebarToggle(collapsed));
  };

  const [popoverVisible] = useState<boolean>(false);

  useEffect(() => {
    if (document) {
      const projectLabel =
        document.querySelector(".project-selection")?.firstChild;
      if (projectLabel) {
        (projectLabel as HTMLDivElement).dataset.label = t("common.project");
      }
    }
  }, [t, collapsed, popoverVisible]);

  // const { query } = useKBar()
  const [collapseButtonVisible, SetCollapseButtonVisible] = useState(
    !collapsed
  );

  // const onboardingState = useSelector(
  //   (state: StoreState) => state.preferences.data?.onboarding_progress
  // );

  const { logo } = useSelector(
    (state: StoreState) => state.login.loginResponse
  );

  const selectedKeys = useMemo(() => {
    return [
      location.pathname.split("/")[
      ["ai-engine", "inventory"].includes(
        location.pathname.split("/")[1]
      )
        ? 2
        : 1
      ],
    ]
  }, [location.pathname]);

  // const defaultCallbacks = {
  //   nextButtonProps: {
  //     onClick: () => {
  //       setCurrentTourStep((step) => step + 1);
  //     },
  //   },
  //   prevButtonProps: {
  //     onClick: () => {
  //       setCurrentTourStep((step) => step - 1);
  //     },
  //   },
  // };

  // const steps: TourProps["steps"] = [
  //   {
  //     title: "Welcome to Polymerize Connect!",
  //     description:
  //       "Connect is a unified data management platform that aims to make data collection and team collaboration a breeze. It supports seamless data import and entry, with data sheets designed according to your experiment workflow. With in-built data analytics and visualization features, you can now make more data-driven decisions in your research journey.",
  //     cover: <img alt="Welcome to Polymerize Connect" src={introImg} />,
  //     style: {
  //       top: "calc(50% - 325px)",
  //     },
  //     ...defaultCallbacks,
  //   },
  //   {
  //     title: "Getting Started",
  //     description:
  //       "To start off, you will need to create a project, followed by Work Orders to insert your data. This video briefly introduces you to project creation, followed by Work Order creation within the project. A Work Order functions like a data sheet containing various trials and their experimental data, such as ingredient compositions, processing conditions, and output property results.",
  //     cover: (
  //       <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
  //         <iframe
  //           title="getting started"
  //           src="https://player.vimeo.com/video/821181703?h=8002de1373&badge=0&autopause=0&player_id=0&app_id=58479/embed"
  //           allow="autoplay; fullscreen; picture-in-picture"
  //           allowFullScreen
  //           frameBorder={0}
  //           style={{
  //             position: "absolute",
  //             top: 0,
  //             left: 0,
  //             width: "100%",
  //             height: "100%",
  //           }}
  //         />
  //       </div>
  //     ),
  //     style: {
  //       height: "100%",
  //       width: 854,
  //       top: "calc(50% - 340px)",
  //     },
  //     ...defaultCallbacks,
  //   },
  //   {
  //     title: "Video Guides",
  //     description:
  //       "Detailed user guide videos explaining the functions within each component are accessible by hovering over the ‘?’ icon right beside each component on the navigation menu on the left. We recommend you watch the videos in this order to get a better understanding of the entire flow of the platform: Projects, Work Orders, Data Management, Formulations, then Inventory. The videos also have chapter timestamps to indicate the start of a new chapter. You can skip to a specific chapter if you wish to understand a function better. Watch the GIF above to understand how you can access specific chapters.",
  //     cover: <img alt="Video Guides" src={outroImg} />,
  //     style: {
  //       width: "70%",
  //       maxWidth: 854,
  //       top: "calc(50% - 366px)",
  //     },
  //     ...defaultCallbacks,
  //   },
  // ];

  // const [open, setOpen] = useState<boolean>(false);

  // const startTour = (step: number) => {
  //   setCurrentTourStep(step);
  //   setOpen(true);
  // };

  // const [onboardingProgress, setOnboardingProgress] = useState<number>(0);

  // const [currentTourStep, setCurrentTourStep] =
  //   useState<number>(onboardingState);

  // const finishTour = () => {
  //   setOnboardingProgress(3);
  // };

  // const resetOnboardingProgress = () => {
  //   setOnboardingProgress(0);
  // };

  // useEffect(() => {
  //   let progress = 0;
  //   if (currentTourStep < 1) {
  //     progress = 0;
  //   } else if (currentTourStep < 2) {
  //     progress = 1;
  //   } else if (currentTourStep < 3) {
  //     progress = 2;
  //   } else {
  //     progress = 3;
  //   }
  //   setOnboardingProgress(progress);
  //   if (
  //     typeof currentTourStep !== "undefined" &&
  //     currentPlatform === "connect"
  //   ) {
  //     dispatch(
  //       preferencesUpdateRequest({ onboarding_progress: currentTourStep })
  //     );
  //   }
  // }, [currentTourStep, dispatch]);

  const handlePlussProjectChange = (projectType: SegmentedValue) => {
    if (celsureEditingWoState || celsureEditingTrialsState || isEditing) {
      dispatch(setCelsureEditingTrialsState(false));
      dispatch(setCelsureEditingWoState(false));
      dispatch(setIsEditing(false));
    }
    dispatch(setProjectTypeRequest({ project_type: projectType }));
    if (location.pathname.includes("/ai-engine/")) {
      push("/ai-engine/predict/properties_prediction");
    }
  };

  useEffect(() => {
    collapsed && SetCollapseButtonVisible(false)
    !collapsed && SetCollapseButtonVisible(true)
  }, [collapsed])

  return (
    <>
      {/* {currentPlatform === "connect" && (
        <Tour
          zIndex={1500}
          current={currentTourStep}
          open={open}
          onClose={() => setOpen(false)}
          steps={steps}
          onFinish={finishTour}
        />
      )} */}
      <Sider
        collapsible
        collapsed={collapsed}
        trigger={null}
        theme="light"
        width={siderWidth}
        className="styled-sider"
        onMouseEnter={() => collapsed && SetCollapseButtonVisible(true)}
        onMouseLeave={() => collapsed && SetCollapseButtonVisible(false)}
      >
        <div style={{ position: 'absolute' }}>
          <StyledButton
            icon={
              collapsed ? (
                <TbLayoutSidebarLeftExpandFilled
                  style={{ fontSize: antdTheme.fontSizeHeading3 }}
                />
              ) : (
                <TbLayoutSidebarLeftCollapseFilled
                  style={{ fontSize: antdTheme.fontSizeHeading3 }}
                />
              )
            }
            onClick={() => {
              onCollapse(!collapsed)
            }}
            style={{
              outline: 'none',
              fontSize: '0.5rem',
              display: collapseButtonVisible ? 'flex' : 'none',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'absolute',
              top: 20,
              left: collapsed ? 64 : 234 + 25,
              color: collapseButtonVisible
                ? '#fff'
                : 'transparent',
              borderColor: collapseButtonVisible
                ? '#fff'
                : 'transparent',
              backgroundColor: collapseButtonVisible
                ? '#1A1C24'
                : 'transparent',
              boxShadow: 'rgba(0, 0, 0, 0.15) 0px 0px 4px 0px',
              ...(collapseButtonVisible
                ? {}
                : {
                  boxShadow: 'none',
                  border: 'none'
                })
            }}
            onMouseEnter={() => collapsed && SetCollapseButtonVisible(true)}
            onMouseLeave={() => collapsed && SetCollapseButtonVisible(false)}
            className='sidebar-background'
          />
        </div>
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            scrollBehavior: 'smooth',
          }}
          className='sidebar-background'
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.75rem',
              padding: '0.75rem',
              paddingTop: '1.5rem',
              paddingBottom: '1rem',
            }}
          >
            <VersionUpdate collapsed={collapsed} />

            <Row
              justify="start"
              align="bottom"
              gutter={16}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 6,
                lineHeight: '0rem'
              }}
            >
              <a
                target="_self"
                href="/"
                rel="noopener noreferrer"
                style={{ margin: 0 }}
              >
                <Space
                  align="center"
                  style={{ backgroundColor: 'rgb(246 250 253)', borderRadius: '0.25rem' }}
                >
                  {!collapsed && (
                    <img src={PolymerizeLogo} alt="Polymerize Logo" height={'30'} className='sidebar-background' />
                  )}
                  {collapsed &&
                    (logo ? (
                      <div style={{
                        margin: '0.25rem',
                      }}>
                        <img
                          src={logo}
                          alt="User Logo"
                          style={{
                            maxWidth: '2.5rem',
                            maxHeight: '1.35rem',
                            width: '2.5rem',
                            height: '1.35rem',
                            mixBlendMode: 'multiply',
                            filter: 'contrast(1)'
                          }}
                        />
                      </div>
                    ) : (
                      <img
                        src={PolymerizeLogo}
                        alt="Polymerize Logo"
                        height={'30'}
                        className='sidebar-background'
                      />
                    ))}
                </Space>
              </a>

              {!collapsed && logo && (
                <Divider
                  type="vertical"
                  style={{
                    height: '40px',
                    color: 'white'
                  }}
                />
              )}

              {!collapsed && logo && (
                <div style={{
                  backgroundColor: 'rgb(246 250 253)',
                  padding: '0.5rem',
                  borderRadius: '0.25rem'
                }}>
                  <img
                    src={logo}
                    alt="User Logo"
                    style={{
                      maxWidth: '100px',
                      maxHeight: '40px',
                      // width: "50px",
                      // height: "50px",
                      mixBlendMode: 'multiply',
                      filter: 'contrast(1.5)'
                    }}
                  />
                </div>
              )}

              {/* {!collapsed && (
                <Tooltip
                  placement="right"
                  overlayInnerStyle={{
                    backgroundColor: "white",
                    width: "290px",
                  }}
                  color="white"
                  title={
                    <p
                      style={{
                        display: "flex",
                        gap: "4px",
                        padding: "10px",
                        color: "#222",
                        margin: 0,
                      }}
                    >
                      Click to start{" "}
                      <Link
                        onClick={() => {
                          sessionStorage.removeItem(
                            "intercom.tour_triggered_from_url"
                          )
                        }}
                        href="/formulations?product_tour_id=378766"
                        style={{
                          color: blue.primary,
                          display: "inline-block",
                          cursor: "pointer",
                        }}
                      >
                        Labs Introduction Tour
                      </Link>
                    </p>
                  }
                >
                  <Link
                    onClick={() => {
                      sessionStorage.removeItem(
                        "intercom.tour_triggered_from_url"
                      )
                    }}
                    href="/formulations?product_tour_id=378766"
                    style={{
                      background: "#ff8a01",
                      cursor: "pointer",
                      padding: "2px 5px",
                      borderRadius: "5px",
                      color: "#fff",
                    }}
                  >
                    Tour
                  </Link>
                </Tooltip>
              )} */}
            </Row>

            {Boolean(featuresConfigs?.celsure) && (
              <Row justify="center">
                <Tooltip title={t('navbar.changeProjectType')}>
                  <Spin
                    size="small"
                    spinning={setProjectTypeStatus === AsyncStates.LOADING}
                    indicator={<LoadingOutlined />}
                  >
                    <Segmented
                      className={project_type + '_segment'}
                      value={project_type}
                      onChange={(value) => {
                        if (location.pathname.includes('projects'))
                          push('/projects')
                        if (location.pathname.includes('work-orders'))
                          push('/work-orders')
                        if (
                          celsureEditingWoState ||
                          celsureEditingTrialsState ||
                          isEditing
                        ) {
                          Modal.confirm({
                            title: (
                              <Text style={{ color: orange.primary }} strong>
                                {t('navbar.unsavedChangesPresent')}
                              </Text>
                            ),
                            icon: <InfoCircleFilled />,
                            okText: t('common.confirm'),
                            cancelText: t('common.cancel'),
                            okButtonProps: { style: { borderRadius: '12px' } },
                            cancelButtonProps: {
                              style: { borderRadius: '12px' }
                            },
                            onOk: () => handlePlussProjectChange(value)
                          })
                        } else {
                          handlePlussProjectChange(value)
                        }
                      }}
                      options={[
                        {
                          value: 'celsure',
                          label: collapsed ? 'C' : 'Celsure',
                          title: 'Celsure'
                        },
                        {
                          value: 'pcm',
                          label: collapsed ? 'P' : 'PCM',
                          title: 'PCM'
                        }
                      ]}
                    />
                  </Spin>
                </Tooltip>
              </Row>
            )}

            {!Boolean(featuresConfigs?.project_selection_disabled) &&
              <Row
                style={{
                  paddingBottom: 0,
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <StyledButton
                  onClick={() => setCreateProjectModal(true)}
                  icon={<PlusOutlined />}
                  size="middle"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#fff',
                    borderColor: '#fff',
                    width: '100%',
                    margin: collapsed ? '0px 4px' : '0px'
                  }}
                  type={'dashed'}
                  className='sidebar-background'
                >
                  {collapsed ? null : t('projects.header.newProject')}
                </StyledButton>
              </Row>
            }

            {!Boolean(featuresConfigs?.project_selection_disabled) && (
              <Row
                justify="center"
                style={{
                  padding: `${collapsed ? '0rem' : '0.75rem'} 0rem`,
                  width: '100%'
                }}
              >
                <ProjectSelectionDropdown />
              </Row>
            )}

          </div>
          <Menu
            key="main-menu"
            mode="inline"
            onClick={(e) => setNavMenuStatus(e.keyPath as string[])}
            onOpenChange={(menukeys) => setNavMenuStatus(menukeys)}
            openKeys={navMenuStatus}
            selectedKeys={selectedKeys}
            selectable
            style={{
              borderRight: '1px solid transparent',
              maxHeight: '100%',
              overflowY: 'auto',
              overflowX: 'hidden'
            }}
            items={menuItems.map((item: any) => {
              const route = item.route
              return {
                ...item,
                onClick: () => {
                  trackEvent(userId, 'User viewed ' + item.key, {
                    'User Id': userId,
                    'Current Project': currentProject,
                    'User Name': user_name,
                    'User Role': user_role,
                    Company: project_type
                  })
                  if (route) {
                    push(route)
                  }
                }
              }
            })}
            theme="dark"
            rootClassName="sidebar-dark-menu"
          />
          {/* {currentPlatform === "connect" && (
            <>
              <Row
                justify={"center"}
                gutter={[8, 8]}
                style={{ marginTop: "auto" }}
              >
                <Col>
							<StyledButton type="primary" shape="circle">?</StyledButton>
						</Col>
                <Col>
                  <Popover
                    arrow={{ pointAtCenter: true }}
                    placement="rightBottom"
                    content={
                      <>
                        <Row>
                          <Steps
                            direction="vertical"
                            size="small"
                            current={onboardingProgress}
                            items={[
                              {
                                title: (
                                  <StyledButton
                                    type="link"
                                    onClick={() => startTour(0)}
                                  >
                                    Introduction
                                  </StyledButton>
                                ),
                              },
                              {
                                title: (
                                  <StyledButton
                                    disabled={onboardingProgress < 1}
                                    type="link"
                                    onClick={() => startTour(1)}
                                  >
                                    Getting Started
                                  </StyledButton>
                                ),
                              },
                              {
                                title: (
                                  <StyledButton
                                    disabled={onboardingProgress < 2}
                                    type="link"
                                    onClick={() => startTour(2)}
                                  >
                                    Feature Videos
                                  </StyledButton>
                                ),
                              },
                            ]}
                          />
                        </Row>
                        <Row justify={"end"}>
                          <StyledButton
                            size="small"
                            onClick={resetOnboardingProgress}
                            type="link"
                          >
                            Reset
                          </StyledButton>
                        </Row>
                      </>
                    }
                    title="Onboarding Guide"
                    trigger="click"
                  >
                    {collapsed ? (
                      <Tooltip
                        placement="right"
                        title={t("navbar.gettingStarted")}
                      >
                        <StyledButton shape="round">
                          <QuestionOutlined />
                        </StyledButton>
                      </Tooltip>
                    ) : (
                      <StyledButton shape="round">
                        {t("navbar.gettingStarted")}
                        <RightOutlined />
                      </StyledButton>
                    )}
                  </Popover>
                </Col>
              </Row>
              <br />
            </>
          )} */}

          <Menu
            key="settings-menu"
            mode="inline"
            style={
              !collapsed
                ? { marginTop: 'auto' }
                : { marginTop: 'auto', borderRight: 'unset' }
            }
            selectedKeys={selectedKeys}
            theme="dark"
            rootClassName="sidebar-dark-menu"
          >
            <HeadwayWidget />
            <Menu.Item
              style={
                !collapsed
                  ? { cursor: 'pointer' }
                  : {
                    cursor: 'pointer',
                    padding: 0,
                    paddingLeft: 0,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative'
                  }
              }
              onClick={() => {
                trackEvent(userId, 'User viewed notifications')
                push('/notifications')
              }}
              icon={
                collapsed ? (
                  <Space style={{ position: 'absolute' }}>
                    {unread > 0 ? (
                      <StyledBadge
                        count={unread}
                        style={{ boxShadow: 'none' }}
                        showZero={false}
                      />
                    ) : (
                      <BellOutlined />
                    )}
                  </Space>
                ) : (
                  <BellOutlined />
                )
              }
              key="notifications"
            >
              <Row justify={'space-between'} align={'middle'}>
                {t('navBar.notifications')}
                {!collapsed &&
                  (unread > 0 ? (
                    <StyledBadge
                      count={unread}
                      style={{ boxShadow: 'none' }}
                      showZero={false}
                    />
                  ) : null)}
              </Row>
            </Menu.Item>
            <Menu.SubMenu
              icon={
                <Avatar
                  src={image_url}
                  alt={user_name}
                  style={{ marginLeft: -8 }}
                />
              }
              popupOffset={[0, 0]}
              data-cy="user"
              key="submenuMustDefineThis"
              title={user_name[0]?.toUpperCase() + user_name?.slice(1)}
            >
              {user_role === 'admin' && (
                <Menu.Item
                  icon={<AuditOutlined />}
                  onClick={() => {
                    trackEvent(userId, 'User viewed audit log')
                    push('/audit-log')
                  }}
                  key="audit-log"
                  style={{ paddingLeft: 24 }}
                >
                  {t('common.auditLog')}
                </Menu.Item>
              )}
              <Menu.Item
                icon={<UserOutlined />}
                onClick={() => {
                  trackEvent(userId, 'User viewed profile')
                  push('/profile/my-details')
                }}
                key="profile"
                style={{ paddingLeft: 24 }}
              >
                {t('common.profile')}
              </Menu.Item>
              <Menu.Item
                icon={<SettingOutlined />}
                onClick={() => {
                  trackEvent(userId, 'User viewed settings')
                  push('/settings')
                }}
                key="settings"
                style={{ paddingLeft: 24 }}
              >
                {t('navBar.menuItem.settings')}
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  push('/feedback')
                }}
                key="feedback"
                icon={<NotificationOutlined />}
                style={{ paddingLeft: 24 }}
              >
                {t('navBar.menuItem.feedback')}
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  trackEvent(userId, 'User Logged out')
                  logout()
                }}
                icon={<LogoutOutlined />}
                key="logout"
                style={{ paddingLeft: 24 }}
              >
                <StyledButton
                  loading={logoutStatus === AsyncStates.LOADING}
                  type="link"
                  style={{ paddingLeft: 0, color: 'inherit' }}
                >
                  {t('navBar.menuItem.logout')}
                </StyledButton>
              </Menu.Item>
              <Space
                direction="horizontal"
                style={{ width: '100%', justifyContent: 'center', padding: 2 }}
              >
                <Text
                  type="secondary"
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: '#fff',
                    fontSize: '0.5rem',
                    wordWrap: 'break-word',
                  }}
                >
                  {t("ver")}{version}
                  {!collapsed && <>&nbsp;&#x2022;&nbsp;</>}
                  {`${!collapsed
                    ? `${t(
                      'navbar.footer.rightsReserved'
                    )} © ${new Date().getFullYear()}`
                    : ''
                    }`}
                </Text>
              </Space>
            </Menu.SubMenu>
          </Menu>
        </div>
      </Sider>

      <Modal
        okText={t('common.ok')}
        cancelText={t('common.cancel')}
        title={t('common.selectProjectType')}
        visible={projectModalvisible}
        footer={null}
        onCancel={() => setProjectModalvisible(false)}
      >
        <Spin
          size="large"
          spinning={setProjectTypeStatus === AsyncStates.LOADING}
          indicator={<LoadingOutlined />}
        >
          <Space direction="vertical" style={{ width: '100%', height: 100 }}>
            {(celsureEditingWoState || celsureEditingTrialsState) && (
              <Text style={{ color: orange.primary }} strong>
                {t('navbar.unsavedChangesPresent')}
              </Text>
            )}
            <div style={{ display: 'flex', width: '100%' }}>
              {['PCM', 'Celsure'].map((tag) => (
                <CheckableTag
                  style={{
                    flex: 1,
                    textAlign: 'center',
                    fontSize: antdTheme.fontSizeHeading3,
                    height: 60,
                    lineHeight: '60px'
                  }}
                  key={tag}
                  checked={project_type === tag.toLowerCase()}
                  onChange={() => {
                    if (celsureEditingWoState || celsureEditingTrialsState) {
                      dispatch(setCelsureEditingTrialsState(false))
                      dispatch(setCelsureEditingWoState(false))
                    }
                    dispatch(
                      setProjectTypeRequest({
                        project_type: tag.toLowerCase()
                      })
                    )
                    if (location.pathname.includes('/ai-engine/')) {
                      push('/ai-engine/predict/properties_prediction')
                    }
                  }}
                >
                  {tag}
                </CheckableTag>
              ))}
            </div>
          </Space>
        </Spin>
      </Modal>

      <CreateProjectModal
        setCreateProjectModal={setCreateProjectModal}
        createProjectModal={createProjectModal}
      />
    </>
  )
});

const Main = memo(({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <div
        className="app__right__content__container"
      >
        {currentPlatform === "connect" && <ConnectTrialEndsIn />}
        <div>{children}</div>
      </div>
      {/* <Footer
      style={{
        marginLeft: siderWidth,
        textAlign: "center",
        color: grey[3],
        padding: 8,
        background: "none",
      }}
    >
      {t("navbar.footer.rightsReserved")} © {new Date().getFullYear()}
    </Footer> */}
    </>
  );
});

const AppSkeleton = ({ children }: { children: React.ReactNode }) => {
  return (
    <Main>{children}</Main>
  );
};

const QuickNavigate = ({ children }: { children: React.ReactNode }) => {
  const [t] = useTranslate();

  const dispatch = useDispatch();

  const { push } = useHistory();


  const { features: featuresConfigs, modules: navbarConfigs } = useSelector(
    (state: StoreState) => state.configs
  );

  const {
    loginResponse: { project_type },
  } = useSelector((state: StoreState) => state.login);


  const actions: any = useMemo(
    () =>
      [
        {
          id: "dashboard",
          name: t("navBar.menuItem.dashboard"),
          shortcut: ["d"],
          keywords: "dashboard",
          perform: () => push("/dashboard"),
          enable: navbarConfigs.dashboard,
        },
        {
          id: "projects",
          name: t("projects.header.title"),
          shortcut: ["p"],
          keywords: "projects",
          perform: () => push("/projects"),
          enable: navbarConfigs.projects,
        },
        {
          id: "work_orders",
          name: t("common.workOrders"),
          shortcut: ["w"],
          keywords: "Work Orders",
          perform: () => push("/work-orders"),
          enable: navbarConfigs.work_orders,
        },
        {
          id: "formulations",
          name: t("navBar.menuItem.formulations"),
          shortcut: ["f"],
          keywords: "formulations",
          perform: () => push("/formulations"),
          enable: navbarConfigs.formulations,
        },
        {
          id: "experiment_history",
          name: t("navBar.menuItem.experimentHistory"),
          shortcut: ["e"],
          keywords: "experiment history",
          perform: () => push("/experiment-history"),
          section: t("navBar.menuItem.DOE"),
          enable: navbarConfigs.doe?.experiment_history,
        },
        {
          id: "generate_new_experiments",
          name: t("common.generateNewExperiments"),
          shortcut: ["g"],
          keywords: "generate new experiments",
          perform: () => push("/generate-new-experiments"),
          section: t("navBar.menuItem.DOE"),
          enable: navbarConfigs.doe?.generate_new_experiments,
        },
        project_type === projectType.celsure
          ? {
            id: "ai_engine",
            name: t("navBar.menuItem.aiEngine"),
            shortcut: ["ai"],
            keywords: "ai engine",
            perform: () => push("/ai-engine/predict/properties_prediction"),
            enable: !!Object.keys(navbarConfigs.ai_engine || {}).length,
          }
          : {},
        {
          id: "predict",
          name: t("aiEngine.predict"),
          shortcut: ["ai"],
          keywords: "predict",
          perform: () => push("/ai-engine/predict/properties_prediction"),
          enable:
            navbarConfigs.ai_engine?.predict &&
            project_type !== projectType.celsure,
          section: t("navBar.menuItem.aiEngine"),
        },
        {
          id: "new_model",
          name: t("navBar.menuItem.aiEngine.newModel"),
          shortcut: ["m"],
          keywords: "New Model",
          perform: () => push("/ai-engine/new_model/custom_ml"),
          enable:
            navbarConfigs.ai_engine?.new_model &&
            project_type !== projectType.celsure,
          section: t("navBar.menuItem.aiEngine"),
        },
        {
          id: "analytics",
          name: t("navBar.menuItem.aiEngine.analytics"),
          shortcut: ["a"],
          keywords: "Analytics",
          perform: () => push("/ai-engine/insights/custom_insights"),
          enable:
            featuresConfigs?.custom_insights ||
            (navbarConfigs.ai_engine?.analytics &&
              project_type !== projectType.celsure),
          section: t("navBar.menuItem.aiEngine"),
        },
        {
          id: "inventory",
          name: t("common.title.inventory"),
          shortcut: ["i"],
          keywords: "inventory",
          perform: () => push("/inventory"),
          enable: navbarConfigs.inventory,
        },
        {
          id: "family",
          name: t("common.family"),
          shortcut: ["f"],
          keywords: "family",
          perform: () => push("/family"),
          enable: navbarConfigs.inventory,
        },
        {
          id: "import_data",
          name: t("common.import"),
          shortcut: ["u"],
          keywords: "import data",
          perform: () => push("/import_data"),
          section: t("navBar.menuItem.dataManagement"),
          enable: navbarConfigs.data_management?.import_data,
        },
        {
          id: "import_history",
          name: t("dataManagement.importHistory"),
          shortcut: ["h"],
          keywords: "import history",
          perform: () => push("/import_history"),
          section: t("navBar.menuItem.dataManagement"),
          enable: navbarConfigs.data_management?.import_history,
        },
        {
          id: "teams",
          name: t("navBar.menuItem.teams"),
          shortcut: ["t"],
          keywords: "teams",
          perform: () => push("/teams"),
          enable: navbarConfigs.teams,
        },
        {
          id: "feedback",
          name: t("navBar.menuItem.feedback"),
          shortcut: ["fe"],
          keywords: "feedback",
          perform: () => push("/feedback"),
          enable: navbarConfigs.feedback,
        },
        {
          id: "labels_ids",
          name: "Label Ids",
          shortcut: ["ids"],
          keywords: "labels",
          perform: () => dispatch({ type: "LANGUAGE_DEV" }),
          enable: true,
          section: "Developer Mode"
        },
      ].reduce((acc: any[], res: any) => {
        if (res?.enable) {
          acc.push(res);
        }
        return acc;
      }, []),
    [push, t, navbarConfigs, project_type, featuresConfigs, dispatch]
  );

  return (
    <KBarProvider actions={actions}>
      <KBarPortal>
        <KBarPositioner className={styles[`kbar`]}>
          <KBarAnimator className={styles["content"]}>
            <KBarSearch
              className={styles[`search-bar`]}
              placeholder={t("kbar.placeholder.search")}
            />
            <RenderResults />
          </KBarAnimator>
        </KBarPositioner>
      </KBarPortal>
      <AppSkeleton>{children}</AppSkeleton>
    </KBarProvider>
  );
};

export default memo(QuickNavigate);
