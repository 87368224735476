import React, { Dispatch, SetStateAction, useCallback } from 'react'
import { FileAddOutlined, InboxOutlined, InfoCircleFilled, LoadingOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Modal, Row, Space, Typography, Form, Select, AutoComplete, message, Col, Input, Divider, Spin, Tooltip, InputNumber, Steps, Collapse } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AsyncStates } from 'src/constants'
import { checkPropertyExistsClear, clearInventoryData, createParameterClear, createParameterRequest, inventoryAttachmentsListClear, inventoryAttachmentsListRequest, listMetadataTemplateRequest, updateParameterClear, updateParameterRequest } from 'src/store/actions/inventory'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import { StyledCard } from 'src/styled_components/StyledCard'
import { convertToPrecision } from 'src/utils/decorator'
import useTranslate from 'src/utils/useTranslate'
import { AddMetaDataModal } from './modals/AddMetaDataModal'
import { AddTemplateModal } from './modals/AddTemplateModal'
import { ViewTemplatesModal } from './modals/ViewTemplatesModal'
import { Prompt, useHistory } from 'react-router-dom'
import { StyledPageHeader } from 'src/styled_components/StyledPageHeader'
import Dragger from 'antd/es/upload/Dragger'
import { AttachmentsList } from './AttachmentsList'
import { useRequiredFieldStar } from '../Common/useRequiredFieldStar'

const { Text, Link } = Typography
const { Option, OptGroup } = Select
const { Step } = Steps

export const PropertiesConstant: any = [
    "Atomic/Molecular Property",
    "Chemical Property",
    "Electrical Property",
    "Magnetic Property",
    "Mechanical Property",
    "Optical Property",
    "Physical Property",
    "Thermal Property"
]

interface AddDataProps {
    setModalOpen?: Dispatch<SetStateAction<boolean>>
}

export const AddData = ({
    setModalOpen
}: AddDataProps) => {

    const [t] = useTranslate()
    const dispatch = useDispatch()
    const requiredFieldStar = useRequiredFieldStar()
    const { push } = useHistory()
    const currency = useSelector((store: StoreState) => store.login.loginResponse.currency)
    const [templateFields, setTemplateFields] = useState<any>({})
    const { updateParameterStatus, createParameterStatus, metadataPropertiesStatus, inventoryData, attachmentsList } = useSelector((state: StoreState) => state.inventory)
    const inventoryDataList = useSelector((state: StoreState) => state.displayNames.inventoryData)
    const unitsList = useSelector((state: StoreState) => state.conversion.unitList)
    const userRole = useSelector((state: StoreState) => state.login.loginResponse.user_role)
    const configs = useSelector((state: StoreState) => state.configs.features)
    const templateData = useSelector((state: StoreState) => state.inventory.templateData)
    const [optionValue, setOptionValue] = useState<string>("characterizations")
    const [items, setItems] = useState<any>([])
    const [name, setName] = useState('')
    const [viewTemplateModalVisible, setViewTemplateModalVisible] = useState<boolean>(false)
    const [metadataModalVisible, setMetadataModalVisible] = useState<boolean>(false)
    const [addTemplateModalVisible, setAddTemplateModalVisible] = useState<boolean>(false)
    const [unsaved, setUnsaved] = useState(false)
    const [step, setStep] = useState(0)
    const [form] = Form.useForm()
    const [costing, setCosting] = useState({
        quantity: null,
        currency: currency?.currency_code,
        amount: null,
        unit: null
    })
    const [fileList, setFileList] = useState<any>([])
    const [propMinValList, setPropMinValList] = useState<any>([])

    useEffect(() => {
        if (!!inventoryData?.identifier) {
            setOptionValue(inventoryData?.type)
        }
    }, [inventoryData])

    const callback = useCallback((e: any) => {
        e.preventDefault()
        e.returnValue = t("workOrderDetails.warn.unsavedChanges")
    }, [t])

    useEffect(() => {
        (unsaved) &&
            window.addEventListener('beforeunload', callback)
        return () => {
            window.removeEventListener('beforeunload', callback)
        }
    }, [unsaved, callback])

    useEffect(() => {
        const ingredientProperties = inventoryData?.meta

        form.setFieldsValue({
            ...inventoryData,
            type: inventoryData?.type,
            unit: inventoryData?.unit,
            // min: inventoryData?.range?.min,
            // max: inventoryData?.range?.max,
            ...(!!ingredientProperties ? {
                properties: ingredientProperties.map((res: any) => ({ ...res, category: inventoryDataList?.metadata_properties?.find((key: any) => key?.identifier === res?.identifier)?.category })),
            } : {})
        })
        if (!!inventoryData.costing) {
            setCosting({ ...inventoryData.costing, currency: inventoryData.costing.currency ?? currency?.currency_code })
        } else {
            setCosting({
                quantity: null,
                currency: currency?.currency_code,
                amount: null,
                unit: null
            })
        }
    }, [form, inventoryData, inventoryDataList, currency])

    useEffect(() => {
        if (updateParameterStatus === AsyncStates.SUCCESS || createParameterStatus === AsyncStates.SUCCESS) {
            setModalOpen && setModalOpen(false);
            // push(`/inventory/library?value=${optionValue}`)
        }
    }, [push, updateParameterStatus, createParameterStatus, optionValue, setModalOpen])

    useEffect(() => {
        if ((optionValue === "metadata_properties") && metadataPropertiesStatus === AsyncStates.SUCCESS) {
            setModalOpen && setModalOpen(false);
            // push(`/inventory/library?value=${optionValue}`)
        }
    }, [push, metadataPropertiesStatus, optionValue, setModalOpen])

    useEffect(() => {
        dispatch(listMetadataTemplateRequest({ type: "company" }))
        return () => {
            dispatch(checkPropertyExistsClear())
            dispatch(updateParameterClear())
            dispatch(createParameterClear())
            dispatch(clearInventoryData())
            dispatch(inventoryAttachmentsListClear())
        }
    }, [dispatch])

    useEffect(() => {
        if (inventoryData?.identifier && optionValue === "ingredients") {
            dispatch(inventoryAttachmentsListRequest({ type: optionValue, identifier: inventoryData?.identifier }))
        }
    }, [dispatch, inventoryData, optionValue])

    const costingUnitList = useMemo(() => (unitsList.filter((res: any) => optionValue === "ingredients" ? ["weight"].includes(res.category.toLowerCase()) && !["cP", "TgC", "phr"].includes(res.name) : true)), [unitsList, optionValue])

    const filterCategories = useMemo(() => {
        if (optionValue === "metadata_properties" || metadataModalVisible) return [...new Set(inventoryDataList?.["metadata_properties"]?.map((res: any) => res?.category).filter((res: any) => !!res?.trim())),
        ...PropertiesConstant
        ]
        return [...new Set(inventoryDataList?.[optionValue]?.map((res: any) => res?.category).filter((res: any) => !!res?.trim()))]
    }, [inventoryDataList, optionValue, metadataModalVisible])

    const filterSubCategories = useMemo(() => {
        return [...new Set(inventoryDataList?.ingredients?.map((res: any) => res?.sub_category).filter((res: any) => !!res?.trim()))]
    }, [inventoryDataList])

    const selectAfter = (
        <Select showSearch value={costing?.unit} onChange={(value) => {
            setCosting((prev: any) => ({ ...prev, unit: value }))
        }}
            placeholder={t("common.selectUnit")}
            style={{ minWidth: "6rem" }}
        >
            {Array.from(new Set(costingUnitList.map((res: any) => res.category.toLowerCase()))).map((category: any) =>
                <OptGroup label={String(category).charAt(0).toLocaleUpperCase() + String(category).slice(1)}>
                    {costingUnitList.filter((res: any) => res.category === category).map((res: any, index: any) => (
                        <Option value={res.name} key={res.name + index}>{res.name}</Option>
                    ))}
                </OptGroup>
            )}
        </Select>
    )

    const uploadProps = {
        onRemove: (file: any) => {
            setFileList((prevState: any) => {
                const index = prevState.indexOf(file)
                const newFileList = prevState.slice()
                newFileList.splice(index, 1)
                return newFileList
            })
        },
        beforeUpload: (file: any) => {
            setFileList((prevState: any) => {
                if (fileList.map((fileRecord: any) => fileRecord.name).includes(file.name)) {
                    message.error("File already uploaded")
                    return prevState
                } else {
                    return [...prevState, file]
                }
            })
        },
        fileList,
        multiple: true
    }

    const addItem = (e: any) => {
        const trimmedName = name?.trim()
        if (!trimmedName) {
            message.error(t("inventory.Addcontentintheitem"))
            return
        }
        if (items.includes(trimmedName)) {
            message.error(`${trimmedName} is already added in options`)
            return
        }
        e.preventDefault()
        setItems([...items, trimmedName])
        setName('')
        form.setFieldsValue({ options: [...items, trimmedName] })
    }

    const onSubmit = (values: any) => {
        setUnsaved(false)
        const nonNullKeys = Object.entries(costing || {})
            .filter(([key, value]) => (key !== "currency" && (value !== null && value !== "")))
            .map(([key, value]) => key);
        if (nonNullKeys.length && (nonNullKeys.length !== Object.keys(costing || {}).length - 1)) {
            message.error(t("costing.fillAllDetails"))
            return
        }
        if (costing.amount === 0 || costing.quantity === 0) {
            message.error(t("costing.amountOrQuantityCannotZero"))
            return
        }
        const { name, category, sub_category, unit, properties, supplier, options, lot_no, sub_group, main_group } = values
        const costingInfo = { ...costing, amount: convertToPrecision(costing.amount, 2), quantity: convertToPrecision(costing.quantity, 3) }
        const params = new FormData()
        if (inventoryData?.identifier) {
            params.append('identifier', inventoryData?.identifier)
        }
        fileList.forEach((file: any) => {
            params.append('files', file)
        })
        params.append("name", name)
        params.append("type", optionValue)
        params.append("category", category)
        params.append("unit", JSON.stringify(unit))
        if (sub_category) {
            params.append("sub_category", sub_category)
        }
        if (lot_no) {
            params.append("lot_no", lot_no)
        }
        if (supplier) {
            params.append("supplier", supplier)
        }
        if (properties?.length) {
            params.append("meta", JSON.stringify(properties.map((res: any) => ({
                identifier: res?.identifier,
                value: res?.value,
                min_val: res?.min_val,
                unit: res?.unit
            }))))
        }
        if (optionValue === "ingredients") {
            // params.append("range", JSON.stringify({ "min": min, "max": max }))
            params.append("costing", JSON.stringify(costingInfo))
        }
        if (options?.length) {
            params.append("options", JSON.stringify(options))
        }
        if (sub_group?.length) {
            params.append("sub_group", JSON.stringify(sub_group))
        }
        if (main_group?.length) {
            params.append("main_group", JSON.stringify(main_group))
        }
        if (inventoryData?.identifier) {
            dispatch(updateParameterRequest(params))
        } else {
            dispatch(createParameterRequest(params))
        }
    }

    return (
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <Prompt
                when={(unsaved) as boolean} message={`${t("common.unsavedChangesLost")}!.`}
            />
            <StyledPageHeader onBack={() => setModalOpen && setModalOpen(false)} ghost={false} title={inventoryData?.identifier ? t("inventory.editData") : t("common.addData")} />
            <StyledCard>
                <Spin spinning={createParameterStatus === AsyncStates.LOADING ||
                    updateParameterStatus === AsyncStates.LOADING || metadataPropertiesStatus === AsyncStates.LOADING}
                    indicator={<LoadingOutlined />}>
                    <Form onFinish={onSubmit} form={form} style={{ padding: "10px 32px" }} layout="vertical"
                        onFieldsChange={() => setUnsaved(true)}
                        onValuesChange={(e) => {
                            if (optionValue === "ingredients" && !!e?.category) {
                                const selectedTemplate = templateData.find((res: any) => (res?.category === e?.category && res?.sub_category === e?.sub_category) || res?.category === e?.category)
                                if (selectedTemplate) {
                                    form.setFieldsValue({
                                        properties: selectedTemplate?.data.map((res: any) => ({
                                            category: inventoryDataList?.metadata_properties?.find((key: any) => key?.identifier === res?.identifier)?.category,
                                            identifier: res?.identifier,
                                            value: res?.value,
                                            unit: res?.unit
                                        })) || []
                                    })
                                    message.success(`Applied ${selectedTemplate?.name} template for properties based on the category selected`)
                                } else {
                                    form.setFieldsValue({ properties: [] })
                                }
                            }
                        }}
                        requiredMark={false}
                    >
                        <Row style={{ width: "60rem" }}>
                            <Col span={14}>
                                {!inventoryData?.identifier &&
                                    <Steps current={step} style={{ marginTop: 20, marginBottom: 30 }}>
                                        <Step title={t("inventory.SelectDataType")} />
                                        <Step title={`Add ${optionValue === "metadata_properties" ? "properties directory" : optionValue}`} />
                                    </Steps>
                                }
                                {step === 0 && !inventoryData?.identifier &&
                                    <Form.Item label={t("common.type")} rules={[{ required: true }]} name="type" required tooltip={requiredFieldStar}>
                                        <Select disabled={inventoryData?.identifier} defaultValue={optionValue} onChange={(e) => {
                                            form.resetFields()
                                            form.setFieldsValue({ type: e })
                                            setOptionValue(e)
                                            setCosting({
                                                quantity: null,
                                                currency: currency?.currency_code,
                                                amount: null,
                                                unit: null
                                            })
                                        }}>
                                            <Option value="characterizations">{t("common.characterizations")}</Option>
                                            <Option value="processing">{t("common.processing")}</Option>
                                            <Option value="properties"> {t("formulations.type.properties")}</Option>
                                            <Option value="metadata_properties">{t("inventory.metadata.title")}</Option>
                                        </Select>
                                    </Form.Item>
                                }
                                {(step === 1 || inventoryData?.identifier) &&
                                    <>
                                        <Form.Item label={t("common.Name")} rules={[{ required: true, min: 2, whitespace: true, type: "string", transform: (value) => value?.trim() }]} name="name" required tooltip={requiredFieldStar}>
                                            <Input placeholder={t("common.name")} />
                                        </Form.Item>
                                        <Form.Item label={t("common.category")} rules={[{ required: true, transform: (value) => value?.trim() }]} name="category" required tooltip={requiredFieldStar}>
                                            <AutoComplete
                                                showSearch
                                                placeholder={t("common.selectCategory")}
                                                filterOption={true}
                                                options={filterCategories.map((res: any) => ({ label: res, value: res }))}
                                            />
                                        </Form.Item>
                                        {optionValue === "ingredients" && <>
                                            <Form.Item label={t("inventory.subCategory")} rules={[{ required: false, transform: (value) => value?.trim() }]} name="sub_category">
                                                <AutoComplete
                                                    showSearch
                                                    placeholder={t("common.selectCategory")}
                                                    filterOption={true}
                                                    options={filterSubCategories.map((res: any) => ({ label: res, value: res }))}
                                                />
                                            </Form.Item>

                                            <Form.Item label={t("inventory.lot_no")} rules={[{ required: false, min: 1, whitespace: true, type: "string", transform: (value) => value?.trim() }]} name="lot_no">
                                                <Input placeholder={t("inventory.lot_no")} />
                                            </Form.Item>
                                            {/* <StyledCard title={t("common.range")} style={{ marginBottom: 10 }}>
                                                <Space>
                                                    <Form.Item name={"min"} label={t('common.min')}
                                                       >
                                                        <Input  onBlur={(e) => {
                                                            const value = Number(e.target.value)
                                                            const maxVal = form?.getFieldsValue()?.max
                                                            if (
                                                                value && maxVal && !isNaN(Number(maxVal)) && Number(value) > Number(maxVal)
                                                            ) {
                                                                message.warning(t('aiEngine.minShouldBeLessThanMax'))
                                                                let { max } = form.getFieldsValue()
                                                                max = null
                                                                form.setFieldsValue({ max })
                                                            }}}/>
                                                    </Form.Item>
                                                    <Form.Item name={"max"} label={t('common.max')}
                                                       >
                                                        <Input onBlur={(e) => {
                                const value = Number(e.target.value)
                                const minVal = form?.getFieldsValue()?.min
                                if (
                                    value && minVal && !isNaN(Number(minVal)) && Number(value) < Number(minVal)
                                ) {
                                    message.warning(t('aiEngine.minShouldBeLessThanMax'))
                                    let { min } = form.getFieldsValue()
                                    min = null
                                    form.setFieldsValue({ min })
                                }
                            }} />
                                                    </Form.Item>
                                                </Space>
                                            </StyledCard> */}
                                        </>
                                        }
                                        <Form.Item label={t("common.unit")} rules={[{ required: true }]} name="unit" required tooltip={requiredFieldStar}>
                                            <Select showSearch mode="multiple"
                                                placeholder={t("common.selectUnit")}>
                                                {Array.from(new Set(unitsList.filter((res: any) => optionValue === "ingredients" ? ["ratio", "weight", "Ignore"].includes(res.category.toLowerCase()) : true).map((res: any) => res.category.toLowerCase()))).map((category: any) =>
                                                    <OptGroup label={String(category).charAt(0).toLocaleUpperCase() + String(category).slice(1)}>
                                                        {unitsList.filter((res: any) => res.category.toLowerCase() === category).map((res: any, index: any) => (
                                                            <Option value={res.name} key={res.name + index}>{res.name}</Option>
                                                        ))}
                                                    </OptGroup>
                                                )}
                                            </Select>
                                        </Form.Item>
                                        {optionValue === "characterizations" &&
                                            <Form.Item name={"options"}
                                                label={t('common.options')}
                                                rules={[{ required: false, message: "required" }]}>
                                                <Select mode="multiple" style={{ width: "100%", maxHeight: 200, overflow: "auto" }}
                                                    placeholder={t("inventory.Selectalltheavailableoptionsforthisselecttypefield")}
                                                    dropdownRender={menu => (
                                                        <>
                                                            {menu}
                                                            <Divider style={{ margin: '8px 0' }} />
                                                            <Space align="center" style={{ padding: '0 8px 4px' }}>
                                                                <Input placeholder={t("inventory.Addanoption")} value={name} onChange={(e) => setName(e.target.value)} minLength={1} />
                                                                <Link onClick={addItem} style={{ whiteSpace: 'nowrap' }}>
                                                                    <PlusOutlined />{t("common.addOption")}
                                                                </Link>
                                                            </Space>
                                                        </>
                                                    )}>
                                                    {items.map((res: any) => (
                                                        <Option value={res} key={res}>{res}</Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        }
                                        {optionValue === "ingredients" && <>
                                            <Form.Item label={t("inventory.supplier")} rules={[{ required: false, transform: (value) => value?.trim() }]} name="supplier">
                                                <Input placeholder={t("inventory.supplier")} />
                                            </Form.Item>
                                            <Form.Item label={t("inventory.costing")} rules={[{ required: false, transform: (value) => value?.trim() }]} name="costing">
                                                <Space style={{ display: "flex", justifyContent: "flex-start", gap: "1rem" }}>
                                                    <Input.Group compact>
                                                        <InputNumber
                                                            type={"number"}
                                                            addonBefore={
                                                                <div>
                                                                    <Tooltip title={costing.currency ? costing.currency : currency?.currency_name}>
                                                                        <Text strong>{costing.currency ?? currency?.currency_code}</Text>
                                                                    </Tooltip>
                                                                    {"  "}
                                                                    {userRole === "admin" &&
                                                                        <Tooltip title={currency?.is_default_value_updated ? t("common.currency.updateCurrencyNote") : t("common.currency.updateCurrencyFromProfile")}><InfoCircleFilled /></Tooltip>
                                                                    }
                                                                </div>}
                                                            placeholder={t("common.amount")}
                                                            min={0}
                                                            name={"amount"}
                                                            addonAfter={t("common.per")}
                                                            value={costing?.amount}
                                                            style={{ width: "55%" }}
                                                            onChange={(e) => {
                                                                setCosting((prev: any) => ({ ...prev, amount: e }))
                                                            }}
                                                        />
                                                        <InputNumber
                                                            min={0}
                                                            type="number"
                                                            placeholder={t("common.quantity")}
                                                            name='quantity'
                                                            onChange={(e) => {
                                                                setCosting((prev: any) => ({ ...prev, quantity: e }))
                                                            }}
                                                            style={{ width: "45%" }}
                                                            value={costing?.quantity}
                                                            addonAfter={selectAfter}
                                                        />
                                                    </Input.Group>
                                                    <Tooltip title={t("costing.clearCosting")}>
                                                        <StyledButton
                                                            onClick={() => setCosting({
                                                                quantity: null,
                                                                currency: currency?.currency_code,
                                                                amount: null,
                                                                unit: null
                                                            })}>{t("common.clear")}
                                                        </StyledButton>
                                                    </Tooltip>
                                                </Space>
                                            </Form.Item>
                                        </>}
                                        {(optionValue === "ingredients" && Boolean(configs?.nestle_configs)) && <>
                                            <Form.Item label={t("common.mainGroup")} rules={[{ required: false }]} name="main_group">
                                                <Select showSearch mode="multiple" allowClear
                                                    placeholder={`${t('common.select')} ${t('common.mainGroup')}`}>
                                                    <Option value={"MaingroupA"} key={"MaingroupA"}>{"MaingroupA"}</Option>
                                                    <Option value={"MaingroupB"} key={"MaingroupB"}>{"MaingroupB"}</Option>
                                                    <Option value={"MaingroupC"} key={"MaingroupC"}>{"MaingroupC"}</Option>
                                                    <Option value={"MaingroupD"} key={"MaingroupD"}>{"MaingroupD"}</Option>
                                                    <Option value={"MaingroupE"} key={"MaingroupE"}>{"MaingroupE"}</Option>
                                                </Select>
                                            </Form.Item>
                                            <Form.Item label={t('common.subGroup')} rules={[{ required: false }]} name="sub_group">
                                                <Select showSearch mode="multiple" allowClear
                                                    placeholder={`${t('common.select')} ${t('common.subGroup')}`}>
                                                    <Option value={"Subgroup1"} key={"Subgroup1"}>{"Subgroup1"}</Option>
                                                    <Option value={"Subgroup2"} key={"Subgroup1"}>{"Subgroup2"}</Option>
                                                    <Option value={"Subgroup3"} key={"Subgroup1"}>{"Subgroup3"}</Option>
                                                    <Option value={"Subgroup4"} key={"Subgroup1"}>{"Subgroup4"}</Option>
                                                    <Option value={"Subgroup5"} key={"Subgroup1"}>{"Subgroup5"}</Option>
                                                    <Option value={"Subgroup6"} key={"Subgroup1"}>{"Subgroup6"}</Option>
                                                    <Option value={"Subgroup7"} key={"Subgroup1"}>{"Subgroup7"}</Option>
                                                    <Option value={"Subgroup8"} key={"Subgroup1"}>{"Subgroup8"}</Option>
                                                    <Option value={"Subgroup9"} key={"Subgroup1"}>{"Subgroup9"}</Option>
                                                    <Option value={"Subgroup10"} key={"Subgroup1"}>{"Subgroup10"}</Option>
                                                    <Option value={"Subgroup11"} key={"Subgroup1"}>{"Subgroup11"}</Option>
                                                </Select>
                                            </Form.Item>
                                        </>
                                        }
                                    </>
                                }
                            </Col>
                        </Row>
                        {optionValue === "ingredients" && (step === 1 || inventoryData?.identifier) ?
                            <StyledCard title={t("formulations.type.properties")} extra={
                                <StyledButton ghost type={'primary'} onClick={() => setViewTemplateModalVisible(true)}>{t("common.templates")}</StyledButton>
                            }>
                                <Form.List name="properties">
                                    {(fields, { add, remove }, { errors }) => (
                                        <>
                                            {fields.map(({ key, name, }, index: any) => (
                                                <Space direction="horizontal" style={{ alignItems: "end" }}>
                                                    <Form.Item
                                                        name={[name, 'category']}
                                                        fieldKey={[key, 'category']}
                                                        rules={[{ required: false, message: t("common.required"), transform: (value) => value?.trim() }]}
                                                    >
                                                        <Input disabled />
                                                    </Form.Item>
                                                    <Form.Item name={[name, 'identifier']} fieldKey={[key, 'identifier']} rules={[{ required: true, message: t("common.required"), transform: (value) => value?.trim() }]} required tooltip={requiredFieldStar}>
                                                        <Select
                                                            showSearch
                                                            style={{ width: 220 }}
                                                            filterOption={true}
                                                            placeholder={t("common.selectProperties")}
                                                            onChange={e => {
                                                                const currentObject = inventoryDataList?.metadata_properties?.find((res: any) => res?.identifier === e)
                                                                const currentFormField = { category: currentObject?.category, identifier: e, unit: currentObject?.unit?.[0], value: null }
                                                                const formProperties = form.getFieldValue("properties")
                                                                formProperties[index] = currentFormField
                                                                form.setFieldsValue({ "properties": formProperties })
                                                            }}
                                                            dropdownRender={(menu) => (
                                                                <div>
                                                                    {menu}
                                                                    <Divider style={{ margin: "4px 0" }} />
                                                                    {/* <CustomSelectionDropdown {...props} /> */}
                                                                    <StyledButton
                                                                        onClick={() => setMetadataModalVisible(true)}
                                                                        type="link"
                                                                    >
                                                                        {t("inventory.AddNewproperty")}
                                                                    </StyledButton>
                                                                </div>
                                                            )}
                                                        >
                                                            {inventoryDataList?.metadata_properties?.map((res: any) => {
                                                                return (
                                                                    <Option value={res?.identifier}>{res?.name}</Option>
                                                                )
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item name={[name, 'unit']} fieldKey={[key, 'unit']} rules={[{ required: true, message: t("common.required") }]} required tooltip={requiredFieldStar}>
                                                        <Select showSearch
                                                            placeholder={t("common.selectUnit")}>
                                                            {Array.from(new Set(unitsList.map((res: any) => res.category.toLowerCase()))).map((category: any) =>
                                                                <OptGroup label={String(category).charAt(0).toLocaleUpperCase() + String(category).slice(1)}>
                                                                    {unitsList.filter((res: any) => res.category.toLowerCase() === category).map((res: any, index: any) => (
                                                                        <Option value={res.name} key={res.name + index}>{res.name}</Option>
                                                                    ))}
                                                                </OptGroup>
                                                            )}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item name={[name, 'min_val']} fieldKey={[key, 'min_val']} label={t('common.min')}
                                                    >
                                                        <Input onBlur={(e) => {
                                                            const value = Number(e.target.value)
                                                            const max = form?.getFieldsValue()?.properties?.[name]?.value
                                                            if (
                                                                value && max && !isNaN(Number(max)) && Number(value) > Number(max)
                                                            ) {
                                                                message.warning(t('aiEngine.minShouldBeLessThanMax'))
                                                                const { properties } = form.getFieldsValue()
                                                                properties[name].value = null
                                                                form.setFieldsValue({ properties })
                                                            }
                                                        }} onChange={(e) => {
                                                            if (e.target.value?.trim() === "0") {
                                                                Modal.confirm({
                                                                    title: t("inventory.metadata.warningTitle"),
                                                                    content: t("inventory.metadata.warningContent"),
                                                                    okText: t("inventory.metadata.warningOk"),
                                                                    cancelText: t("inventory.metadata.warningCancel"),
                                                                    width: 600,
                                                                    onOk: () => {
                                                                        const formProperties = form.getFieldValue("properties")
                                                                        const currentFormField = formProperties[index]
                                                                        currentFormField["min_value"] = 0
                                                                        formProperties[index] = currentFormField
                                                                        form.setFieldsValue({ "properties": formProperties })
                                                                    },
                                                                    onCancel: () => {
                                                                        const formProperties = form.getFieldValue("properties")
                                                                        const currentFormField = formProperties[index]
                                                                        currentFormField["value"] = ""
                                                                        formProperties[index] = currentFormField
                                                                        form.setFieldsValue({ "properties": formProperties })
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                            placeholder={t("common.minimum") + " " + t("formulations.placeholder.value")} />
                                                    </Form.Item>
                                                    <Form.Item name={[name, 'value']} fieldKey={[key, 'value']} label={t('common.max')}
                                                    >
                                                        <Input onBlur={(e) => {
                                                            const value = Number(e.target.value)
                                                            const min = form?.getFieldsValue()?.properties?.[name]?.min_val
                                                            if (
                                                                value && min && !isNaN(Number(min)) && Number(value) < Number(min)
                                                            ) {
                                                                message.warning(t('aiEngine.minShouldBeLessThanMax'))
                                                                const { properties } = form.getFieldsValue()
                                                                properties[name].min_val = null
                                                                form.setFieldsValue({ properties })
                                                            }
                                                        }} onChange={(e) => {
                                                            if (e.target.value?.trim() === "0") {
                                                                Modal.confirm({
                                                                    title: t("inventory.metadata.warningTitle"),
                                                                    content: t("inventory.metadata.warningContent"),
                                                                    okText: t("inventory.metadata.warningOk"),
                                                                    cancelText: t("inventory.metadata.warningCancel"),
                                                                    width: 600,
                                                                    onOk: () => {
                                                                        const formProperties = form.getFieldValue("properties")
                                                                        const currentFormField = formProperties[index]
                                                                        currentFormField["value"] = 0
                                                                        formProperties[index] = currentFormField
                                                                        form.setFieldsValue({ "properties": formProperties })
                                                                    },
                                                                    onCancel: () => {
                                                                        const formProperties = form.getFieldValue("properties")
                                                                        const currentFormField = formProperties[index]
                                                                        currentFormField["value"] = ""
                                                                        formProperties[index] = currentFormField
                                                                        form.setFieldsValue({ "properties": formProperties })
                                                                    }
                                                                })
                                                            }
                                                        }}
                                                            placeholder={`${t("formulations.placeholder.maximum")} ${t("formulations.placeholder.value")}`} />
                                                    </Form.Item>
                                                    <Form.Item >
                                                        <MinusCircleOutlined
                                                            className="dynamic-delete-button"
                                                            onClick={() => {
                                                                setPropMinValList([...propMinValList.filter((value: any) => value !== key)]);
                                                                remove(name)
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Space>
                                            ))}
                                            <Form.Item>
                                                <Space>
                                                    <StyledButton
                                                        type="dashed"
                                                        onClick={() => add()}
                                                        icon={<PlusOutlined />}
                                                    >
                                                        {t("inventory.addProperty")}
                                                    </StyledButton>
                                                    {fields?.length ? (
                                                        <StyledButton
                                                            type="primary"
                                                            ghost
                                                            icon={<FileAddOutlined />}
                                                            onClick={() => {
                                                                setTemplateFields({
                                                                    properties: form.getFieldValue("properties")
                                                                })
                                                                setAddTemplateModalVisible(true)
                                                            }}
                                                        >
                                                            {"Save Template"}
                                                        </StyledButton>
                                                    ) : null
                                                    }
                                                </Space>
                                                <Form.ErrorList errors={errors} />
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List> </StyledCard> : ""}
                        {((optionValue === "ingredients" && step === 1) || (optionValue === "ingredients" && inventoryData?.identifier)) &&
                            <Collapse defaultActiveKey={"attachments"} style={{ marginTop: 20 }}>
                                <Collapse.Panel header={<Text strong>{"Attachments"}</Text>} key="attachments">
                                    <Space direction="vertical" size="large" style={{ width: "100%" }}>
                                        <Dragger {...uploadProps} >
                                            <p className="ant-upload-drag-icon">
                                                <InboxOutlined />
                                            </p>
                                            <p className="ant-upload-text">{t("fileupload.upload")}</p>
                                        </Dragger>
                                        {attachmentsList?.length ?
                                            <AttachmentsList /> : null
                                        }
                                    </Space>
                                </Collapse.Panel>
                            </Collapse>
                        }
                        <Col style={{ marginTop: 20, textAlign: "right" }}>
                            <Row justify={step === 0 ? "end" : "center"}>
                                {step === 0 && !inventoryData?.identifier ?
                                    <Space>
                                        <StyledButton type='link' onClick={() => {
                                            setModalOpen && setModalOpen(false);
                                            // push(`/inventory/library?value=${optionValue}`)
                                        }}>
                                            {t("common.cancel")}
                                        </StyledButton>
                                        <Tooltip title={optionValue ? "Next" : "Select a data type"}>
                                            <StyledButton disabled={!optionValue} onClick={() => setStep(1)} type={"primary"}>
                                                {"Next"}
                                            </StyledButton>
                                        </Tooltip>
                                    </Space>
                                    :
                                    <Space>
                                        <StyledButton type='link' onClick={() => {
                                            setModalOpen && setModalOpen(false);
                                            // push(`/inventory/library?value=${optionValue}`)
                                        }}>
                                            {t("common.cancel")}
                                        </StyledButton>
                                        {!inventoryData?.identifier &&
                                            <StyledButton type="primary" ghost onClick={() => {
                                                setStep(0)
                                                form.resetFields()
                                                setOptionValue("characterizations")
                                                setUnsaved(false);
                                                setCosting({
                                                    quantity: null,
                                                    currency: currency?.currency_code,
                                                    amount: null,
                                                    unit: null
                                                })
                                            }}>
                                                {t("common.back")}
                                            </StyledButton>
                                        }
                                        <StyledButton type="primary" htmlType="submit">
                                            {t("common.submit")}
                                        </StyledButton>
                                    </Space>
                                }
                            </Row>
                        </Col>
                    </Form>
                </Spin>
            </StyledCard>
            <AddTemplateModal
                visible={addTemplateModalVisible}
                setVisible={setAddTemplateModalVisible}
                templateFields={templateFields}
                setTemplateFields={setTemplateFields}
                filterCategories={filterCategories}
                filterSubCategories={filterSubCategories}
                setMetadataModalVisible={setMetadataModalVisible}
            />
            <ViewTemplatesModal
                visible={viewTemplateModalVisible}
                setVisible={setViewTemplateModalVisible}
                addTemplateModalVisible={addTemplateModalVisible}
                setAddTemplateModalVisible={setAddTemplateModalVisible}
                setMetadataModalVisible={setMetadataModalVisible}
                form={form}
            />
            <AddMetaDataModal
                metadataModalVisible={metadataModalVisible}
                setMetadataModalVisible={setMetadataModalVisible}
                filterCategories={filterCategories}
            />
        </Space>
    )
}
