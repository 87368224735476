import { createActions } from 'redux-actions'

export enum QualityCheckViewerVisibility {
  QC_TOGGLE_FILE_NAVIGATION = 'QC_TOGGLE_FILE_NAVIGATION',
  QC_SET_SELECTED_FILE = 'QC_SET_SELECTED_FILE',
  QC_SET_PAGE_NUMBER = 'QC_SET_PAGE_NUMBER',
  QC_SET_IS_REPORT_SECTION_UNSAVED = 'QC_SET_IS_REPORT_SECTION_UNSAVED',
  QC_SET_REGISTER_LINK_MODAL_VISIBLE = 'QC_SET_REGISTER_LINK_MODAL_VISIBLE',
  QC_SET_FILE_DRAWER_VISIBLE = 'QC_SET_FILE_DRAWER_VISIBLE',
  QC_SET_FILE_UPLOAD_MODAL_VISIBLE = 'QC_SET_FILE_UPLOAD_MODAL_VISIBLE',

  QC_SET_SELECTED_FILES_FOR_REPORT = 'QC_SET_SELECTED_FILES_FOR_REPORT',
  QC_CLEAR_SELECTED_FILES_FOR_REPORT = 'QC_CLEAR_SELECTED_FILES_FOR_REPORT',

  QC_SET_GET_FILES_PAGE_NUMBER = 'QC_SET_GET_FILES_PAGE_NUMBER',
  QC_SET_GET_FILES_FILTERS = 'QC_SET_GET_FILES_FILTERS',
  QC_SET_GET_FILES_SEARCH_TERM = 'QC_SET_GET_FILES_SEARCH_TERM',

  QC_SET_SELECT_ALL_FILES = 'QC_SET_SELECT_ALL_FILES',

  QC_ADD_IGNORE_FILES_FOR_REPORT = 'QC_ADD_IGNORE_FILES_FOR_REPORT',
  QC_REMOVE_IGNORE_FILES_FOR_REPORT = 'QC_REMOVE_IGNORE_FILES_FOR_REPORT'
}

export enum QualityCheckGetFiles {
  QC_GET_FILES_REQUEST = 'QC_GET_FILES_REQUEST',
  QC_GET_FILES_SUCCESS = 'QC_GET_FILES_SUCCESS',
  QC_GET_FILES_FAILURE = 'QC_GET_FILES_FAILURE',
  QC_GET_FILES_CLEAR = 'QC_GET_FILES_CLEAR'
}

export enum QualityCheckApplyFiles {
  QC_APPLY_FILES_REQUEST = 'QC_APPLY_FILES_REQUEST',
  QC_APPLY_FILES_SUCCESS = 'QC_APPLY_FILES_SUCCESS',
  QC_APPLY_FILES_FAILURE = 'QC_APPLY_FILES_FAILURE',
  QC_APPLY_FILES_CLEAR = 'QC_APPLY_FILES_CLEAR'
}

export enum QualityCheckFileExtractions {
  QC_FILE_EXTRACTIONS_SUCCESS = 'QC_FILE_EXTRACTIONS_SUCCESS',
  QC_FILE_EXTRACTIONS_REQUEST = 'QC_FILE_EXTRACTIONS_REQUEST',
  QC_FILE_EXTRACTIONS_FAILURE = 'QC_FILE_EXTRACTIONS_FAILURE',
  QC_FILE_EXTRACTIONS_CLEAR = 'QC_FILE_EXTRACTIONS_CLEAR'
}

export enum QualityCheckSaveChangesAndApply {
  QC_SAVE_CHANGES_AND_APPLY_REQUEST = 'QC_SAVE_CHANGES_AND_APPLY_REQUEST',
  QC_SAVE_CHANGES_AND_APPLY_SUCCESS = 'QC_SAVE_CHANGES_AND_APPLY_SUCCESS',
  QC_SAVE_CHANGES_AND_APPLY_FAILURE = 'QC_SAVE_CHANGES_AND_APPLY_FAILURE',
  QC_SAVE_CHANGES_AND_APPLY_CLEAR = 'QC_SAVE_CHANGES_AND_APPLY_CLEAR'
}

export enum QualityCheckDeleteFile {
  QC_DELETE_FILE_REQUEST = 'QC_DELETE_FILE_REQUEST',
  QC_DELETE_FILE_SUCCESS = 'QC_DELETE_FILE_SUCCESS',
  QC_DELETE_FILE_FAILURE = 'QC_DELETE_FILE_FAILURE',
  QC_DELETE_FILE_CLEAR = 'QC_DELETE_FILE_CLEAR'
}

export enum QualityCheckRegisterLinkLocalUpdate {
  QC_REGISTER_LINK_LOCAL_UPDATE = 'QC_REGISTER_LINK_LOCAL_UPDATE'
}

export enum QualityCheckGetRegisterLinkData {
  QC_GET_REGISTER_LINK_DATA_REQUEST = 'QC_GET_REGISTER_LINK_DATA_REQUEST',
  QC_GET_REGISTER_LINK_DATA_SUCCESS = 'QC_GET_REGISTER_LINK_DATA_SUCCESS',
  QC_GET_REGISTER_LINK_DATA_FAILURE = 'QC_GET_REGISTER_LINK_DATA_FAILURE',
  QC_GET_REGISTER_LINK_DATA_CLEAR = 'QC_GET_REGISTER_LINK_DATA_CLEAR'
}

export enum QualityCheckValidateLink {
  QC_VALIDATE_LINK_REQUEST = 'QC_VALIDATE_LINK_REQUEST',
  QC_VALIDATE_LINK_SUCCESS = 'QC_VALIDATE_LINK_SUCCESS',
  QC_VALIDATE_LINK_FAILURE = 'QC_VALIDATE_LINK_FAILURE',
  QC_VALIDATE_LINK_CLEAR = 'QC_VALIDATE_LINK_CLEAR'
}

export enum QualityCheckLinkRecurringCycle {
  QC_LINK_RECURRING_CYCLE_REQUEST = 'QC_LINK_RECURRING_CYCLE_REQUEST',
  QC_LINK_RECURRING_CYCLE_SUCCESS = 'QC_LINK_RECURRING_CYCLE_SUCCESS',
  QC_LINK_RECURRING_CYCLE_FAILURE = 'QC_LINK_RECURRING_CYCLE_FAILURE',
  QC_LINK_RECURRING_CYCLE_CLEAR = 'QC_LINK_RECURRING_CYCLE_CLEAR'
}

export enum QualityCheckSyncOnDemand {
  QC_SYNC_ON_DEMAND_REQUEST = 'QC_SYNC_ON_DEMAND_REQUEST',
  QC_SYNC_ON_DEMAND_SUCCESS = 'QC_SYNC_ON_DEMAND_SUCCESS',
  QC_SYNC_ON_DEMAND_FAILURE = 'QC_SYNC_ON_DEMAND_FAILURE',
  QC_SYNC_ON_DEMAND_CLEAR = 'QC_SYNC_ON_DEMAND_CLEAR'
}

export enum QualityCheckLocalUpdatesOnFiles {
  QC_LOCAL_UPDATE_FILE = 'QC_LOCAL_UPDATE_FILE'
}

export enum QualityCheckGenerateReport {
  QC_GENERATE_REPORT_REQUEST = 'QC_GENERATE_REPORT_REQUEST',
  QC_GENERATE_REPORT_SUCCESS = 'QC_GENERATE_REPORT_SUCCESS',
  QC_GENERATE_REPORT_FAILURE = 'QC_GENERATE_REPORT_FAILURE',
  QC_GENERATE_REPORT_CLEAR = 'QC_GENERATE_REPORT_CLEAR'
}

// Metadata

export enum QualityCheckMetadataList {
  QC_METADATA_LIST_REQUEST = 'QC_METADATA_LIST_REQUEST',
  QC_METADATA_LIST_SUCCESS = 'QC_METADATA_LIST_SUCCESS',
  QC_METADATA_LIST_FAILURE = 'QC_METADATA_LIST_FAILURE',
  QC_METADATA_LIST_CLEAR = 'QC_METADATA_LIST_CLEAR'
}

export enum QualityCheckMetadataLocal {
  QC_METADATA_SET_FILTERS = 'QC_METADATA_SET_FILTERS',
  QC_METADATA_SET_SEARCH_TERM = 'QC_METADATA_SET_SEARCH_TERM',
  QC_METADATA_SET_PAGE_NUMBER = 'QC_METADATA_SET_PAGE_NUMBER',
  QC_METADATA_EDIT_DRAWER_VISIBLE = 'QC_METADATA_EDIT_DRAWER_VISIBLE',

  QC_METADATA_UPDATE = 'QC_METADATA_UPDATE',
  QC_METADATA_STEP_ERRORS = 'QC_METADATA_STEP_ERRORS'
}

export enum QualityCheckGetMetadata {
  QC_GET_METADATA_REQUEST = 'QC_GET_METADATA_REQUEST',
  QC_GET_METADATA_SUCCESS = 'QC_GET_METADATA_SUCCESS',
  QC_GET_METADATA_FAILURE = 'QC_GET_METADATA_FAILURE',
  QC_GET_METADATA_CLEAR = 'QC_GET_METADATA_CLEAR'
}

export enum QualityCheckCreateMetadata {
  QC_CREATE_METADATA_REQUEST = 'QC_CREATE_METADATA_REQUEST',
  QC_CREATE_METADATA_SUCCESS = 'QC_CREATE_METADATA_SUCCESS',
  QC_CREATE_METADATA_FAILURE = 'QC_CREATE_METADATA_FAILURE',
  QC_CREATE_METADATA_CLEAR = 'QC_CREATE_METADATA_CLEAR'
}

export enum QualityCheckUpdateMetadata {
  QC_UPDATE_METADATA_REQUEST = 'QC_UPDATE_METADATA_REQUEST',
  QC_UPDATE_METADATA_SUCCESS = 'QC_UPDATE_METADATA_SUCCESS',
  QC_UPDATE_METADATA_FAILURE = 'QC_UPDATE_METADATA_FAILURE',
  QC_UPDATE_METADATA_CLEAR = 'QC_UPDATE_METADATA_CLEAR'
}

export enum QualityCheckDeleteMetadata {
  QC_DELETE_METADATA_REQUEST = 'QC_DELETE_METADATA_REQUEST',
  QC_DELETE_METADATA_SUCCESS = 'QC_DELETE_METADATA_SUCCESS',
  QC_DELETE_METADATA_FAILURE = 'QC_DELETE_METADATA_FAILURE',
  QC_DELETE_METADATA_CLEAR = 'QC_DELETE_METADATA_CLEAR'
}

export enum QualityCheckGetNameDetails {
  QC_GET_NAME_DETAILS_REQUEST = 'QC_GET_NAME_DETAILS_REQUEST',
  QC_GET_NAME_DETAILS_SUCCESS = 'QC_GET_NAME_DETAILS_SUCCESS',
  QC_GET_NAME_DETAILS_FAILURE = 'QC_GET_NAME_DETAILS_FAILURE',
  QC_GET_NAME_DETAILS_CLEAR = 'QC_GET_NAME_DETAILS_CLEAR'
}

export enum QualityCheckCreateNameDetails {
  QC_CREATE_NAME_DETAILS_REQUEST = 'QC_CREATE_NAME_DETAILS_REQUEST',
  QC_CREATE_NAME_DETAILS_SUCCESS = 'QC_CREATE_NAME_DETAILS_SUCCESS',
  QC_CREATE_NAME_DETAILS_FAILURE = 'QC_CREATE_NAME_DETAILS_FAILURE',
  QC_CREATE_NAME_DETAILS_CLEAR = 'QC_CREATE_NAME_DETAILS_CLEAR'
}

export enum QualityCheckUpdateNameDetails {
  QC_UPDATE_NAME_DETAILS_REQUEST = 'QC_UPDATE_NAME_DETAILS_REQUEST',
  QC_UPDATE_NAME_DETAILS_SUCCESS = 'QC_UPDATE_NAME_DETAILS_SUCCESS',
  QC_UPDATE_NAME_DETAILS_FAILURE = 'QC_UPDATE_NAME_DETAILS_FAILURE',
  QC_UPDATE_NAME_DETAILS_CLEAR = 'QC_UPDATE_NAME_DETAILS_CLEAR'
}

export enum QualityCheckDeleteNameDetails {
  QC_DELETE_NAME_DETAILS_REQUEST = 'QC_DELETE_NAME_DETAILS_REQUEST',
  QC_DELETE_NAME_DETAILS_SUCCESS = 'QC_DELETE_NAME_DETAILS_SUCCESS',
  QC_DELETE_NAME_DETAILS_FAILURE = 'QC_DELETE_NAME_DETAILS_FAILURE',
  QC_DELETE_NAME_DETAILS_CLEAR = 'QC_DELETE_NAME_DETAILS_CLEAR'
}

export enum QualityCheckGetImportDataOptions {
  QC_GET_IMPORT_DATA_OPTIONS_REQUEST = 'QC_GET_IMPORT_DATA_OPTIONS_REQUEST',
  QC_GET_IMPORT_DATA_OPTIONS_SUCCESS = 'QC_GET_IMPORT_DATA_OPTIONS_SUCCESS',
  QC_GET_IMPORT_DATA_OPTIONS_FAILURE = 'QC_GET_IMPORT_DATA_OPTIONS_FAILURE',
  QC_GET_IMPORT_DATA_OPTIONS_CLEAR = 'QC_GET_IMPORT_DATA_OPTIONS_CLEAR'
}

export enum QualityCheckImportData {
  QC_IMPORT_DATA_REQUEST = 'QC_IMPORT_DATA_REQUEST',
  QC_IMPORT_DATA_SUCCESS = 'QC_IMPORT_DATA_SUCCESS',
  QC_IMPORT_DATA_FAILURE = 'QC_IMPORT_DATA_FAILURE',
  QC_IMPORT_DATA_CLEAR = 'QC_IMPORT_DATA_CLEAR'
}

export enum QualityCheckAddDetailsLocal {
  QC_ADD_DETAILS = 'QC_ADD_DETAILS'
}

export const {
  qcToggleFileNavigation,
  qcSetSelectedFile,
  qcSetPageNumber,
  qcSetIsReportSectionUnsaved,
  qcSetRegisterLinkModalVisible,
  qcSetFileDrawerVisible,
  qcSetFileUploadModalVisible,
  qcSetSelectedFilesForReport,
  qcClearSelectedFilesForReport,
  qcSetGetFilesPageNumber,
  qcSetGetFilesFilters,
  qcSetGetFilesSearchTerm,
  qcSetSelectAllFiles,
  qcAddIgnoreFilesForReport,
  qcRemoveIgnoreFilesForReport,

  qcGetFilesRequest,
  qcGetFilesSuccess,
  qcGetFilesFailure,
  qcGetFilesClear,

  qcApplyFilesRequest,
  qcApplyFilesSuccess,
  qcApplyFilesFailure,
  qcApplyFilesClear,

  qcFileExtractionsRequest,
  qcFileExtractionsSuccess,
  qcFileExtractionsFailure,
  qcFileExtractionsClear,

  qcSaveChangesAndApplyRequest,
  qcSaveChangesAndApplySuccess,
  qcSaveChangesAndApplyFailure,
  qcSaveChangesAndApplyClear,

  qcDeleteFileRequest,
  qcDeleteFileSuccess,
  qcDeleteFileFailure,
  qcDeleteFileClear,

  qcRegisterLinkLocalUpdate,

  qcGetRegisterLinkDataRequest,
  qcGetRegisterLinkDataSuccess,
  qcGetRegisterLinkDataFailure,
  qcGetRegisterLinkDataClear,

  qcValidateLinkRequest,
  qcValidateLinkSuccess,
  qcValidateLinkFailure,
  qcValidateLinkClear,

  qcLinkRecurringCycleRequest,
  qcLinkRecurringCycleSuccess,
  qcLinkRecurringCycleFailure,
  qcLinkRecurringCycleClear,

  qcSyncOnDemandRequest,
  qcSyncOnDemandSuccess,
  qcSyncOnDemandFailure,
  qcSyncOnDemandClear,

  qcLocalUpdateFile,

  qcGenerateReportRequest,
  qcGenerateReportSuccess,
  qcGenerateReportFailure,
  qcGenerateReportClear,

  // Metadata

  qcMetadataListRequest,
  qcMetadataListSuccess,
  qcMetadataListFailure,
  qcMetadataListClear,

  qcMetadataSetFilters,
  qcMetadataSetSearchTerm,
  qcMetadataSetPageNumber,
  qcMetadataEditDrawerVisible,
  qcMetadataUpdate,
  qcMetadataStepErrors,

  qcGetMetadataRequest,
  qcGetMetadataSuccess,
  qcGetMetadataFailure,
  qcGetMetadataClear,

  qcCreateMetadataRequest,
  qcCreateMetadataSuccess,
  qcCreateMetadataFailure,
  qcCreateMetadataClear,

  qcUpdateMetadataRequest,
  qcUpdateMetadataSuccess,
  qcUpdateMetadataFailure,
  qcUpdateMetadataClear,

  qcDeleteMetadataRequest,
  qcDeleteMetadataSuccess,
  qcDeleteMetadataFailure,
  qcDeleteMetadataClear,

  // Name Details

  qcGetNameDetailsRequest,
  qcGetNameDetailsSuccess,
  qcGetNameDetailsFailure,
  qcGetNameDetailsClear,

  qcCreateNameDetailsRequest,
  qcCreateNameDetailsSuccess,
  qcCreateNameDetailsFailure,
  qcCreateNameDetailsClear,

  qcUpdateNameDetailsRequest,
  qcUpdateNameDetailsSuccess,
  qcUpdateNameDetailsFailure,
  qcUpdateNameDetailsClear,

  qcDeleteNameDetailsRequest,
  qcDeleteNameDetailsSuccess,
  qcDeleteNameDetailsFailure,
  qcDeleteNameDetailsClear,

  qcGetImportDataOptionsRequest,
  qcGetImportDataOptionsSuccess,
  qcGetImportDataOptionsFailure,
  qcGetImportDataOptionsClear,

  qcImportDataRequest,
  qcImportDataSuccess,
  qcImportDataFailure,
  qcImportDataClear,

  qcAddDetails
} = createActions({
  [QualityCheckViewerVisibility.QC_TOGGLE_FILE_NAVIGATION]: () => ({}),
  [QualityCheckViewerVisibility.QC_SET_SELECTED_FILE]: (payload: any) => payload,
  [QualityCheckViewerVisibility.QC_SET_PAGE_NUMBER]: (payload: any) => payload,
  [QualityCheckViewerVisibility.QC_SET_IS_REPORT_SECTION_UNSAVED]: (payload: any) => payload,
  [QualityCheckViewerVisibility.QC_SET_REGISTER_LINK_MODAL_VISIBLE]: (payload: any) => payload,
  [QualityCheckViewerVisibility.QC_SET_FILE_DRAWER_VISIBLE]: (payload: any) => payload,
  [QualityCheckViewerVisibility.QC_SET_FILE_UPLOAD_MODAL_VISIBLE]: (payload: any) => payload,
  [QualityCheckViewerVisibility.QC_SET_SELECTED_FILES_FOR_REPORT]: (payload: any) => payload,
  [QualityCheckViewerVisibility.QC_CLEAR_SELECTED_FILES_FOR_REPORT]: () => ({}),
  [QualityCheckViewerVisibility.QC_SET_GET_FILES_PAGE_NUMBER]: (payload: any) => payload,
  [QualityCheckViewerVisibility.QC_SET_GET_FILES_FILTERS]: (payload: any) => payload,
  [QualityCheckViewerVisibility.QC_SET_GET_FILES_SEARCH_TERM]: (payload: any) => payload,
  [QualityCheckViewerVisibility.QC_SET_SELECT_ALL_FILES]: (payload: any) => payload,
  [QualityCheckViewerVisibility.QC_ADD_IGNORE_FILES_FOR_REPORT]: (payload: any) => payload,
  [QualityCheckViewerVisibility.QC_REMOVE_IGNORE_FILES_FOR_REPORT]: (payload: any) => payload,

  [QualityCheckGetFiles.QC_GET_FILES_REQUEST]: (payload: any) => payload,
  [QualityCheckGetFiles.QC_GET_FILES_SUCCESS]: (payload: any) => payload,
  [QualityCheckGetFiles.QC_GET_FILES_FAILURE]: (payload: any) => payload,
  [QualityCheckGetFiles.QC_GET_FILES_CLEAR]: () => ({}),

  [QualityCheckApplyFiles.QC_APPLY_FILES_REQUEST]: (payload: any) => payload,
  [QualityCheckApplyFiles.QC_APPLY_FILES_SUCCESS]: (payload: any) => payload,
  [QualityCheckApplyFiles.QC_APPLY_FILES_FAILURE]: (payload: any) => payload,
  [QualityCheckApplyFiles.QC_APPLY_FILES_CLEAR]: () => ({}),

  [QualityCheckFileExtractions.QC_FILE_EXTRACTIONS_REQUEST]: (payload: any) => payload,
  [QualityCheckFileExtractions.QC_FILE_EXTRACTIONS_SUCCESS]: (payload: any) => payload,
  [QualityCheckFileExtractions.QC_FILE_EXTRACTIONS_FAILURE]: (payload: any) => payload,
  [QualityCheckFileExtractions.QC_FILE_EXTRACTIONS_CLEAR]: () => ({}),

  [QualityCheckSaveChangesAndApply.QC_SAVE_CHANGES_AND_APPLY_REQUEST]: (payload: any) => payload,
  [QualityCheckSaveChangesAndApply.QC_SAVE_CHANGES_AND_APPLY_SUCCESS]: (payload: any) => payload,
  [QualityCheckSaveChangesAndApply.QC_SAVE_CHANGES_AND_APPLY_FAILURE]: (payload: any) => payload,
  [QualityCheckSaveChangesAndApply.QC_SAVE_CHANGES_AND_APPLY_CLEAR]: () => ({}),

  [QualityCheckDeleteFile.QC_DELETE_FILE_REQUEST]: (payload: any) => payload,
  [QualityCheckDeleteFile.QC_DELETE_FILE_SUCCESS]: (payload: any) => payload,
  [QualityCheckDeleteFile.QC_DELETE_FILE_FAILURE]: (payload: any) => payload,
  [QualityCheckDeleteFile.QC_DELETE_FILE_CLEAR]: () => ({}),

  [QualityCheckRegisterLinkLocalUpdate.QC_REGISTER_LINK_LOCAL_UPDATE]: (payload: any) => payload,

  [QualityCheckGetRegisterLinkData.QC_GET_REGISTER_LINK_DATA_REQUEST]: (payload: any) => payload,
  [QualityCheckGetRegisterLinkData.QC_GET_REGISTER_LINK_DATA_SUCCESS]: (payload: any) => payload,
  [QualityCheckGetRegisterLinkData.QC_GET_REGISTER_LINK_DATA_FAILURE]: (payload: any) => payload,
  [QualityCheckGetRegisterLinkData.QC_GET_REGISTER_LINK_DATA_CLEAR]: () => ({}),

  [QualityCheckValidateLink.QC_VALIDATE_LINK_REQUEST]: (payload: any) => payload,
  [QualityCheckValidateLink.QC_VALIDATE_LINK_SUCCESS]: (payload: any) => payload,
  [QualityCheckValidateLink.QC_VALIDATE_LINK_FAILURE]: (payload: any) => payload,
  [QualityCheckValidateLink.QC_VALIDATE_LINK_CLEAR]: () => ({}),

  [QualityCheckLinkRecurringCycle.QC_LINK_RECURRING_CYCLE_REQUEST]: (payload: any) => payload,
  [QualityCheckLinkRecurringCycle.QC_LINK_RECURRING_CYCLE_SUCCESS]: (payload: any) => payload,
  [QualityCheckLinkRecurringCycle.QC_LINK_RECURRING_CYCLE_FAILURE]: (payload: any) => payload,
  [QualityCheckLinkRecurringCycle.QC_LINK_RECURRING_CYCLE_CLEAR]: () => ({}),

  [QualityCheckSyncOnDemand.QC_SYNC_ON_DEMAND_REQUEST]: (payload: any) => payload,
  [QualityCheckSyncOnDemand.QC_SYNC_ON_DEMAND_SUCCESS]: (payload: any) => payload,
  [QualityCheckSyncOnDemand.QC_SYNC_ON_DEMAND_FAILURE]: (payload: any) => payload,
  [QualityCheckSyncOnDemand.QC_SYNC_ON_DEMAND_CLEAR]: () => ({}),

  [QualityCheckLocalUpdatesOnFiles.QC_LOCAL_UPDATE_FILE]: (payload: any) => payload,

  [QualityCheckGenerateReport.QC_GENERATE_REPORT_REQUEST]: (payload: any) => payload,
  [QualityCheckGenerateReport.QC_GENERATE_REPORT_SUCCESS]: (payload: any) => payload,
  [QualityCheckGenerateReport.QC_GENERATE_REPORT_FAILURE]: (payload: any) => payload,
  [QualityCheckGenerateReport.QC_GENERATE_REPORT_CLEAR]: () => ({}),

  // Metadata
  [QualityCheckMetadataList.QC_METADATA_LIST_REQUEST]: (payload: any) => payload,
  [QualityCheckMetadataList.QC_METADATA_LIST_SUCCESS]: (payload: any) => payload,
  [QualityCheckMetadataList.QC_METADATA_LIST_FAILURE]: (payload: any) => payload,
  [QualityCheckMetadataList.QC_METADATA_LIST_CLEAR]: () => ({}),

  [QualityCheckMetadataLocal.QC_METADATA_SET_FILTERS]: (payload: any) => payload,
  [QualityCheckMetadataLocal.QC_METADATA_SET_SEARCH_TERM]: (payload: any) => payload,
  [QualityCheckMetadataLocal.QC_METADATA_SET_PAGE_NUMBER]: (payload: any) => payload,
  [QualityCheckMetadataLocal.QC_METADATA_EDIT_DRAWER_VISIBLE]: (payload: any) => payload,
  [QualityCheckMetadataLocal.QC_METADATA_UPDATE]: (payload: any) => payload,
  [QualityCheckMetadataLocal.QC_METADATA_STEP_ERRORS]: (payload: any) => payload,

  [QualityCheckGetMetadata.QC_GET_METADATA_REQUEST]: (payload: any) => payload,
  [QualityCheckGetMetadata.QC_GET_METADATA_SUCCESS]: (payload: any) => payload,
  [QualityCheckGetMetadata.QC_GET_METADATA_FAILURE]: (payload: any) => payload,
  [QualityCheckGetMetadata.QC_GET_METADATA_CLEAR]: () => ({}),

  [QualityCheckCreateMetadata.QC_CREATE_METADATA_REQUEST]: (payload: any) => payload,
  [QualityCheckCreateMetadata.QC_CREATE_METADATA_SUCCESS]: (payload: any) => payload,
  [QualityCheckCreateMetadata.QC_CREATE_METADATA_FAILURE]: (payload: any) => payload,
  [QualityCheckCreateMetadata.QC_CREATE_METADATA_CLEAR]: () => ({}),

  [QualityCheckUpdateMetadata.QC_UPDATE_METADATA_REQUEST]: (payload: any) => payload,
  [QualityCheckUpdateMetadata.QC_UPDATE_METADATA_SUCCESS]: (payload: any) => payload,
  [QualityCheckUpdateMetadata.QC_UPDATE_METADATA_FAILURE]: (payload: any) => payload,
  [QualityCheckUpdateMetadata.QC_UPDATE_METADATA_CLEAR]: () => ({}),

  [QualityCheckDeleteMetadata.QC_DELETE_METADATA_REQUEST]: (payload: any) => payload,
  [QualityCheckDeleteMetadata.QC_DELETE_METADATA_SUCCESS]: (payload: any) => payload,
  [QualityCheckDeleteMetadata.QC_DELETE_METADATA_FAILURE]: (payload: any) => payload,
  [QualityCheckDeleteMetadata.QC_DELETE_METADATA_CLEAR]: () => ({}),

  // Name Details

  [QualityCheckGetNameDetails.QC_GET_NAME_DETAILS_REQUEST]: (payload: any) => payload,
  [QualityCheckGetNameDetails.QC_GET_NAME_DETAILS_SUCCESS]: (payload: any) => payload,
  [QualityCheckGetNameDetails.QC_GET_NAME_DETAILS_FAILURE]: (payload: any) => payload,
  [QualityCheckGetNameDetails.QC_GET_NAME_DETAILS_CLEAR]: () => ({}),

  [QualityCheckCreateNameDetails.QC_CREATE_NAME_DETAILS_REQUEST]: (payload: any) => payload,
  [QualityCheckCreateNameDetails.QC_CREATE_NAME_DETAILS_SUCCESS]: (payload: any) => payload,
  [QualityCheckCreateNameDetails.QC_CREATE_NAME_DETAILS_FAILURE]: (payload: any) => payload,
  [QualityCheckCreateNameDetails.QC_CREATE_NAME_DETAILS_CLEAR]: () => ({}),

  [QualityCheckUpdateNameDetails.QC_UPDATE_NAME_DETAILS_REQUEST]: (payload: any) => payload,
  [QualityCheckUpdateNameDetails.QC_UPDATE_NAME_DETAILS_SUCCESS]: (payload: any) => payload,
  [QualityCheckUpdateNameDetails.QC_UPDATE_NAME_DETAILS_FAILURE]: (payload: any) => payload,
  [QualityCheckUpdateNameDetails.QC_UPDATE_NAME_DETAILS_CLEAR]: () => ({}),

  [QualityCheckDeleteNameDetails.QC_DELETE_NAME_DETAILS_REQUEST]: (payload: any) => payload,
  [QualityCheckDeleteNameDetails.QC_DELETE_NAME_DETAILS_SUCCESS]: (payload: any) => payload,
  [QualityCheckDeleteNameDetails.QC_DELETE_NAME_DETAILS_FAILURE]: (payload: any) => payload,
  [QualityCheckDeleteNameDetails.QC_DELETE_NAME_DETAILS_CLEAR]: () => ({}),

  [QualityCheckGetImportDataOptions.QC_GET_IMPORT_DATA_OPTIONS_REQUEST]: (payload: any) => payload,
  [QualityCheckGetImportDataOptions.QC_GET_IMPORT_DATA_OPTIONS_SUCCESS]: (payload: any) => payload,
  [QualityCheckGetImportDataOptions.QC_GET_IMPORT_DATA_OPTIONS_FAILURE]: (payload: any) => payload,
  [QualityCheckGetImportDataOptions.QC_GET_IMPORT_DATA_OPTIONS_CLEAR]: () => ({}),

  [QualityCheckImportData.QC_IMPORT_DATA_REQUEST]: (payload: any) => payload,
  [QualityCheckImportData.QC_IMPORT_DATA_SUCCESS]: (payload: any) => payload,
  [QualityCheckImportData.QC_IMPORT_DATA_FAILURE]: (payload: any) => payload,
  [QualityCheckImportData.QC_IMPORT_DATA_CLEAR]: () => ({}),

  [QualityCheckAddDetailsLocal.QC_ADD_DETAILS]: (payload: any) => payload
})
