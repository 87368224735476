import { CloseCircleFilled, CheckCircleFilled, ExclamationCircleFilled, ScanOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import useTranslate from 'src/utils/useTranslate'
import { QualityCheckStatus } from '..'
import { antdTheme } from 'src/constants'

const Status = ({ qc_details, status }: any) => {
  const [t] = useTranslate()

  if(status === QualityCheckStatus.NOT_SCANNED){
    return (
      <Tooltip title={t('qc.notScanned')}>
        <ScanOutlined style={{ color: '#FF4D4F',  fontSize:antdTheme.fontSizeHeading4 }} />
      </Tooltip>
    )
  }

  if(status === QualityCheckStatus.COMPLETED){
  if(qc_details?.total === 0 && qc_details?.failed === 0 && qc_details?.passed === 0){
    return (
      <Tooltip title={t('qc.unsupportedFile')}>
        <CloseCircleFilled style={{ color: '#FF4D4F',  fontSize:antdTheme.fontSizeHeading4 }} />
      </Tooltip>
    )
  }
  if(qc_details?.alert && qc_details?.alert > 0){
    return (
      <Tooltip title={t('qc.alert')}>
        <ExclamationCircleFilled style={{ color: '#FAAD14',  fontSize:antdTheme.fontSizeHeading4 }} />
      </Tooltip>
    )
  }
  if(qc_details?.total - qc_details?.passed > 0){
    return (
      <CloseCircleFilled style={{ color: '#FF4D4F',  fontSize:antdTheme.fontSizeHeading4 }} />
    )
  }
  if(qc_details?.total - qc_details?.passed === 0){
    return (
      <CheckCircleFilled style={{ color: '#52C41A', fontSize:antdTheme.fontSizeHeading4 }} />
    )
  }
}
  return null
}

export default Status
