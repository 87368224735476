import { sendGet, sendGetWholeUrl, sendPost, sendPostWholeUrl } from "src/utils/sendApi";



export const applyForQC = process.env.REACT_APP_ENV === "production" 
  ? sendPostWholeUrl("https://qc.polymerize.io/v1/data/qc") 
  : sendPost("v1/data/qc")

export const getQCFiles = sendPost("v1/data/qc/get_files")

export const getQCFilesFilters = sendGet("v1/data/qc/get_filter_options")

export const getFileExtractions = sendPost("v1/data/qc/open_file")

export const renameBatch = sendPost("v1/data/qc/rename_batch")

export const deleteFile = sendPost("v1/data/qc/delete")

export const mergeBatches = sendPost("v1/data/qc/merge")

export const saveChangesAndApply = sendPost("v1/data/qc/save")

export const getRegisterLinkData = sendGet("v1/data/qc/get_link_details")

export const validateLink = sendPost("v1/data/qc/validate_link")

export const linkRecurringCycle = sendPost("v1/data/qc/register_link")

export const syncOnDemand = process.env.REACT_APP_ENV === "production" 
? sendGetWholeUrl("https://qc.polymerize.io/v1/data/qc/sync_now")
: sendGet("v1/data/qc/sync_now")

export const getFirebaseProgressIds = sendGet("v1/data/qc/progress")

export const generateReport = sendPost("v1/data/qc/generate_report")

export const getMetadataList = sendPost("v1/data/qc/get_all_profiles")

export const getMetadata = sendPost("v1/data/qc/get_profile")

export const createMetadata = sendPost("v1/data/qc/create_profile")

export const updateMetadata = sendPost("v1/data/qc/edit_profile")

export const deleteMetadata = sendPost("v1/data/qc/delete_profile")

export const getNameDetails = sendPost("v1/data/qc/get_details")

export const createNameDetails = sendPost("v1/data/qc/add_details")

export const updateNameDetails = sendPost("v1/data/qc/edit_details")

export const deleteNameDetails = sendPost("v1/data/qc/delete_details")

export const getMetadataListShort = sendPost("v1/data/qc/get_all_profiles_short")

export const importData = sendPost("v1/data/qc/import_data")