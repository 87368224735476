import { Row, Space } from "antd";
import Text from "antd/lib/typography/Text";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetWorkOrder } from "src/store/actions/workOrderDetails";
import { StoreState } from "src/store/configureStore";
import { StyledCard } from "src/styled_components/StyledCard";
import { WoCard } from "./WoCard";
import useTranslate from "src/utils/useTranslate";
import { usePermission } from "src/utils/usePermissions";

export const AllClosedWorkOrders = ({onDelete}:any) => {
  const dispatch = useDispatch();
  const [t] = useTranslate();
  const { push } = useHistory();

  const { data: workOrders } = useSelector(
    (state: StoreState) => state.workOrders
)
  const userAccess = usePermission();

  function showDetails(this: any) {
    dispatch(resetWorkOrder());
    push(`/work-orders/details/${this.work_order_id}/`);
  }

  function handleDelete(this: any) {
    onDelete(this.work_order_id);
  }

  return (
    <StyledCard
      style={{ marginTop: 20 }}
      bodyStyle={{ padding: "8px 16px" }}
      headStyle={{ padding: "8px 16px" }}
    >
      <Space direction="vertical" style={{ width: "100%" }} size="large">
          {!!workOrders.filter((res: any) => res?.status === "closed")
            ?.length ? (
            <Row>
              {workOrders
                .filter((res: any) => res?.status === "closed")
                .map((order: any) => (
                  <WoCard
                    key={order.work_order_name}
                    order={order}
                    showDetails={showDetails}
                    handleDelete={handleDelete}
                    userAccess={userAccess}
                  />
                ))}
            </Row>
          ) : (
            <Text strong>{t("closeWO.noCloseWO")}</Text>
          )}
      </Space>
    </StyledCard>
  );
};
