import { useEffect, useState } from "react";
import EditorJs from "react-editor-js";
import Undo from "editorjs-undo";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Table from "editorjs-table";
import Paragraph from "@editorjs/paragraph";
import Marker from "@editorjs/marker";
import ImageTool from "@editorjs/image";
import { Input, Modal } from "antd";
import { ReportElement } from "../Design";
import useTranslate from "src/utils/useTranslate";

interface Editorjs {
  save: any;
  render: any;
  isReady: any;
  clear: any;
}

interface SimpleEditorProps {
  element: ReportElement;
  visible: boolean;
  onOk: Function;
  onCancel: Function;
  dataEditStatus: any[]
}

export const ReportSimpleEditor = ({
  element,
  visible,
  onOk,
  onCancel,
  dataEditStatus
}: SimpleEditorProps) => {
  const [t] = useTranslate();

  const [editorInstance, setEditorInstance] = useState<Editorjs>();
  const [headingState, setHeadingState] = useState<string>()
  const [editStatus, setEditStatus] = dataEditStatus

  useEffect(() => {
    setHeadingState(element.label)
  }, [element.label])

  useEffect(() => {
    if (editorInstance) {
      editorInstance.isReady
        .then(() => {
          if (element.editor_content?.length !== 0) {
            editorInstance.render({ blocks: element.editor_content });
          }
        })
        .catch((err: any) => null);
    }
  }, [editorInstance, element.editor_content]);

  const handleOk = () => {
    if (editorInstance) {
      editorInstance.save().then((res: any) => {
        onOk({ label: headingState, editor_content: res.blocks });
      });
    }
  };

  // Cleanup when the component unmounts
  //   return () => {
  //     if (editorInstance) {
  //       editorInstance.destroy();
  //     }
  //   };
  // }, [editorInstance]);

  const changeHandler = () => {
    if (!editStatus) setEditStatus(true)
  };

  return (
    <Modal
      title={element.label}
      maskClosable={false}
      okText={t("common.ok")}
      cancelText={t("common.cancel")}
      open={visible}
      onCancel={() => onCancel()}
      onOk={() => handleOk()}
    >
      <div
        style={{
          marginTop: "10px",
          maxHeight: "320px",
          overflowY: "auto",
        }}
      >

        <Input value={headingState} style={{ marginBottom: "0.5rem" }} onChange={(event) => {
          if (!editStatus) setEditStatus(true)
          setHeadingState(event.target.value)
        }} />

        {element?.editor_content && <div style={{
          padding: "0.5rem",
          border: "1px solid #d9d9d9"
        }}><EditorJs
            placeholder={t('common.edit')}
            instanceRef={(instance: any) => setEditorInstance(instance)}
            onReady={() => {
              new Undo({ editor: editorInstance });
            }}
            onChange={changeHandler}
            tools={{
              table: { class: Table, inlineToolbar: true },
              paragraph: { class: Paragraph, inlineToolbar: true },
              Marker: {
                class: Marker,
                shortcut: "CMD+SHIFT+M",
                inlineToolbar: true,
              },
              header: {
                class: Header,
                shortcut: "CMD+SHIFT+H",
                inlineToolbar: true,
              },
              list: { class: List, inlineToolbar: true },
              image: {
                class: ImageTool,
                config: {
                  uploader: {},
                },
              },
            }}
          /></div>}
      </div>
    </Modal>
  );
};
