import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { Col, Row, Space, Spin, Table } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { AsyncStates } from "src/constants";
import { StyledButton } from "src/styled_components/StyledButton";
import { StyledCard } from "src/styled_components/StyledCard";
import useTranslate from "src/utils/useTranslate";
import { SuggestedExpPlot } from "./SuggestedExpPlot";
import { ReciepeDistributionFilterForm } from "./ReciepeDistributionFilterForm";
import { SummaryWrapper } from "../DataSummary/tabs/summaries";
import { LinkedTrialsAiEngine } from "../common/LinkedTrialsAiEngine";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { linkedFormulationsAiEngineRequest } from "src/store/actions/common";


export const OPS = ['=', '<=', '>=', 'exists', 'range']

export const RecipeDistribution = ({ status, tableColumnsVis, filteredDataVis, generateWO, selectedRecipeIds, setselectedRecipeIds, toolbarEnabled = true, experiments, expStatus, recipeDistribution: recipeDistributionFrom, currentTab, selectedStages, metaInputIngredients }: any) => {
  const dispatch = useDispatch()

  const { linkedFormulationData } = useSelector((state: StoreState) => state.common)
  const [t] = useTranslate()
  const [filterValues, setFilterValues] = useState([{ param: [], op: '', val: null }])
  const [visibilityArray, setVisibilityArray] = useState(filterValues.map(() => true))

  useEffect(() => {
    setVisibilityArray((arr: any) => [...arr, true])
  }, [filterValues.length])

  const chartRef = useRef(null)

  const [plotIdList, setplotIdList] = useState<string[]>(['recipe_dist_1'])
  const addPlot = useCallback(() => {
    setplotIdList(ids => {
      const lastId: string | undefined = ids.at(-1)
      const lastIdCount = lastId?.split('_').at(-1)
      const newId = 'recipe_dist_' + (Number(lastIdCount ?? 0) + 1)
      return [...ids, newId]
    })
  },
    []
  )
  const removePlot = useCallback(
    (id) => {
      setplotIdList(ids => ids.filter(_id => _id !== id))
    },
    [],
  )


  return (
    <SummaryWrapper
      key={'recipes-distribution'}
      heading={t("aiEngine.RecipesDistribution")}
      tooltip={t("aiEngine.RecipesDistribution")}
      expandIconPosition="end"
    >
      <Spin
        spinning={status}
        indicator={<LoadingOutlined />}
        tip={t('projects.gettingRecipesDistributionReady')}
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <ReciepeDistributionFilterForm
            reciepeExperiments={recipeDistributionFrom === "data-summary" ? !!selectedStages.length ? experiments.filter((experiment: any) => selectedStages.includes(experiment.stage)) : experiments : experiments}
            visibilityArray={visibilityArray}
            setVisibilityArray={setVisibilityArray}
            filterValues={filterValues}
            setFilterValues={setFilterValues}
            recipeDistributionFrom={recipeDistributionFrom}
            metaInputIngredients={metaInputIngredients}
          />
          {plotIdList.map((id) => (
            <StyledCard
              extra={<CloseOutlined onClick={() => removePlot(id)} />}
              key={id}
            >
              <SuggestedExpPlot
                ref={chartRef}
                visibilityArray={visibilityArray}
                filterValues={filterValues}
                setselectedRecipes={setselectedRecipeIds}
                selectedRecipes={selectedRecipeIds}
                experiments={recipeDistributionFrom === "data-summary" ? !!selectedStages.length ? experiments.filter((experiment: any) => selectedStages.includes(experiment.stage)) : experiments : experiments}
                recipeDistributionFrom={recipeDistributionFrom}
                metaInputIngredients={metaInputIngredients}
              />
            </StyledCard>
          ))}
          <StyledButton onClick={addPlot} style={{ paddingBottom: '16px' }}>{t('common.addPlot')}</StyledButton>
          {!!selectedRecipeIds.length && (
            <Table
              id="suggested-exp"
              dataSource={filteredDataVis}
              columns={tableColumnsVis}
              bordered
              scroll={{ x: 400, y: 800 }}
              className="suggested-exp-result-table"
              title={() =>
                toolbarEnabled ? (
                  <Row justify="end" gutter={8}>
                    <Col>
                      <StyledButton
                        type="primary"
                        disabled={!selectedRecipeIds?.length}
                        onClick={() => setselectedRecipeIds([])}
                      >
                        {t('common.removeSelection')}
                      </StyledButton>
                    </Col>
                    <Col>
                      <StyledButton
                        type="primary"
                        disabled={expStatus === AsyncStates.LOADING}
                        onClick={() => generateWO("recipe-suggested")}
                      >
                        {t("aiEngine.generateWorkOrder")}
                      </StyledButton>
                    </Col>
                  </Row>
                ) : null
              }
              pagination={false}
              loading={{
                spinning: expStatus === AsyncStates.LOADING,
                indicator: <LoadingOutlined />,
              }}
              expandable={{
                expandedRowRender: (record) => {
                  return <LinkedTrialsAiEngine record={record} experiments={recipeDistributionFrom !== "data-summary" ? experiments?.filter(({ experiment_id }: any) => selectedRecipeIds.includes(experiment_id)) : experiments}
                    linkedFormulationDetailsData={linkedFormulationData} from={recipeDistributionFrom} selectedStages={selectedStages} />
                },
                rowExpandable: (record) => {
                  return !!record?.linked_trial
                },
                onExpand: (expanded, record) => {
                  if (expanded && !!record?.linked_trial && !linkedFormulationData?.[record?.linked_trial]) {
                    const payload = {
                      formulation_id: [record?.linked_trial], is_nested: true
                    }
                    dispatch(linkedFormulationsAiEngineRequest(payload))
                  }
                },
              }}
            />
          )}
        </Space>
      </Spin>
    </SummaryWrapper>
  );
}