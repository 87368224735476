import { useEffect, useMemo, useState } from "react"
import {
    Space,
    Row,
    Modal,
    Table,
    Typography,
    Popconfirm,
    Select,
    notification,
    Tooltip,
    message,
    Dropdown,
    Divider,
} from "antd";
import { CalculatorOutlined, DownOutlined, EditOutlined, InfoCircleFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "../../store/configureStore"
import { geekblue } from "@ant-design/colors"
import { EditableCell, EditableRow } from "./EditableCell"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import "./editableTable.css"
import { setIsEditing } from "src/store/actions/isEditing"
import useTranslate from "src/utils/useTranslate"
import { StyledButton } from "src/styled_components/StyledButton"
import { useValue } from "src/utils/useValue"
import { TaguchiSelectedLevelType } from "./NewExperimentAlgo"
import styles from "./DoE.module.scss"
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon"
const { Text } = Typography
const { Option } = Select

interface AddFactorsProps {
	selectedAlgorithm: any
	ingredientsFactors: any
	setIngredientsFactors: React.Dispatch<React.SetStateAction<any>>
	factorsCheck: boolean
	setFactorModalCheck: React.Dispatch<React.SetStateAction<string>>
	setAddFactorsModalVisible: React.Dispatch<React.SetStateAction<any>>
	processingFactors: any[]
	setProcessingFactors: React.Dispatch<React.SetStateAction<any[]>>
	setNewFactorsModalVisible: React.Dispatch<React.SetStateAction<boolean>>
	taguchiSelectedLevel: TaguchiSelectedLevelType,
	setTaguchiSelectedLevel: React.Dispatch<React.SetStateAction<TaguchiSelectedLevelType>>
}
interface TableRow {
	center: number | string
	factor: string
	high: number | string
	key: number | string
	level: number | string
	low: number | string
	factorType: string
	unit: string
}

export function AddFactors({
	selectedAlgorithm,
	ingredientsFactors,
	setIngredientsFactors,
	factorsCheck,
	setAddFactorsModalVisible,
	setFactorModalCheck,
	processingFactors,
	setProcessingFactors,
	setNewFactorsModalVisible,
	taguchiSelectedLevel,
	setTaguchiSelectedLevel
}: AddFactorsProps) {
	const { listAlgorithmsData } = useSelector((state: StoreState) => state.doe)
	const [selectedRecords, setSelectedRecords] = useState<any>([])
	const [
		assignLevelsModalVisible,
		setAssignLevelsModalVisible,
	] = useState<boolean>(false)
	const [assignLevelsData, setAssignLevelsData] = useState<any>([])
	const [selectLevelsAll, setSelectLevelsAll] = useState<any>()
	const [selectedRowsType, setSelectedRowsType] = useState<any>("")

	const dispatch = useDispatch()
	const [t] = useTranslate()
	const { getValue } = useValue()

	useEffect(() => window.scrollTo(0, 0), [])

	const factorsTableColumns = [
		{
			dataIndex: "factor",
			title: t("common.factor"),
			key: "factor",
			width: 350,
		},
		{
			dataIndex: "unit",
			title: () => (
				<Space>
					<Text>{t("aiEngine.unitOfMeasurement")}</Text>
					<Tooltip title={t("constantFactors.editUnitsfromWO")}>
						<InfoCircleFilled />
					</Tooltip>
				</Space>
			),
			key: "unit",
			align: "center",
			width: 150,
			render(text: any, record: any) {
				return {
					props: {
						style: { background: "#f0f0f0" },
					},
					children: <div>{text}</div>,
				}
			},
		},
		{
			dataIndex: "low",
			title:
				selectedAlgorithm.name === "_lhs" ? t("common.min") : t("common.low"),
			key: "low",
			editable: true,
			align: "center",
			width: 150,
			render: (text: any, record: any) => getValue(text)
		},
		{
			dataIndex: "center",
			title: t("common.center"),
			key: "center",
			editable: true,
			align: "center",
			width: 150,
			render(text: any, record: any) {
				return {
					props: {
						style: record.level === 2 ? { background: "#f0f0f0" } : {},
					},
					children: <div>{getValue(text)}</div>,
				}
			},
		},
		{
			dataIndex: "high",
			title:
				selectedAlgorithm.name === "_lhs" ? t("common.max") : t("common.high"),
			key: "high",
			editable: true,
			align: "center",
			width: 150,
			render: (text: any, record: any) => getValue(text)
		},
	]

	const mergedColumns = factorsTableColumns.map((col: any) => {
		if (!col.editable) {
			return col
		}
		return {
			...col,
			onCell: (record: any) => ({
				record,
				inputType: col.dataIndex === "high" || col.dataIndex === "center" || col.dataIndex === "low" ? "number" : "text",
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				handleSave: (row: any) => {
					if (row.level === 2) {
						if (row.high !== null && row.low !== null && row.low >= row.high) {
							if (selectedAlgorithm.name === "_lhs")
								message.error(
									t("addFactor.errorMessage.maxGreaterThanMinValue")
								)
							else
								message.error(
									t("addFactor.errorMessage.highGreaterThanLowValue")
								)
							return
						}
					} else if (row.level === 3) {
						if (col.dataIndex === "low") {
							if (
								(row.low !== null &&
									row.center !== null &&
									row.center <= row.low) ||
								(row.low !== null && row.high !== null && row.high <= row.low)
							) {
								message.error(
									t("addFactor.errorMessage.lowSmallerThanCenterAndHighValue")
								)
								return
							}
						} else if (col.dataIndex === "center") {
							if (
								row.low !== null &&
								row.center !== null &&
								row.center <= row.low
							) {
								message.error(
									t("addFactor.errorMessage.centerGreaterThanHighValue")
								)
								return
							} else if (
								row.center !== null &&
								row.high !== null &&
								row.high <= row.center
							) {
								message.error(
									t("addFactor.errorMessage.centerSmallerThanHighValue")
								)
								return
							} else if (!row.low && !row.high) {
								message.error(
									t("addFactor.errorMessage.fillLowAndHighValueFirst")
								)
								return
							}
						} else if (col.dataIndex === "high") {
							if (row.low !== null && row.high !== null && row.high <= row.low) {
								message.error(
									t("addFactor.errorMessage.highGreaterThanLowValue")
								)
								return
							} else if (
								row.center !== null &&
								row.high !== null &&
								row.high <= row.center
							) {
								message.error(
									t("addFactor.errorMessage.highGreaterThanCenterValue")
								)
								return
							}
						}
					}
					if (row.factorType === "ingredients") {
						setIngredientsFactors((prevState: TableRow[]) => {
							const newArray = [...prevState]
							newArray.forEach((res: TableRow) => {
								if (res.key === row.key) {
									res.high = row.high
									res.low = row.low
									if (res.level === 3) res.center = row.center
								}
								return res
							})
							return newArray
						})
					} else {
						setProcessingFactors((prevState: TableRow[]) => {
							const newArray = [...prevState]
							newArray.forEach((res: TableRow) => {
								if (res.key === row.key) {
									res.high = row.high
									res.low = row.low
									if (res.level === 3) res.center = row.center
								}
								return res
							})
							return newArray
						})
					}
					dispatch(setIsEditing(true))
				},
			}),
		}
	})

	const deleteModal = () => {
		Modal.confirm({
			title: t("common.confirmDelete"),
			width: 500,
			icon: "",
			content: (
				<Text type="secondary">
					{t("aiEngine.youAreDeleting")}{" "}
					<Text strong>
						{selectedRecords?.length} {t("common.records")}{" "}
					</Text>
					{t("common.cannotUndoOperation")}
				</Text>
			),
			okText: t("common.confirm"),
			cancelText: t("common.cancel"),
			maskClosable: false,
			onOk: () => {
				if (selectedRowsType === "ingredients") {
					setIngredientsFactors(
						ingredientsFactors.filter(
							(data: any) =>
								!selectedRecords.map((res: any) => res.key).includes(data.key)
						)
					)
				} else if (selectedRowsType === "processing") {
					setProcessingFactors(
						processingFactors.filter(
							(data: any) =>
								!selectedRecords.map((res: any) => res.key).includes(data.key)
						)
					)
				}
				notification.success({
					message: `${t("addFactor.successMessage.successfullyDeleted")} ${selectedRecords?.length
						} ${t("addFactor.records")}`,
					icon: <StyledDeleteIcon style={{ color: geekblue[4] }} />,
				})
				setSelectedRecords([])
			},
		})
	}

	const defaultCenterValues = () => {
		if (selectedRowsType === "ingredients") {
			setIngredientsFactors((prevState: any) => {
				const array = [...prevState]
				array.forEach((res: any) => {
					selectedRecords.forEach((record: any) => {
						if (record.key === res.key) {
							if (res.level === 3 && res.high !== null && res.low !== null) {
								res.center = (Number(res?.high ?? 0) + Number(res?.low || 0)) / 2
							}
						}
					})
				})
				return array
			})
		} else if (selectedRowsType === "processing") {
			setProcessingFactors((prevState: any) => {
				const array = [...prevState]
				array.forEach((res: any) => {
					selectedRecords.forEach((record: any) => {
						if (record.key === res.key) {
							if (res.level === 3 && res.high !== null && res.low !== null) {
								res.center = (Number(res?.high ?? 0) + Number(res?.low || 0)) / 2
							}
						}
					})
				})
				return array
			})
		}
		setSelectedRecords([])
		notification.success({
			message: t("addFactor.successMessage.defaultCenterValuesAdded"),
			icon: <CalculatorOutlined style={{ color: geekblue[4] }} />,
		})
	}

	const assignLevelsTableColumns = [
		{
			dataIndex: "factor",
			title: t("common.factorName"),
			key: "factor",
		},
		{
			dataIndex: "levels",
			title: t("addFactor.levels"),
			key: "levels",
			render: (text: any, record: any, index: any) => {
				return (
					<Select
						value={record.level}
						style={{ width: 100 }}
						onChange={(e: any) => {
							setAssignLevelsData((prevState: any) => {
								const array = [...prevState]
								array.forEach((res: any) => {
									if (res.key === record.key) {
										res.level = e
									}
								})
								return array
							})
							setSelectLevelsAll("")
						}}
					>
						<Option key="option 1" value={2}>
							{t("doe.2levels")}
						</Option>
						<Option key="option 2" value={3}>
							{t("addFactor.3Levels")}
						</Option>
					</Select>
				)
			},
		},
	]

	const moveRow = (dragIndex: any, hoverIndex: any, type: string) => {
		if (type === "ingredientFactors") {
			setIngredientsFactors((prevState: any) => {
				const newArray = [...prevState]
				const [removed] = newArray.splice(dragIndex, 1)
				newArray.splice(hoverIndex, 0, removed)
				return newArray
			})
		} else if (type === "processingFactors") {
			setProcessingFactors((prevState: any) => {
				const newArray = [...prevState]
				const [removed] = newArray.splice(dragIndex, 1)
				newArray.splice(hoverIndex, 0, removed)
				return newArray
			})
		}
	}

	const isUseDefaultCenterValuesBtnDisabled = useMemo(() => {
		const isLength = selectedRecords.length > 0
		const isLowAndHigh = selectedRecords.every(
			(res: any) => res.low !== null && res.high !== null
		)
		const isFactorLevel2 = selectedRecords.every((res: any) => res.level === 2)

		if (isLength && isLowAndHigh && !isFactorLevel2) return false
		return true
	}, [selectedRecords])

	const formatRange = (min: number, max: number) => {
		if (min === max) return max
		return `${min} - ${max}`
	}

	return (
		<Space
			size="large"
			direction="vertical"
			style={{ width: "100%", marginTop: 20 }}
		>
			<Row justify="space-between" style={{ padding: 16, backgroundColor: "#FAFAFA" }}>
				<Space>
					<Space direction={factorsCheck ? "horizontal" : "vertical"}>
						<Space>
							<Text>{t("addFactors.selectedAlgorithms")}</Text>
							<Text strong>
								{Object.entries(listAlgorithmsData || {})
									.find(
										([key, value]: any) => selectedAlgorithm.name === value.name
									)?.[0]
									?.split("_")
									.map((res: any) => res.charAt(0).toUpperCase() + res.slice(1))
									.join(" ")}
							</Text>
						</Space>
						{!factorsCheck && (
							<Text type="secondary" strong>
								{`${t("common.minimum")} ${selectedAlgorithm.name === "_bbdesign" ? 3 : 2
									} ${t("addFactor.factorsRequired")} `}
								<Text style={{ color: "red" }}>*</Text>{" "}
							</Text>
						)}
					</Space>
					{selectedAlgorithm?.name === "_taguchi" && (
						<>
							<Select
								showSearch
								value={taguchiSelectedLevel.selectedLevel}
								style={{ width: 100 }}
								popupMatchSelectWidth={150}
								onChange={(e: any) => {
									setTaguchiSelectedLevel({
										selectedLevel: e,
										isMixedLevelModalOpen: e === "mixed_level" ? true : false,
										seletedLevelData: null
									})
									setIngredientsFactors((prevState: any) => {
										const array = [...prevState]
										array.forEach((res: any) => {
											if (res.level === 3 && e === 2) {
												res.center = null
											}
										})
										return array
									})
									setProcessingFactors((prevState: any) => {
										const array = [...prevState]
										array.forEach((res: any) => {
											if (res.level === 3 && e === 2) {
												res.center = null
											}
										})
										return array
									})
								}}
							>
								<Option key="option 1" value={2}>
									{t("doe.2levels")}
								</Option>
								<Option key="option 2" value={3}>
									{t("addFactor.3Levels")}
								</Option>
								<Option key="mixed_level" value={"mixed_level"}>
									{t("doe.mixedLevel")}
								</Option>
							</Select>
							{
								(taguchiSelectedLevel.selectedLevel === "mixed_level" && Object.keys(taguchiSelectedLevel.seletedLevelData || {}).length > 0) && (
									<div style={{ display: "flex", gap: "0.5rem", background: "#F0F0F0", borderRadius: "6px", padding: "0.5rem" }}>
										<div style={{ display: "flex", flexDirection: "column" }}>
											<Text className={styles.taguchi__header__text}>{t('expResult.ExpDesign')}</Text>
											<Text
												ellipsis={{
													tooltip: taguchiSelectedLevel?.seletedLevelData?.design_name,
												}}
												className={styles.taguchi__data__text}
											>{taguchiSelectedLevel?.seletedLevelData?.design_name}</Text>
										</div>

										<Divider type="vertical" style={{
											height: "43px",
											margin: 0
										}} />

										<div style={{ display: "flex", flexDirection: "column" }}>
											<Text className={styles.taguchi__header__text}>{t("doe.2levels")}</Text>
											<Text
												ellipsis={{
													tooltip: formatRange(taguchiSelectedLevel?.seletedLevelData.allowed_2_level_factors?.min ?? "", taguchiSelectedLevel?.seletedLevelData?.allowed_2_level_factors?.max ?? ""),
												}}
												className={styles.taguchi__data__text}
											>{formatRange(taguchiSelectedLevel?.seletedLevelData.allowed_2_level_factors?.min ?? "", taguchiSelectedLevel?.seletedLevelData?.allowed_2_level_factors?.max ?? "")}</Text>
										</div>

										<Divider type="vertical" style={{
											height: "43px",
											margin: 0
										}} />

										<div style={{ display: "flex", flexDirection: "column" }}>
											<Text className={styles.taguchi__header__text}>{t('addFactor.3Levels')}</Text>
											<Text
												className={styles.taguchi__data__text}
												ellipsis={{
													tooltip: formatRange(taguchiSelectedLevel?.seletedLevelData.allowed_3_level_factors?.min ?? "", taguchiSelectedLevel?.seletedLevelData?.allowed_3_level_factors?.max ?? ""),
												}}
											>
												{formatRange(taguchiSelectedLevel?.seletedLevelData.allowed_3_level_factors?.min ?? "", taguchiSelectedLevel?.seletedLevelData?.allowed_3_level_factors?.max ?? "")}
											</Text>
										</div>
									</div>
								)
							}
						</>
					)}
				</Space>

				<Space>
					<Dropdown
						placement="bottomRight"
						menu={{
							items: [
								{
									label: t("constantFactors.addNewFactors"),
									key: "1",
									onClick: () => setNewFactorsModalVisible(true),
									style: { outline: "none" },
								},
								{
									label: t("doe.factorFromInventory"),
									key: "0",
									onClick: () => {
										setFactorModalCheck("variableFactors")
										setAddFactorsModalVisible(true)
									},
									style: { outline: "none" },
								},
							],
						}}
					>
						<StyledButton type="primary">{t("common.Add New")} {" "} <DownOutlined /></StyledButton>
					</Dropdown>
					{selectedAlgorithm?.parameters?.levels === null && (
						<StyledButton
							disabled={!selectedRecords.length}
							onClick={() => {
								setAssignLevelsData(
									selectedRecords.reduce(
										(arr: any, res: any) => [
											...arr,
											{ factor: res.factor, key: res.key, level: res.level },
										],
										[]
									)
								)
								setAssignLevelsModalVisible(true)
							}}
						>
							{"Configure factor levels"}
						</StyledButton>
					)}
					{selectedAlgorithm.parameters?.levels !== 2 && (
						<Popconfirm
							okText={t("common.ok")}
							cancelText={t("common.cancel")}
							disabled={isUseDefaultCenterValuesBtnDisabled}
							title={`${t("addFactor.useDefaultCenter")} ?`}
							onConfirm={defaultCenterValues}
						>
							<Tooltip
								placement="bottom"
								title={
									isUseDefaultCenterValuesBtnDisabled
										? t("addFactor.selectAtleastOneFactors")
										: t("addFactor.calculateDefaultCenterValues")
								}
							>
								<StyledButton disabled={isUseDefaultCenterValuesBtnDisabled}>
									{t("aiEngine.defaultCenterValues")}
								</StyledButton>
							</Tooltip>
						</Popconfirm>
					)}
					<StyledButton
						icon={<StyledDeleteIcon />}
						onClick={deleteModal}
						disabled={!selectedRecords.length}
						danger
					>
						{t("common.delete")}
					</StyledButton>
				</Space>
			</Row>

			<DndProvider backend={HTML5Backend}>
				<Table
					bordered
					locale={{ emptyText: t("addFactor.emptyText.addIngredientFactors") }}
					style={{ paddingLeft: 8, paddingRight: 8 }}
					title={() => <Text strong>{t("common.ingredientsFactors")}</Text>}
					components={{
						body: {
							row: EditableRow,
							cell: EditableCell,
						},
					}}
					pagination={false}
					rowClassName={() => "editable-row"}
					rowSelection={{
						type: "checkbox",
						selectedRowKeys: selectedRecords.map((res: any) => res.key),
						onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
							setSelectedRowsType("ingredients")
							setSelectedRecords(selectedRows)
						},
					}}
					onRow={(record, index) =>
					({
						index,
						moveRow: (dragIndex: any, hoverIndex: any) =>
							moveRow(dragIndex, hoverIndex, "ingredientFactors"),
					} as any)
					}
					columns={
						((selectedAlgorithm.name === "_taguchi" && taguchiSelectedLevel.selectedLevel === 2) || selectedAlgorithm?.parameters?.levels === 2)
							? mergedColumns.filter((res: any) => res.dataIndex !== "center")
							: mergedColumns
					}
					dataSource={ingredientsFactors}
				/>
			</DndProvider>

			<DndProvider backend={HTML5Backend}>
				<Table
					bordered
					style={{ paddingLeft: 8, paddingRight: 8 }}
					locale={{ emptyText: t("addFactor.emptyText.addProcessingFactors") }}
					title={() => <Text strong>{t("common.processingFactors")}</Text>}
					components={{
						body: {
							row: EditableRow,
							cell: EditableCell,
						},
					}}
					pagination={false}
					rowClassName={() => "editable-row"}
					rowSelection={{
						type: "checkbox",
						selectedRowKeys: selectedRecords.map((res: any) => res.key),
						onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
							setSelectedRowsType("processing")
							setSelectedRecords(selectedRows)
						},
					}}
					onRow={(record, index) =>
					({
						index,
						moveRow: (dragIndex: any, hoverIndex: any) =>
							moveRow(dragIndex, hoverIndex, "processingFactors"),
					} as any)
					}
					columns={
						((selectedAlgorithm.name === "_taguchi" && taguchiSelectedLevel.selectedLevel === 2) || selectedAlgorithm?.parameters?.levels === 2)
							? mergedColumns.filter((res: any) => res.dataIndex !== "center")
							: mergedColumns
					}
					dataSource={processingFactors}
				/>
			</DndProvider>

			<Modal
				cancelText={t("common.cancel")}
				width={600}
				title={t("addFactor.reassignFactorLevels")}
				visible={assignLevelsModalVisible}
				maskClosable={false}
				onCancel={() => {
					setAssignLevelsData([])
					setAssignLevelsModalVisible(false)
				}}
				okText={t("common.confirm")}
				onOk={() => {
					if (selectedRowsType === "ingredients") {
						setIngredientsFactors((prevState: any) => {
							const array = [...prevState]
							array.forEach((factor: any) => {
								assignLevelsData.forEach((element: any) => {
									if (factor.key === element.key) {
										factor.level = element.level
									}
								})
								if (factor.level === 2) {
									factor.center = null
								}
							})
							return array
						})
					} else if (selectedRowsType === "processing") {
						setProcessingFactors((prevState: any) => {
							const array = [...prevState]
							array.forEach((factor: any) => {
								assignLevelsData.forEach((element: any) => {
									if (factor.key === element.key) {
										factor.level = element.level
									}
								})
								if (factor.level === 2) {
									factor.center = null
								}
							})
							return array
						})
					}
					notification.success({
						message: `${t("addFactor.successMessage.re-ssignedLevels")} ${assignLevelsData?.length
							} factors`,
						icon: <EditOutlined style={{ color: geekblue[4] }} />,
					})
					setAssignLevelsData([])
					setAssignLevelsModalVisible(false)
					setSelectedRecords([])
				}}
			>
				<Space size="large" direction="vertical" style={{ width: "100%" }}>
					<Text type="secondary">
						{t("addFactors.reassignLevelsToYourFactorsNote")}
					</Text>
					<Space>
						<Text strong>{t("aiEngine.applyLevelforAllFactors")}</Text>
						<Select
							value={selectLevelsAll}
							allowClear
							style={{ width: 100 }}
							onSelect={(e: any) => {
								if (e) {
									setAssignLevelsData((prevState: any) => {
										const array = [...prevState]
										array.forEach((res: any) => {
											res.level = e
										})
										return array
									})
									setSelectLevelsAll(e)
								}
							}}
						>
							<Option key="option 1" value={2}>
								{t("doe.2levels")}
							</Option>
							<Option key="option 2" value={3}>
								{t("addFactor.3Levels")}
							</Option>
						</Select>
					</Space>
					<Table
						columns={assignLevelsTableColumns}
						dataSource={assignLevelsData}
						pagination={{ pageSize: 6 }}
					/>
				</Space>
			</Modal>
		</Space>
	)
}
