import { useCallback, useEffect, useMemo, useState } from "react";
import { Avatar, List, Tooltip, Popconfirm, Skeleton, Space } from "antd";
import Text from "antd/lib/typography/Text";
import dayjs from "dayjs";
import { CheckCircleFilled, CheckCircleOutlined } from "@ant-design/icons";
import useTranslate from "src/utils/useTranslate";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { Comment } from "@ant-design/compatible";
import relativeTime from "dayjs/plugin/relativeTime";
import { FB_COLLECTION_NAME_COMMENTS, firestoreDb } from "src/utils/firebase";
import {
  collection,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { woCommentListRequest } from "src/store/actions/workOrderDetails";
import { useWOCommentsSocket } from "src/utils/hooks/useWOCommentsSocket";
import { antdTheme } from "src/constants";
import StyledDeleteIcon from "src/styled_components/StyledDeleteIcon";
import { Editable, Slate, withReact } from "slate-react";
import { createEditor } from "slate";
import { Element, Leaf, withMentions } from "../CommentEditor/CommentEditor";
dayjs.extend(relativeTime);

export const CommentList = (props: any) => {
  const [t] = useTranslate();
  const key = useSelector(
    (state: StoreState) => state.login.loginResponse.user_id
  );
  const name = useSelector(
    (state: StoreState) => state.login.loginResponse.user_name
  );
  const work_order_id = useSelector(
    (state: StoreState) => state.workOrderDetails.workOrder.work_order_id
  );
  const mentions = useSelector((state: StoreState) => state.teams.data);

  const [commentFeed, setCommentFeed] = useState<any[]>([]);

  const possibleResolvers: { user_id: string, user_name: string }[] = useMemo(
    () => [
      ...new Set(
        (mentions || [])?.map((mention) => ({
          user_name: mention.user_name,
          user_id: mention.user_id,
        })),
      ),
      { user_id: key, user_name: name },
    ],
    [key, name, mentions],
  )

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(woCommentListRequest({ work_order_id }))
  }, [dispatch, work_order_id])

  const woCommentList = useSelector((state: StoreState) => state.workOrderDetails.woCommentList)

  useEffect(() => {
    setCommentFeed(woCommentList)
  }, [woCommentList])

  const deleteComment = async (comment_id: string) => {
    socket?.emit("delete_comment", { comment_id })
  };

  const resolveComment = async (comment_id: string) => {
    const col = collection(firestoreDb, FB_COLLECTION_NAME_COMMENTS);

    const commentQuery = query(col, where("comment_id", "==", comment_id));
    const querySnapshot = await getDocs(commentQuery);
    querySnapshot.forEach((docSnap) => {
      updateDoc(docSnap.ref, {
        status: "resolved",
        resolvedBy: key,
      });
    });
    socket?.emit("edit_comment", {
      status: "resolved",
      resolvedBy: key,
      comment_id
    })
  }

  const socket = useWOCommentsSocket('comments', work_order_id)

  useEffect(() => {
    const onNewComment = (comment: any) => {
      setCommentFeed(state => [comment, ...state])
    }
    socket?.on("new_comment", onNewComment)

    const onEditComment = (comment: any) => {
      setCommentFeed(state => state.map(_comment =>
        (_comment.comment_id === comment.comment_id) ? { ..._comment, ...comment } : _comment
      ))
    }
    socket?.on("update_comment", onEditComment)

    const onDeleteComment = (comment: any) => {
      setCommentFeed(state => state.filter(_comment => _comment.comment_id !== comment.comment_id))
    }
    socket?.on("remove_comment", onDeleteComment)

    return () => {
      socket?.off("new_comment", onNewComment)
      socket?.off("update_comment", onEditComment)
      socket?.off("remove_comment", onDeleteComment)
      socket?.disconnect()
    }
  }, [socket])


  return (
    <>
      {Boolean(commentFeed?.length) && (
        <List
          dataSource={commentFeed}
          header={
            <Text strong>
              {commentFeed.length} {commentFeed?.length > 1
                ? t("common.comments")
                : t("common.comment")}
            </Text>
          }
          className="comment-list"
          itemLayout="horizontal"
          renderItem={(comment: any) => (
            <Comment
              key={comment.comment_id}
              author={<Text>{comment.user_name}</Text>}
              content={<CommentRenderer comment={comment.comment} />}
              avatar={
                <Avatar src={comment.image_url} alt={comment.user_name} />
              }
              style={{
                padding: "0 10px",
                borderRadius: 5,
                ...(comment.status === 'resolved' && { backgroundColor: '#f6ffed', border: '1px solid #52c41a' })
              }}
              actions={[
                comment.user_id === key && (
                  <Tooltip key="comment-delete" title={t("common.delete")}>
                    <Popconfirm
                      okText={t("common.ok")}
                      cancelText={t("common.cancel")}
                      title={`${t("common.commentDeleteConfirmation")}?`}
                      onConfirm={() => {
                        deleteComment(comment.comment_id);
                      }}
                    >
                      <StyledDeleteIcon />
                    </Popconfirm>
                  </Tooltip>
                ),
                (comment?.user_id === key || (comment?.tags || []).includes(key)) && comment?.status === 'active' && (
                  <Tooltip key="comment-resolve" title={t("common.resolve")}>
                    <CheckCircleOutlined className="comment-icon" onClick={() => {
                      resolveComment(comment.comment_id);
                    }} />
                  </Tooltip>
                ),
                (comment?.status === 'resolved' && possibleResolvers?.find(resolver => resolver.user_id === comment?.resolvedBy)) && (
                  <Space style={{
                    gap: 4
                  }}>
                    <CheckCircleFilled style={{ color: '#52c41a', fontSize: antdTheme.fontSize }} />
                    <Text>{t("common.resolvedBy")} {
                      possibleResolvers?.find(resolver => resolver.user_id === comment?.resolvedBy)?.user_name
                    }
                    </Text>
                  </Space>
                )
                // target === comment.comment_id && (
                //   <Spin indicator={<LoadingOutlined />} />
                // ),
              ]}
              datetime={dayjs(comment.created).fromNow()}
            />
          )}
        />
      )}
      {!Boolean(commentFeed?.length) &&
        [...Array(3)].map((item, index) => (
          <Skeleton key={index} loading={false} active avatar />
        ))}
    </>
  );
};

const getJSON = (comment: string) => {
  try {
    return JSON.parse(comment)
  }
  catch {
    return [
      {
        type: 'paragraph',
        children: [{ text: comment }],
      },
    ]
  }
}

const CommentRenderer = ({ comment }: { comment: string }) => {
  const editor = useMemo(
    () => withMentions(withReact(createEditor())),
    []
  )

  const renderElement = useCallback(props => <Element {...props} />, [])
  const renderLeaf = useCallback(props => <Leaf {...props} />, [])

  return (
    <Slate editor={editor} initialValue={getJSON(comment)}>
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        readOnly />
    </Slate>
  )
}
