import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Checkbox, Divider, Form, message, Modal, Select, Spin, Typography } from "antd"
import useTranslate from "src/utils/useTranslate"
import { useDispatch, useSelector } from "react-redux"
import {
  analyticsRecipeDistributionClear,
  analyticsRecipeDistributionTableClear,
  boxPlotSummaryClear,
  dataSummaryClear,
  dataSummaryFiltersClear,
  dataSummaryRequest,
  dataSummaryStreamRequest,
  getZeonCharacterizationDsmRequest,
} from "src/store/actions/dataSummary"
import { TabKeys } from "../DataSummary"
import { catDataSummaryClear, catDataSummaryRequest } from "src/store/actions/catDataSummary"
import { CharacterizationVariation, WorkOrder } from "src/typings"
import { StyledButton } from "src/styled_components/StyledButton"
import { CharacsList } from "../../common"
import { StoreState } from "src/store/configureStore"
import { AsyncStates } from "src/constants"
import { ZeonCharacterizationVariationsTable } from "./ZeonCharacterizationVariationsTable"
import { LoadingOutlined } from "@ant-design/icons"
import { listProcessingProfilesRequest } from "src/store/actions/workOrderDetails"
import { pivotTableClear } from "src/store/actions/pivotTable"
import { useQuery } from "src/utils/useQuery"
import { useRequiredFieldStar } from "src/components/Common/useRequiredFieldStar"
const { Text } = Typography

type P = {
  workOrderIds: string[]
  expIds: string[]
  setWorkOrderIds: Dispatch<SetStateAction<string[]>>
  setTab: Dispatch<SetStateAction<TabKeys>>
  workOrders: WorkOrder[]
  isErrorShowed: React.MutableRefObject<boolean>
  setCharacterizationMethodId: Dispatch<SetStateAction<string>>
  characterizationMethodId: string,
  setSelectedVariation: any,
  selectedVariation: any,
  normalise: boolean,
  setStageIdentifier: Dispatch<SetStateAction<string[]>>
  selectedStages: string[],
  setSelectedExperiments: Dispatch<SetStateAction<string[]>>,
  setNormalise: Dispatch<SetStateAction<boolean>>,
  selectedFormulations: string[],
  setSelectedFormulations: Dispatch<SetStateAction<string[]>>
}

export const SelectWorkOrderForm = ({
  workOrderIds,
  expIds,
  setTab,
  setWorkOrderIds,
  workOrders,
  isErrorShowed,
  setCharacterizationMethodId,
  characterizationMethodId,
  setSelectedVariation,
  selectedVariation,
  normalise,
  selectedStages,
  setStageIdentifier,
  setSelectedExperiments,
  setNormalise,
  selectedFormulations,
  setSelectedFormulations
}: P) => {
  const [t] = useTranslate()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  let query = useQuery();
  const mounted = useRef(false);
  const requiredFieldStar = useRequiredFieldStar()


  const [isWorkOrdersUpdated, setIsWorkOrdersUpdated] = useState(false)
  const { numericalSummaryStatus, characterizationMethodsZeonDsm, characterizationMethodsZeonDsmStatus, selectedFormulationsDataFromCompare: { formulationOptions = [] } = {} } = useSelector((state: StoreState) => state.dataSummary)
  const [characsList, setCharacsList] = useState<CharacsList[]>([])
  const [variationsList, setVariationsList] = useState<CharacterizationVariation[]>([])
  const projectId = useSelector((state: StoreState) => state.projects.current)
  const WorkOrderStatus = useSelector((state: StoreState) => state.workOrders.status)
  const configs = useSelector((state: StoreState) => state.configs.features)
  const { status: categoricalSummaryStatus } = useSelector((state: StoreState) => state.catDataSummary)
  const selectedWorkOrderStages = useMemo(() => workOrders.reduce((acc: any[], currWo: any) => {
    if (workOrderIds.includes(currWo.work_order_id)) {
      acc.push(...currWo.stages.map((stage: any) => ({ ...stage, actual_stage_name: stage.actual_stage_name + " - " + currWo.work_order_name, work_order_id: currWo.work_order_id })))
    }
    return acc
  }, []) ?? [], [workOrders, workOrderIds])

  const isFromCompareFormulation = Boolean(query?.get("source") === "compare-formulation")

  useEffect(() => {
    if (characterizationMethodsZeonDsm && characterizationMethodsZeonDsm.type === "methods") {
      setCharacsList(characterizationMethodsZeonDsm.data)
    }
    if (characterizationMethodsZeonDsm && characterizationMethodsZeonDsm.type === "variations") {
      setVariationsList(characterizationMethodsZeonDsm.data.variations)
    }
  }, [characterizationMethodsZeonDsm])

  useEffect(() => {
    if (workOrderIds.length > 0) setIsWorkOrdersUpdated(true)
    else setIsWorkOrdersUpdated(false)
  }, [workOrderIds])


  function onSubmit(values: any) {
    if (Boolean(configs?.nomralised_data_summary)) {
      setNormalise(false)
    }

    if (isFromCompareFormulation && !selectedFormulations.length) {
      return message.error(t("formulations.selectOneTrial"))
    }

    if (!!selectedFormulations.length && selectedFormulations.length < 5) {
      return message.error(t("formulations.selectFiveTrial"))
    }

    // if (!isFromCompareFormulation && Boolean(configs?.kurita_configs) && !selectedStages.length) {
    //   return message.error("Please Select at least one stage.")
    // }

    const characterizationsEnabled = isCharacterizationVariationEnabled
    if (!isFromCompareFormulation && workOrderIds.length === 0)
      return message.error("Please select a Work Order")

    if (!isFromCompareFormulation && characterizationsEnabled && Object.keys(selectedVariation || {}).length === 0) {
      return message.error("Please select one variation")
    }
    const selectedWorkOrders = workOrders.filter((wo) => workOrderIds.includes(wo.work_order_id))
    const experiment_ids = selectedWorkOrders?.reduce((acc: any, workOrder: any) => {
      workOrder.stages.forEach((stage: any, stageIndex: number) => {
        if (selectedStages.includes(stage.identifier)) {
          acc.push(workOrder?.experiment_id?.[stageIndex])
        }
      })
      return acc
    }, [])

    setSelectedExperiments((!!selectedStages.length ? experiment_ids : [...new Set(selectedWorkOrders.flatMap((workOrder) => workOrder.experiment_id))]) ?? [])

    const payload = {
      ...(characterizationsEnabled) && {
        characterization_set_id: selectedVariation?.characterization_set_id,
      },
      ...(Boolean(configs?.nomralised_data_summary)) && {
        normalise: false
      },
      ...(isFromCompareFormulation ? { formulation_ids: selectedFormulations } : {
        ...(!!selectedFormulations.length ? { formulation_ids: selectedFormulations } : { experiment_ids: (!!selectedStages.length ? experiment_ids : [...new Set(selectedWorkOrders.flatMap((workOrder) => workOrder.experiment_id))]) ?? [] }),
      }),
      ...(Boolean(configs?.nestle_configs)) && {
        with_processing_profile: true
      },
    }

    if (characterizationsEnabled) {
      setCharacterizationMethodId(values.characterization)
    }
    isErrorShowed.current = false
    setIsWorkOrdersUpdated(false)
    dispatch(dataSummaryClear())
    // dispatch(boxPlotSummaryClear())
    setTab("overview")
    if (Boolean(configs?.nestle_configs)) {
      dispatch(listProcessingProfilesRequest())
    }
    dispatch(dataSummaryRequest(payload))
    dispatch(catDataSummaryRequest(payload))

    dispatch(dataSummaryFiltersClear());
    dispatch(dataSummaryStreamRequest({
      formulation_ids: [],
      type: "options",
      input_type: "all",
      newSocket: true,
      ...payload,
    }));
  }

  useEffect(() => {
    if (isFromCompareFormulation && !mounted.current && selectedFormulations && Array.isArray(selectedFormulations) && selectedFormulations.length > 0) {
      mounted.current = true;
      dispatch(
        dataSummaryStreamRequest({
          formulation_ids: selectedFormulations,
          type: "options",
          input_type: "all",
          newSocket: true,
        })
      );
    }
  }, [dispatch, isFromCompareFormulation, selectedFormulations]);

  useEffect(() => {
    if (workOrderIds.length === 0 && !isFromCompareFormulation) {
      setCharacterizationMethodId("")
      dispatch(boxPlotSummaryClear())
      dispatch(dataSummaryClear())
      dispatch(catDataSummaryClear())
      setVariationsList([])
      setSelectedVariation({})
      form.resetFields()
      setNormalise(false)
    }
  }, [workOrderIds, dispatch, form, setSelectedVariation, setCharacterizationMethodId, setNormalise, isFromCompareFormulation])

  useEffect(() => {
    if (!isFromCompareFormulation) {
      dispatch(dataSummaryClear())
      dispatch(catDataSummaryClear())
      dispatch(boxPlotSummaryClear())
      setVariationsList([])
      setSelectedVariation({})
    }
  }, [dispatch, setCharacterizationMethodId, setSelectedVariation, characterizationMethodId, isFromCompareFormulation])

  const isCharacterizationVariationEnabled = useMemo(() => {
    return Boolean(configs?.characterization_methods) && !Boolean(configs.nestle_configs)
  }, [configs?.characterization_methods, configs.nestle_configs])

  const handleAnalyticsStateClear = () => {
    dispatch(dataSummaryClear())
    dispatch(catDataSummaryClear())
    dispatch(boxPlotSummaryClear())
    dispatch(analyticsRecipeDistributionClear())
    dispatch(analyticsRecipeDistributionTableClear())
    dispatch(pivotTableClear())
  }

  const getStageName = useCallback((workOrder, stageId) => {
    let stageName = "";
    workOrder.stages.forEach((currWo: any, index: number) => {
      if (currWo.identifier === stageId) {
        stageName = currWo.name ? currWo.name : `Stage ${index + 1}`;
      }
    });
    return stageName;
  }, []);

  const formulationsOptions = useMemo(() => {
    return isFromCompareFormulation ? formulationOptions : workOrders
      .filter((wo) => workOrderIds.includes(wo.work_order_id))
      .reduce((acc: any[], workOrder: any) => {
        const filteredFormulation = workOrder.formulation_id.filter((currFormulation: any) => selectedStages.includes(currFormulation.stage))
        if (filteredFormulation.length) {
          acc.push({
            label: workOrder.work_order_name,
            options: filteredFormulation.map((formulation: any, index: number) => {
              const stageName = getStageName(workOrder, formulation.stage)
              return {
                work_order_name: workOrder.work_order_name?.trim(),
                trial_name: `${formulation.display_id} - ${stageName}`,
                label: (
                  <span>
                    {formulation.display_id} - {" "}
                    <span style={{ color: "gray" }}>
                      {stageName}
                    </span>
                  </span>
                ),
                value: formulation.formulation_id,
              }
            }),
          })
        }
        return acc
      }, [])
  }, [isFromCompareFormulation, formulationOptions, workOrders, workOrderIds, selectedStages, getStageName]);

  return (
    <div className="dsm__form__wrapper">
      <Form
        form={form}
        onFinish={onSubmit}
        layout={"vertical"}
        style={{
          display: "grid",
          gap: "0.5rem",
          gridTemplateColumns: `repeat(auto-fit, minmax(${isCharacterizationVariationEnabled ? '600' : '100'}px,0.75fr))`,
          alignItems: 'start'
        }}
        requiredMark={false}
      >
        {query?.get("source") !== "compare-formulation" && <>
          <Form.Item
            label={t("common.selectWorkOrders")}
          >
            <Select
              style={{ width: "100%" }}
              value={workOrderIds}
              placeholder={t("common.selectWorkOrders")}
              optionFilterProp="children"
              maxTagTextLength={15}
              maxTagCount={5}
              mode="multiple"
              showSearch
              notFoundContent={<Text>{t("datasummary.noClosedWorkOrders")}</Text>}
              onChange={(e: string[]) => {
                if (!!e.length || (!e.length && ((numericalSummaryStatus === AsyncStates.INITIAL) && (categoricalSummaryStatus === AsyncStates.INITIAL)))) {
                  setWorkOrderIds(e);
                } else {
                  Modal.confirm({
                    okText: t("common.ok"),
                    cancelText: t("common.cancel"),
                    title: `${t("graphs.clearPlots")}`,
                    onOk: () => {
                      setWorkOrderIds(e);
                      setStageIdentifier([])
                      setSelectedFormulations([])
                      handleAnalyticsStateClear()
                    },
                  })
                }
              }}
              allowClear
              onDeselect={(e) => {
                const removedStagesIds = selectedWorkOrderStages.filter((stage) => stage.work_order_id === e).map((stage) => stage.identifier)
                const filteredStages = selectedStages.filter((stage) => !removedStagesIds.includes(stage))
                setStageIdentifier(filteredStages)

                if(!filteredStages.length) {
                  setSelectedFormulations([])
                }

                filteredStages.forEach((stageId) => {
                  const availableFormulationIdsSet = workOrders
                    .filter((wo) => workOrderIds.includes(wo.work_order_id))
                    .flatMap((workOrder) =>
                      workOrder.formulation_id.filter((res: any) => res.stage === stageId)
                    )
                    .reduce((acc, curr: any) => {
                      if (!acc.has(curr.formulation_id)) {
                        acc.add(curr.formulation_id);
                      }
                      return acc;
                    }, new Set());

                  const availableFormulationIds = Array.from(availableFormulationIdsSet);

                  setSelectedFormulations((prev) =>
                    prev.filter((formulation) => availableFormulationIds.includes(formulation))
                  );
                })
              }}
              onClear={() => {
                setStageIdentifier([])
                setSelectedFormulations([])
              }}
              dropdownRender={(menu) => {
                return (
                  <Spin
                    spinning={WorkOrderStatus === AsyncStates.LOADING}
                    indicator={<LoadingOutlined />}
                  >
                    <div style={WorkOrderStatus === AsyncStates.LOADING ? { height: 100, opacity: 0 } : {}}>
                      {workOrders.length > 0 && (<>
                        <Checkbox
                          style={{ padding: 10 }}
                          checked={workOrderIds?.length === workOrders?.length}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setWorkOrderIds(workOrders.map((workOrder) => workOrder.work_order_id));
                            } else {
                              Modal.confirm({
                                okText: t("common.ok"),
                                cancelText: t("common.cancel"),
                                title: `Clear Plot(s)?`,
                                onOk: () => {
                                  setWorkOrderIds([]);
                                  setStageIdentifier([]);
                                  setSelectedFormulations([])
                                  handleAnalyticsStateClear()
                                },
                              })
                            }
                          }}
                        >
                          {`${t("common.selectAll")} ${t("common.workOrders")}`}
                        </Checkbox>
                        <Divider style={{ margin: "0.25rem" }} />
                      </>
                      )}
                      {menu}
                    </div>
                  </Spin>
                );
              }}
            >
              {(WorkOrderStatus !== AsyncStates.LOADING) && workOrders.map((wo) => {
                return (
                  <Select.Option value={wo.work_order_id} key={wo.work_order_id}>
                    {wo.work_order_name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item label={t('common.selectStageName')}>
            <Select
              style={{ width: "100%" }}
              placeholder={t('common.selectStageName')}
              allowClear
              value={selectedStages}
              onClear={() => {
                setSelectedFormulations([])
              }}
              showSearch
              mode={"multiple"}
              onDeselect={(stageId) => {
                const availableFormulationIdsSet = workOrders
                  .filter((wo) => workOrderIds.includes(wo.work_order_id))
                  .flatMap((workOrder) =>
                    workOrder.formulation_id.filter((res: any) => res.stage !== stageId)
                  )
                  .reduce((acc, curr: any) => {
                    if (!acc.has(curr.formulation_id)) {
                      acc.add(curr.formulation_id);
                    }
                    return acc;
                  }, new Set());

                const availableFormulationIds = Array.from(availableFormulationIdsSet);

                setSelectedFormulations((prev) =>
                  prev.filter((formulation) => availableFormulationIds.includes(formulation))
                );

              }}
              optionFilterProp="children"
              maxTagTextLength={15}
              onChange={(e: string[]) => {
                // const disable = Boolean(configs?.kurita_configs) ? workOrderIds.some((woId) => {
                //   const stages = selectedWorkOrderStages
                //     .filter((res) => res.work_order_id === woId)
                //     .map((res) => res.identifier);
                //   const matchingStages = e.filter((stage) => stages.includes(stage))
                //   return matchingStages.length > 1 ? true : false
                // }) : false

                // if (!disable) {
                setStageIdentifier(e);
                // } else {
                //   message.error("Please Select One Stage per Work Order")
                // }
              }}
              dropdownRender={(menu) => {
                return (
                  <div>
                    {selectedWorkOrderStages.length > 0 && (
                      <Checkbox
                        style={{ padding: 10 }}
                        checked={
                          selectedWorkOrderStages?.length ===
                          selectedStages?.length
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setStageIdentifier(
                              selectedWorkOrderStages.map(
                                (stage) => stage.identifier
                              )
                            );
                          } else {
                            setStageIdentifier([]);
                            setSelectedFormulations([])
                          }
                        }}
                      >{`${t("common.selectAllStages")}`}</Checkbox>
                    )}
                    {menu}
                  </div>
                );
              }}
              notFoundContent={<Text>{t("common.noStagesAvailable")}</Text>}
            >
              {selectedWorkOrderStages.map((res: any, index: number) => (
                <Select.Option
                  value={res?.identifier}
                  key={res?.identifier}
                >{res?.actual_stage_name || `Stage ${index + 1} ${res?.name ? `: ${res?.name}` : ""}`}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </>
        }
        <Form.Item label={t("common.selectFormulations")}>
          <Select
            style={{ width: "100%" }}
            placeholder={t("common.selectFormulations")}
            allowClear
            value={selectedFormulations}
            showSearch
            mode={"multiple"}
            maxTagTextLength={20}
            maxTagCount={10}
            filterOption={(inputValue, option: any) => {
              const trimmedSearchInput = inputValue?.trim()?.toLowerCase()
              
              // Check trial_name or work_order_name directly on the option
              return (
                option?.trial_name?.trim()?.toLowerCase()?.includes(trimmedSearchInput) ||
                option?.work_order_name?.trim()?.toLowerCase()?.includes(trimmedSearchInput)
              )
            }}
            options={formulationsOptions}
            onChange={(formulation) => {
              setSelectedFormulations(formulation)
            }}
            notFoundContent={<Text>{t("common.noFormulations")}</Text>}
            dropdownRender={(menu) => {
              const allFormulationIds = formulationsOptions.reduce((acc: string[], curr: any) => {
                if (!!curr?.options?.length) {
                  const keys = curr.options.map((option: any) => option.value)
                  acc.push(...keys)
                }
                return [...new Set(acc)]
              }, [])
              return (
                <div>
                  {formulationsOptions.length > 0 && (
                    <Checkbox
                      style={{ padding: 10 }}
                      checked={selectedFormulations?.length === allFormulationIds?.length}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedFormulations(allFormulationIds);
                        } else {
                          setSelectedFormulations([]);
                        }
                      }}
                    >{`${t("common.selectAll")} Formulations`}</Checkbox>
                  )}
                  {menu}
                </div>
              );
            }}
          />
        </Form.Item>

        {isCharacterizationVariationEnabled && (
          <>
            <Form.Item
              name="characterization"
              style={{ width: "100%" }}
              label={t("common.characterization")}
              rules={[
                { required: true, message: "Please select a characterization" },
              ]}
              required
              tooltip={requiredFieldStar}
            >
              <Select
                allowClear
                showSearch
                optionFilterProp="children"
                maxTagTextLength={15}
                style={{ width: "100%" }}
                onChange={(e) => setCharacterizationMethodId(e)}
                popupMatchSelectWidth={false}
                placeholder="Please select a characterization"
              >
                {characsList.map((charac) => {
                  return (
                    <Select.Option
                      key={charac.characterization_id}
                      value={charac.characterization_id}
                    >
                      {charac.characterization_name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item style={{ width: "100%" }} >
              <StyledButton
                loading={
                  characterizationMethodsZeonDsmStatus === AsyncStates.LOADING
                }
                style={{ width: "100%" }}
                onClick={() => {
                  if (characterizationMethodId) {
                    dispatch(
                      getZeonCharacterizationDsmRequest({
                        characterization_method_id: characterizationMethodId,
                        project_id: projectId,
                      })
                    );
                    setSelectedVariation({});
                  } else {
                    message.error("Please select a characterization");
                  }
                }}
              >
                {t("common.getCharacterizationVariations")}
              </StyledButton>
            </Form.Item>

            <Form.Item style={{ width: "100%" }} >
              <ZeonCharacterizationVariationsTable
                loading={
                  characterizationMethodsZeonDsmStatus === AsyncStates.LOADING
                }
                setSelectedVariation={setSelectedVariation}
                data={variationsList}
                selectedVariation={selectedVariation}
              />
            </Form.Item>
          </>
        )}
        <Form.Item style={{ width: "100%" }} label={" "}>
          <StyledButton
            htmlType="submit"
            type="primary"
            loading={(numericalSummaryStatus === AsyncStates.LOADING) || (categoricalSummaryStatus === AsyncStates.LOADING)}
          >
            {t("feedback.form.submit")}
          </StyledButton>
        </Form.Item>
      </Form>

      {isWorkOrdersUpdated && selectedStages?.length >= 1 && (
        <Text type="warning">
          {t("common.pleaseClickSubmitBtnToViewUpdatedData")}
        </Text>
      )}
    </div>
  );
}