import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsHeatmap from "highcharts/modules/heatmap";
import { blue, grey, red } from "@ant-design/colors";
import { useRef } from "react";
import { useValue } from "src/utils/useValue";
import useTranslate from "src/utils/useTranslate";

HighchartsMore(Highcharts);
HighchartsHeatmap(Highcharts);

const getChartOptions = (
  type: string,
  performance: any,
  model: any,
  data: any,
  getEUValue: any,
  t: any
) => {
  if (type === "REGRESSOR")
    return scatterOptions(performance, model, data, getEUValue, t);
  if (type === "CLASSIFIER")
    return heatmapOptions(performance, model, data, getEUValue, t);
};

const heatmapOptions = (
  performance: any,
  model: any,
  data: any,
  getEUValue: any,
  t: any
): Highcharts.Options => {
  const categories = performance[model]["CATEGORY_ORDER"];
  const result = performance[model]["CLASSIFICATION_RESULT"];

  const dataHeatmap: any[] = [];
  for (let row = 0; row < result.length; row++) {
    for (let col = 0; col < result[row].length; col++) {
      dataHeatmap.push([row, col, result[row][col]]);
    }
  }

  return {
    chart: {
      type: "heatmap",
      zoomType: "xy",
      height: 600,
      plotBorderWidth: 1,
      reflow: true
    },
    credits: { enabled: false },
    legend: { enabled: false },
    xAxis: {
      gridLineWidth: 0,
      categories: categories,
      opposite: true,
      title: {
        text: "Predicted",
      },
    },
    tooltip: { enabled: false },
    yAxis: {
      gridLineWidth: 0,
      categories: categories,
      reversed: true,
      title: {
        text: "Actual",
      },
    },
    title: {
      text: `<b>${model}</b>`,
      align: "left",
    },
    colorAxis: {
      min: 0,
      minColor: "#FFFFFF",
      maxColor: "#2caffe",
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 1280,
          },
          chartOptions: {
            chart: {
              height: 300,
            },
          },
        },
      ],
    },
    series: [
      {
        type: "heatmap",
        data: dataHeatmap,
        borderWidth: 1,
        dataLabels: {
          enabled: true,
          formatter: function (this: any) {
            return `<b>${getEUValue(this.point.value)}</b>`;
          },
          color: "#000000",
          style: {
            fontSize: "1rem",
          },
        },
      },
    ],
  };
};

const scatterOptions = (
  performance: any,
  model: any,
  data: any,
  getEUValue: any,
  t: any
): Highcharts.Options => {
  const r2 = performance[model]["R2 Score"];
  const mape = performance[model]["MAPE"];
  const actual = data["actual"];
  const predicted = data["predicted"][model]["central"];
  const upper = data["predicted"][model]["upper_limit"];
  const lower = data["predicted"][model]["lower_limit"];

  return {
    chart: {
      zoomType: "xy",
      margin: [0, 15, 0, 15],
      height: 600,
      reflow: true,
    },
    credits: { enabled: false },
    legend: { enabled: false },
    xAxis: { gridLineWidth: 0, visible: false },
    yAxis: { gridLineWidth: 0, visible: false },
    title: {
      text: `<b>${model}</b>`,
      align: "left",
    },
    subtitle: {
      text: `<b>${t("aiEngine.modelAnalysis.r2Score")}: ${getEUValue(r2)}</b> <br/> <b>MAPE: ${getEUValue(mape)}%</b>`,
      align: "left",
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 1280,
          },
          chartOptions: {
            chart: {
              height: 300,
            },
          },
        },
      ],
    },
    series: [
      {
        type: "errorbar",
        pointWidth: 15,
        data: actual.map((a: any, i: any) => [a, lower[i], upper[i]]),
        color: grey[2],
        enableMouseTracking: true,
        crisp: false,
        tooltip: {
          pointFormatter: function (this: any) {
            return `<b>${getEUValue(this.low)} - ${getEUValue(this.high)}</b>`
          },
        },
      },
      {
        type: "line",
        data: actual.map((a: any) => [a, a]),
        lineWidth: 3,
        color: red[5],
        enableMouseTracking: false,
        dashStyle: "Dot",
        crisp: false,
        marker: {
          enabled: false,
        },
      },
      {
        type: "scatter",
        data: actual.map((a: any, i: any) => [a, predicted[i]]),
        tooltip: {
          pointFormatter: function (this: any) {
            return `<b>${t("aiEngine.modelAnalysis.predictedValue")}: ${getEUValue(this.y)}</b><br><b>${t("aiEngine.modelAnalysis.actualValue")}: ${getEUValue(this.x)}</b><br>`
          },
        },
        color: blue[5],
        marker: {
          symbol: "circle",
          radius: 3,
        },
        crisp: false,
      },
    ],
  };
};

export default function PerformanceScatterWrapper({
  performance,
  data,
  type,
}: any) {

  const { getValue: getEUValue } = useValue()
  const [t] = useTranslate()
  const IndividualChart = ({ model, index }: any) => {

    const chartRef = useRef(null);
    
    return (<div
      key={model + index}
      style={{
        border: "1px solid #ccc",
        width: "100%",
      }}
    >
      <HighchartsReact
        highcharts={Highcharts}
        options={getChartOptions(type, performance, model, data, getEUValue, t)}
        ref={chartRef}
      />
    </div>)
  }

  return (
    <div style={{ display: "flex", gap: 10, width: "100%" }}>
      <div
        style={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "repeat(3, minmax(0,1fr))",
          gap: 10,
        }}
      >
        {Object.keys(performance).map((model: any, index: number) => {
          return (
            <IndividualChart model={model} index={index} />
          );
        })}
      </div>
    </div>
  );
}
