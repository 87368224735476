import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"

type P = {
	options: Highcharts.Options
}

export const BarChart = ({ options }: P) => {

	return (
		<HighchartsReact highcharts={Highcharts} options={options} />
	)
}
