import { Result, Space, Typography } from "antd"
import { POLYMERIZE_EMAIL } from "src/constants"
import { StyledButton } from "src/styled_components/StyledButton"
import useTranslate from "src/utils/useTranslate"
const { Text } = Typography

type P = {
	error: Error
}

export const ErrorBoundaryFallback = ({ error }: P) => {
	const [t] = useTranslate()

	return (
		<Space size="large" direction="vertical" style={{ width: "100%" }}>
			<Result
				status={error.name === "ChunkLoadError" ? "info" : "warning"}
				title={
					error.name === "ChunkLoadError" ? (
						<>
							<Text>{t("common.appVersionUpdated")}</Text>
							<br />
							<Text type="secondary">
								<small>{t("common.pleaseReloadApp")}</small>
							</Text>
						</>
					) : (
						<>
							<Text>{t("error.operationalProblems")}</Text>
							<br />
							<Text type="secondary">
								<small>
									{t("common.dropUsMailAt")}{" "}
									<a href="mailto: contact@polymerize.io">
										{POLYMERIZE_EMAIL}
									</a>{" "}
									{t("common.ifTheProblemPersists")}
								</small>
							</Text>
						</>
					)
				}
				extra={
					<StyledButton
						size="large"
						type="primary"
						key="reload"
						onClick={() => window.location.reload()}
					>
						{t("common.reload")}
					</StyledButton>
				}
			/>
		</Space>
	)
}
