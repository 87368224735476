import { Typography } from 'antd'
import React from 'react'
import EmptyImage from 'src/assets/imgs/EmptyImage'
import { antdTheme } from 'src/constants'
import useTranslate from 'src/utils/useTranslate'

const EmptyComponent = () => {
  const [t] = useTranslate()
  return (
    <div style={{ padding: '0.5rem', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1rem', height: '100%', justifyContent:'center' }}>
      <EmptyImage />
      <Typography.Text style={{ fontSize: antdTheme.fontSizeSM }}>{t('common.noDataAvailable')}</Typography.Text>
    </div>
  )
}

export default EmptyComponent
