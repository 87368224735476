import { InboxOutlined } from '@ant-design/icons'
import { message, Space, Typography, UploadProps } from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import React from 'react'
import useTranslate from 'src/utils/useTranslate'
import XLSX from 'xlsx'
import { PARAMETER_NAME, PARAMETER_TYPE } from './UploadComponent'

const FORWARD_TEMPLATE_URL = 'https://polymerize-misc.s3.ap-southeast-1.amazonaws.com/Forward+Prediction+Upload+template.xlsx'

const validateTemplate = (json: any, totalStages: number, t:any) => {
  let isValid = true
  const sheetNames = Object.keys(json)

  if (sheetNames.length < totalStages) {
    isValid = false
    return { isValid, message: t('aiEngine.forward.upload.sheetNumbersError') }
  }

  sheetNames.forEach((sheetName) => {
    if (json[sheetName].length === 0) {
      isValid = false
    } else {
      const sheetData = json[sheetName]

      sheetData.forEach((row: any) => {
        const keysList = Object.keys(row)

        if (!keysList.includes(PARAMETER_TYPE) || !keysList.includes(PARAMETER_NAME) || row[PARAMETER_TYPE] === '' || row[PARAMETER_NAME] === '') {
          isValid = false
        }
      })
    }
  })

  return { isValid, message: t('aiEngine.forward.upload.templateError') }
}

const UploadStep = ({
  file,
  setFile,
  setFileJson,
  selectedStages
}: {
  file: any
  setFile: any
  setFileJson: any
  selectedStages: [number, number]
}) => {
  const [t] = useTranslate()

  const totalStages = new Array(selectedStages?.[selectedStages?.length - 1] - selectedStages?.[0] + 1).fill(null).length

  const uploadProps: UploadProps = {
    onRemove: (record: any) => {
      setFile(null)
    },
    accept: '.xlsx, .xls,',
    beforeUpload: async (record: any) => {
      const fileSheets = await record.arrayBuffer()
      const workBook = XLSX.read(fileSheets)
      const xlsxToJson = workBook.SheetNames.reduce((initial: any, name: string) => {
        const sheet = workBook.Sheets[name]
        initial[name] = XLSX.utils.sheet_to_json(sheet)
        return initial
      }, {})

      const { isValid, message: errorMessage } = validateTemplate(xlsxToJson, totalStages, t)
      if (isValid) {
        setFileJson(xlsxToJson)
        setFile(record)
      } else {
        message.error(errorMessage)
      }

      return false
    },
    multiple: false,
    fileList: !!file ? [file] : []
  }

  return (
    <Space direction={'vertical'} align='center' >
      <Dragger
        style={{
          width: '500px'
        }}
        name="file"
        {...uploadProps}
        height={150}
      >
        <p
          className="ant-upload-drag-icon"
          style={{
            marginBottom: '0.5rem'
          }}
        >
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{t('common.dragUpload')}</p>
      </Dragger>
      <Typography.Text>
        {t("datamapper.needHelp?")}{" "}
        <a
          target={"_blank"}
          rel="noreferrer"
          href={FORWARD_TEMPLATE_URL}
        >
          {t("datamapper.downloadTemplate?")}{" "}
        </a>
        {t("datamapper.spreadsheet?")}
      </Typography.Text>
    </Space>
  )
}

export default UploadStep
