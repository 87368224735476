import React, { useState } from "react";
import { Modal } from "antd";
import { NameAndCategoryForm } from './UnitConversionNameNCategoryForm'
// import { AlternateValuesForm } from './UnitConversionAlternateValuesForm'
// import { ConversionMetricForm } from './UnitConversionMetricsForm'
import { useDispatch } from "react-redux";
import { createUnitRequest } from "src/store/actions/conversion";
import useTranslate from "src/utils/useTranslate";

const steps = [
    {
        title: 'Add Name and Category',
        content: NameAndCategoryForm,
    },
    // {
    //     title: 'Add Alternate Value',
    //     content: AlternateValuesForm,
    // },
    // {
    //     title: 'Add Conversion Metric',
    //     content: ConversionMetricForm,
    // },
];

export const initialData = {
    name: '',
    category: '',
    alternative_values: [],
    conversion_metric: []
}

export const AddUnitModal = ({ isAddNewUnitModal, closeModal, refetch }) => {
    const [unit, setUnit] = useState(initialData)
    const [currentStep, setCurrentStep] = useState(0)

    const next = () => setCurrentStep(currentStep + 1);
    const prev = () => setCurrentStep(currentStep - 1);
    const CurrentStep = steps[currentStep]?.content
    const [t] = useTranslate()
    const dispatch = useDispatch()

    async function handleAddUnit() {
        const conversion_metric = {}
        unit.conversion_metric.forEach(metric => {
            conversion_metric[metric.key] = {
                [metric.conversion_method]: [
                    { "var": 0 },
                    Number(metric.value)
                ]
            }
        })

        const payload = {
            name: unit.name,
            category: unit.category,
            alternative_values: unit.alternative_values,
            conversion_metric
        }

        dispatch(createUnitRequest(payload))

        setUnit(initialData)
        refetch()
        closeModal()
    }
    return (
        <>
            <Modal
                title={t('units.addNewUnit')}
                footer={null}
                width={900}
                onCancel={closeModal}
                open={isAddNewUnitModal}
            >
                {/* <Steps current={currentStep}>
                {steps.map(step => {
                    return <Steps.Step key={step.title} title={step.title} />
                })}
            </Steps> */}
                <div style={{ marginTop: '32px' }}>
                    <CurrentStep
                        setUnit={setUnit}
                        next={next}
                        prev={prev}
                        finish={handleAddUnit}
                        unit={unit}
                    />
                </div>
            </Modal >
        </>
    )
};