import { Form, message, Modal, Select, Spin, Typography, Upload } from 'antd';
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import useTranslate from 'src/utils/useTranslate'
import { StyledText } from '../FilesTable'
import { AsyncStates } from 'src/constants'
import { StyledButton } from 'src/styled_components/StyledButton'
import { UploadOutlined } from '@ant-design/icons'
import { UploadProps } from 'antd/lib'
import { qcApplyFilesRequest, qcSetFileUploadModalVisible } from 'src/store/actions/qualityCheck'
import { useRequiredFieldStar } from 'src/components/Common/useRequiredFieldStar'
import { useForm } from 'antd/es/form/Form';


const FileUpload = () => {
  const [t] = useTranslate()
  const [form] = useForm<FormData>()

  const [supplier, setSupplier] = React.useState<string | null>()
  const [files, setFiles] = React.useState<any[]>([])
  const { getNameDetailsResponse, getNameDetailsStatus, fileUploadModalVisible, applyFilesStatus } = useSelector(
    (state: StoreState) => state.qualityCheck
  )
  const dispatch = useDispatch()
  const requiredFieldStar = useRequiredFieldStar()

  const props: UploadProps = {
    multiple: false,
    beforeUpload: (file: any) => {
      setFiles((prevState: any) => {
        if (file?.size / (1024 * 1024) <= 5) {
          return [...prevState, file]
        } else {
          message.warning(`File ${file.name} Size Greater Than 5MB`)
          return prevState
        }
      })
      return false;
    },
    style: { width: '100%', height: '100%' },
    showUploadList: true,
    accept: '.pdf'
  }

  const onFinish = useCallback(() => {
    if (files.length > 0) {
      const formData = new FormData()
      files.forEach((file: any) => {
        formData.append('file', file)
      })
      formData.append('supplier', supplier || '')
      dispatch(qcApplyFilesRequest(formData))
    }
  },[dispatch, files, supplier])

  const onCancel = useCallback(() => {
    dispatch(qcSetFileUploadModalVisible(false))
    setFiles([])
    setSupplier(null)
    form.resetFields()
  },[dispatch, form])

  useEffect(() => {
    form.resetFields()
  }, [form, fileUploadModalVisible])

  return (
    <Modal
      open={fileUploadModalVisible}
      title={
        <Typography.Title level={4}>{t('common.uploadAFile')}</Typography.Title>
      }
      footer={null}
      onCancel={onCancel}
      styles={{
        body: {
          paddingTop:16
        }
      }}
      destroyOnClose
    >
      <Spin spinning={getNameDetailsStatus === AsyncStates.LOADING}>
        <Form
          labelCol={{ span: 4 }}
          layout="horizontal"
          labelAlign='left'
          style={{ width: '100%' }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          form={form}
          requiredMark={false}
        >
          <Form.Item label="Select" name='supplier' tooltip={requiredFieldStar} rules={[{ required: true, message: t('common.SelectSupplier') }]}>
            <Select
              placeholder={StyledText(t('common.SelectSupplier'))}
              value={supplier}
              onChange={(value) => {
                setSupplier(value)
              }}
              options={[
                ...(getNameDetailsResponse?.['supplier'] || [])?.map((category: any) => {
                  return {
                    label: StyledText(category.name),
                    value: category.detail_id
                  }
                })
              ]}
              allowClear
              onClear={() => setSupplier(null)}
            />
          </Form.Item>

          <Form.Item label={t('common.upload')} name='file' tooltip={requiredFieldStar} rules={[{ required: true , message: t('dataUpload.selectFile')}]}>
            <Upload.Dragger {...props}>
              <StyledButton
                icon={<UploadOutlined />}
                style={{ outline: 'none' }}
              >
                {t('qualityCheck.uploadButton')}
              </StyledButton>
            </Upload.Dragger>
          </Form.Item>

          <Form.Item label={''}>
            <div style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '100%',
              gap:12
            }} >
              <StyledButton
                onClick={onCancel}
                style={{ outline: 'none' }}
                htmlType='reset'
              >
                {t('common.cancel')}
              </StyledButton>
              <StyledButton type="primary" htmlType='submit' loading={applyFilesStatus===AsyncStates.LOADING}>
                {t('common.upload')}
              </StyledButton>
            </div>
            </Form.Item>
        </Form>
      </Spin>
    </Modal>
  )
}

export default FileUpload
