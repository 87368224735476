import { Input, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import useTranslate from 'src/utils/useTranslate'

type P = {
  open: boolean
  closeModal: () => void
  onRenameFolder: (updatedName: string, assetId: string) => void
}

const RenameFolderModal = ({ open, closeModal, onRenameFolder }: P) => {
  const [t] = useTranslate()
  // Selector
  const { assetLibrarySelectedDirectories, assetLibraryGetContentResponse } =
    useSelector((state: StoreState) => state.assetLibrary)

  // States
  const [newFolderName, setNewFolderName] = useState<string>(''
  )
  
  useEffect(()=>{

    const isFile = assetLibraryGetContentResponse?.files?.find(
      (file: any) => file?.asset_id === assetLibrarySelectedDirectories[0]
    )

    const isFolder = assetLibraryGetContentResponse?.folders?.find(
      (folder: any) => folder?.asset_id === assetLibrarySelectedDirectories[0]
    )

    if(isFile){
      setNewFolderName(isFile?.name)
    }

    if(isFolder){
      setNewFolderName(isFolder?.name)
    }
    
  },[assetLibraryGetContentResponse, assetLibrarySelectedDirectories])

  return (
    <Modal
      title="Rename Folder/File"
      open={open}
      width={400}
      okText="Rename"
      centered
      cancelText="Cancel"
      closable={true}
      onOk={() => {
        onRenameFolder(
          newFolderName,
          assetLibrarySelectedDirectories[0]
        )
        closeModal()
        setNewFolderName('')
      }}
      onCancel={() => {
        closeModal()
        setNewFolderName('')
      }}
      okButtonProps={{
        disabled: newFolderName === '',
        style: {
          outline: 'none'
        }
      }}
      cancelButtonProps={{
        style: {
          outline: 'none'
        }
      }}
    >
      <Input
        style={{
          fontSize: 12
        }}
        value={newFolderName}
        onChange={(e) => {
          setNewFolderName(e.target.value)
        }}
        placeholder={t('common.enterName')}
        onClick={(e) => {
          e.stopPropagation()
        }}
      />
    </Modal>
  )
}

export default RenameFolderModal
