import { useEffect, useState } from "react";
import Sha1 from "js-sha1";
import { Divider, Form, Input, Space, Typography, Layout, Alert, Row } from 'antd';
import { KeyOutlined, LinkOutlined, MailOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import '../SignUp/SignUp.scss'
import useTranslate from "src/utils/useTranslate";
import QRCode from "react-qr-code";
import { signInViaFirebase } from "src/services/firebase/firebase-auth";
import { antdTheme, AsyncStates, currentPlatform, platformInfo } from "src/constants";
import { loginFailure } from "src/store/actions";
import { StoreState } from "src/store/configureStore";
import { loginRequest, setOtp, setVerification, verifyOtpRequest, verifyOtpTrueRequest } from "src/store/actions/login";
import Spinner from "src/components/Spinner/Spinner";
import LeftSideOnboarding from "../SignUp/LeftSideOnboarding";
import AllRightsReserved from "../SignUp/AllRightsReserved";
import { trackEvent } from "src/analytics";
import { SingleSignIn } from "../SingleSignIn/SingleSignIn";
import { MagicLinkLogin } from "../MagicLinkLogin/MagicLinkLogin";
import { loginFirebaseRequest } from "src/store/actions/firebase";
import { useSessionStorage } from "src/utils/hooks/useSessionStorage";
import { useRequiredFieldStar } from "../Common/useRequiredFieldStar";
import { StyledButton } from "src/styled_components/StyledButton";


export function MicrosoftIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={21}
      height={21}
      viewBox="0 0 21 21"
      {...props}
    >
      <title>{"MS-SymbolLockup"}</title>
      <path fill="#f25022" d="M1 1h9v9H1z" />
      <path fill="#00a4ef" d="M1 11h9v9H1z" />
      <path fill="#7fba00" d="M11 1h9v9h-9z" />
      <path fill="#ffb900" d="M11 11h9v9h-9z" />
    </svg>
  )
}

export function GsuiteIcon(props: any) {
  return (
    <svg
      width={118}
      height={120}
      viewBox="0 0 118 120"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"google_buttn"}</title>
      <g fill="none" fillRule="evenodd">
        <path
          d="M117.6 61.364c0-4.255-.382-8.346-1.09-12.273H60V72.3h32.29c-1.39 7.5-5.617 13.855-11.972 18.11v15.054H99.71c11.346-10.446 17.891-25.828 17.891-44.1z"
          fill="#4285F4"
        />
        <path
          d="M60 120c16.2 0 29.782-5.373 39.71-14.536L80.317 90.409c-5.373 3.6-12.245 5.727-20.318 5.727-15.627 0-28.855-10.554-33.573-24.736H6.382v15.545C16.255 106.555 36.545 120 60 120z"
          fill="#34A853"
        />
        <path
          d="M26.427 71.4c-1.2-3.6-1.882-7.445-1.882-11.4s.682-7.8 1.882-11.4V33.055H6.382A59.976 59.976 0 000 60a59.976 59.976 0 006.382 26.945L26.427 71.4z"
          fill="#FBBC05"
        />
        <path
          d="M60 23.864c8.81 0 16.718 3.027 22.936 8.972l17.21-17.209C89.754 5.945 76.172 0 60 0 36.545 0 16.255 13.445 6.382 33.055L26.427 48.6C31.145 34.418 44.373 23.864 60 23.864z"
          fill="#EA4335"
        />
        <path d="M0 0h120v120H0V0z" />
      </g>
    </svg>
  )
}

const { Content: LayoutContent } = Layout
const { Text, Title } = Typography;

const Login = () => {
  const [t] = useTranslate()
  const dispatch = useDispatch();
  const requiredFieldStar = useRequiredFieldStar()
  const getSessionStorage = useSessionStorage().getSessionStorage

  const { otpRequired, error: errorMsg, status: apiStatus, otpVerificationRequired, requestOtpData, verifyOtpTrueStatus } = useSelector((state: StoreState) => state.login)
  const { error: errorMsgFB } = useSelector((state: StoreState) => state.loginFirebase)
  const [otpValue, setOtpValue] = useState<string>("")
  const [loginForm] = Form.useForm()
  const [otpForm] = Form.useForm()
  const [loginViaMagicLink, setLoginViaMagicLink] = useState(false)

  useEffect(() => {
    if (AsyncStates.LOADING === "LOADING") {
      dispatch(loginFailure(""))
    }

  }, [dispatch, loginForm])



  const firebaseStatus = useSelector<StoreState, AsyncStates>(
    (state: StoreState) => state.loginFirebase.status
  );
  const userId = useSelector((state: StoreState) => state.login.loginResponse.user_id)

  const submitForm = (values: any) => {
    const encryptedPassword = Sha1(values.password.trim())
    trackEvent(userId, 'User tried to log in through email', { "Login details": values.user_email })
    dispatch(loginRequest({ ...values, password: encryptedPassword }));
  }

  const submitOtp = (values: any) => {
    dispatch(verifyOtpRequest({ otp: values.otp }))
  }

  const [showSpinner, setshowSpinner] = useState(false)

  useEffect(() => {
    const isLoginWithFirebaseInitiated = getSessionStorage("isLoginWithFirebaseInitiated")
    if (isLoginWithFirebaseInitiated) {
      dispatch(loginFirebaseRequest())
    }
  }, [dispatch, getSessionStorage])

  const codeChange = (e: any) => {
    if (e.target.value?.length < 7) {
      setOtpValue(e.target.value)
    }
  }

  return (
    <Layout
      style={{ textAlign: "center", background: "white" }}
      className="fontWorkSans"
    >
      <LayoutContent style={{ overflow: "auto" }}>
        <Space style={{ width: "100%" }} direction="horizontal" size="large">
          <LeftSideOnboarding minWidth="55vw" />
          <Space
            direction="vertical"
            style={{ width: "100%", padding: "6rem", maxWidth: "40vw" }}
            align="center"
            className="login-card-container"
          >
            {otpRequired ? (
              <Space style={{ margin: "50px" }}>
                <Form onFinish={submitOtp} form={otpForm} requiredMark={false} >
                  <Form.Item>
                    <Typography.Title
                      level={4}
                      style={{
                        marginBottom: "40px",
                      }}
                    >
                      {t("login.authenticatorApp")}
                    </Typography.Title>
                  </Form.Item>

                  <Form.Item
                    name="otp"
                    rules={[
                      {
                        required: true,
                        message: (
                          <Text
                            style={{
                              color: "red",
                              display: "block",
                              textAlign: "left",
                              margin: "0.2rem",
                            }}
                          >
                            {t("login.enterOtp")}
                          </Text>
                        ),
                      },
                    ]}
                    required tooltip={requiredFieldStar}
                  >
                    <Input
                      type="number"
                      value={otpValue}
                      style={{
                        borderRadius: "7.5px",
                        minHeight: "50px",
                        width: "100%",
                      }}
                      placeholder="Enter Code"
                      onChange={(e) => {
                        if (e.target.value?.length < 7)
                          setOtpValue(e.target.value);
                      }}
                    />
                  </Form.Item>

                  <Form.Item>
                    <StyledButton
                      className="primary-btn"
                      style={{
                        background: "#1B55CE",
                        width: "100%",
                        borderRadius: "7.5px",
                        minHeight: "50px",
                      }}
                      size="large"
                      type="primary"
                      htmlType="submit"
                      disabled={!otpValue}
                    >
                      {t("common.submit")}
                    </StyledButton>
                  </Form.Item>

                  <Typography.Title
                    level={5}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    {t("common.goBackTo")}{' '}
                    <StyledButton
                      style={{
                        padding: 0,
                        marginLeft: "0.3rem",
                        color: "#0062FF",
                      }}
                      onClick={() => dispatch(setOtp(false))}
                      size="small"
                      type="link"
                    >
                      {t("common.login")}
                    </StyledButton>
                  </Typography.Title>
                </Form>
              </Space>
            ) : otpVerificationRequired ? (
              <Space direction="vertical">
                <Space direction="vertical">
                  <Text strong>{t("login.adminEnabled2fa")} </Text>
                  <Title level={5}>{t("login.setting2FA")}</Title>
                  <Text type="secondary" strong>
                    {t("common.authenticatorApp")}
                  </Text>
                  <Text type="secondary" strong>
                    {t("common.QRCodeverification")}
                  </Text>
                </Space>
                <Space direction="vertical">
                  <QRCode value={requestOtpData} />
                  <Input
                    style={{
                      borderRadius: "7.5px",
                      minHeight: "50px",
                      width: "100%",
                    }}
                    addonBefore={<Text strong>{t("common.code")}</Text>}
                    value={otpValue}
                    onChange={codeChange}
                    placeholder={t("common.enterCode")}
                    size="large"
                    type="number"
                    maxLength={6}
                  />
                  <StyledButton
                    loading={verifyOtpTrueStatus === AsyncStates.LOADING}
                    style={{
                      background: "#1753E2",
                      width: "100%",
                      borderRadius: "7.5px",
                      minHeight: "50px",
                    }}
                    size="large"
                    className="primary-btn"
                    disabled={otpValue?.length !== 6}
                    type="primary"
                    onClick={() => {
                      setOtpValue("");
                      dispatch(
                        verifyOtpTrueRequest({
                          otp: otpValue,
                          from: "login",
                        })
                      );
                    }}
                  >
                    {"Verify"}
                  </StyledButton>
                  <StyledButton
                    onClick={() =>
                      dispatch(
                        setVerification({
                          otpVerificationRequired: false,
                          requestOtpData: false,
                        })
                      )
                    }
                    size="small"
                    type="link"
                  >
                    {t("common.backToLogin")}
                  </StyledButton>
                </Space>
              </Space>
            ) : (
              <Space direction="vertical">
                <Title
                  level={1}
                  style={{
                    marginBottom: "40px",
                    fontSize: "2.5rem",
                    fontWeight: 500,
                  }}
                  className="mainLayoutMargin"
                >
                  {t("common.welcomTo")}{" "}
                  <span style={{ color: "#f29444", display: "block" }}>{currentPlatform && platformInfo[currentPlatform].name}</span>
                </Title>

                {loginViaMagicLink ? <MagicLinkLogin /> :
                  <Form
                    name="normal_login"
                    className="login-form"
                    layout="vertical"
                    onFinish={submitForm}
                    form={loginForm}
                    requiredMark={false}

                  >
                    {!!errorMsg && (
                      <Alert
                        message={
                          <div dangerouslySetInnerHTML={{ __html: errorMsg }}></div>
                        }
                        type="error"
                        style={{
                          backgroundColor: "#CF1322",
                          color: "white",
                          textAlign: "left",
                          display: "block",
                          marginBottom: "1rem",
                        }}
                        className="error-alert-msg"
                      />
                    )}
                    {!!errorMsgFB && (
                      <Alert
                        message={
                          <div dangerouslySetInnerHTML={{ __html: errorMsgFB }}></div>
                        }
                        type="error"
                        style={{
                          backgroundColor: "#CF1322",
                          color: "white",
                          textAlign: "left",
                          display: "block",
                          marginBottom: "1rem",
                        }}
                        className="error-alert-msg"
                      />
                    )}

                    <Form.Item
                      label={""}
                      name="user_email"
                      validateTrigger="onChange"
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp(
                            /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
                          ),
                          message: (
                            <Text
                              style={{
                                color: "red",
                                display: "block",
                                textAlign: "left",
                                margin: "0.2rem",
                                marginLeft: "3.8rem",
                              }}
                            >
                              {t("common.notAvalidMail")}
                            </Text>
                          ),
                        },
                      ]}
                      required tooltip={requiredFieldStar}
                    >
                      <Input
                        style={{
                          borderRadius: "7.5px",
                          minHeight: "50px",
                          width: "80%",
                        }}
                        prefix={<MailOutlined />}
                        size="large"
                        placeholder={t("common.email")}
                        className="round-text"
                        minLength={4}
                        autoFocus
                      />
                    </Form.Item>

                    <Form.Item
                      label={""}
                      name="password"
                      validateTrigger="onChange"
                      rules={[
                        {
                          required: true,
                          message: (
                            <Text
                              style={{
                                color: "red",
                                display: "block",
                                textAlign: "left",
                                margin: "0.2rem",
                                marginLeft: "3.8rem",
                              }}
                            >
                              {t("login.warn.password")}
                            </Text>
                          ),
                          pattern: new RegExp(/^[^\s]+(\s+[^\s]+)*$/),
                        },
                      ]}
                      required tooltip={requiredFieldStar}
                    >
                      <Input.Password
                        style={{
                          borderRadius: "7.5px",
                          minHeight: "50px",
                          width: "80%",
                        }}
                        prefix={<KeyOutlined />}
                        size="large"
                        placeholder={t("login.password")}
                        className="round-text"
                        type="password"
                      />
                    </Form.Item>
                    <Form.Item>
                      <StyledButton
                        style={{
                          background: "#1753E2",
                          width: "80%",
                          borderRadius: "7.5px",
                          minHeight: "50px",
                        }}
                        size="large"
                        type="primary"
                        htmlType="submit"
                        className="primary-btn"
                      >
                        {t("common.login")}
                      </StyledButton>
                    </Form.Item>
                  </Form>
                }
                {!loginViaMagicLink &&
                  <Text
                    strong
                    type="secondary"
                    style={{
                      textAlign: "center",
                      display: "block",
                    }}
                  >
                    <Link to="/forgot_password" style={{ color: "#1753E2" }}>
                      {t("login.forgotPassword")}?
                    </Link>
                  </Text>
                }
                <Form.Item>
                  <Divider plain>
                    <Text type="secondary">{t('common.or')}</Text>
                  </Divider>
                </Form.Item>
                <Row
                  style={{
                    gap: "14px",
                    justifyContent: "center",
                    fontWeight: "bold",
                  }}
                >
                  <Form.Item>
                    <StyledButton
                      size="large"
                      className="round-text social-btn"
                      onClick={() => setLoginViaMagicLink(prev => !prev)}
                      style={{
                        whiteSpace: "normal",
                        display: "block",
                        height: "max-content",
                        textAlign: "center",
                        padding: "8px, 16px, 8px, 16px",
                        width: "100%",
                      }}
                    >
                      <Space style={{ fontWeight: 600, padding: "5px" }}>
                        {!loginViaMagicLink ? <LinkOutlined style={{fontSize: antdTheme.fontSizeHeading2}} /> : <MailOutlined style={{fontSize: antdTheme.fontSizeHeading2}} />}
                        {`Login with ${!loginViaMagicLink ? "Magic Link" : "Email"}`}
                      </Space>
                    </StyledButton>
                  </Form.Item>
                  <SingleSignIn setshowSpinner={setshowSpinner} signInViaFirebase={signInViaFirebase} />
                </Row>
                <Text
                  strong
                  style={{
                    textAlign: "center",
                    display: "block",
                    color: "#969AB8",
                    marginTop: "1.5rem",
                  }}
                >
                  {t("login.noAccount")}?{" "}
                  <Link to="/signup" style={{ color: "#1753E2" }}>
                    {t("login.signUp")}
                  </Link>
                </Text>
              </Space>
            )}
            <AllRightsReserved
              styleProp={
                otpVerificationRequired || otpRequired || loginViaMagicLink
                  ? { position: "fixed" }
                  : { position: "relative", marginTop: "9rem" }
              }
            />
          </Space>
        </Space>
      </LayoutContent>
      {
        (showSpinner ||
          apiStatus === AsyncStates.LOADING ||
          firebaseStatus === AsyncStates.LOADING) && <Spinner />
      }
    </Layout>
  );
};
export default Login;
