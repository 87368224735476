import "./style.scss"
import {
	Checkbox,
	DatePicker,
	Input,
	InputNumber,
	notification,
	Popconfirm,
	Row,
	Select,
	Space,
	Tag,
	Typography,
} from "antd"
import { blue, yellow } from "@ant-design/colors"
import { LanguageUnion, TranslationKey } from "src/utils/useTranslate"
import dayjs from "dayjs"
import { KuritaCompanyId, permissions, projectStatus } from "src/constants"
import { ParameterTooltip } from "src/components/AIEngine/ForwardModel/form/payload-form/ParameterTooltip"
import { messages } from "../hooks"
import {
	CheckCircleFilled,
	ClockCircleOutlined,
	CloseCircleFilled,
	InfoCircleFilled,
	InfoCircleOutlined,
	StarFilled,
	StarOutlined,
	SyncOutlined,
} from "@ant-design/icons"
import { ReactElement } from "react"
const { Option } = Select
const { Text, Title } = Typography

export const generateOptionFields = (data: object | undefined = {}) =>
	Object.entries(data).map(([key, value]) => ({ label: value, value: key }))

export const convertToPrecision = (value: any, precision: number = 3) => {
	if (!value) return value
	const numericCheck = !isNaN(Number(value))
	if (numericCheck) {
		if (value > -1 && value < 1) {
			const precisedNumber = Number(value).toFixed(6)
			return parseFloat(precisedNumber)
		} else {
			const precisedNumber = Number(value).toFixed(precision)
			return parseFloat(precisedNumber)
		}
	}
	return String(value).trim()
}

export const getLinkedTableCellData = (text: string, allowSpacing: boolean = true,) => {
	const className = allowSpacing ? "checkbox-spacing" : 'checkbox-spacing-remove'
	return {
		props: {
			style: { background: colors["geekblue"] },
			className
		},
		children: <ParameterTooltip value={text} />,
	}
}

const colors = {
	sunsetOrange: "#FFF7E6",
	geekblue: "#F0F5FF",
	grey: "#F5F5F5",
}

// BOM

const buildOfMaterialsCellData = (
	record: any,
	text: string,
	allowSpacing: boolean = false,
) => {
	if (
		["ingredients-header", "processing-header", "properties-header"].includes(
			record?.key,
		)
	) {
		return (
			<Title level={5}>
				{text}
			</Title>
		)
	}
	if (!!record?.isProperty) {
		return {
			props: {
				style: {
					background: !!record?.isTotal
						? colors["grey"]
						: !!record?.isLast
							? colors["geekblue"]
							: "white",
				},

			},
			children: <ParameterTooltip value={text} />,
		}
	} else if (!!record?.isProcessing) {
		return {
			props: {
				style: {
					background: !!record?.isTotal
						? colors["grey"]
						: !!record?.isLast
							? colors["sunsetOrange"]
							: "white",
				},

			},
			children: <ParameterTooltip value={text} />,
		}
	} else {
		return {
			props: {

				style: {
					background: !!record?.isTotal ? colors["grey"] : "white",
					fontWeight: !!record?.isLast ? 600 : "normal",
				},
			},
			children: <ParameterTooltip value={text} />,
		}
	}
}

export const buildOfMaterialsColumnData = (
	data: any,
	t: (key: TranslationKey) => string,
	pageNumber: number,
) => {
	return [
		{
			title: () => (
				<Text strong>{`${t(
					"common.stages",
				)}`}</Text>
			),
			dataIndex: "stage",
			key: "stage",
			align: "left",
			fixed: "left" as "left",
			width: "170px",
			render: (text: any, record: any, index: any) => {
				const allowSpacing = true
				return buildOfMaterialsCellData(record, text, allowSpacing)
			},
			onCell: (record: any) => ({ rowSpan: record?.rowSpan }),
		},
		{
			title: () => (
				<Text strong>{`${t("common.inputs")}/${t(
					"common.predictedProperties",
				)}`}</Text>
			),
			dataIndex: "parameter",
			key: "parameter",
			align: "left",
			fixed: "left" as "left",
			width: "200px",
			render: (text: any, record: any, index: any) => {
				return buildOfMaterialsCellData(record, text)
			},
		},
		{
			dataIndex: "category",
			key: "Category",
			width: "170px",
			title: (
				<Text strong>
					{t("common.category")}
				</Text>
			),
			align: "left",
			fixed: "left" as "left",
			render: (text: any, record: any, index: any) => {
				return buildOfMaterialsCellData(record, text)
			},
		},
		{
			dataIndex: "unit",
			key: "unit",
			width: "80px",
			title: (
				<Text strong>
					{t("common.unit")}
				</Text>
			),
			align: "left",
			fixed: "left" as "left",
			render: (text: any, record: any, index: any) => {
				return buildOfMaterialsCellData(record, text)
			},
		},
		...data.map((res: any, i: number) => ({
			title: () => (
				<Text strong>{`Trial ${(pageNumber - 1) * 10 + i + 1
					}`}</Text>
			),
			align: "left",
			dataIndex: res.experiment_id,
			width: "170px",
			key: res.experiment_id,
			render: (text: any, record: any, index: any) => {
				return buildOfMaterialsCellData(record, text)
			},
		})),
	]
}

export const buildOfMaterialsTransposeData = (
	exp_data: any,
	t: (key: TranslationKey) => string,
	getValue: Function,
	zeonCategoryList: any,
	isZeonAccount: boolean,
	isNestleAccount: boolean = false,
	linkedTrialData: any[] = [],
) => {
	let data: any = []
	if (exp_data.length !== 0) {
		if (exp_data?.some((exp: any) => exp.ingredients)) {
			let stages = Array.from(
				new Set(
					exp_data?.flatMap((exp: any) => Object.keys(exp.ingredients || {})),
				),
			)
			data = [
				...stages
					.map((stage: any, stageIdx: number, stagesArray: any[]) => {
						let parameters = Array.from(
							new Set(
								exp_data?.flatMap((exp: any) =>
									Object.keys(exp.ingredients[stage] || {}),
								),
							),
						)

						let allIngredients = exp_data?.reduce(
							(acc: any, exp: any) => ({ ...acc, ...exp.ingredients[stage] }),
							{},
						)

						if (isNestleAccount) {
							let initialParameters = []
							if (
								Object.keys(exp_data?.[0]?.ingredients[stage] || {}).includes(
									"substrate",
								)
							) {
								initialParameters.push("substrate")
							}
							if (
								Object.keys(exp_data?.[0]?.ingredients[stage] || {}).includes(
									"water",
								)
							) {
								initialParameters.push("water")
							}
							const newParams = Object.keys(
								exp_data?.[0]?.ingredients[stage] || {},
							).filter((res: any) => res !== "substrate" && res !== "water")
							newParams.sort((a, b) => {
								const aNum = a.includes("enzyme_")
									? parseInt(a.split("_")[1])
									: Infinity
								const bNum = b.includes("enzyme_")
									? parseInt(b.split("_")[1])
									: Infinity
								if (aNum === bNum) {
									return a.localeCompare(b)
								} else {
									return aNum - bNum
								}
							})
							parameters = [...initialParameters, ...newParams]
						}

						return parameters.map(
							(ingredient: any, idx: number, ingredientsArray: any[]) => ({
								...exp_data.reduce(
									(a: any, o: any) => ({
										...a,
										unit:
											a.unit && a.unit !== "-"
												? a.unit
												: o?.ingredients?.[stage]?.[ingredient]?.unit || "-",
										[o.experiment_id]: getValue(
											o?.ingredients?.[stage]?.[ingredient]?.value,
										),
									}),
									{},
								),
								stage,
								category: allIngredients?.[ingredient]?.category ?? "-",
								isIngredient: true,
								key: idx + 1,
								parameter:
									linkedTrialData?.find(
										({ id_set }: any) =>
											id_set.formulation_id ===
											allIngredients?.[ingredient]?.name,
									)?.meta?.display_id ||
									allIngredients?.[ingredient]?.name ||
									ingredient,
								parameterType: "ingredients",
								rowSpan: idx === 0 ? ingredientsArray.length : 0,
								// isTotal: stageIdx === stagesArray.length - 1,
								isLast: stageIdx === stagesArray.length - 1,
							}),
						)
					})
					.flat(1),
			]
			data = [
				...(data?.length ? [{
					...data[0],
					stage: `${t("common.ingredients")} (${data.length})`,
					key: "ingredients-header",
				}] : []),
				...data,
			]
			if (data?.length) Object.keys(data[0]).forEach((val) => {
				if (val !== "key" && val !== "stage") data[0][val] = ""
			})
		}
		if ("processing" in exp_data?.[0]) {
			let stages = Array.from(
				new Set(
					exp_data?.flatMap((exp: any) => Object.keys(exp.processing || {})),
				),
			)
			if ("material" in exp_data?.[0]) {
				for (let i in exp_data) {
					for (const stage of stages) {
						exp_data[i].processing[stage as string].Material = {
							value: exp_data[i].material,
						}
					}
				}
			}
			let processingData = [
				...stages
					.map((stage: any, stageIdx: number, stagesArray: any[]) => {
						let parameters: any[] = Array.from(
							new Set(
								exp_data?.flatMap((exp: any) =>
									Object.keys(exp.processing[stage] || {}),
								),
							),
						)
						return parameters.map(
							(processing: string, idx: number, processingArray: any[]) => ({
								...exp_data.reduce(
									(a: any, o: any) => ({
										...a,
										unit:
											a.unit && a.unit !== "-"
												? a.unit
												: o?.processing?.[stage]?.[processing]?.unit || "-",
										[o.experiment_id]: getValue(
											o?.processing?.[stage]?.[processing]?.value,
										),
									}),
									{},
								),
								stage,
								category: exp_data?.[0]?.processing?.[processing]?.category ?? "-",
								parameter: processing,
								parameterType: "processing",
								rowSpan: idx === 0 ? processingArray.length : 0,
								isProcessing: true,
								// isTotal: stageIdx === stagesArray.length - 1,
								isLast: stageIdx === stagesArray.length - 1,
							}),
						)
					})
					.flat(1),
			]
			processingData = [
				...(processingData?.length ? [{
					...processingData[0],
					stage: `${t("common.processing")} (${processingData.length})`,
					key: "processing-header",
				}] : []),
				...processingData,
			]
			if (processingData?.length) Object.keys(processingData[0]).forEach((val) => {
				if (val !== "key" && val !== "stage") processingData[0][val] = ""
			})

			data = [...data, ...processingData]
		}
		if ("predicted_properties" in exp_data[0]) {
			let stages = Array.from(
				new Set(
					exp_data?.flatMap((exp: any) =>
						Object.keys(exp.predicted_properties || {}),
					),
				),
			)
			let propertiesData = [
				...stages
					.map((stage: any, stageIdx: number, stagesArray: any[]) => {
						let parameters: any[] = Array.from(
							new Set(
								exp_data?.flatMap((exp: any) =>
									Object.keys(exp.predicted_properties[stage] || {}),
								),
							),
						)
						return parameters.map(
							(
								predicted_properties: string,
								idx: number,
								predicted_properties_array: any[],
							) => ({
								...exp_data.reduce((a: any, o: any) => {
									const value =
										o?.predicted_properties?.[stage]?.[predicted_properties]
											?.value
									const std = getValue(
										o?.predicted_properties?.[stage]?.[predicted_properties]
											?.std,
									)
									return {
										...a,
										unit:
											a.unit && a.unit !== "-"
												? a.unit
												: o?.predicted_properties?.[stage]?.[
													predicted_properties
												]?.unit || "-",
										[o.experiment_id]: !!std
											? `${getValue(value)} ± ${getValue(
												std,
											)}`
											: getValue(value),
										property_data: !!Object.keys(o?.property_data || {}).length,
									}
								}, {}),
								stage,
								category:
									exp_data?.[0]?.predicted_properties?.[stage]?.[predicted_properties]
										?.category ?? "-",
								parameter: predicted_properties,
								parameterType: "properties",
								rowSpan: idx === 0 ? predicted_properties_array.length : 0,
								isProperty: true,
								// isTotal: stageIdx === stagesArray.length - 1,
								isLast: stageIdx === stagesArray.length - 1,
							}),
						)
					})
					.flat(1),
			]

			propertiesData = [
				...(propertiesData?.length ? [{
					...propertiesData[0],
					stage: `${t("common.properties")} (${propertiesData.length})`,
					key: "properties-header",
				}] : []),
				...propertiesData,
			]
			if (propertiesData?.length) Object.keys(propertiesData[0]).forEach((val) => {
				if (val !== "key" && val !== "stage") propertiesData[0][val] = ""
			})

			data = [...data, ...propertiesData]
		}
	}
	const result = isZeonAccount
		? data.sort(function (a: any, b: any) {
			if (!a.isIngredient || !b.isIngredient) {
				return 20
			}
			if (a.category === "-") {
				return 10
			}
			if (b.category === "-") {
				return -10
			}
			const indexOfA = zeonCategoryList.indexOf(a.category)
			const indexOfB = zeonCategoryList.indexOf(b.category)
			if (indexOfA > indexOfB) {
				return 1
			} else if (indexOfA < indexOfB) {
				return -1
			} else {
				return 0
			}
		})
		: data

	return result
}

// Suggested Table

export const suggColumnData = (
	data: any,
	checkChange: any,
	displayNames: any,
	checked: any,
	type: string,
	selectAll: any,
	t: (key: TranslationKey) => string,
	pageNumber: number,
	isZeonAccount: boolean = false,
) => {
	return [
		{
			title: () => (
				<Checkbox
					name={"Inputs"}
					onChange={(e) => selectAll(e, type)}
					checked={
						type === "suggested_clicked"
							? checked?.length === data?.length
							: checked?.length === data?.length ||
							checked?.checkAllPages?.includes(pageNumber)
					}
					className="dec-checkbox"
					style={{
						alignItems: "center",
						flexDirection: "column",
						gap: "10px",
						wordBreak: "break-word",
					}}
				>
					{`${t("common.inputs")}/${t("common.predictedProperties")}`}
				</Checkbox>
			),
			dataIndex: "parameter",
			key: "parameter",
			align: "center" as "center",
			fixed: "left" as "left",
			width: "170px",
			render: (text: any, record: any, index: any) => {
				return getCellData(data, record, text, displayNames, t, type)
			},
		},
		{
			dataIndex: "category",
			key: "Category",
			width: "170px",
			title: t("common.category"),
			align: "center" as "center",
			fixed: "left" as "left",
			render: (text: any, record: any, index: any) => {
				return getCellData(data, record, text, displayNames, t, type)
			},
		},
		{
			dataIndex: "unit",
			key: "unit",
			width: "80px",
			title: t("common.unit"),
			align: "center" as "center",
			fixed: "left" as "left",
			render: (text: any, record: any, index: any) => {
				return getCellData(data, record, text, displayNames, t, type)
			},
		},
		...(data
			? data?.map((res: any, i: number) => ({
				title: () => (
					<Checkbox
						className="dec-checkbox"
						name={res.experiment_id}
						style={{
							alignItems: "center",
							flexDirection: "column",
							gap: "10px",
							wordBreak: "break-word",
						}}
						checked={
							type === "suggested_clicked"
								? checked?.includes(res.experiment_id)
								: type === "forward"
									? checked?.includes(res.experiment_id)
									: checked?.includes(res.experiment_id)
						}
						onChange={(e) => checkChange(e, i, type)}
					>
						<p
							style={{
								margin: 0,
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}
						>
							<span>{res.experiment_id}</span>
							{res.formulation_id && <small>{res.formulation_id}</small>}
						</p>
					</Checkbox>
				),
				align: "center" as "center",
				dataIndex: res.experiment_id,
				width: "170px",
				key: res.experiment_id,
				render: (text: any, record: any, index: any) => {
					return getCellData(data, record, text, displayNames, t, type)
				},
			}))
			: []),
	].filter((col) => {
		if (!isZeonAccount) {
			return col.dataIndex !== "category"
		}
		return true
	})
}

// Prediction main table - forward, inverse

export const transposeData = (
	exp_data: any,
	t: (key: TranslationKey) => string,
	getValue: Function,
	zeonCategoryList: any,
	isZeonAccount: boolean,
	isNestleAccount: boolean = false,
	linkedTrialData: any[] = [],
) => {
	let data: any = []
	if (exp_data?.length > 0) {
		if (exp_data?.some((exp: any) => exp.ingredients)) {
			let parameters = Array.from(
				new Set(
					exp_data?.flatMap((exp: any) => Object.keys(exp.ingredients || {})),
				),
			)
			let allIngredients = exp_data?.reduce(
				(acc: any, exp: any) => ({ ...acc, ...exp.ingredients }),
				{},
			)
			if (isNestleAccount) {
				let initialParameters = []
				if (
					Object.keys(exp_data?.[0]?.ingredients || {}).includes("substrate")
				) {
					initialParameters.push("substrate")
				}
				if (Object.keys(exp_data?.[0]?.ingredients || {}).includes("water")) {
					initialParameters.push("water")
				}
				const newParams = Object.keys(exp_data?.[0]?.ingredients || {}).filter(
					(res: any) => res !== "substrate" && res !== "water",
				)
				newParams.sort((a, b) => {
					const aNum = a.includes("enzyme_")
						? parseInt(a.split("_")[1])
						: Infinity
					const bNum = b.includes("enzyme_")
						? parseInt(b.split("_")[1])
						: Infinity
					if (aNum === bNum) {
						return a.localeCompare(b)
					} else {
						return aNum - bNum
					}
				})
				parameters = [...initialParameters, ...newParams]
			}
			data = [
				...parameters.map((ingredient: any, idx: number) => ({
					...exp_data.reduce(
						(a: any, o: any) => ({
							...a,
							unit:
								a.unit && a.unit !== "-"
									? a.unit
									: o?.ingredients?.[ingredient]?.unit || "-",
							[o.experiment_id]: getValue(
								o?.ingredients?.[ingredient]?.value,
							),
						}),
						{},
					),
					category: allIngredients?.[ingredient]?.category ?? "-",
					isIngredient: true,
					parameter:
						linkedTrialData?.find(
							({ id_set }: any) =>
								id_set.formulation_id === allIngredients?.[ingredient]?.name,
						)?.meta?.display_id ||
						allIngredients?.[ingredient]?.name ||
						ingredient,
					parameterType: "ingredients",
					linked_trial: allIngredients?.[ingredient]?.type === "trials" ? ingredient : null,
					predictionTrial: allIngredients?.[ingredient]?.type === "prediction_trials" ? ingredient : null,
				})),
			]
			data = [
				{
					...data[0],
					parameter: `${t("common.ingredients")} (${data.length})`,
					key: "ingredients-header",
				},
				...data,
			]
			Object.keys(data[0]).forEach((val) => {
				if (val !== "key" && val !== "parameter") data[0][val] = ""
			})
		}
		if ("processing" in exp_data?.[0]) {
			if ("material" in exp_data?.[0]) {
				for (let i in exp_data) {
					exp_data[i].processing.Material = { value: exp_data[i].material }
				}
			}
			let processingData = Object.keys(exp_data?.[0]?.processing || {}).map(
				(processings: string, idx: number) => ({
					...exp_data.reduce(
						(a: any, o: any) => ({
							...a,
							unit:
								a.unit && a.unit !== "-"
									? a.unit
									: o?.processing?.[processings]?.unit || "-",
							[o.experiment_id]: getValue(
								o?.processing?.[processings]?.value,
							),
						}),
						{},
					),
					category: exp_data?.[0]?.processing?.[processings]?.category ?? "-",
					parameter: processings,
					parameterType: "processing",
				}),
			)

			processingData = [
				{
					...processingData[0],
					parameter: `${t("common.processing")} (${processingData.length})`,
					key: "processing-header",
				},
				...processingData,
			]
			Object.keys(processingData[0]).forEach((val) => {
				if (val !== "key" && val !== "parameter") processingData[0][val] = ""
			})

			data = [...data, ...processingData]
		}
		if ("predicted_properties" in exp_data[0]) {
			let propertiesData = Object.keys(
				exp_data?.[0]?.predicted_properties || {},
			).map((predicted_properties: string, idx: number) => ({
				...exp_data.reduce((a: any, o: any) => {
					const value = o?.predicted_properties?.[predicted_properties]?.value
					const std = getValue(
						o?.predicted_properties?.[predicted_properties]?.std,
					)
					return {
						...a,
						unit:
							a.unit && a.unit !== "-"
								? a.unit
								: o?.predicted_properties?.[predicted_properties]?.unit || "-",
						[o.experiment_id]: !!std
							? `${getValue(value)} ± ${getValue(std)}`
							: getValue(value),
						property_data: !!Object.keys(o?.property_data || {}).length,
					}
				}, {}),
				category: exp_data?.[0]?.predicted_properties?.[predicted_properties]?.category ?? "-",
				parameter: predicted_properties,
				parameterType: "properties",
			}))

			propertiesData = [
				{
					...propertiesData[0],
					parameter: `${t("common.properties")} (${propertiesData.length})`,
					key: "properties-header",
				},
				...propertiesData,
			]
			Object.keys(propertiesData[0]).forEach((val) => {
				if (val !== "key" && val !== "parameter") propertiesData[0][val] = ""
			})

			data = [...data, ...propertiesData]
		}
	}
	const result = isZeonAccount
		? data.sort(function (a: any, b: any) {
			if (!a.isIngredient || !b.isIngredient) {
				return 20
			}
			if (a.category === "-") {
				return 10
			}
			if (b.category === "-") {
				return -10
			}
			const indexOfA = zeonCategoryList.indexOf(a.category)
			const indexOfB = zeonCategoryList.indexOf(b.category)
			if (indexOfA > indexOfB) {
				return 1
			} else if (indexOfA < indexOfB) {
				return -1
			} else {
				return 0
			}
		})
		: data

	return result
}

const getCellData = (
	data: any[],
	record: any,
	text: string,
	displayNames: any,
	t: (key: TranslationKey) => string,
	type: string | null,
	allowSpacing: boolean = true,
	setMetricsVisible?: any,
	setCurrentProperty?: any,
	showMetrics?: boolean,
	showMetaDataTag?: boolean,
) => {
	const className = allowSpacing ? "checkbox-spacing" : 'checkbox-spacing-remove'
	if (
		["ingredients-header", "processing-header", "properties-header"].includes(
			record?.key,
		)
	) {
		return (
			<Title level={5}>
				{text}
			</Title>
		)
	}
	if (
		Object.keys(data?.[0]?.predicted_properties ?? {}).includes(
			record?.parameter,
		) ||
		Object.keys(data?.[0]?.properties ?? {}).includes(record?.parameter) ||
		!!record?.isProperty
	) {
		return {
			props: {
				style: {
					background:
						record?.key === "total" ? colors["grey"] : colors["geekblue"],
				},
				className
			},
			children: (
				<Space>
					<ParameterTooltip value={text} />
					{showMetrics &&
						displayNames?.properties?.[text]?.name &&
						type === "forward" ? (
						<InfoCircleOutlined
							onClick={() => {
								setCurrentProperty(text)
								setMetricsVisible(true)
							}}
							style={{ cursor: "pointer" }}
						/>
					) : (
						""
					)}
				</Space>
			),
		}
	} else if (
		Object.keys(data?.[0]?.processing || {}).includes(record?.parameter)
	) {
		return {
			props: {
				style: {
					background:
						record?.key === "total" ? colors["grey"] : colors["sunsetOrange"],
				},
				className
			},
			children: <ParameterTooltip value={text} />,
		}
	} else {
		return {
			props: {
				style: {
					background: record?.key === "total" ? colors["grey"] : "white",
				},
				className
			},
			children: 
				<>
					<ParameterTooltip value={text} />
					{record?.meta_ingredient && showMetaDataTag ? <>&nbsp;&nbsp;<Tag style={{ padding: '2px 6px', fontSize: '10px', lineHeight: "12px" }} color="processing">{t("common.New")}</Tag></> : null}
				</>,
		}
	}
}

export const newColumnData = (
	data: any,
	checkChange: any,
	displayNames: any,
	checked: any,
	type: string,
	selectAll: any,
	t: (key: TranslationKey) => string,
	pageNumber: number,
	isZeonAccount: boolean = false,
	setMetricsVisible?: any,
	setCurrentProperty?: any,
	showMetrics?: boolean,
) => {
	return [
		{
			title: () => {
				const isAllSelected = checked?.experimentIdList?.filter((res: any) => res.page === pageNumber).length === data?.length
				return (
					<Space
						style={{
							alignItems: "center",
							gap: "10px",
							wordBreak: "break-word",
							lineHeight: 1,
						}}
					>
						<Checkbox
							name={"Inputs"}
							onChange={(e) => selectAll(e, type)}
							checked={
								type === "suggested_clicked" || type === "recipe-suggested"
									? checked?.length === data?.length
									: isAllSelected ||
									checked?.checkAllPages?.includes(pageNumber)
							}
							className="dec-checkbox"
						/>
						<Text strong>{`${t("common.inputs")}/${t(
							"common.predictedProperties",
						)}`}</Text>
					</Space>
				)
			},
			dataIndex: "parameter",
			key: "parameter",
			align: "left",
			fixed: "left" as "left",
			width: "300px",
			render: (text: any, record: any, index: any) => {
				return getCellData(
					data,
					record,
					text,
					displayNames,
					t,
					type,
					true, //spacing
					setMetricsVisible,
					setCurrentProperty,
					showMetrics,
					Boolean(record?.meta_ingredient)
				)
			},
		},
		{
			dataIndex: "category",
			key: "Category",
			width: "170px",
			title: (
				<Text strong>
					{t("common.category")}
				</Text>
			),
			align: "left",
			fixed: "left" as "left",
			render: (text: any, record: any, index: any) => {
				return getCellData(data, record, text, displayNames, t, type, false)
			},
		},
		{
			dataIndex: "unit",
			key: "unit",
			width: "80px",
			title: (
				<Text strong>
					{t("common.unit")}
				</Text>
			),
			align: "left",
			fixed: "left" as "left",
			render: (text: any, record: any, index: any) => {
				return getCellData(data, record, text, displayNames, t, type, false)
			},
		},
		...(data ? data.map((res: any, i: number) => ({
			title: () => (
				<Space
					style={{
						alignItems: "center",
						gap: "10px",
						wordBreak: "break-word",
						lineHeight: 1,
					}}
				>
					<Checkbox
						className="dec-checkbox"
						name={res.experiment_id}
						checked={type === "suggested_clicked" || type === "recipe-suggested" ?
							checked?.includes(res.experiment_id) :
							checked?.experimentIdList?.map((res: any) => res.experiment_id)?.includes(res.experiment_id)
						}
						onChange={(e) => checkChange(e, i, type, res?.trial_name)}
					/>
					<Text strong>{res?.trial_name ? res.trial_name : `Trial ${(pageNumber - 1) * 10 + i + 1}`}</Text>
				</Space>
			),
			align: "left",
			dataIndex: res.experiment_id,
			width: "170px",
			key: res.experiment_id,
			render: (text: any, record: any, index: any) => {
				return getCellData(data, record, text, displayNames, t, type, true)
			},
		})) : []),
	]
}

// Predicted Models Forward

export const predictedModelsColumns = (data: any[], setModelStatus: any, t: (key: TranslationKey) => string, pageNumber: number) => {
	return [
		{
			dataIndex: "models",
			key: "models",
			width: "470px", // 300 + 170
			title: (
				<Text strong style={{ paddingLeft: "26px" }}>
					{t("common.models")}
				</Text>
			),
			align: "left" as "center",
			fixed: "left" as "left",
			render: (text: string, record: any) => {
				if (text === "Polymerize") {
					return {
						props: {
							style: { background: colors["geekblue"] },
							className: 'checkbox-spacing'
						},
						children: <Text>{text}</Text>,
					}
				}
				return (
					{
						props: {
							style: {},
							className: 'checkbox-spacing'
						},
						children: (<Space>
							<Text>{text}</Text>
							{record?.favourite ? (
								<Popconfirm
									title={`Unmark ${text} as favourite ?`}
									onConfirm={() =>
										setModelStatus(text, record?.currentProperty, false)
									}
								>
									<StarFilled
										style={{ cursor: "pointer", color: yellow.primary }}
									/>
								</Popconfirm>
							) : (
								<Popconfirm
									title={`Mark ${text} as favourite ?`}
									onConfirm={() =>
										setModelStatus(text, record?.currentProperty, true)
									}
								>
									<StarOutlined style={{ cursor: "pointer" }} />
								</Popconfirm>
							)}
						</Space>)
					}
				)
			},
		},
		{
			dataIndex: "unit",
			key: "unit",
			width: "80px",
			title: (
				<Text strong>
					{t("common.unit")}
				</Text>
			),
			align: "left" as "left",
			fixed: "left" as "left",
			render: (text: any, record: any) => {
				if (record?.models === "Polymerize") {
					return {
						props: {
							style: { background: colors["geekblue"] },
						},
						children: <Text>{text}</Text>,
					}
				}
				return {
					props: {
						style: {},
					},
					children: <Text>{text}</Text>,
				}
			},
		},
		...data.map((res: any, i: number) => ({
			title: () => (
				<Space
					style={{
						alignItems: "center",
						gap: "10px",
						wordBreak: "break-word",
						lineHeight: 1,
						paddingLeft: "26px"
					}}
				>
					<Text strong>{res?.trial_name ? res.trial_name : `Trial ${(pageNumber - 1) * 10 + i + 1}`}</Text>
					{/* <Text>({res.experiment_id})</Text>
					{res.formulation_id && <small>{res.formulation_id}</small>} */}
				</Space>
			),
			align: "left" as "left",
			dataIndex: res.experiment_id,
			width: "170px",
			key: res.experiment_id,
			render: (text: any, record: any) => {
				if (record?.models === "Polymerize") {
					return {
						props: {
							style: { background: colors["geekblue"] },
							className: 'checkbox-spacing'
						},
						children: <Text>{text}</Text>,
					}
				}
				return {
					props: {
						style: {},
						className: 'checkbox-spacing'
					},
					children: <Text>{text}</Text>,
				}
			},
		})),
	]
}

export const mapIndex = (data: any, disp: any) => {
	for (let i in data) {
		if ("processings" in data[i]) {
			data[i].processings =
				disp?.processing?.[data[i]?.processings]?.name ||
				disp?.properties?.[data[i]?.processings]?.name ||
				data[i]?.processings
		} else if ("ingredient" in data[i]) {
			data[i].ingredient =
				disp?.ingredients?.[data[i]?.ingredient]?.name ||
				disp?.properties?.[data[i]?.ingredient]?.name ||
				data[i]?.ingredient
		} else if ("ingredients" in data[i]) {
			data[i].ingredients =
				disp?.ingredients?.[data[i]?.ingredients]?.name || data[i]?.ingredients
		} else if ("properties" in data[i]) {
			data[i].properties =
				disp?.properties?.[data[i]?.properties]?.name || data[i]?.properties
		} else if ("Ingredient" in data[i]) {
			data[i].Ingredient =
				disp?.ingredients?.[data[i]?.Ingredient]?.name || data[i].Ingredient
		}
	}
}

export const removeNulls = (data: any, type: string, convertValue: Function, experiments: any = [],) => {
	if (data?.length) {
		if (type === "suggested_exp") {
			return data.filter((res: any) => {
				const experimentIds = experiments.map((exp: any) => exp?.experiment_id)
				const array = experimentIds.length
					? Object.entries(res).filter(([key, value]: any) =>
						experimentIds.includes(key) || ["ingredients-header", "processing-header", "properties-header"].includes(value),
					)
					: Object.entries(res)
				return (
					array.reduce(
						(sum: any, [key, value]: any) => convertValue(sum) + convertValue(value), 0,) !== 0
				)
			})
		} else if (type === "insights") {
			const filteredData = data[0].filter((res: any) => {
				const array = Object.values(res)
				let flag: any = false
				array.forEach((element: any) => {
					if (!isNaN(element) && element !== 0) flag = true
				})
				return flag === true
			})
			return [filteredData, data[1]]
		}
	} else return []
}

export const tableDataForCustomInsights = (tableData: any) => {
	const tableKeys = Object.keys(tableData[0]?.data || {})
	const tableRows1: any = []
	for (let i = 0; i < tableData[0]?.data?.Ingredient?.length; i++) {
		const obj: any = {}
		for (let j of tableKeys) {
			obj[j] = tableData[0]?.data?.[j]?.[i] ?? "-"
		}
		tableRows1.push(obj)
	}
	const tableRows2: any = Object.entries(tableData[1]?.data || {}).reduce(
		(obj: any, [key, value]: [any, any]) => ({ ...obj, [key]: value[0] }),
		{},
	)
	return [tableRows1, [tableRows2]]
}

export const tableColumnsForCustomInisghts = (tableData: any) => {
	return tableData.reduce(
		(array: any, element: any) => [
			...array,
			Object.keys(element.data).map((res: any) => {
				if (res === "P-Value") {
					return {
						title: res,
						index: res,
						dataIndex: res,
						align: "center",
						render: (value: any) =>
							Number(value) < 0.05 ? (
								<Row
									style={{ background: colors["geekblue"] }}
									justify="center"
								>
									<b>{value}</b>
								</Row>
							) : (
								value
							),
						filters: [
							{
								text: "< 0.05",
								value: 0.05,
							},
						],
						filterMultiple: false,
						onFilter: (value: any, record: any) => record[res] < value,
					}
				} else {
					return {
						title: res,
						index: res,
						dataIndex: res,
						align: "center",
					}
				}
			}),
		],
		[],
	)
}

export const customMLtableData = (
	data: any,
	type: any,
	ingredientsDataInfo: any,
	getValue: any

) => {
	if (type === "ingredients") {
		return [
			...[
				...new Set(
					data?.flatMap((res: any) => Object.keys(res?.ingredients || {})),
				),
			].map((ingredients: any, idx: number) => ({
				...data?.reduce((a: any, o: any) => {
					return {
						...a,
						[ingredientsDataInfo?.[o.meta.display_id]?.name ??
							o.meta.display_id]: getValue(
								o?.ingredients?.[ingredients]?.value,
							),
						key: ingredients,
					}
				}, {}),
				ingredients,
			})),
		]
	} else {
		return [
			...[
				...new Set(
					data.flatMap((res: any) =>
						Object.keys(res?.[type]?.[0]?.[type] || {}),
					),
				),
			].map((element: any, idx: number) => ({
				...data?.reduce(
					(a: any, o: any) => ({
						...a,
						[o.meta.display_id]: getValue(
							o?.[type]?.[0]?.[type]?.[element]?.value,
						),
						key: element,
					}),
					{},
				),
				[type]: element,
			})),
		]
	}
}

export const celsureGenerateOptionalFields: any = (identifier: any) =>
	Object.values(identifier || {}).map((value, index) => ({
		value,
		key: String(value) + String(index),
	}))

export const emptyTrialsCheck = (
	formulationList: any[],
	company_id: string | null = null,
	ln: LanguageUnion,
) => {
	if (!formulationList.length) {
		notification.error({
			message: messages[ln].formulation_empty_data_error,
			description: messages[ln].properties_empty_data_error,
			duration: 3,
		})
	}
	const emptyTrials = formulationList.filter((res: any) =>
		Object.values(res?.ingredients).every(
			(ingredient: any) => !ingredient.value,
		),
	)
	if (!!emptyTrials.length) {
		notification.error({
			message: messages[ln].formulation_empty_data_error,
			description: emptyTrials
				.map((res: any) => res?.meta?.display_id)
				.join(", "),
			duration: 3,
		})
	}
	const emptyProperties = formulationList.filter((res: any) =>
		Object.values(res?.properties?.[0]?.properties || {}).every(
			(property: any) => !property.value,
		),
	)
	if (!!emptyProperties.length) {
		notification.error({
			message: messages[ln].properties_empty_data_error,
			description: emptyProperties
				.map((res: any) => res?.meta?.display_id)
				.join(", "),
			duration: 3,
		})
	}
	return (
		!emptyProperties.length && !emptyTrials.length && !!formulationList.length
	)
}

export const getDropdownFilteredValue = (
	inputValue: string,
	option: { label: string; value: string; children: any },
) => {
	return (
		option?.label?.toLowerCase()?.includes(inputValue?.toLowerCase()) ||
		option?.value?.toLowerCase()?.includes(inputValue?.toLowerCase()) ||
		(typeof option?.children === "object"
			? option?.children?.props?.children
				?.toLowerCase()
				?.includes(inputValue?.toLowerCase())
			: option?.children?.toLowerCase()?.includes(inputValue?.toLowerCase()))
	)
}

export const mapFields = (field: any, disabled: boolean = false, formatter: any, parser: any) => {
	switch (field.field_type) {
		case "text":
		case "alpha_numeric":
		case "link":
			return (
				<Input
					defaultValue={field.default_value ?? field.value}
					disabled={disabled}
				/>
			)
		case "description":
			return (
				<Input.TextArea
					autoSize={true}
					defaultValue={field.default_value ?? field.value}
					disabled={disabled}
				/>
			)
		case "number":
		case "numerical":
			return (
				<InputNumber
					parser={parser}
					formatter={formatter}
					defaultValue={field.default_value ?? field.value}
					disabled={disabled}
				/>
			)
		case "date":
			return (
				<DatePicker
					defaultValue={
						!!field?.default_value || !!field?.value
							? dayjs(field.value)
							: (null as any)
					}
					disabled={disabled}
				// disabledDate={(curr) =>
				// 	curr < dayjs().endOf("day").subtract(1, "day")
				// }
				/>
			)
		case "select":
		case "categorical":
			return (
				<Select
					defaultValue={field?.default_value ?? field.value}
					mode="multiple"
					disabled={disabled}
					style={{ width: 400 }}
				>
					{field?.options?.map((res: any) => (
						<Option value={res}>{res}</Option>
					))}
				</Select>
			)
	}
}

export const displayTextPermissions = (access: any) => {
	if (access?.status === projectStatus.completed) {
		return (
			<Text style={{ color: blue.primary }} strong>
				{"This project is completed. You can only view its contents"}
			</Text>
		)
	} else if (access?.status === projectStatus.archived) {
		return (
			<Text style={{ color: blue.primary }} strong>
				{"This project is archived. You can only view its contents"}
			</Text>
		)
	} else if (access?.permission === permissions.viewer) {
		return (
			<Text type="warning" strong>
				{"You're the viewer of the project. You can only view the contents"}
			</Text>
		)
	}
	return null
}

export const generateZeonDataExportFormulationPayload = (
	workOrderIds: string[],
	formulationIds: string[],
	formulationsList: any,
) => {
	const result = workOrderIds.map((workOrderId) => {
		const data = formulationIds
			.map((id) => {
				const item = formulationsList?.find(
					(res: any) =>
						res?.formulation_id === id && res.work_order_id === workOrderId,
				)?.formulation_id
				return item
			})
			.filter((res) => res)
		return data
	})
	return result
}

export const transposePredictedData = (
	exp_data: any,
	zeonCategoryList: any,
	isZeonAccount: boolean,
	isNestleAccount: boolean = false,
) => {
	let data: any = []
	if (exp_data?.length > 0) {
		if ("ingredients" in exp_data?.[0]) {
			let parameters = Object.keys(exp_data?.[0]?.ingredients || {})
			if (isNestleAccount) {
				let initialParameters = []
				if (
					Object.keys(exp_data?.[0]?.ingredients || {}).includes("substrate")
				) {
					initialParameters.push("substrate")
				}
				if (Object.keys(exp_data?.[0]?.ingredients || {}).includes("water")) {
					initialParameters.push("water")
				}
				const newParams = Object.keys(exp_data?.[0]?.ingredients || {}).filter(
					(res: any) => res !== "substrate" && res !== "water",
				)
				newParams.sort((a, b) => {
					const aNum = a.includes("enzyme_")
						? parseInt(a.split("_")[1])
						: Infinity
					const bNum = b.includes("enzyme_")
						? parseInt(b.split("_")[1])
						: Infinity
					if (aNum === bNum) {
						return a.localeCompare(b)
					} else {
						return aNum - bNum
					}
				})
				parameters = [...initialParameters, ...newParams]
			}
			data = [
				...parameters.map((ingredient: string, idx: number) => ({
					...exp_data.reduce(
						(a: any, o: any) => ({
							...a,
							unit: o?.ingredients?.[ingredient]?.unit || "-",
							[o?.id_set?.formulation_id || o.experiment_id]:
								convertToPrecision(o?.ingredients?.[ingredient]?.value),
						}),
						{},
					),
					...(isZeonAccount && {
						category: exp_data?.[0]?.ingredients?.[ingredient]?.category ?? "-",
						isIngredient: true,
					}),
					key: idx,
					parameter:
						exp_data?.[0]?.ingredients?.[ingredient]?.name ?? ingredient,
					linked_trial:
						exp_data?.[0]?.ingredients?.[ingredient]?.type === "trials"
							? ingredient
							: null,
				})),
			]
		}
		if ("processing" in exp_data?.[0]) {
			if ("material" in exp_data?.[0]) {
				for (let i in exp_data) {
					exp_data[i].processing.Material = { value: exp_data[i].material }
				}
			}
			data = [
				...data,
				...Object.keys(exp_data?.[0]?.processing || {}).map(
					(processings: string, idx: number) => ({
						...exp_data.reduce(
							(a: any, o: any) => ({
								...a,
								unit: o?.processing?.[processings]?.unit || "-",
								[o?.id_set?.formulation_id || o.experiment_id]:
									convertToPrecision(o?.processing?.[processings]?.value),
							}),
							{},
						),
						category: exp_data?.[0]?.processing?.[processings]?.category ?? "-",
						parameter: processings,
					}),
				),
			]
		}
		if ("predicted_properties" in exp_data[0]) {
			data = [
				...data,
				...Object.keys(
					exp_data?.[0]?.predicted_properties ||
					exp_data?.[0]?.properties ||
					{},
				).map((predicted_properties: string, idx: number) => ({
					...exp_data.reduce((a: any, o: any) => {
						const value =
							o[
								o?.predicted_properties ? "predicted_properties" : "properties"
							]?.[predicted_properties]?.value
						const std = Number(
							o[
								o?.predicted_properties ? "predicted_properties" : "properties"
							]?.[predicted_properties]?.std,
						)
						return {
							...a,
							unit:
								o[
									o?.predicted_properties
										? "predicted_properties"
										: "properties"
								]?.[predicted_properties]?.unit || "-",
							[o?.id_set?.formulation_id || o.experiment_id]: !!std
								? `${convertToPrecision(value)} ± ${convertToPrecision(std)}`
								: convertToPrecision(value),
						}
					}, {}),
					...(isZeonAccount && {
						category:
							exp_data?.[0]?.ingredients?.[predicted_properties]?.category ??
							"-",
					}),
					parameter: predicted_properties,
				})),
			]
		}
	}
	const result = isZeonAccount
		? data.sort(function (a: any, b: any) {
			if (!a.isIngredient || !b.isIngredient) {
				return 20
			}
			if (a.category === "-") {
				return 10
			}
			if (b.category === "-") {
				return -10
			}
			const indexOfA = zeonCategoryList.indexOf(a.category)
			const indexOfB = zeonCategoryList.indexOf(b.category)
			if (indexOfA > indexOfB) {
				return 1
			} else if (indexOfA < indexOfB) {
				return -1
			} else {
				return 0
			}
		})
		: data

	return result
}

export const dsmRecipetransposePredictedData = (
	exp_data: any,
	zeonCategoryList: any,
	isZeonAccount: boolean,
	recipeDistributionDisplayNames: any,
	displayNames: any,
	filteredReciepeDistributionDisplayKeys: any,
	from: string | null | undefined,
	isNestleAccount: boolean,
	getEUValue: any
) => {
	let data: any = []
	if (exp_data?.length > 0) {
		if (!!filteredReciepeDistributionDisplayKeys?.ingredients?.length) {
			let parameters = filteredReciepeDistributionDisplayKeys?.ingredients
			if (isNestleAccount) {
				let initialParameters = []
				if (
					filteredReciepeDistributionDisplayKeys?.ingredients?.includes(
						"substrate",
					)
				) {
					initialParameters.push("substrate")
				}
				if (
					filteredReciepeDistributionDisplayKeys?.ingredients?.includes("water")
				) {
					initialParameters.push("water")
				}
				const newParams =
					filteredReciepeDistributionDisplayKeys?.ingredients?.filter(
						(res: any) => res !== "substrate" && res !== "water",
					)
				newParams.sort((a: string, b: string) => {
					const aNum = a.includes("enzyme_")
						? parseInt(a.split("_")[1])
						: Infinity
					const bNum = b.includes("enzyme_")
						? parseInt(b.split("_")[1])
						: Infinity
					if (aNum === bNum) {
						return a.localeCompare(b)
					} else {
						return aNum - bNum
					}
				})
				parameters = [...initialParameters, ...newParams, "Formulation Cost"]
			}
			data = [
				...parameters?.map((ingredient: string, idx: number) => ({
					...exp_data.reduce(
						(a: any, o: any) => ({
							...a,
							unit:
								a.unit && a.unit !== "-"
									? a.unit
									: o?.ingredients?.[ingredient]?.unit || "-",
							[o?.id_set?.formulation_id || o.experiment_id]:
								getEUValue(
									ingredient === "Formulation Cost"
										? o.cost.total_cost
										: o?.ingredients?.[ingredient]?.value,
								),
						}),
						{},
					),
					...(isZeonAccount && {
						category: displayNames?.ingredients?.[ingredient]?.category ?? "-",
						isIngredient: true,
					}),
					key: idx,
					parameter:
						displayNames?.ingredients?.[ingredient]?.name ??
						recipeDistributionDisplayNames?.linked_trials?.[ingredient] ??
						ingredient,
					linked_trial: Object.keys(
						recipeDistributionDisplayNames?.linked_trials || {},
					)?.includes(ingredient)
						? ingredient
						: null,
				})),
			]
		}
		if (!!filteredReciepeDistributionDisplayKeys?.processing?.length) {
			data = [
				...data,
				...(filteredReciepeDistributionDisplayKeys?.processing || []).map(
					(processings: string, idx: number) => ({
						...exp_data.reduce(
							(a: any, o: any) => ({
								...a,
								unit: o?.processing?.[processings]?.unit || "-",
								[o?.id_set?.formulation_id || o.experiment_id]:
									getEUValue(o?.processing?.[processings]?.value),
							}),
							{},
						),
						parameter: processings,
					}),
				),
			]
		}
		const allProperties = [
			...new Set([
				...filteredReciepeDistributionDisplayKeys?.properties,
				...filteredReciepeDistributionDisplayKeys?.predicted_properties,
			]),
		]
		if (allProperties.length && !isNestleAccount) {
			data = [
				...data,
				...allProperties.map((property: string, idx: number) => ({
					...exp_data.reduce((a: any, o: any) => {
						const parameterType = !!Object.keys(o?.predicted_properties || {})
							.length
							? "predicted_properties"
							: "properties"
						const value = o[parameterType]?.[property]?.value
						const std = Number(o[parameterType]?.[property]?.std)
						return {
							...a,
							unit: o[parameterType]?.[property]?.unit || "-",
							[o?.id_set?.formulation_id || o.experiment_id]: !!std
								? `${getEUValue(value)} ± ${getEUValue(std)}`
								: getEUValue(value),
						}
					}, {}),
					...(isZeonAccount && {
						category: displayNames?.properties?.[property]?.category ?? "-",
					}),
					key: "prop_" + idx,
					parameter: property,
				})),
			]
		} else if (isNestleAccount) {
			const separator = `_---><---_`
			const propertiesMap = allProperties.reduce((result, propertyData) => {
				result.push(
					...Object.keys(propertyData?.data ?? {}).map(
						(propertyIdentifier) =>
							`${propertyData.variation_id}${separator}${propertyIdentifier}`,
					),
				)
				return [...new Set(result)]
			}, [])
			data = [
				...data,
				...propertiesMap.map((property: any, idx: number) => ({
					...exp_data.reduce((accumulator: any, experiment: any) => {
						const [variationId, propertyIdentifier] = property.split(separator)
						const propertyData =
							experiment?.properties?.find(
								(prop: any) => prop.variation_id === variationId,
							)?.data ?? {}

						return {
							...accumulator,
							unit: propertyData?.[propertyIdentifier]?.unit || "-",
							[experiment.id_set?.formulation_id || experiment.experiment_id]:
								getEUValue(propertyData?.[propertyIdentifier]?.value),
							...(isZeonAccount && {
								category:
									displayNames?.properties?.[
										propertyData?.[propertyIdentifier]?.category
									]?.category ??
									propertyData?.[propertyIdentifier]?.category ??
									"-",
							}),
							key: `prop_${variationId}_${propertyIdentifier}`,
							parameter: `${displayNames?.properties?.[propertyIdentifier]?.name
								} (${displayNames?.characterization_methods?.[variationId]?.name ??
								"-"
								})`,
							isProperty: true,
						}
					}, {}),
				})),
			]
		}
	}
	const result = isZeonAccount
		? data.sort(function (a: any, b: any) {
			if (!a.isIngredient || !b.isIngredient) {
				return 20
			}
			if (a.category === "-") {
				return 10
			}
			if (b.category === "-") {
				return -10
			}
			const indexOfA = zeonCategoryList.indexOf(a.category)
			const indexOfB = zeonCategoryList.indexOf(b.category)
			if (indexOfA > indexOfB) {
				return 1
			} else if (indexOfA < indexOfB) {
				return -1
			} else {
				return 0
			}
		})
		: data

	return result
}

export const columnData = (
	data: any,
	displayNames: any,
	t: (key: TranslationKey) => string,
	isZeonAccount: boolean = false,
) => {
	const mergedColumns: any[] = [
		{
			title: <Text strong style={{ paddingLeft: '26px' }}>{`${t("common.inputs")}/${t(
				"common.predictedProperties",
			)}`}</Text>,
			dataIndex: "parameter",
			key: "parameter",
			align: "center" as "center",
			fixed: "left" as "left",
			width: "300px",
			render: (text: any, record: any, index: any) => {
				return getCellData(data, record, text, displayNames, t, null)
			},
		},
		{
			dataIndex: "category",
			key: "Category",
			width: "170px",
			title: t("common.category"),
			align: "center" as "center",
			fixed: "left" as "left",
			render: (text: any, record: any, index: any) => {
				return getCellData(data, record, text, displayNames, t, null)
			},
		},
		{
			dataIndex: "unit",
			key: "unit",
			width: "80px",
			title: t("common.unit"),
			align: "center" as "center",
			fixed: "left" as "left",
			render: (text: any, record: any, index: any) => {
				return getCellData(data, record, text, displayNames, t, null)
			},
		},
	]

	if (data?.length > 0) {
		const mergeIdMap: { [key: string]: any } = {}

		data.forEach((res: any, i: number) => {
			const mergeId = res?.formulation_merge_id
			if (mergeId && !mergeIdMap[mergeId]) {
				const mergedColumn: any = {
					title: (
						<Space
							style={{
								alignItems: "center",
								gap: "10px",
								wordBreak: "break-word",
								lineHeight: 1,
								paddingLeft: '26px'
							}}
						>
							<span>{res?.meta?.display_id ?? res.experiment_id}</span>
							{/* {res?.id_set?.formulation_id && (
								<small>{res?.id_set?.formulation_id}</small>
							)} */}
						</Space>
					),
					align: "center" as "center",
					dataIndex: mergeId,
					width: "170px",
					key: mergeId,
					render: (text: any, record: any, index: any) => {
						let mergedValue = 0

						// Loop through each object in the data and sum the values based on formulation_merge_id
						data.forEach((dataObj: any) => {
							const formulationId = dataObj?.id_set?.formulation_id
							if (dataObj?.formulation_merge_id === res?.formulation_merge_id) {
								mergedValue += parseFloat(record[formulationId] || 0)
							}
						})

						const newMergedValue =
							isNaN(mergedValue) || mergedValue === 0 ? " " : mergedValue

						return getCellData(
							data,
							record,
							newMergedValue.toString(),
							displayNames,
							t,
							null,
						)
					},
				}

				mergedColumns.push(mergedColumn)
				mergeIdMap[mergeId] = true
			} else if (!mergeId) {
				// Handle the case when merge_id is null
				const defaultColumn: any = {
					title: (
						<p
							style={{
								margin: 0,
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}
						>
							<span>{res?.meta?.display_id ?? res.experiment_id}</span>
							{res?.id_set?.formulation_id && (
								<small>{res?.id_set?.formulation_id}</small>
							)}
						</p>
					),
					align: "center" as "center",
					dataIndex: res?.id_set?.formulation_id,
					width: "200px",
					key: res?.id_set?.formulation_id,
					render: (text: any, record: any, index: any) => {
						return getCellData(data, record, text, displayNames, t, null)
					},
				}

				mergedColumns.push(defaultColumn)
			}
		})
	}

	return mergedColumns.filter((col) => {
		if (!isZeonAccount) {
			return col.dataIndex !== "category"
		}
		return true
	})
}

export const pickDescriptorsKey = (key: string, obj: any) => {
	const newObj = obj?.[0]?.[key] ?? []
	return newObj
}

export const getStageName = (name: any, index: number, t: any) => {
	return !!name ? `${t("common.stage")} ${index + 1}: ${name}` : `${t("common.stage")} ${index + 1}`
}

type ScrollIntoViewOptionType = {
	behavior?: "auto" | "smooth" | "instant"
	block?: "start" | "center" | "end" | "nearest"
	inline?: "start" | "center" | "end" | "nearest"
}

const defaultScrollIntoViewOption: ScrollIntoViewOptionType = {
	behavior: "smooth",
	block: "start",
	inline: "start",
}

export const customScrollIntoView = (
	ref: React.RefObject<HTMLDivElement>,
	option: ScrollIntoViewOptionType = defaultScrollIntoViewOption,
	timeout: number = 500,
) => {
	// setTimeout(() => {
	// 	ref?.current?.scrollIntoView(option as any)
	// }, timeout)
}

export const metaDataInfoMol = (filteredIngs: any[]) => {
	return filteredIngs.reduce((acc: any, curr: any) => {
		return {
			...acc,
			[curr.identifier]: curr.meta?.reduce((a: any, c: any) => {
				return {
					...a,
					[c.identifier]: {
						...c,
					},
				}
			}, {}),
		}
	}, {})
}

export const getIngredientsListForMol = (
	filteredIngs: any[] = [],
	companyId: string = KuritaCompanyId,
) => {
	return filteredIngs
		.filter((metadata: any) => !!metadata?.meta?.length)
		.filter((ing) =>
			companyId === KuritaCompanyId ? ing.category === "Monomer" : true,
		)
		.map((ing) => ing.identifier) // Checks catergory for kurita only else take all ingredients
}

export const isValidNumber = (value: any) => {
	if (value === undefined || value === null || value === "") {
		return false
	}
	const numValue = Number(value)

	return !isNaN(numValue)
}

export const generateVersionName = (version: number, configData: any) => {
	const versionObjective = `${configData.find((res: any) => res.version === Number(version))?.objective ??
		""
		}`
	const versionComment = `${configData.find((res: any) => res.version === Number(version))?.comments ??
		""
		}`
	const result = `${versionObjective} ${!!versionComment.length ? `(${versionComment})` : ""
		}`
	return version
		? result.length > 1
			? result
			: `Default Model`
		: `Default Model`
}

export const predictionStatus: { [key: string]: string } = {
	completed: "Completed",
	failed: "Failed",
	in_progress: "In Progress",
	Completed: "Completed",
	Failed: "Failed",
	"In Queue": "In Queue",
	"in_queue": "In Queue",
	"In Progress": "In Progress",
	"No Results Found": "No Results Found",
	"no_results_found": "No Results Found",
}

export const predictionStatusColorText: { [key: string]: string } = {
	completed: "success",
	failed: "error",
	in_progress: "processing",
	Completed: "success",
	Failed: "error",
	"In Queue": "cyan",
	"in_queue": "cyan",
	"In Progress": "processing",
	"No Results Found": "orange",
	"no_results_found": "orange",
}

export const predictionStatusIcon: { [key: string]: ReactElement } = {
	completed: <CheckCircleFilled />,
	failed: <CloseCircleFilled />,
	in_progress: <SyncOutlined spin />,
	Completed: <CheckCircleFilled />,
	Failed: <CloseCircleFilled />,
	"In Queue": <ClockCircleOutlined />,
	"in_queue": <ClockCircleOutlined />,
	"In Progress": <SyncOutlined spin />,
	"No Results Found": <InfoCircleFilled />,
	"no_results_found": <InfoCircleFilled />,
}

export const translatedLabel = (input: string, t: (key: TranslationKey) => string) => {
	if (input === "ingredients") return t("common.ingredients")
	if (input === "processing") return t("common.processing")
	if (input === "predicted_properties") return t("common.properties")
	if (input === "cost") return t("inventory.costing")
	return input
}

export const isInRange = (inputNumber: number, rangeStart: number, rangeEnd: number) => {
	if (isValidNumber(inputNumber) && isValidNumber(rangeStart) && isValidNumber(rangeEnd)) {
		return inputNumber >= rangeStart && inputNumber <= rangeEnd;
	}
	return false;
}

export const getExternalLink = (link: string) => link?.startsWith('http://') || link?.startsWith('https://')
	? link
	: `http://${link}`;
