import { Input, Modal } from 'antd'
import React, { useState } from 'react'
import useTranslate from 'src/utils/useTranslate'

type P = {
  open: boolean
  closeModal: () => void
  onAddFolder: (folderName: string) => void
}

const AddFolderModal = ({ open, closeModal, onAddFolder }: P) => {
  const [t] = useTranslate()

  const [newFolderName, setNewFolderName] = useState<string>('')

  return (
    <Modal
      title="Add Folder"
      open={open}
      width={400}
      okText="Add"
      centered
      cancelText="Cancel"
      closable={true}
      onOk={() => {
        onAddFolder(newFolderName)
        closeModal()
        setNewFolderName('')
      }}
      onCancel={() => {
        closeModal()
        setNewFolderName('')
      }}
      okButtonProps={{
        disabled: newFolderName?.trim?.() === '',
        style: {
          outline: 'none'
        }
      }}
      cancelButtonProps={{
        style: {
          outline: 'none'
        }
      }}
    >
      <Input
        style={{
          fontSize: 12,
        }}
        value={newFolderName}
        onChange={(e) => {
          setNewFolderName(e.target.value)
        }}
        placeholder={
          t('common.enterName')
        }
      />

    </Modal>
  )
}

export default AddFolderModal