import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

type P = {
  options: Highcharts.Options
}

export const PieChart = ({ options }: P) => {
  return <HighchartsReact highcharts={Highcharts} options={options} />
}
