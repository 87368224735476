import { useDispatch, useSelector } from "react-redux"
import GeneralSuggestExpWrapper from "./GeneralSuggestExpWrapper"
import ZeonSuggestExpWrapper from "./ZeonSuggestExpWrapper"
import { useEffect, useState } from "react"
import { StoreState } from "src/store/configureStore"
import { changeTitleClear, suggestedExperimentsClear, suggestedExperimentsRequest } from "src/store/actions/suggestedExp"
import { ZeonCurrentPageInfo } from "../InverseModelNew/catwise/inverse-model"
import { clearSuggestedExpInsights, insightsSocketConnect } from "src/store/actions/insights"
import { fetchVersionListRequest } from "src/store/actions/newInverseModel"
import { useQuery } from "src/utils/useQuery"
import jwtManager from "src/utils/jwtManager"
import { inverseConfigRequest } from "src/store/actions/inverseModel"

const InverseResult = ({
  tab,
  selectedObjective,
  setExperimentsCurrent,
  experimentsCurrent,
  setSelectedObjective
}: any) => {
  const dispatch = useDispatch()
  const query = useQuery()
  const [filters, setFilters] = useState<any>({})
  const configs = useSelector((state: StoreState) => state.configs.features)
  const { inverseVersionList, newInverseModel } = useSelector(
    (state: StoreState) => state.newInverseModel,
  )
  const userId = useSelector(
    (state: StoreState) => state.login.loginResponse.user_id,
  )

  useEffect(() => {
    return () => {
      dispatch(changeTitleClear())
    }
  }, [dispatch])

  const inverseModel = useSelector((state: StoreState) => state.inverseModel)
  const [currentVersionTab, setCurrentVersionTab] = useState<
    number | undefined
  >(inverseVersionList?.[0])

  const predId = query?.get("predId")

  const [zeonCurrentPageInfo, setZeonCurrentPageInfo] =
    useState<ZeonCurrentPageInfo>({
      variation_id: 0,
      version: inverseVersionList?.[0]?.versions?.[0],
      currentPage: 1,
    })

  useEffect(() => {
    if (
      inverseVersionList?.[0]?.versions &&
      Boolean(configs?.characterization_methods)
    ) {
      setZeonCurrentPageInfo((prev: any) => ({
        ...prev,
        version: inverseVersionList?.[0]?.versions?.[0],
        variation_id: 0,
      }))
    }
  }, [inverseVersionList, configs, selectedObjective])

  useEffect(() => {
    if (!Boolean(configs?.characterization_methods)) {
      setCurrentVersionTab(inverseVersionList[0])
    }
  }, [inverseVersionList, configs, selectedObjective])

  const pageChange = (
    pageNum: Number,
    type: string,
    payload: any = { expFilters: [], sorts: [] },
  ) => {
    if (type === "experiments") {
      const additionalPayload = Boolean(configs?.characterization_methods)
        ? {
          variation:
            inverseVersionList?.[zeonCurrentPageInfo?.variation_id]
              ?.variation,
          version: zeonCurrentPageInfo?.version,
        }
        : {
          version: currentVersionTab,
        }

      dispatch(
        suggestedExperimentsRequest(
          selectedObjective || newInverseModel?.prediction_id
            ? {
              prediction_id:
                selectedObjective || newInverseModel?.prediction_id,
              ...additionalPayload,
              version:
                currentVersionTab ??
                zeonCurrentPageInfo?.version ??
                inverseVersionList?.[0],
              pageNum,
              filters: payload.expFilters,
              sorts: payload.sorts,
              ...(payload?.stage ? { stage: payload?.stage } : {})
            }
            : {
              prediction_id:
                inverseModel.predictionIdsData.data[0].prediction_id,
              pageNum,
              filters: payload.expFilters,
              sorts: payload.sorts,
              ...(payload?.stage ? { stage: payload?.stage } : {})
            },
        ),
      )
      if (Boolean(configs?.characterization_methods)) {
        setZeonCurrentPageInfo((prev: any) => ({
          ...prev,
          currentPage: pageNum,
        }))
      } else {
        setExperimentsCurrent(pageNum)
      }
    }
  }
  useEffect(() => {
    return () => {
      dispatch(suggestedExperimentsClear())
    }
  }, [dispatch])


  useEffect(() => {
    if (predId && userId) {
      dispatch(clearSuggestedExpInsights())
      dispatch(
        fetchVersionListRequest({
          prediction_id: predId,
        }),
      )
      setSelectedObjective(predId as string)
      setExperimentsCurrent(1)
      if (!Boolean(configs?.characterization_methods)) {
        dispatch(
          insightsSocketConnect({
            event: "custom_insights",
            type: "inverse",
            action: "results",
            data: { prediction_id: predId },
            key: userId,
            token: jwtManager.getToken(),
          }),
        )
      }
      dispatch(inverseConfigRequest())
    }
  }, [configs?.characterization_methods, dispatch, predId, setExperimentsCurrent, setSelectedObjective, userId])

  return (
    <>
      {Boolean(configs?.ai_engine_with_methods) ? (
        <ZeonSuggestExpWrapper
          zeonCurrentPageInfo={zeonCurrentPageInfo}
          setZeonCurrentPageInfo={setZeonCurrentPageInfo}
          currentVersionTab={currentVersionTab}
          experimentsCurrent={experimentsCurrent}
          filters={filters}
          pageChange={pageChange}
          setFilters={setFilters}
          selectedObjective={selectedObjective}
          tab={tab}
        />
      ) : (
        <GeneralSuggestExpWrapper
          selectedObjective={selectedObjective}
          experimentsCurrent={experimentsCurrent}
          filters={filters}
          pageChange={pageChange}
          setFilters={setFilters}
          tab={tab}
        />
      )}
    </>
  )
}

export default InverseResult
