import { message, Space, Steps } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import UploadStep from './UploadStep'
import MapStagesStep from './MapStagesStep'
import MapParametersStep from './MapParametersStep'
import { StyledButton } from 'src/styled_components/StyledButton'
import { BuildOutlined, LeftOutlined, RedoOutlined, RightOutlined, UploadOutlined } from '@ant-design/icons'
import './UploadComponent.scss'
import ReviewAndPredictStep from './ReviewAndPredictStep'
import useTranslate from 'src/utils/useTranslate'
import { useDispatch, useSelector } from 'react-redux'
import { modelsConfigRequest } from 'src/store/actions/formulate'
import { StoreState } from 'src/store/configureStore'
import { useQuery } from 'src/utils/useQuery'

export const PARAMETER_TYPE = 'Type'
export const PARAMETER_NAME = 'Ingredients/Processing'
export const POSSIBLE_PARAMETER_TYPES = ['ingredients', 'processing']

const RenderStep = ({
  currentStep,
  file,
  setFile,
  selectedStages,
  sheetToStageMap,
  setSheetToStageMap,
  setFileJson,
  isMultiStageModel,
  rowToParameterMap,
  setRowToParameterMap,
  ingredientsInputs,
  processingInputs,
  trialDisplayNameList,
  hasCategories,
  groupedIngredientOptions,
  fileJson,
  currentSelectedStage,
  setCurrentSelectedStage,
  setTrialDisplayNameList
}: {
  currentStep: number
  file: any
  setFile: any
  selectedStages: [number, number]
  sheetToStageMap: any
  setSheetToStageMap: any
  setFileJson: any
  isMultiStageModel: boolean
  rowToParameterMap: any
  setRowToParameterMap: any
  ingredientsInputs: any
  processingInputs: any
  trialDisplayNameList: any
  hasCategories: boolean
  groupedIngredientOptions: any
  fileJson: any
  currentSelectedStage: number
  setCurrentSelectedStage: any
  setTrialDisplayNameList: any
}) => {
  switch (currentStep) {
    case 0:
      return <UploadStep file={file} setFile={setFile} setFileJson={setFileJson} selectedStages={selectedStages} />
    case 1:
      return <MapStagesStep selectedStages={selectedStages} sheetToStageMap={sheetToStageMap} setSheetToStageMap={setSheetToStageMap} />
    case 2:
      return (
        <MapParametersStep
          selectedStages={selectedStages}
          sheetToStageMap={sheetToStageMap}
          isMultiStageModel={isMultiStageModel}
          rowToParameterMap={rowToParameterMap}
          setRowToParameterMap={setRowToParameterMap}
          ingredientsInputs={ingredientsInputs}
          processingInputs={processingInputs}
          trialDisplayNameList={trialDisplayNameList}
          hasCategories={hasCategories}
          groupedIngredientOptions={groupedIngredientOptions}
          currentSelectedStage={currentSelectedStage}
          setCurrentSelectedStage={setCurrentSelectedStage}
          setTrialDisplayNameList={setTrialDisplayNameList}
          fileJson={fileJson}
        />
      )
    case 3:
      return (
        <ReviewAndPredictStep
          rowToParameterMap={rowToParameterMap}
          sheetToStageMap={sheetToStageMap}
          fileJson={fileJson}
          isMultiStageModel={isMultiStageModel}
          trialDisplayNameList={trialDisplayNameList}
          selectedStages={selectedStages}
        />
      )
    default:
      return <UploadStep file={file} setFile={setFile} setFileJson={setFileJson} selectedStages={selectedStages} />
  }
}

const UploadComponent = ({
  isMultiStageModel,
  selectedStages,
  ingredientsInputs,
  processingInputs,
  trialDisplayNameList,
  hasCategories,
  groupedIngredientOptions,
  currentSelectedStage,
  setCurrentSelectedStage,
  setTrialDisplayNameList
}: {
  isMultiStageModel: boolean
  selectedStages: [number, number]
  ingredientsInputs: any
  processingInputs: any
  trialDisplayNameList: any
  hasCategories: boolean
  groupedIngredientOptions: any
  currentSelectedStage: number
  setCurrentSelectedStage: any
  setTrialDisplayNameList: any
}) => {
  const [t] = useTranslate()

  let query = useQuery()
  let modelVersion = query?.get('version')
  const dispatch = useDispatch()

  const modelConfigData = useSelector((state: StoreState) => state.formulate.modelConfigData)

  const [currentStep, setCurrentStep] = useState(0)
  const [file, setFile] = useState<any>(null)
  const [fileJson, setFileJson] = useState<any>(null)
  const [sheetToStageMap, setSheetToStageMap] = useState<Record<string, Record<string, any> | null>>({})
  const [rowToParameterMap, setRowToParameterMap] = useState<Record<string, Record<string, Record<string, string>>>>({})

  // Setting sheetname to mapping
  useEffect(() => {
    if (fileJson) {
      const sheetNames = Object.keys(fileJson)
      if (!isMultiStageModel) {
        setSheetToStageMap({
          [sheetNames[0]]: {
            stageName: 'Default',
            stageNumber: selectedStages[0]
          }
        })
      } else {
        setSheetToStageMap(
          sheetNames.reduce((acc: any, sheetName: string) => {
            acc[sheetName] = {
              stageName: '',
              stageNumber: null
            }
            return acc
          }, {})
        )
      }
    }
  }, [fileJson, isMultiStageModel, selectedStages])

  // Setting row to parameter mapping
  useEffect(() => {
    if (fileJson) {
      setRowToParameterMap((prevState: any) => {
        const mappedSheets = Object.keys(sheetToStageMap).filter((sheet) => sheetToStageMap[sheet]?.stageNumber !== -1)
        const sheetNames = Object.keys(fileJson).filter((sheetName) => mappedSheets.includes(sheetName))
        const stagewiseParametersData: Record<string, Record<string, Record<string, string>>> = { ...prevState }

        sheetNames.forEach((sheetName: string) => {
          const sheetData = fileJson[sheetName]
          const parametersData: Record<string, Record<string, string>> = {...stagewiseParametersData[sheetName]}

          sheetData.forEach((row: any, idx: number) => {
            const type = row[PARAMETER_TYPE].trim().toLowerCase()
            // if (!parametersData[type]) {
            //   parametersData[type] = {}
            // }

            if (POSSIBLE_PARAMETER_TYPES.includes(type)) {
              if (!parametersData['ingredients']) {
                parametersData['ingredients'] = {}
              }

              if (!parametersData['processing']) {
                parametersData['processing'] = {}
              }

              if (!parametersData?.[type]?.[row[PARAMETER_NAME]] && (type === 'ingredients' || type === 'processing')) {
                parametersData[type][row[PARAMETER_NAME]] = ''
              }
            }
          })

          stagewiseParametersData[sheetName] = parametersData
        })
        return stagewiseParametersData
      })
    }
  }, [fileJson, sheetToStageMap])


  // Bring back to upload step if selected stages range is changed
  useEffect(() => {
    setCurrentSelectedStage(selectedStages[0])
    setCurrentStep(0)
  }, [selectedStages, setCurrentSelectedStage])

  const validateStep = useCallback(() => {
    if (currentStep === 1) {
      const totalStages = new Array(selectedStages?.[selectedStages?.length - 1] - selectedStages?.[0] + 1).fill(null).length
      const unmappedSheets = Object.keys(sheetToStageMap).filter((sheetName) => sheetToStageMap[sheetName]?.stageNumber === null)
      const validMappedSheets = Object.values(sheetToStageMap).filter((mapping) => mapping?.stageNumber !== -1 && mapping?.stageNumber !== null)

      if (unmappedSheets.length > 0) {
        setSheetToStageMap((prevState: any) => {
          const updatedState = { ...prevState }
          Object.keys(updatedState).forEach((sheetName) => {
            if (updatedState[sheetName]?.stageNumber === null) {
              updatedState[sheetName].isError = true
            }
          })
          return updatedState
        })
        return { isError: true, errorMessage: t('aiEngine.forward.upload.mapAllSheetsError') }
      }
      if (totalStages !== validMappedSheets.length) {
        return { isError: true, errorMessage: t('aiEngine.forward.upload.sheetsIgnoredError') }
      }
    } else if (currentStep === 2) {
      const allEmptyParameters: Record<string, string> = {}
      const ignoredParameters: Record<string, string> = {}
      let totalParameters = 0

      const sheetNameForCurrentStage =
        Object.keys(sheetToStageMap).find((sheetName) => sheetToStageMap[sheetName]?.stageNumber === currentSelectedStage) || ''

      Object.values(rowToParameterMap[sheetNameForCurrentStage]).forEach((parameters) => {
        Object.keys(parameters).forEach((parameter) => {
          allEmptyParameters[parameter] = parameters[parameter]

          if (parameters[parameter] === 'ignore') {
            ignoredParameters[parameter] = parameters[parameter]
          }

          totalParameters++
        })
      })
      setRowToParameterMap((prevState: any) => {
        const updatedState = { ...prevState }

        Object.entries(updatedState[sheetNameForCurrentStage]).forEach(([type, parameters]: any) => {
          Object.keys(parameters).forEach((parameter) => {
            allEmptyParameters[parameter] = parameters[parameter]

            delete updatedState[sheetNameForCurrentStage][type][`polymerize_custom_error_${parameter}`]

            if(parameters[parameter] === '')
              updatedState[sheetNameForCurrentStage][type][`polymerize_custom_error_${parameter}`] = true
          })
        })
        return updatedState
      })

      if (Object.values(allEmptyParameters).includes('')) {
        return { isError: true, errorMessage: t('aiEngine.forward.upload.mapAllParametersError') }
      }

      if (Object.values(ignoredParameters).length === totalParameters) {
        return { isError: true, errorMessage: t('aiEngine.forward.upload.allParametersIgnoredError') }
      }
    }

    return { isError: false, errorMessage: '' }
  }, [currentSelectedStage, currentStep, rowToParameterMap, selectedStages, sheetToStageMap, t])

  const isFileUploaded = !!file

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: '1rem',
        gap: '2rem',
        height: '400px'
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <Steps
          current={currentStep}
          direction="horizontal"
          style={{ width: '70%', userSelect: 'none' }}
          items={[
            { title: t('aiEngine.forward.upload.uploadStep'), icon: <UploadOutlined /> },
            {
              title: t('aiEngine.forward.upload.mapStagesStep'),
              icon: <BuildOutlined />,
              disabled: !isFileUploaded,
              style: { display: isMultiStageModel ? 'block' : 'none' }
            },
            { title: t('aiEngine.forward.upload.mapParametersStep'), icon: <BuildOutlined />, disabled: !isFileUploaded },
            { title: t('aiEngine.forward.upload.reviewAndPredictStep'), icon: <RedoOutlined />, disabled: !isFileUploaded }
          ]}
          onChange={(current) => {
            setCurrentStep(current)
          }}
        />
        {/* Previous and next button */}
        <Space>
          <StyledButton
            onClick={()=>{
              setFile(null)
              setFileJson(null)
              setSheetToStageMap({})
              setRowToParameterMap({})
              setCurrentStep(0)
              setCurrentSelectedStage(selectedStages[0])  
            }}
            disabled={!file}
          >
            {t('common.Clear')}
          </StyledButton>
          <StyledButton
            disabled={currentStep === 0}
            onClick={() => {
              if (!isMultiStageModel && currentStep === 2) {
                setCurrentStep(0)
              } if(isMultiStageModel && currentStep===2){
                setCurrentSelectedStage(selectedStages[0])
                setCurrentStep(1)
              }
              else {
                setCurrentStep(currentStep - 1)
              }
            }}
            icon={<LeftOutlined />}
          />
          <StyledButton
            disabled={!isFileUploaded || currentStep === 3}
            onClick={() => {
              if (isMultiStageModel && currentStep === 2 && currentSelectedStage < selectedStages[1]) {
                const { isError, errorMessage } = validateStep()
                if (isError) {
                  message.error(errorMessage)
                  return
                }
                setCurrentSelectedStage((prev: number) => {
                  const newStage = prev + 1

                  if (!Object.keys(modelConfigData[newStage - 1] || {}).length) {
                    dispatch(
                      modelsConfigRequest({
                        version: modelVersion,
                        isMultiStage: isMultiStageModel,
                        stage_name: modelConfigData?.[0]?.all_stages?.[newStage - 1]
                      })
                    )
                  }

                  return newStage
                })

                return
              }

              const { isError, errorMessage } = validateStep()

              if (isError) {
                message.error(errorMessage)
                return
              }
              if (!isMultiStageModel && currentStep === 0) {
                setCurrentStep(2)
              } else {
                setCurrentStep(prev=>{
                  if(prev === 2){
                    setCurrentSelectedStage(selectedStages[0])
                  }
                  return prev + 1
                })
              }
            }}
            icon={<RightOutlined />}
          />
        </Space>
      </div>
      <div
        style={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <RenderStep
          currentStep={currentStep}
          file={file}
          setFile={setFile}
          selectedStages={selectedStages}
          sheetToStageMap={sheetToStageMap}
          setSheetToStageMap={setSheetToStageMap}
          setFileJson={setFileJson}
          isMultiStageModel={isMultiStageModel}
          rowToParameterMap={rowToParameterMap}
          setRowToParameterMap={setRowToParameterMap}
          ingredientsInputs={ingredientsInputs}
          processingInputs={processingInputs}
          trialDisplayNameList={trialDisplayNameList}
          hasCategories={hasCategories}
          groupedIngredientOptions={groupedIngredientOptions}
          fileJson={fileJson}
          currentSelectedStage={currentSelectedStage}
          setCurrentSelectedStage={setCurrentSelectedStage}
          setTrialDisplayNameList={setTrialDisplayNameList}
        />
      </div>
    </div>
  )
}

export default UploadComponent
