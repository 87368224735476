import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Row, Typography, Tooltip, Input } from 'antd';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncStates } from 'src/constants';
import { StoreState } from 'src/store/configureStore';
import { selectAllProjects, setProject } from "src/store/actions/projects"
import { geekblue } from '@ant-design/colors';
import { StyledButton } from 'src/styled_components/StyledButton';
import useTranslate from 'src/utils/useTranslate';
// import styles from './ProjectSelection.module.scss'

const { Text } = Typography

export const ProjectSelection = ({ selectAll, size = "large", maxHeightOuter = undefined, maxHeightInner = undefined, setCreateProjectModal, createProjectModal, actions }: { selectAll?: boolean, size?: any, maxHeightOuter?: number, maxHeightInner?: number, setCreateProjectModal?: Function, createProjectModal?: boolean, actions?: Function }) => {
  const dispatch = useDispatch()
  const [t] = useTranslate()
  const [search, setSearch] = useState("");

  const currentProject = useSelector((state: StoreState) => state.projects.current)
  const { projectList, projectListStatus, selectAllProjects: selectAllprojectsCheck } = useSelector((state: StoreState) => state.projects)

  const projectOptions = useMemo(() => [...new Set(projectList.filter(project => project.name.toLowerCase().includes(search.toLowerCase())).map(project => project.category))].map(category => (
    <Menu.ItemGroup key={category} title={category}>
      {projectList.filter(project => project.category === category)
        .filter(project => project.name.toLowerCase().includes(search.toLowerCase())).map(project => <Menu.Item key={project.project_id} className="projects-title-menu"><Tooltip title={project.name}>{project.name}</Tooltip></Menu.Item>)}
    </Menu.ItemGroup>
  )), [projectList, search])


  const menu = useMemo(() =>
    <Menu style={{ maxHeight: maxHeightOuter || 500, overflowY: "auto", width: "13.5em" }} onClick={e => {
      if (e.key === "selectAllProjects") {
        dispatch(selectAllProjects(!selectAllprojectsCheck))
      } else {
        dispatch(selectAllProjects(false))
        dispatch(setProject(e.key))
      }
      if (actions) actions();
    }}>
      <Row justify="center" style={{ padding: 5 }}>
        <Input placeholder={t('common.search')} onChange={(event) => setSearch(event.target.value)} />
      </Row>
      {selectAll ? <Menu.Item key="selectAllProjects" style={selectAllprojectsCheck ? { padding: 15, background: geekblue[0] } : { padding: 15 }}>
        <Text strong>{t("common.allProjects")}</Text>
      </Menu.Item> : ""}
      <Menu.Divider key="project-menu-divider-1" />
      <div style={{ maxHeight: maxHeightInner || 380, overflowY: "auto", }}>
        {projectList.filter(project => project.name.toLowerCase().includes(search.toLowerCase())).length > 0 ? projectOptions : <Row style={{ justifyContent: "center" }}>{t('noProjectsFound')}</Row>}
      </div>
      <Menu.Divider key="project-menu-divider-1" />
      <Row justify='center' style={{ padding: 5 }}>
        <StyledButton type="primary" onClick={() => setCreateProjectModal && setCreateProjectModal(true)} icon={<PlusOutlined />} size="large">
          {t("projects.header.newProject")}
        </StyledButton>
      </Row>
    </Menu>,
    [projectOptions, dispatch, selectAllprojectsCheck, selectAll, t, setCreateProjectModal, projectList, search, maxHeightInner, maxHeightOuter, actions]
  )

  return (
    <Dropdown overlay={menu} trigger={['click']} >
      <StyledButton type="primary" size={size} style={{ width: '12em' }} loading={projectListStatus === AsyncStates.LOADING}>
        <Tooltip placement="top" title={(selectAll && selectAllprojectsCheck) ? t("common.allProjects") : projectList.find(project => project.project_id === currentProject)?.name}>
          <span className='project-title'>
            {(selectAll && selectAllprojectsCheck) ? t("common.allProjects") : projectList.find(project => project.project_id === currentProject)?.name}
          </span>
        </Tooltip>
        <DownOutlined style={{ verticalAlign: "super" }} />
      </StyledButton>
    </Dropdown >
  )
}