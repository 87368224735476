import { Alert } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { StyledButton } from 'src/styled_components/StyledButton'
import Text from "antd/lib/typography/Text"
import dayjs from "dayjs"
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { useTrialReminderAlert } from "src/utils/hooks/useTrialReminderAlert"
import { StoreState } from 'src/store/configureStore'
import { useSelector } from 'react-redux'
import useTranslate from 'src/utils/useTranslate'
dayjs.extend(isSameOrAfter)

const ConnectTrialEndsIn = () => {
    const [t] = useTranslate()
    const { push } = useHistory()
    const userInfo = useSelector((state: StoreState) => state.login.loginResponse)
    const { showTrialAlert, remindMeLater, trialEndsIn } = useTrialReminderAlert()

    return (
        (showTrialAlert && trialEndsIn !== null) ?
            <div style={{ display: "flex", justifyContent: "center", gap: 20, alignItems: "center", marginBottom: "0.5rem" }}>
                {/* <Affix> */}
                <Alert type="info" showIcon message={
                    <div style={{ maxWidth: 600, display: "flex", justifyContent: "space-between", gap: 20, alignItems: "center" }}>
                        <Text>{t('billing.trialEnds')} {trialEndsIn} {t('billing.days')}</Text>
                        <div style={{ display: "flex", justifyContent: "space-around", gap: 20, alignItems: "center" }}>
                            {
                                (trialEndsIn > 1) &&
                                <StyledButton onClick={() => remindMeLater()} disabled={dayjs(userInfo?.license_period_end).startOf("day").isSame(dayjs().startOf("day"))}>{t('common.remindMeLater')}</StyledButton>
                            }
                            <StyledButton
                                onClick={() => {
                                    push("/settings?tab=billing")
                                }}>{t('billing.addCardDetails')}</StyledButton>
                        </div>
                    </div>
                }>
                </Alert>
                {/* </Affix> */}
            </div>
            : null
    )
}

export default ConnectTrialEndsIn