import React, { useCallback, useMemo } from "react";
import {
  Form,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
  Table,
  Input, Popconfirm,
} from "antd";
import { StyledButton } from "src/styled_components/StyledButton";
import "mathlive";
import "./CustomML.scss";
import { MathJax } from "better-react-mathjax"
import { useParams } from "react-router-dom";
import useTranslate from "src/utils/useTranslate";

interface Item {
  key: string;
  variable: string;
  type: number;
  operator: string;
  value: string;
  valueType: string;
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editable: boolean;
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'equation' | 'text';
  record: Item;
  index: number;
  data: any[];
  varsData: any[];
  update: any;
}

const ConstraintsEditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  record,
  index,
  dataIndex,
  children,
  editable,
  title,
  update,
  data,
  varsData,
  ...restProps
}) => {
  const [t] = useTranslate()

  const onVariableChange = useCallback((variable: string) => {
    const newData = data.map((_variable: any, _index: number) => index === _index ? { ..._variable, variable } : _variable)
    update(newData)
  }, [data, index, update])

  const onOperatorChange = useCallback((operator: string) => {
    const newData = data.map((_variable: any, _index: number) => index === _index ? { ..._variable, operator } : _variable)
    update(newData)
  }, [data, index, update])

  const onValueMinChange = useCallback((valueMin: string) => {
    const newData = data.map((_variable: any, _index: number) => index === _index ? { ..._variable, valueMin } : _variable)
    update(newData)
  }, [data, index, update])

  const onValueMaxChange = useCallback((valueMax: string) => {
    const newData = data.map((_variable: any, _index: number) => index === _index ? { ..._variable, valueMax } : _variable)
    update(newData)
  }, [data, index, update])

  const onValueChange = useCallback((value: string) => {
    const newData = data.map((_variable: any, _index: number) => index === _index ? { ..._variable, value } : _variable)
    update(newData)
  }, [data, index, update])

  const onValueTypeChange = useCallback((e: RadioChangeEvent) => {
    const newData = data.map((constraint: any, _index: number) => index === _index ? { ...constraint, valueType: e.target.value } : constraint)
    update(newData)
  }, [data, index, update])


  const varsOpts = useMemo(() => {
    return varsData?.map((variable) => ({ value: variable?.variable, label: <MathJax dynamic={true}>{`\\(${variable?.variable}\\)`}</MathJax> })) || []
  }, [varsData])

  const field = useMemo(() => {
    if (!editable) {
      return children
    }

    switch (dataIndex) {
      case "variable":
        return (
          <Form.Item
            name={dataIndex + record.key}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            <Select
              defaultValue={record[dataIndex]}
              style={{ width: 80 }}
              onChange={onVariableChange}
              options={varsOpts}
            />
          </Form.Item>
        )

      case "valueType":
        return (
          <Form.Item
            name={dataIndex + record.key}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            <Radio.Group onChange={onValueTypeChange} defaultValue={record[dataIndex]}>
              <Space>
                <Radio value={"variable"}>{t("variable")}</Radio>
                <Radio value={"constant"}>{t("constant")}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        )

      case "operator":
        return (
          <Form.Item
            name={dataIndex + record.key}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            <Select
              defaultValue={record[dataIndex]}
              style={{ width: 80 }}
              onChange={onOperatorChange}
              options={[
                { value: '=', label: '=' },
                { value: '<=', label: '<=' },
                { value: '>=', label: '>=' },
                { value: '<', label: '<' },
                { value: '>', label: '>' },
                { value: 'range', label: 'Range' },
              ]}
            />
          </Form.Item>
        )

      case "value":
        if (data[index].valueType === "constant" && data[index].operator === "range") {
          return (<Space>
            <Form.Item
              name={dataIndex + record.key + "min"}
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ]}
              noStyle
            >
              <Input defaultValue={(record as any)[`${dataIndex}Min`]} onChange={e => { onValueMinChange(e.target.value) }} />
            </Form.Item>{"-"}
            <Form.Item
              name={dataIndex + record.key + "max"}
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ]}
              noStyle
            >
              <Input defaultValue={(record as any)[`${dataIndex}Max`]} onChange={e => { onValueMaxChange(e.target.value) }} />
            </Form.Item>
          </Space>)
        } else if (data[index].valueType === "constant") {
          return (
            <Form.Item
              name={dataIndex + record.key}
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ]}
            >
              <Input defaultValue={(record as any)[dataIndex]} onChange={e => { onValueChange(e.target.value) }} />
            </Form.Item>
          )
        } else {
          return (
            <Form.Item
              name={dataIndex + record.key}
              style={{ margin: 0 }}
              rules={[
                {
                  required: true,
                  message: `Please Input ${title}!`,
                },
              ]}
            >
              <Select
                value={record[dataIndex]}
                defaultValue={record[dataIndex]}
                style={{ width: 80 }}
                onChange={onValueChange}
                options={varsOpts}
              />
            </Form.Item>
          )
        }

      default:
        return (
          <Form.Item
            name={dataIndex + record.key}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            <Input defaultValue={(record as any)[dataIndex]} />
          </Form.Item>
        )
    }

  }, [children, data, dataIndex, editable, index, onOperatorChange, onValueChange, onValueMaxChange, onValueMinChange, onValueTypeChange, onVariableChange, record, title, varsOpts, t])

  return (
    <td {...restProps}>
      {field}
    </td>
  );
};

export const Constraints = ({ data, varsData, add, remove: _remove, update }: any) => {
  const [t] = useTranslate();
  const [form] = Form.useForm();
  const { runid } = useParams<{ runid: string }>()

  const remove = (key: string) => {
    _remove(key)
  };

  const defaultColumns = [
    {
      title: 'Variable',
      dataIndex: 'variable',
      width: '15%',
      editable: true,
    },
    {
      title: 'Operator',
      dataIndex: 'operator',
      width: '5%',
      editable: true,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      width: '15%',
      editable: true,
    },
    {
      title: 'Value Type',
      dataIndex: 'valueType',
      width: '30%',
      editable: true,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_: any, record: Item) => {
        return (
          <Popconfirm title="Sure to remove?" onConfirm={() => remove(record.key)}>
            <StyledButton style={{ border: "none", padding: 0 }} type="link">{t("common.remove")}</StyledButton>
          </Popconfirm>
        );
      },
    },
  ];

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item, index: number | undefined) => ({
        record,
        index,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        data,
        varsData,
        update
      }),
    };
  });

  return (
    <Form form={form} component={false} disabled={!!runid && runid !== "new"}>
      <Table
        style={{ marginTop: 16 }}
        components={{
          body: {
            cell: ConstraintsEditableCell,
          },
        }}
        dataSource={data}
        columns={columns}
        rowClassName="editable-row"
        pagination={false}
        footer={() => <StyledButton disabled={!!runid && runid !== "new"} size="small" onClick={add}>{`+ ${t("common.add")}`}</StyledButton>}
        size="small"
      />
    </Form>
  );
};
