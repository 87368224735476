import React, { Dispatch, SetStateAction, useMemo, useState } from "react";
import { Popover, Row, Select, Typography } from "antd";
import { CloseOutlined, FilterOutlined } from "@ant-design/icons";
import { StoreState } from "src/store/configureStore";
import { useSelector } from "react-redux";
import useTranslate from "src/utils/useTranslate";
import { StyledButton } from "src/styled_components/StyledButton";
import { useMemberName } from "src/utils/useMemberName";
import { antdTheme } from "src/constants";
const { Text } = Typography;
const { Option } = Select;

type ProjectFilterKey = "member" | "project_owner" | "tags" | "showOnlyPolymers";

export type ProjectFilter = {
  [key in ProjectFilterKey]: string | string[] | null | boolean;
};

type Props = {
  filters: ProjectFilter;
  setFilters: Dispatch<SetStateAction<ProjectFilter>>;
};

export const ProjectFilters = ({ filters, setFilters }: Props) => {
  const [t] = useTranslate();
  const [filterVisible, setFilterVisible] = useState(false);
  const teams = useSelector((state: StoreState) => state.teams?.data);
  const projects = useSelector(
    (state: StoreState) => state.projects.projectList
  );
  const { getName } = useMemberName();
  const projectOwners = useMemo(() => {
    if (!projects) return [];
    return [
      ...new Set(
        projects.map((project) => project.created_by).filter((res) => !!res)
      ),
    ];
  }, [projects]);

  const filtersOverlay = useMemo(
    () => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: ".5rem",
        }}
      >
        <Select
          allowClear
          placeholder={t("common.filterbyMember")}
          value={filters.member}
          onChange={(member) => {
            setFilters((prevState) => ({ ...prevState, member }));
          }}
          style={{ width: 300 }}
        >
          {teams?.map((team, i) => (
            <Option key={i} value={team.user_id}>
              {team.user_name}
            </Option>
          ))}
        </Select>

        <Select
          allowClear
          value={filters.project_owner}
          placeholder={t("projects.filterByProjectOwner")}
          onChange={(project_owner) => {
            setFilters((prevState) => ({ ...prevState, project_owner }));
          }}
          style={{ width: 300 }}
        >
          {projectOwners.map((owner, i) => (
            <Option key={i} value={owner}>
              {getName(owner)}
            </Option>
          ))}
        </Select>

        <Select
          allowClear
          mode="multiple"
          placeholder={t("common.enterTags")}
          value={filters.tags as string[]}
          onChange={(tags: string[]) => {
            setFilters((prevState: any) => ({ ...prevState, tags }));
          }}
          style={{ width: 300 }}
        >
          {[...new Set(projects.flatMap((project) => project.tags))]
            ?.filter((res) => res)
            ?.map((tag, i) => (
              <Option key={i} value={tag}>
                {tag}
              </Option>
            ))}
        </Select>
      </div>
    ),
    [filters, getName, projectOwners, setFilters, t, teams, projects]
  );

  return (
    <Popover
      style={{ zIndex: 5 }}
      content={filtersOverlay}
      placement="bottomLeft"
      title={
        <Row justify="space-between" style={{ padding: "5px" }}>
          <Text strong>{t("common.filters")}</Text>
          <CloseOutlined
            onClick={() => setFilterVisible(false)}
            style={{ fontSize: antdTheme.fontSizeHeading5, cursor: "pointer", marginTop: "4px" }}
          />
        </Row>
      }
      visible={filterVisible}
    >
      <StyledButton
        type="primary"
        ghost
        icon={<FilterOutlined />}
        onClick={() => {
          setFilterVisible(true);
        }}
      >
        {t("common.filters")}
      </StyledButton>
    </Popover>
  );
};
