import { Suspense, useEffect, useMemo } from "react";
import { Switch, Route, Link, Redirect, useLocation } from "react-router-dom";
import AppSkeleton, { SideBar } from "../components/AppSkeleton/AppSkeleton";
import { useSelector } from "react-redux";
import { StoreState } from "../store/configureStore";
import { LoginProgress } from "../store/reducers/login";
import { Result } from "antd";
import { AsyncStates, currentPlatform } from "src/constants";
import Spinner from "src/components/Spinner/Spinner";
import { RouteObj, getRoutes } from "./routes";
import { history } from "src";
import { AnimatePresence } from "framer-motion";
import { LoadingOutlined } from "@ant-design/icons";
import SignUp from "src/components/SignUp/SignUp";
import Widget from "src/components/Widget/Widget";
import { useVariableValue } from "@devcycle/devcycle-react-sdk";
import { Layout } from 'antd';
import "./AppRouter.scss"
import useTranslate from "src/utils/useTranslate";

const { Content } = Layout;

export const OPEN_SIDEBAR_WIDTH = 275;
export const COLLAPSED_SIDEBAR_WIDTH = 80;

const NoMatch = () => {
  const [t] = useTranslate()
  return (
  <Result
    style={{ height: "80vh", display: "flex", flexDirection: "column" }}
    status="404"
    title="Work in Progress!"
    subTitle=""
    extra={
      <Link type="primary" to="/formulations">
        {t("backHome")}
      </Link>
    }
  />
)};

const UnprotectedRoute = (props: RouteObj) => {
  const token = useSelector<StoreState, string>((state) => {
    return state.login.loginResponse.user_email;
  });
  const isfirstLogin = useSelector((state: StoreState) => state.login.loginResponse?.is_first_login);
  const isUserVerified = useSelector((state: StoreState) => state.login.loginResponse?.user_verified);
  const isUserAccessAllowed = useSelector((state: StoreState) => state.login.loginResponse?.user_access)

  if (!!token && !props.path.includes("/reset-password")) { // Allow only reset-password page to be visited when there is a token
    if (isfirstLogin && isUserVerified && isUserAccessAllowed) {
      history.push("/update-password");
      return null;
    } else {
      history.push("/formulations");
      return null;
    }
  } else {
    return <Route {...props} />;
  }
};

const ProtectedRoute = (props: RouteObj) => {

  const token = useSelector<StoreState, string>((state) => {
    return state.login.loginResponse.user_email;
  });
  const status = useSelector((state: StoreState) => state.displayNames.status);
  const isfirstLogin = useSelector((state: StoreState) => state.login.loginResponse?.is_first_login);
  const isUserVerified = useSelector((state: StoreState) => state.login.loginResponse?.user_verified);
  const isUserAccessAllowed = useSelector((state: StoreState) => state.login.loginResponse?.user_access)

  const {
    projectListStatus,
    current: currentProject,
  } = useSelector((state: StoreState) => state.projects);

  const configsStatus = useSelector(
    (state: StoreState) => state.configs.configsStatus
  );

  useEffect(() => {
    if (isfirstLogin && isUserVerified && isUserAccessAllowed) {
      history.push("/update-password");
    } else if (!currentProject && projectListStatus === AsyncStates.SUCCESS) {
      history.push("/project/update");
    }
  }, [currentProject, isUserAccessAllowed, isUserVerified, isfirstLogin, projectListStatus])

  if (!token) {
    history.push("/login");
    return null;
  }

  if ((props.path === "/update-password") && !isfirstLogin && (isUserVerified && isUserAccessAllowed)) {
    history.push("/formulations");
    return null;
  }

  if (!props.enable && configsStatus === AsyncStates.SUCCESS) {
    return <NoMatch />;
  }

  return token ? (
    status !== AsyncStates.SUCCESS ? (
      <Spinner />
    ) : (
      <AppSkeleton>
        <Route {...props} />
      </AppSkeleton>
    )
  ) : null;
};

export const SectionLoader = () => {
  return (
    <Result
      icon={<LoadingOutlined />}
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    />
  );
};

const AppRouter = (props: any) => {
  const { isDark, toggleDarkTheme } = props
  const location = useLocation();
  const sem_flag = useVariableValue("sem", false);
  const signInProgress = useSelector<StoreState, string>((state) => {
    return state.login.loginResponse.user_email;
  });

  const { modules: navbarConfigs } = useSelector(
    (state: StoreState) => state.configs
  );

  const companyId = useSelector(
    (state: StoreState) => state.login.loginResponse.company_id,
  )

  const activeRoutes = useMemo(() => {
    return getRoutes(navbarConfigs, companyId, sem_flag, isDark, toggleDarkTheme);
  }, [navbarConfigs, companyId, sem_flag, isDark, toggleDarkTheme]);

  const collapsed = useSelector((state: StoreState) => state.sidebar.collapsed);
  const siderWidth = useMemo(() => (collapsed ? COLLAPSED_SIDEBAR_WIDTH : OPEN_SIDEBAR_WIDTH), [collapsed]);

  const token = useSelector<StoreState, string>((state) => state.login.loginResponse.user_email);
  const status = useSelector((state: StoreState) => state.displayNames.status);

  const isfirstLogin = useSelector((state: StoreState) => state.login.loginResponse?.is_first_login);

  return (
    <AnimatePresence exitBeforeEnter>
      <Layout className="app__container">
        {token ? status !== AsyncStates.SUCCESS ? <Spinner /> :
          isfirstLogin || location?.pathname === "/reset-password" ? null : // Allow only reset-password page and isfirstLogin is true then only hide the sidebar
            <>
              {currentPlatform === "connect" ? null : <Widget />}
              <SideBar siderWidth={siderWidth} />
            </>
          : null}

        <Layout className="app__content__layout__container">
          <Content>
            <Switch key={location.pathname} location={location}>
              {Object.entries(activeRoutes).map(([key, route]: [any, any]) => {
                return !route.protectedRoute ? (
                  <UnprotectedRoute {...route} key={key} />
                ) : (
                  <ProtectedRoute {...route} key={key} />
                );
              })}

              {signInProgress === LoginProgress.IN_PROGRESS && (
                <Suspense fallback={<SectionLoader />}>
                  <Route exact path="/signup" render={() => <SignUp />} />
                </Suspense>
              )}
              <Suspense fallback={<SectionLoader />}>
                <Route
                  exact
                  path="/"
                  render={() => {
                    return (
                      <Redirect to="/formulations" />
                      // <Redirect to="/dashboard" />
                    );
                  }}
                />
                <Route component={NoMatch} />
              </Suspense>
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </AnimatePresence>
  );
};

export default AppRouter;
