import { Col, Row, Space, Checkbox, Form, Spin, Cascader } from "antd";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { StyledCard } from "src/styled_components/StyledCard";
import { PolynomialRegressor } from '@rainij/polynomial-regression-js';
import { LoadingOutlined } from "@ant-design/icons";
import useTranslate from "src/utils/useTranslate";
import { surfacePlotRequest } from "src/store/actions/dataSummary";
import { AsyncStates } from "src/constants";
import { PlotType } from "../Analytics/enums";
import { useValue } from "src/utils/useValue";
require("highcharts/highcharts-3d")(Highcharts);
require("highcharts/modules/heatmap")(Highcharts)
require("highcharts-coloraxis-bands")(Highcharts)
require("highcharts-contour")(Highcharts)
require("highcharts-draggable-3d")(Highcharts)

const fastGridWidth = 7;
const prettyGridWidth = 21;

export type SurfacePlotTypes = {
  experiments: any, selectedExperiments: string[], workOrderIds: string[],
  setSaveGraphsPayload: any,
  selectedPlotsData: { [key: string]: any },
  selectedFormulations: string[]

}

export const SurfacePlot = memo(({ selectedExperiments, workOrderIds, setSaveGraphsPayload, selectedPlotsData, selectedFormulations }: SurfacePlotTypes) => {
  const [t] = useTranslate()
  const dispatch = useDispatch()
  const displayNames = useSelector((state: StoreState) => state.displayNames.data)
  const configs = useSelector((state: StoreState) => state.configs.features)
  const { surfacePlotData, surfacePlotStatus } = useSelector((state: StoreState) => state.dataSummary)
  const { plotOptions, plotOptionsFormulationDisplayNames } = useSelector((state: StoreState) => state.dataSummary.dataSummaryPlotOptions)
  const chartRef = useRef<any>(null)
  const [Xaxis, setXaxis] = useState<string[]>([])
  const [Zaxis, setZaxis] = useState<string[]>([])
  const [Yaxis, setYaxis] = useState<string[]>([])
  const [mode2d, setMode2d] = useState(false)

  const { getValue: getEUValue } = useValue()

  const getValue = (value: any) => value !== '' ? value : null

  const setGridWidth = useCallback((grid_width: number) => { setHighchartsOptions((state: any) => ({ ...state, series: [{ ...state.series?.[0], grid_width }] as any })) }, [])

  const [model, setModel] = useState<any>()

  const costModExperiments = useMemo(() => surfacePlotData.map((exp: any) => ({ ...exp, cost: { total_cost: { value: exp?.cost?.total_cost } } })), [surfacePlotData])

  useEffect(() => {
    if (!!Xaxis.length && !!Yaxis.length && !!Zaxis.length) {
      const paramTypes = ["properties", "processing"]
      const payload = {
        "x": { "type": Xaxis[0], "value": Xaxis.at(-1), ...((Boolean(configs?.nestle_configs) && (paramTypes.includes(Xaxis[0]))) && { category: Xaxis[1] }) },
        "y": { "type": Yaxis[0], "value": Yaxis.at(-1), ...((Boolean(configs?.nestle_configs) && (paramTypes.includes(Yaxis[0]))) && { category: Yaxis[1] }) },
        "z": { "type": Zaxis[0], "value": Zaxis.at(-1), ...((Boolean(configs?.nestle_configs) && (paramTypes.includes(Zaxis[0]))) && { category: Zaxis[1] }) },
        ...(!!selectedFormulations.length ? { formulation_ids: selectedFormulations } : { experiment_ids: selectedExperiments }),
        work_order_id: workOrderIds
      }
      dispatch(surfacePlotRequest(payload))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Xaxis, Yaxis, Zaxis, dispatch])

  useEffect(() => {
    if (!!Xaxis?.length && !!Yaxis?.length && !!Zaxis?.length) {
      setSaveGraphsPayload((prev: any) => ({
        ...prev,
        [PlotType.SURFACE_PLOT]: {
          "x": Xaxis,
          "y": Yaxis,
          "z": Zaxis
        }
      }))
    } else {
      setSaveGraphsPayload((prev: any) => {
        if (prev?.[PlotType.SURFACE_PLOT]) {
          delete prev[PlotType.SURFACE_PLOT]
          return prev
        }
        return prev
      })
    }

  }, [Xaxis, Yaxis, Zaxis, setSaveGraphsPayload])

  const checkPropertyExist = useCallback((variation_id: string, propertyIdentifier: string) => {
    return plotOptions?.variation_data?.properties?.[variation_id]?.properties?.includes(propertyIdentifier)
  }, [plotOptions?.variation_data?.properties])

  const setAxis = (category: string, axis: string[]) => {
    switch (category) {
      case "xaxis":
        setXaxis(axis);
        break;
      case "yaxis":
        setYaxis(axis);
        break;
      case "zaxis":
        setZaxis(axis);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (Object.keys(selectedPlotsData?.data?.plot_data ?? {}).includes(PlotType.SURFACE_PLOT)) {
      const surfacePlotData = selectedPlotsData?.data?.plot_data?.[PlotType.SURFACE_PLOT] || {};
      const setIfValid = (axis: string[], category: string) => {
        const allowIngredients = Boolean(configs?.nestle_configs) ? (axis?.[0] === "ingredients") : plotOptions[axis?.[0]]?.includes(axis?.[1])
        if (allowIngredients) {
          if (plotOptions[axis?.[0]]?.includes(axis?.[1])) {
            setAxis(category, axis || []);
          } else {
            setAxis(category, axis || []);
          }
        } if (axis?.[0] === "properties" && checkPropertyExist(axis?.[1], axis?.[axis?.length - 1])) {
          setAxis(category, axis || []);
        }
      };

      setIfValid(surfacePlotData?.x, "xaxis");
      setIfValid(surfacePlotData?.y, "yaxis");
      setIfValid(surfacePlotData?.z, "zaxis");
    }
  }, [checkPropertyExist, configs?.nestle_configs, plotOptions, selectedPlotsData]);

  const nestleData = useCallback(() => (Xaxis.at(-1) && Zaxis.at(-1) && Yaxis.at(-1) ? costModExperiments?.map((exp: any, index: number) => {
    return ({
      exp: exp?.formulation_display_id ?? exp?.formulation_id,
      xName: exp?.x?.parameter ?? Xaxis.at(-1),
      yName: exp?.z?.parameter ?? Zaxis.at(-1),
      x: getValue(exp?.x?.value),
      y: getValue(exp?.y?.value),
      z: getValue(exp?.z?.value)
    })
  }
  ) : []), [Xaxis, Zaxis, Yaxis, costModExperiments])

  const generalData = useCallback(() => (Xaxis.at(-1) && Zaxis.at(-1) && Yaxis.at(-1) ? costModExperiments?.map((exp: any, index: number) => {
    return ({
      exp: exp?.formulation_display_id ?? exp?.formulation_id,
      xName: exp?.x?.parameter ?? Xaxis.at(-1),
      yName: exp?.z?.parameter ?? Zaxis.at(-1),
      x: getValue(exp?.x?.value),
      y: getValue(exp?.y?.value),
      z: getValue(exp?.z?.value)
    })
  }
  ) : []), [Xaxis, Zaxis, Yaxis, costModExperiments])

  const data = useMemo(() => {
    return Boolean(configs?.nestle_configs) ? nestleData() : generalData()
  }, [configs, generalData, nestleData])

  const formatAxisLabel = useCallback((value: string | number) => {
    return getEUValue(value)
  }, [getEUValue])

  const err = useMemo(() => {
    let err
    const x = data
      // .filter(({ x, y, z }: any) => x && y && z)
      .filter(({ x, y, z }: any) => typeof x === 'number' && typeof y === 'number' && typeof z === 'number')
      .map(({ x, z }: any) => {
        return [x, z]
      })
    const actual = data
      // .filter(({ x, y, z }: any) => x && y && z)
      .filter(({ x, y, z }: any) => typeof x === 'number' && typeof y === 'number' && typeof z === 'number')
      .map(({ y }: any) => {
        return y
      })
    const count = actual?.length
    if (model && count) {
      const mean = actual.reduce((agg: number, cur: number) => cur + agg, 0) / count
      const estimated = model?.predict(x)?.map((arr: number[]) => arr[0]) ?? 0
      const num = Number(Array.from({ length: count }).reduce((agg: any, cur: any, index: number) => Math.pow(actual[index] - estimated[index], 2) + agg, 0))
      const den = Number(Array.from({ length: count }).reduce((agg: any, cur: any, index: number) => Math.pow(actual[index] - mean, 2) + agg, 0))
      err = 1 - (num / den)
    }
    return err
  }, [data, model])

  const _3DChartConfigs = useMemo(() => ({
    chart: {
      margin: 125,
      marginRight: 175,
      marginBottom: 175,
      options3d: {
        enabled: true,
        // alpha: 30,
        // beta: 45,
        depth: 250,
        fitToPlot: false,
        axisLabelPosition: 'auto',
        drag: {
          enabled: true,
          minBeta: Number.NEGATIVE_INFINITY,
          maxBeta: Number.POSITIVE_INFINITY,
          snap: 15,
          animateSnap: true,
          beforeDrag: function () {
            setGridWidth(fastGridWidth)
          },
          afterDrag: function () {
            setGridWidth(prettyGridWidth)
          }
        },
        frame: {
          size: 10,
          visible: 'auto',
        }
      }
    },
  }), [setGridWidth])

  const _2DChartConfigs = useMemo(() => ({
    chart: {
      marginRight: 175,
      options3d: {
        enabled: false,
        // alpha: 30,
        // beta: 45,
        // depth: 250,
        fitToPlot: false,
        axisLabelPosition: 'auto',
        drag: {
          enabled: true,
          minBeta: Number.NEGATIVE_INFINITY,
          maxBeta: Number.POSITIVE_INFINITY,
          snap: 15,
          animateSnap: true,
          beforeDrag: function () {
            setGridWidth(fastGridWidth)
          },
          afterDrag: function () {
            setGridWidth(prettyGridWidth)
          }
        },
        frame: {
          size: 10,
          visible: 'auto',
        }
      }
    },
    tooltip: {
      pointFormatter: function (this: any) {
        const point = this
        return `X: <b>${getEUValue(point.x.toFixed(1))}</b><br/>Y: <b>${getEUValue(point.z.toFixed(1))}</b>`;
      },
      // pointFormat: 'X: <b>{point.x:.1f}</b><br/>Y: <b>{point.y:.1f}</b>',
    },
  }), [setGridWidth, getEUValue])

  const [highchartsOptions, setHighchartsOptions] = useState<Highcharts.Options | any>(() => {
    return {
      credits: {
        enabled: false
      },
      chart: {
        margin: 125,
        marginRight: 175,
        marginBottom: 175,
        options3d: {
          enabled: true,
          alpha: 30,
          beta: 45,
          depth: 250,
          fitToPlot: false,
          axisLabelPosition: 'auto',
          drag: {
            enabled: true,
            minBeta: Number.NEGATIVE_INFINITY,
            maxBeta: Number.POSITIVE_INFINITY,
            snap: 15,
            animateSnap: true,
            beforeDrag: function () {
              setGridWidth(fastGridWidth)
            },
            afterDrag: function () {
              setGridWidth(prettyGridWidth)
            }
          },
          frame: {
            size: 10,
            visible: 'auto',
          }
        }
      },
      title: {
        text: err ? `<small style="color: red">R2 ${t("graphs.score")}: ${getEUValue(err?.toPrecision(2))}</small>` : undefined,
        useHTML: err ? true : false,
        align: 'left'
      },
      subtitle: {
        text: '',
      },
      tooltip: {
        pointFormatter: function (this: any) {
          const point = this
          return `X: <b>${getEUValue(point.x.toFixed(1))}</b><br/>Y: <b>${getEUValue(point.z.toFixed(1))}</b><br/>Z: <b>${getEUValue(point.y.toFixed(1))}</b>`;
        },
      },
      legend: {
        layout: 'horizontal',
        verticalAlign: 'middle',
        align: 'right',
        padding: 30
      },
      yAxis: {
        title: {
          text: 'Axis-Y'
        },
        labels: {
          skew3d: true,
          position3d: 'flap',
          formatter: (obj: any) => {
            if (obj.value % 1) {
              return formatAxisLabel(obj.value.toFixed(3))
            }
            return formatAxisLabel(obj.value)
          }
        },
        tickPixelInterval: 30,
        minPadding: 0.05,
        maxPadding: 0.05,
      },
      xAxis: {
        title: {
          text: 'Axis-X',
        },
        labels: {
          skew3d: true,
          position3d: 'flap',
          formatter: (obj: any) => {
            if (obj.value % 1) {
              return formatAxisLabel(obj.value.toFixed(3))
            }
            return formatAxisLabel(obj.value)
          }
        },
        tickPixelInterval: 30,
        minPadding: 0,
        maxPadding: 0,
        min: -10,
        max: 10,
      },
      zAxis: {
        title: {
          text: 'Axis-Z',
        },
        labels: {
          skew3d: true,
          position3d: 'flap',
          formatter: (obj: any) => {
            if (obj.value % 1) {
              return formatAxisLabel(obj.value.toFixed(3))
            }
            return formatAxisLabel(obj.value)
          }
        },
        tickPixelInterval: 30,
        minPadding: 0,
        maxPadding: 0,
        min: -10,
        max: 10,
      },
      colorAxis: {
        layout: 'vertical',
        stops: [
          [0.0, '#3060cfaa'],
          [0.5, '#fffbbcaa'],
          [0.9, '#c4463aaa']
        ],
        banding: 0.5,
        labels: {
          formatter: (obj: any) => {
            if (obj.value % 1) {
              return formatAxisLabel(obj.value.toFixed(3))
            }
            return formatAxisLabel(obj.value)
          }
        }
        // tickPositioner: function (): any {
        //   if (chart) {
        //     return chart?.yAxis?.[0]?.tickPositions?.slice()
        //   }
        // }
      }
    }
  })

  const [errorMsg, setErrorMsg] = useState("")

  useEffect(() => {
    if (!!data?.length) {
      const x = data
        .filter(({ x, y, z }: any) => typeof x === 'number' && typeof y === 'number' && typeof z === 'number')
        .map(({ x, z }: any) => {
          return [x, z]
        })
      const y = data
        .filter(({ x, y, z }: any) => typeof x === 'number' && typeof y === 'number' && typeof z === 'number')
        .map(({ y }: any) => {
          return [y]
        })
      const model = new PolynomialRegressor(2);
      try {
        model.fit(x, y) // Training
        setModel(model);
        setErrorMsg("")
        setMode2d(false)
      } catch (e: any) {
        setModel(undefined);
        setErrorMsg(e.message)
      }
    }
  }, [data])

  const getBoundNestle = useCallback((bound: 'min' | 'max', axis: string) => {
    const val = Math[bound](
      ...costModExperiments?.map((exp: any) => getValue(exp?.[axis]?.value))
    )
    return val
  }, [costModExperiments])

  const getBoundGeneral = useCallback((bound: 'min' | 'max', axis: string) => {
    const val = Math[bound](
      ...costModExperiments?.map((exp: any) => getValue(exp?.[axis]?.value))
    )
    return val
  }, [costModExperiments])

  const getBound = useCallback((bound: 'min' | 'max', axis: string) => {
    return Boolean(configs?.nestle_configs) ? getBoundNestle(bound, axis) : getBoundGeneral(bound, axis)
  }, [configs, getBoundGeneral, getBoundNestle])

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!loading && surfacePlotStatus === AsyncStates.LOADING) {
      setLoading(true)
    }
  }, [loading, surfacePlotStatus])


  const getDisplayNames = useMemo(() => {
    return {
      ...displayNames['ingredients'], ...displayNames['processing'], ...displayNames['properties'],
      ...Object.keys(plotOptionsFormulationDisplayNames || {}).reduce((acc, curr) => {
        return {
          ...acc,
          [curr]: {
            name: plotOptionsFormulationDisplayNames[curr]
          }
        }
      }, {}),
      total_cost: { name: 'Formulation' }
    }
  }, [displayNames, plotOptionsFormulationDisplayNames])

  useEffect(() => {
    setLoading(true)
    const delay = setTimeout(() => {
      if (model && surfacePlotStatus === AsyncStates.SUCCESS) {
        if (mode2d) {
          setHighchartsOptions((state: any) => {
            const { chart, ...otherChartOptions } = state
            return ({
              ...otherChartOptions,
              ..._2DChartConfigs,
              title: {
                text: err ? `<small style="color: red">R2 ${t("graphs.score")}: ${getEUValue(err?.toPrecision(2))}</small>` : undefined,
                useHTML: err ? true : false,
                align: 'left'
              },
              xAxis: {
                ...state?.xAxis,
                title: {
                  text: (getDisplayNames?.[Xaxis.at(-1) as any]?.name ?? Xaxis.at(-1)) + `${Xaxis.at(-2) === 'cost' ? ' Cost' : ''}`
                },
                min: getBound("min", "x"),
                max: getBound("max", "x")
              },
              yAxis: {
                ...state?.yAxis,
                title: {
                  text: (getDisplayNames?.[Yaxis.at(-1) as any]?.name ?? Yaxis.at(-1)) + `${Yaxis.at(-2) === 'cost' ? ' Cost' : ''}`
                },
                min: getBound("min", "y"),
                max: getBound("max", "y")
              },
              zAxis: {
                ...state.zAxis,
                visible: false,
                title: {
                  ...state?.zAxis?.title,
                  text: (getDisplayNames?.[Yaxis.at(-1) as any]?.name ?? Yaxis.at(-1)) + `${Yaxis.at(-2) === 'cost' ? ' Cost' : ''}`,
                  enabled: false
                },
                labels: {
                  ...state?.zAxis?.labels,
                  enabled: false
                },
              },
              series: [{
                id: 'contour-series',
                type: 'contour',
                showEdges: true,
                dataFunction: (coord: any) => {
                  const value = model?.predict([[coord.x, coord.y]])[0][0] ?? null
                  if (data.some(({ y }: any) => y < 0)) {
                    return value
                  } else {
                    if (value < 0) {
                      return null
                    } else {
                      return value
                    }
                  }
                },
                grid_width: prettyGridWidth,
                interpolateTooltip: true,
                contours: ["value"],
              }]
            })
          })
        }
        else {
          setHighchartsOptions((state: any) => {
            const { chart, ...otherChartOptions } = state
            return ({
              ...otherChartOptions,
              ..._3DChartConfigs,
              title: {
                text: err ? `<small style="color: red">R2 ${t("graphs.score")}: ${getEUValue(err?.toPrecision(2))}</small>` : undefined,
                useHTML: err ? true : false,
                align: 'left'
              },
              xAxis: {
                ...state.xAxis,
                title: {
                  text: (getDisplayNames?.[Xaxis.at(-1) as any]?.name ?? Xaxis.at(-1)) + `${Xaxis.at(-2) === 'cost' ? ' Cost' : ''}`
                },
                min: getBound("min", "x"),
                max: getBound("max", "x")
              },
              yAxis: {
                title: {
                  text: (getDisplayNames?.[Zaxis.at(-1) as any]?.name ?? Zaxis.at(-1)) + `${Zaxis.at(-2) === 'cost' ? ' Cost' : ''}`
                },
                labels: {
                  skew3d: true,
                  position3d: 'flap',
                  formatter: (obj: any) => {
                    if (obj.value % 1) {
                      return formatAxisLabel(obj.value.toFixed(3))
                    }
                    return formatAxisLabel(obj.value)
                  }
                },
                tickPixelInterval: 30,
                minPadding: 0.05,
                maxPadding: 0.05,
                min: null,
                max: null
              },
              zAxis: {
                ...state?.zAxis,
                visible: true,
                title: {
                  ...state?.zAxis?.title,
                  text: (getDisplayNames?.[Yaxis.at(-1) as any]?.name ?? Yaxis.at(-1)) + `${Yaxis.at(-2) === 'cost' ? ' Cost' : ''}`,
                  enabled: true
                },
                labels: {
                  ...state?.zAxis?.labels,
                  enabled: true
                },
                min: getBound("min", "y"),
                max: getBound("max", "y")
              },
              series: [{
                id: 'contour-series',
                type: 'contour',
                showEdges: true,
                dataFunction: (coord: any) => {
                  const value = model?.predict([[coord.x, coord.z]])[0][0] ?? null
                  if (data.some(({ y }: any) => y < 0)) {
                    return value
                  } else {
                    if (value < 0) {
                      return null
                    } else {
                      return value
                    }
                  }
                },
                grid_width: prettyGridWidth,
                interpolateTooltip: true,
                contours: ["value"],
              }]
            })
          })
        }
      }
      setLoading(false)
    }, 300)

    return () => {
      clearTimeout(delay)
      setLoading(false)
    }
  }, [setGridWidth, model, data, Xaxis, Yaxis, Zaxis, err, displayNames, surfacePlotStatus, getBound, mode2d, _2DChartConfigs, _3DChartConfigs, getDisplayNames, t, getEUValue, formatAxisLabel])

  const _2DToggle = useCallback(async (e: CheckboxChangeEvent) => {
    setMode2d(e.target.checked)
  }, [])

  const displayRender = (labels: string[]) => labels.join('/')

  const getCascaderOptions = useCallback((parameter: string) => {
    const recipeData: any[] = plotOptions?.[parameter] ?? []

    if (['properties', 'processing'].includes(parameter) && Boolean(configs?.nestle_configs)) {
      if (parameter === "properties") {
        const variations = plotOptions?.variation_data?.properties || {}
        return Object.keys(variations || {}).map((variation) => ({
          value: variation,
          label: variations?.[variation]?.variation_name,
          children: variations?.[variation]?.properties?.map((property: any) => ({
            label: plotOptionsFormulationDisplayNames?.[property] || displayNames['properties'][property]?.name,
            value: property
          }))
        }))
      } else {
        const processingProfiles = plotOptions?.variation_data?.processing || {}
        return [{
          value: "Profile",
          label: "Profile",
          children: Object.keys(processingProfiles || {}).map((profile: any) => ({
            label: processingProfiles?.[profile] ?? profile,
            value: profile
          }))
        }]
      }
    } else if (parameter === 'costing') {
      return [
        { value: 'total_cost', label: 'Formulation Cost' },
        ...plotOptions?.["ingredients"].map((value: any) => ({ value, label: displayNames?.ingredients[value as any]?.name + " Cost" }))
      ]
    } else {
      return [...recipeData?.map(value => ({ value: value, label: displayNames.hasOwnProperty(parameter) ? displayNames[parameter][value]?.name || plotOptionsFormulationDisplayNames?.[value] || value : displayNames['properties'][value]?.name }))]
    }
  }, [displayNames, plotOptions, plotOptionsFormulationDisplayNames, configs?.nestle_configs])

  const options: any = useMemo(() => ([
    {
      ...(!!plotOptions?.["ingredients"]?.length && {
        value: 'ingredients',
        label: t('common.ingredients'),
        children: [...getCascaderOptions('ingredients')]
      }),
    },
    {
      ...(!!plotOptions?.["properties"]?.length && {
        value: 'properties',
        label: t('common.properties'),
        children: [...getCascaderOptions('properties')],
      }),
    },
    {
      ...((!!plotOptions?.["processing"]?.length && !Boolean(configs?.nestle_configs)) && {
        value: 'processing',
        label: t('common.processing'),
        children: [...getCascaderOptions('processing')]
      }),
    },
    {
      ...((!!plotOptions?.["costing"]?.length && !Boolean(configs?.nestle_configs)) && {
        value: 'cost',
        label: t('inventory.costing'),
        children: [...getCascaderOptions('costing')]
      })
    }
  ].filter(values => JSON.stringify(values) !== '{}')), [plotOptions, t, getCascaderOptions, configs?.nestle_configs])

  return (
    <StyledCard title={t('dataSummary.surfacePlot')} id="surface-plot">
      <Space direction="vertical" style={{ width: "100%" }}>
        <Row gutter={[8, 8]}>
          <Form layout="vertical">
            <Space>
              <Form.Item label={`X ${t("graphs.axis")}`}>
                <Cascader style={{ width: 200 }}
                  options={options}
                  value={Xaxis}
                  displayRender={displayRender}
                  showSearch
                  onChange={(value: any) => {
                    setXaxis(value ?? [])
                  }}
                  placeholder={t("common.pleaseSelect")}
                />
              </Form.Item>
              <Form.Item label={`Y ${t("graphs.axis")}`}>
                <Cascader style={{ width: 200 }}
                  options={options}
                  value={Yaxis}
                  displayRender={displayRender}
                  showSearch
                  onChange={(value: any) => {
                    setYaxis(value ?? [])
                  }}
                  placeholder={t("common.pleaseSelect")}
                />
              </Form.Item>
              <Form.Item label={`Z ${t("graphs.axis")}`}>
                <Cascader style={{ width: 200 }}
                  options={options}
                  value={Zaxis}
                  displayRender={displayRender}
                  showSearch
                  onChange={(value: any) => {
                    setZaxis(value ?? [])
                  }}
                  placeholder={t("common.pleaseSelect")}
                />
              </Form.Item>
            </Space>
          </Form>
        </Row>
        <Spin
          style={{ width: '100%' }}
          spinning={(!errorMsg && !!model && loading) || (surfacePlotStatus === AsyncStates.LOADING)}
          indicator={<LoadingOutlined />}
        >
          <Row gutter={[8, 8]} align="middle">
            {/* <Col>
            <Select size="small" value={fittingMethod} onChange={selectFitting}>
              <Select.Option key="standard">Standard</Select.Option>
              <Select.Option key="custom">Custom</Select.Option>
            </Select>
          </Col> */}
            {!!model &&
              <Col>
                <Checkbox checked={mode2d} onChange={_2DToggle}>{t("graphs.plotIn2d")}</Checkbox>
              </Col>
            }
            {/* {fittingMethod === "custom" && (<>
            <Col>
              <Text type="secondary">f(x,y): </Text>
            </Col>
            <Col>
              <StyledInput onChange={(e) => onChangeFittingExpr(e.target.value)} placeholder="Custom function"></StyledInput>
            </Col>
            <Col>
              {!fittingExprErr ? (fittingExprIsApplied ? <CheckOutlined /> : <StyledButton size="small" type="primary" onClick={applyFittingExpr}>Apply</StyledButton>) : <CloseOutlined />}
            </Col>
            <Col>
              <span>{fittingExprErr.toString()}</span>
            </Col>
          </>)} */}
          </Row>
          {/* {err && <Row gutter={[8, 16]}>
          <StyledButton size="small" danger>{`R2 Score: ${err.toPrecision(2)}`}</StyledButton>
        </Row>} */}
          <Row gutter={[8, 16]}>
            {errorMsg}
          </Row>
          <Row gutter={[8, 16]}>
            <Col span={24}>

              {!errorMsg && !!model && <HighchartsReact
                ref={chartRef}
                highcharts={Highcharts}
                options={highchartsOptions}
                containerProps={{ style: { width: '100%' } }}
              />}
            </Col>
          </Row>
        </Spin>
      </Space>
    </StyledCard >
  )
})
