import { useEffect, useState } from "react";
import {
  Space,
  Card,
  Tabs,
  Typography,
  Spin,
  Row,
  Upload,
  message,
  Modal,
  Divider,
  Select,
  Segmented,
} from "antd";
import useTranslate from "src/utils/useTranslate";
import {
  ProjectType,
  TwoFactorAuth,
  UpdatePassword,
  // ProductGuideSettings,
} from "./tabs";
import "../Settings/Settings.scss";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { AsyncStates, currentPlatform } from "src/constants";
import { parseSearchQuery } from "src/utils/general";
import { StyledPageHeader } from "src/styled_components/StyledPageHeader";
import { ConnectBilling } from "./tabs/ConnectBilling/ConnectBilling";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { LanguageSelector } from "../core";
import jwtManager from "src/utils/jwtManager";
import type { UploadFile, UploadProps } from "antd/es/upload";
import { updateLoginResponse } from "src/store/actions/login";
import { LabLocation } from "./tabs/LabLocation/LabLocation";
import { updateNumericalCodeRequest } from "src/store/actions/settings";
import DOMPurify from "dompurify";
import { useVariableValue } from "@devcycle/devcycle-react-sdk";

const { Text } = Typography;

type Tab =
  | "project_type"
  | "reset_password"
  | "product_guide_settings"
  | "two_fa"
  | "billing"
  | "general";

const Settings = (props: any) => {
  const { isDark, toggleDarkTheme } = props
  useEffect(() => {
    localStorage.setItem("isDark", String(isDark))
  }, [isDark])
  const [t] = useTranslate();
  const { user_role: userRole } = useSelector<StoreState, any>(
    (state: StoreState) => state.login.loginResponse
  );
  const dispatch = useDispatch();
  const configs = useSelector((state: StoreState) => state.configs.features);
  const updateNumericCodeStatus = useSelector((state: StoreState) => state.settings.updateNumericCodeStatus);
  const [tab, setTab] = useState<Tab>("general");
  const { billingDetailsStatus } = useSelector(
    (state: StoreState) => state.settings
  );
  const login = useSelector((state: StoreState) => state.login);
  const { user_role } = useSelector(
    (state: StoreState) => state.login.loginResponse
  );

  const [fileList, setFileList] = useState<UploadFile[]>([
    {
      uid: "-1",
      name: "logo",
      status: "done",
      url: login.loginResponse.logo,
    },
  ]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  useEffect(() => {
    setIsModalOpen(localStorage.getItem("isLanguageAnnouncement") === "true")
  }, [])

  useEffect(() => {
    const query = window.location.search.split("?")[1];
    const searchParams =
      parseSearchQuery<{
        tab: string;
      }>(query);

    if (searchParams.tab === "product_guide_settings") {
      setTab("product_guide_settings");
    } else if (
      searchParams.tab === "billing" &&
      userRole === "admin" &&
      currentPlatform === "connect"
    ) {
      setTab("billing");
    }
  }, [userRole]);

  const handleCancel = () => setPreviewOpen(false);

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    const logo_url = newFileList.find(({ uid }) => uid !== "-1")?.response
      ?.result?.data?.logo;
    setFileList([...newFileList.filter(({ uid }) => uid !== "-1")]);
    dispatch(
      updateLoginResponse({
        ...login.loginResponse,
        logo: logo_url,
      })
    );
  };

  const handlePreview = async (file: any) => {
    setPreviewImage(login?.loginResponse?.logo || file.thumbUrl);
    setPreviewOpen(true);
    setPreviewTitle(t('logo.companyLogo'));
  };

  const billingFlag = useVariableValue("billing", false)

  return (
    <>
      <Space size="large" direction="vertical" style={{ width: "100%" }}>
        <StyledPageHeader
          title={t("navBar.menuItem.settings")}
          ghost={false}
        // extra={
        //   <Text type="secondary">{`v${localStorage.getItem(
        //     "version"
        //   )}`}</Text>
        // }
        />

        <Card bodyStyle={{ padding: "16px", paddingLeft: 0 }}>
          <Tabs
            defaultActiveKey="two_fa"
            activeKey={tab}
            onChange={(t) => {
              setTab(t as Tab);
            }}
            tabPosition="left"
            className="settings-tabs"
          >
            <Tabs.TabPane
              tab={t("common.general")}
              key="general"
              tabKey="general"
            >
              <Text>{t("settings.expTheme")}</Text>
              <Row style={{ marginTop: 10 }}>
                <Segmented
                  defaultValue={String(isDark)}
                  value={String(isDark)}
                  options={[{ label: 'Light', value: "false" }, { label: 'Dark', value: "true" }]}
                  onChange={toggleDarkTheme}
                />
              </Row>
              <Divider />
              <Text>{t("settings.selectLanguage")}</Text>
              <Row style={{ marginTop: 10 }}>
                <LanguageSelector />
              </Row>
              <Divider />
              <Spin spinning={updateNumericCodeStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
                <Text>{"Numerical System"}</Text>
                <Row style={{ marginTop: 10 }}>
                  <Select style={{ width: "100%" }} value={configs?.locale ?? "en_US"} onChange={(e) => {
                    dispatch(updateNumericalCodeRequest({ code: e }))
                  }}>
                    <Select.Option value={"en_US"} key={"en_US"}>
                      {"English (en_US)"}
                    </Select.Option>
                    <Select.Option value={"de_DE"} key={"de_DE"}>
                      {"German (de_DE)"}
                    </Select.Option>
                  </Select>
                </Row>
              </Spin>
              <Divider />
              <Row
                style={{
                  marginBlock: "2rem",
                  textAlign: "center",
                }}
              >
                <Text>{t('settings.uploadCompayLogo')}</Text>

                <Upload
                  fileList={fileList}
                  beforeUpload={async (file) => {
                    const { type } = file;
                    if (file?.type === "image/svg+xml") {
                      const fileText = await file?.text();
                      const cleanFileText = DOMPurify.sanitize(fileText);
                      const updatedFile = new File([cleanFileText], file.name, { type: "image/svg+xml" });
                      return updatedFile;
                    }
                    if (!type.includes("image"))
                      message.error(`${file.name} is not an image file`);
                    return file;
                  }}

                  customRequest={async ({ file, action }: any) => {
                    const formData = new FormData();


                    if (file?.type === "image/svg+xml") {
                      const fileText = await file?.text();
                      const cleanFileText = DOMPurify.sanitize(fileText);
                      const updatedFile = new File([cleanFileText], file.name, { type: "image/svg+xml" });
                      formData.append("file", updatedFile);
                    } else {
                      formData.append("file", file);
                    }

                    formData.append('file_type', file?.type)

                    action = `${process.env.REACT_APP_API_URL}v1/data/_update_company_logo`;
                    fetch(action, {
                      method: "POST",
                      headers: {
                        token: jwtManager.getToken() || "",
                      },
                      body: formData,
                    })
                      .then((response) => response.json())
                      .then((result) => {
                        fileList.filter(({ uid }) => uid !== "-1")[0].status = "done"
                        const logo_url = result?.result?.data?.logo;
                        dispatch(
                          updateLoginResponse({
                            ...login.loginResponse,
                            logo: logo_url,
                          })
                        );
                      })
                      .catch((error) => {
                      });


                  }}
                  maxCount={1}
                  onChange={handleChange}
                  onPreview={handlePreview}
                  disabled={user_role !== "admin"}
                  // action={`${process.env.REACT_APP_API_URL}v1/data/_update_company_logo`}
                  listType="picture-card"
                  accept="image"
                  headers={{ token: jwtManager.getToken() || "" }}
                  onRemove={() => {
                    const formData = new FormData();
                    formData.append('file_type', 'image/png')
                    fetch(`${process.env.REACT_APP_API_URL}v1/data/_update_company_logo`, {
                      method: "POST",
                      headers: {
                        token: jwtManager.getToken() || "",
                      },
                      body: formData,
                    })
                      .then((response) => response.json())
                      .then((result) => {
                        fileList.filter(({ uid }) => uid !== "-1")[0].status = "done"
                        const logo_url = result?.result?.data?.logo;
                        dispatch(
                          updateLoginResponse({
                            ...login.loginResponse,
                            logo: logo_url,
                          })
                        );
                      })
                      .catch((error) => {
                      });
                  }}
                >
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>{t('common.upload')}</div>
                  </div>
                </Upload>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={t("common.updatePassword")}
              key="reset_password"
              tabKey="reset_password"
            >
              <UpdatePassword />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t("common.2fa")} key="two_fa" tabKey="two_fa">
              <TwoFactorAuth />
            </Tabs.TabPane>
            {Boolean(configs?.celsure) && (
              <Tabs.TabPane
                tab={t("common.projectType")}
                key="project_type"
                tabKey="project_type"
              >
                <ProjectType />
              </Tabs.TabPane>
            )}
            {/* <Tabs.TabPane
						tab={t("common.productGuideSettings")}
						key="product_guide_settings"
					>
						<ProductGuideSettings />
					</Tabs.TabPane> */}

            {userRole === "admin" && currentPlatform === "connect" && billingFlag && (
              <Tabs.TabPane tab={t("common.billing")} key="billing">
                <Spin
                  spinning={billingDetailsStatus === AsyncStates.LOADING}
                  indicator={<LoadingOutlined />}
                >
                  <ConnectBilling />
                </Spin>
              </Tabs.TabPane>
            )}
            <Tabs.TabPane tab={t("common.labLocations")} key="lab-location">
              <LabLocation />
            </Tabs.TabPane>
          </Tabs>
        </Card>
      </Space>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
      <Modal open={isModalOpen} footer={null} onCancel={() => {
        setIsModalOpen(false)
        localStorage.removeItem("isLanguageAnnouncement")
      }}>
        <p style={{ marginTop: '1rem' }}>{t("common.languageAnnouncement")}</p>
      </Modal>
    </>
  );
};

export default Settings;
