import { DeleteOutlined, EnterOutlined } from '@ant-design/icons'
import { 
  // Checkbox, 
  Divider, Input, Select, Typography } from 'antd'
import React, { useEffect, useMemo } from 'react'
import { antdTheme, AsyncStates } from 'src/constants'
import useTranslate from 'src/utils/useTranslate'
import './AddDetails.scss'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { qcAddDetails, qcCreateNameDetailsRequest, qcDeleteNameDetailsRequest } from 'src/store/actions/qualityCheck'
// import BgImage from './bg_image.png'
import _ from 'lodash'
import EmptyComponent from 'src/components/QualityCheckMetadata/EmptyComponent'

const AddDetails = () => {
  const [t] = useTranslate()
  const dispatch = useDispatch()

  const { addDetails: addDetailsRedux } = useSelector((state: StoreState) => state.qualityCheck)

  const [addDetails, setAddDetails] = React.useState<Record<string, string | boolean>>({
    ...addDetailsRedux
  })

  useEffect(() => {
    dispatch(qcAddDetails(addDetails))
  }, [dispatch, addDetails])

  const detailsData = useMemo(() => {
    return [
      {
        label: t('qc.supplierName'),
        placeholder: `${t('common.add')} ${t('qc.supplierName')}`,
        type: 'supplier'
      },
      {
        label: t('qc.productName'),
        placeholder: `${t('common.add')} ${t('qc.productName')}`,
        type: 'product'
      },
      {
        label: t('common.Category'),
        placeholder: `${t('common.add')} ${t('common.Category')}`,
        type: 'category'
      }
    ]
  }, [t])

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          width: '375px'
        }}
      >
        <Typography.Title level={3} style={{ marginBottom: 16 }}>
          {t('qc.metadata.step1')}
        </Typography.Title>
        {detailsData?.map((data, idx) => (
          <FormEntries
            key={idx}
            {...data}
            onChange={(key: string, value: string | boolean) => {
              const newAddDetails = _.cloneDeep(addDetailsRedux)
              delete newAddDetails[`error_${key}_id`]
              setAddDetails((prev) => ({
                ...newAddDetails,
                [`${key}_id`]: value
              }))
            }}
          />
        ))}
        {/* Extract From Doc Checkbox and details */}

        {/* <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            width: '100%',
            padding: '1.25rem 1.5rem',
            borderRadius: '0.5rem',
            marginTop: '2rem',
            color: 'white',
            background: 'linear-gradient(180deg, #5E678E 0%, #04134F 100%)',
            position: 'relative'
          }}
        >
          <img
            src={BgImage}
            alt="bg_image"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              borderRadius: '0.5rem'
            }}
          />
          <div
            style={{
              display: 'flex',
              gap: '0.5rem'
            }}
          >
            <Checkbox
              checked={!!addDetails['extract_criteria'] || false}
              onChange={(e) => {
                setAddDetails((prev) => ({
                  ...prev,
                  extract_criteria: e.target.checked
                }))
              }}
            />
            <Typography.Title
              level={4}
              style={{
                margin: 0,
                color: 'white'
              }}
            >
              {t('qc.metadata.extractFromDoc.title')}
            </Typography.Title>
          </div>
          <p
            style={{
              margin: 0
            }}
          >
            {t('qc.metadata.extractFromDoc.sentence1')}
            <span
              style={{
                fontWeight: 600
              }}
            >
              {t('qc.metadata.step4')}
            </span>
            {t('qc.metadata.extractFromDoc.sentence2')}
          </p>
          <p
            style={{
              margin: 0
            }}
          >
            {t('qc.metadata.extractFromDoc.sentence3')}
          </p>
        </div> */}
      </div>
    </div>
  )
}

export default AddDetails

const FormEntries = ({
  label,
  placeholder,
  type,
  onChange
}: {
  label: string
  placeholder: string
  type: string
  onChange: (key: string, value: string | boolean) => void
}) => {
  const [t] = useTranslate()
  const dispatch = useDispatch()

  const [optionsState, setOptionsState] = React.useState<any[]>([])
  const [newOptionName, setNewOptionName] = React.useState<string>('')

  const { getNameDetailsStatus, getNameDetailsResponse, addDetails } = useSelector((state: StoreState) => state.qualityCheck)

  useEffect(() => {
    setOptionsState(getNameDetailsResponse?.[type] || [])
  }, [getNameDetailsResponse, type])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.25rem',
        justifyItems: 'start'
      }}
    >
      <Typography.Text
        style={{
          fontSize: antdTheme.fontSizeSM,
          display: 'flex'
        }}
      >
        <Typography.Text style={{ color: antdTheme.colorError, marginRight: '0.25rem', fontFamily: 'SimSong', fontWeight: 600 }}>*</Typography.Text>
        {label}
      </Typography.Text>
      <Select
        style={{ width: '100%' }}
        placeholder={t('common.typeOrSelect')}
        dropdownStyle={{
          borderRadius: 2
        }}
        value={addDetails?.[`${type}_id`]}
        {...(addDetails?.[`error_${type}_id`] && { status: 'error' })}
        className="qc_add_details_select"
        loading={getNameDetailsStatus === AsyncStates.LOADING}
        showSearch
        onChange={(val) => {
          onChange(type, val)
        }}
        notFoundContent={
          <EmptyComponent/>
        }
        // filterOption={(input, option) => {
        //   return option?.name?.toLowerCase().includes(input.toLowerCase())
        // }}
        optionFilterProp='label'
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider
              style={{
                margin: '0',
                marginTop: 4
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.25rem',
                padding: '0.5rem 1rem'
              }}
            >
              <Typography.Text
                style={{
                  fontSize: antdTheme.fontSizeSM
                }}
              >
                {t('common.addNew')}
              </Typography.Text>
              <Input
                placeholder={placeholder}
                value={newOptionName}
                onChange={(e) => {
                  setNewOptionName(e.target.value)
                }}
                suffix={
                  <EnterOutlined
                    style={{
                      color: 'rgba(0, 0, 0, 0.45)'
                    }}
                  />
                }
                onKeyDown={(e) => e.stopPropagation()}
                onPressEnter={() => {
                  if (newOptionName) {
                    dispatch(
                      qcCreateNameDetailsRequest({
                        name: newOptionName,
                        type
                      })
                    )
                    setNewOptionName('')
                  }
                }}
                style={{
                  borderRadius: 2
                }}
              />
              <Typography.Text
                type="secondary"
                style={{
                  fontSize: antdTheme.fontSizeSM
                }}
              >
                {t('common.tapEnterToAdd')}
              </Typography.Text>
            </div>
          </>
        )}
        optionRender={(op:any) => {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              {op.label}
              {!op?.data?.used_count ? <DeleteOutlined
              style={{
                color: antdTheme.colorError,
                cursor: 'pointer',
                outline: 'none'
              }}
              onClick={(e) => {
                e.stopPropagation()
                dispatch(
                  qcDeleteNameDetailsRequest({
                    detail_id: op.value,
                    type: type
                  })
                )
                if(addDetails[`${type}_id`] === op.value) {
                  onChange(type, '')
                }
              }}
            />: null}
            </div>
          )
        }}
        options={optionsState.map((opt) => ({ label: opt.name, value: opt.detail_id, name: opt.name,...opt }))}
      />
    </div>
  )
}
