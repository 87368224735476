import { Icon } from '@ant-design/compatible'
import { Form, Space } from 'antd';
import { GsuiteIcon, MicrosoftIcon } from '../Login/Login'
import { trackEvent } from 'src/analytics'
import { Services } from 'src/services/firebase/firebase-auth'
import { StoreState } from 'src/store/configureStore'
import { useSelector } from 'react-redux'
import { useSessionStorage } from 'src/utils/hooks/useSessionStorage'
import { antdTheme } from 'src/constants'
import { StyledButton } from 'src/styled_components/StyledButton'

const GOOGLE = 'Google'
const MICROSOFT = 'Microsoft'

export const SingleSignIn = ({ setshowSpinner, signInViaFirebase }: any) => {
    const userId = useSelector((state: StoreState) => state.login.loginResponse.user_id)
    const setSessionStorage = useSessionStorage().setSessionStorage

    const signInViaGsuite = () => {
        setshowSpinner(true)
        trackEvent(userId, 'User tried to log in through Gmail')
        setSessionStorage("isLoginWithFirebaseInitiated", true)
        signInViaFirebase(Services.gsuite)
    }
    const signInViaMicrosoft = async () => {
        setshowSpinner(true)
        trackEvent(userId, 'User tried to log in through Microsoft')
        setSessionStorage("isLoginWithFirebaseInitiated", true)
        signInViaFirebase(Services.microsoft)
    }

    return (
        <>
            <Form.Item>
                <StyledButton
                    size="large"
                    className="round-text social-btn"
                    onClick={signInViaGsuite}
                    style={{
                        whiteSpace: "normal",
                        display: "block",
                        height: "max-content",
                        textAlign: "center",
                        padding: "8px, 16px, 8px, 16px",
                        width: "100%",
                    }}
                >
                    <Space style={{ fontWeight: 600, padding: "5px" }}>
                        <Icon component={GsuiteIcon} style={{fontSize: antdTheme.fontSizeHeading2}} />
                        {GOOGLE}
                    </Space>
                </StyledButton>
            </Form.Item>
            <Form.Item>
                <StyledButton
                    size="large"
                    className="round-text social-btn"
                    onClick={signInViaMicrosoft}
                    style={{
                        whiteSpace: "normal",
                        height: "max-content",
                        textAlign: "center",
                        padding: "8px, 16px, 8px, 16px",
                        width: "100%",
                    }}
                >
                    <Space style={{ fontWeight: 600, padding: "5px" }}>
                        <Icon component={MicrosoftIcon} style={{fontSize: antdTheme.fontSizeHeading2}} />
                        {MICROSOFT}
                    </Space>
                </StyledButton>
            </Form.Item>
        </>
    )
}
