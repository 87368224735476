import React, { memo } from "react"
import { List, Typography } from "antd"
import useTranslate from "src/utils/useTranslate"
import { useValue } from "src/utils/useValue"
const { Text } = Typography

export const Notes = memo(() => {
	const [t] = useTranslate()
	const { getDecimalSeparator } = useValue()

	const listData = [
		`F-statistic: The higher the F-statistic, the stronger the correlation is.`,
		`p-value: A p-value less than 0${getDecimalSeparator()}05 shows that the correlation is statistically significant. The lower the p-value, the more significant the correlation is`,
	]

	return (
		<div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
			<List
				header={<Text strong>{t("projects.notes")}</Text>}
				size="small"
				bordered
				dataSource={listData}
				renderItem={(item) => (
					<List.Item>
						<Text strong type="secondary">
							{item}
						</Text>
					</List.Item>
				)}
			/>
			<div style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}>
				<div style={{ display: "flex", gap: "5px" }}>
					<span
						style={{ width: "20px", height: "20px", background: "green" }}
					></span>
					<span>{t("common.processing")}</span>
				</div>
				<div style={{ display: "flex", gap: "5px" }}>
					<span
						style={{ width: "20px", height: "20px", background: "blue" }}
					></span>
					<span>{t("common.ingredients")}</span>
				</div>
			</div>
		</div>
	)
})
