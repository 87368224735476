import React from 'react'

const EmptyImage = () => {
  return (
    <svg width="99" height="60" viewBox="0 0 99 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.8"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.4134 11.1486C21.413 8.00458 22.3433 6.04708 23.2027 5.27533C25.5826 3.13933 28.8731 4.30183 29.5727 4.46833C32.0302 5.05483 31.2311 1.19533 33.3396 0.410829C34.7448 -0.111921 35.9005 0.527079 36.8073 2.32783C37.6102 0.647829 38.8337 -0.121671 40.4778 0.0155795C42.9444 0.223329 43.8075 8.51158 47.1968 6.69733C50.5869 4.88233 54.7428 4.46758 56.5182 7.16458C56.9019 7.74808 57.0489 6.84283 59.6587 3.75208C62.2685 0.660579 64.8708 -0.701421 70.2095 1.09858C72.6369 1.91608 74.6331 4.13233 76.2003 7.74658C76.2003 12.9051 80.0148 15.9591 87.6422 16.9063C99.0848 18.3276 90.203 30.5668 76.2003 34.1511C62.1969 37.7361 29.9571 39.6711 12.4619 30.6141C0.798364 24.5773 3.44889 18.0883 20.4127 11.1486H20.4134Z"
        fill="url(#paint0_linear_12574_38295)"
      />
      <path
        d="M49.1057 56.7326C60.7577 56.7326 70.2036 54.5574 70.2036 51.8741C70.2036 49.1908 60.7577 47.0156 49.1057 47.0156C37.4537 47.0156 28.0078 49.1908 28.0078 51.8741C28.0078 54.5574 37.4537 56.7326 49.1057 56.7326Z"
        fill="url(#paint1_linear_12574_38295)"
      />
      <path
        opacity="0.675"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M84.7249 57.9356C68.8617 61.8941 14.7152 57.3664 8.95201 55.2986C6.14695 54.2914 3.61855 52.3976 1.36757 49.6166C1.11563 49.3057 0.957272 48.9301 0.910793 48.5334C0.864313 48.1367 0.931607 47.735 1.1049 47.3747C1.27819 47.0145 1.55041 46.7103 1.8901 46.4975C2.22978 46.2846 2.62307 46.1717 3.02452 46.1719H97.6428C99.6065 51.3754 95.3006 55.2964 84.7249 57.9356Z"
        fill="url(#paint2_linear_12574_38295)"
      />
      <path
        d="M65.2959 38.8554L58.0809 30.4614C57.9102 30.2611 57.6982 30.0999 57.4592 29.9886C57.2202 29.8772 56.9599 29.8185 56.6961 29.8164H41.1819C40.6497 29.8164 40.1439 30.0594 39.7971 30.4614L32.582 38.8554V43.4634H65.2959V38.8554Z"
        fill="url(#paint3_linear_12574_38295)"
      />
      <path
        d="M63.252 43.6322L56.937 36.3212C56.7844 36.1485 56.5962 36.0106 56.3852 35.9168C56.1742 35.823 55.9454 35.7754 55.7143 35.7774H42.1647C41.6995 35.7774 41.245 35.9694 40.9419 36.3212L34.627 43.6322V47.6477H63.252V43.6322Z"
        fill="url(#paint4_linear_12574_38295)"
      />
      <path
        d="M65.2959 48.7448C65.2959 49.3845 65.0065 49.9598 64.5496 50.3453L64.4569 50.4202C64.0947 50.6934 63.6525 50.8409 63.198 50.8403H34.6807C34.4229 50.8403 34.1764 50.7937 33.9488 50.7082L33.8357 50.6632C33.4626 50.4981 33.1457 50.229 32.9232 49.8883C32.7007 49.5477 32.5822 49.1502 32.582 48.744V38.9062H40.5193C41.396 38.9062 42.1024 39.624 42.1024 40.4948V40.506C42.1024 41.3775 42.817 42.0802 43.6937 42.0802H54.1842C54.6057 42.0799 55.0097 41.9133 55.308 41.6172C55.6063 41.321 55.7744 40.9193 55.7756 40.5C55.7756 39.6255 56.4827 38.9062 57.3587 38.9062H65.2967L65.2959 48.7448Z"
        fill="url(#paint5_linear_12574_38295)"
      />
      <defs>
        <linearGradient id="paint0_linear_12574_38295" x1="51.1634" y1="27.7125" x2="51.1634" y2="-6.55011" gradientUnits="userSpaceOnUse">
          <stop stop-color="#DEDEDE" stop-opacity="0" />
          <stop offset="1" stop-color="#A9A9A9" stop-opacity="0.3" />
        </linearGradient>
        <linearGradient id="paint1_linear_12574_38295" x1="46.9073" y1="56.7326" x2="46.9073" y2="47.0156" gradientUnits="userSpaceOnUse">
          <stop stop-color="white" stop-opacity="0" />
          <stop offset="1" stop-color="#96A1C5" stop-opacity="0.373" />
        </linearGradient>
        <linearGradient id="paint2_linear_12574_38295" x1="49.5003" y1="59.5057" x2="49.5003" y2="43.5485" gradientUnits="userSpaceOnUse">
          <stop stop-color="white" stop-opacity="0" />
          <stop offset="1" stop-color="#919191" stop-opacity="0.15" />
        </linearGradient>
        <linearGradient id="paint3_linear_12574_38295" x1="48.939" y1="29.8164" x2="48.939" y2="35.9507" gradientUnits="userSpaceOnUse">
          <stop stop-color="#5389F5" />
          <stop offset="1" stop-color="#416FDC" />
        </linearGradient>
        <linearGradient id="paint4_linear_12574_38295" x1="52.7595" y1="47.6477" x2="52.7595" y2="35.1463" gradientUnits="userSpaceOnUse">
          <stop stop-color="#DCE9FF" />
          <stop offset="1" stop-color="#B6CFFF" />
        </linearGradient>
        <linearGradient id="paint5_linear_12574_38295" x1="48.9394" y1="38.9062" x2="48.9394" y2="50.8403" gradientUnits="userSpaceOnUse">
          <stop stop-color="#7CA5F7" />
          <stop offset="1" stop-color="#C4D6FC" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default EmptyImage
