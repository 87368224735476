import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Space, Form, Typography, Modal, Input, Row, Select, Spin, Tooltip
} from 'antd'
import { StoreState } from 'src/store/configureStore'
import { AsyncStates, permissions, projectStatus } from 'src/constants'
import { LoadingOutlined } from '@ant-design/icons'
import { woStatusChangeRequest } from "src/store/actions/workOrderDetails"
import { StyledButton } from "src/styled_components/StyledButton"
import { StyledCard } from "src/styled_components/StyledCard"
import { workOrdersRequest } from 'src/store/actions/workOrders'
import { setIsEditing } from 'src/store/actions/isEditing'
import { CustomPrompt } from 'src/utils/CustomPrompts'
import { orange } from '@ant-design/colors'
import useTranslate from 'src/utils/useTranslate'
import dayjs from 'dayjs'
import { usePermission } from 'src/utils/usePermissions'
import { WoReportPreview } from './WoReportPreview'
import { useRequiredFieldStar } from '../Common/useRequiredFieldStar'

const { Text } = Typography
const { Option } = Select
const { confirm }: any = Modal;


export const WoStatus = ({ workOrder }: any) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [t] = useTranslate()
  const requiredFieldStar = useRequiredFieldStar()


  const woStatusChange = useSelector((state: StoreState) => state.workOrderDetails.woStatusChange)
  const experiments = useSelector((state: StoreState) => state.workOrderDetails.experiment)
  const { projectList, current } = useSelector((state: StoreState) => state.projects)
  const currentProject = useMemo(() => projectList.find((project: any) => project.project_id === current), [projectList, current])
  const woListStatus = useSelector((state: StoreState) => state.workOrders.status)
  const displayIdList: any = useSelector((state: StoreState) => state.workOrderDetails.displayIdList)
  const configs = useSelector((state: StoreState) => state.configs.features)
  const isEditing = useSelector((state: StoreState) => state.isEditing)
  const userAccess = usePermission()
  const disabled = useMemo(() => ((userAccess.permission === permissions.viewer || userAccess?.status !== projectStatus.in_progress) && !userAccess?.stages?.includes("work_order_status")), [userAccess])
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (woListStatus === AsyncStates.INITIAL) {
      dispatch(workOrdersRequest({ project_id: currentProject.project_id }))
    }
  }, [dispatch, currentProject, woListStatus])

  useEffect(() => {
    if (woStatusChange === AsyncStates.SUCCESS) {
      dispatch(setIsEditing(false))
    }
  }, [dispatch, woStatusChange])

  const closeWorkOrder = async (values: any) => {
    const { comment, description, final_formulation_id } = values
    dispatch(woStatusChangeRequest({
      workOrderId: workOrder.work_order_id,
      status: "closed",
      comment: comment,
      description: description,
      final_formulation_id: final_formulation_id === "not_applicable" ? [] : [final_formulation_id],
    }))
  }

  const openConfirmationPrompt = () => {
    confirm({
      width: 500,
      title: t("common.modal.unsavedwarning"),
      onOk() {
        form.resetFields();
        setVisible(false);
        dispatch(setIsEditing(false))
      },
      okText: t("common.yes"),
      okType: 'danger',
      cancelText: t("common.no"),
      onCancel() { },
    });
  }

  const handleClose = () => {
    if (isEditing) {
      openConfirmationPrompt()
    } else {
      setVisible(false);
      dispatch(setIsEditing(false))
    }
  }


  return (
    <Spin spinning={woListStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
      <StyledCard
        extra={
          <Space>
            {!disabled ?
              <Tooltip title={!experiments.length ? "One or more stages are empty. Please enter data or delete empty stage(s) to close the work order." : null}>
                <StyledButton onClick={() => {
                  setVisible(true)
                }} size='large' type='primary' disabled={!experiments.length}>{t("woStatus.reviewAndCloseWo")}</StyledButton>
              </Tooltip> : null
            }
          </Space>
        }>
        <Space direction="vertical" size="large" style={{ width: "100%", overflow: 'auto' }} >
          <CustomPrompt isEditing={isEditing} message={`${t("common.unsavedChangesLost")}!.`} />
          <WoReportPreview workOrder={workOrder} from={"report_preview"} />
          <Modal
            okText={t("common.ok")}
            cancelText={t("common.cancel")}
            width={600} visible={visible} onCancel={handleClose} footer={null} title={<Space style={{ textAlign: "center" }}>{t("common.closeWorkOrder")} {(isEditing && <Text style={{ color: orange.primary }}>{t("common.unsavedChanges")}</Text>)}</Space>}>
            <Spin spinning={woStatusChange === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
              <Space style={{ width: "100%" }} direction="vertical" size="large">
                <Text strong type="secondary">{t("woStatus.afterClosing")}</Text>
                <Text strong type="secondary">{t("woStatus.notifyMember")}</Text>
                {(!workOrder.close_date && (dayjs().isBefore(dayjs(workOrder?.start_date)))) && <Text style={{ color: orange.primary }}>{"You are Closing Work Order Before Start Date."}</Text>}
                <Form onFinish={(values: any) => closeWorkOrder(values)} requiredMark={false}
                  form={form} onFieldsChange={() => dispatch(setIsEditing(true))} layout="vertical">
                  {Boolean(configs?.pcm_graphs) &&
                    <Form.Item name="final_formulation_id" label={t('wo.finalFormulation')}>
                      <Select>
                        <Option value={"not_applicable"} key={"not_applicable"}>
                          <Text type="secondary" strong>{"Not Applicable"}</Text>
                        </Option>
                        {displayIdList.map((res: any) => (
                          <Option value={res?.formulation_id}>{res?.formulation_display_id}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  }
                  <Form.Item name={"description"} label={t("common.outcome")} rules={[{ required: true, message: t("common.required") }]} required tooltip={requiredFieldStar}>
                    <Select>
                      <Option value={"Excellent"}>{t("common.excellent")}</Option>
                      <Option value={"Good"}>{t("common.good")}</Option>
                      <Option value={"Average"}>{t("common.average")}</Option>
                      <Option value={"Poor"}>{t("common.poor")}</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name={"comment"} label={t("common.comments")} rules={[{ required: true, message: t("common.required") }, { pattern: new RegExp(/^[^\s]+(\s+[^\s]+)*$/), message: <>{t("woStatus.commentMessage")}</> }]} required tooltip={requiredFieldStar}>
                    <Input.TextArea rows={5} />
                  </Form.Item>
                  <Form.Item shouldUpdate>
                    {() => (
                      <Row justify='end'>
                        <StyledButton htmlType='submit' type="primary" ghost
                          disabled={!form.isFieldsTouched() || !!form.getFieldsError().filter(({ errors }: any) => errors.length).length}
                        >
                          {t("common.confirm")}
                        </StyledButton>
                      </Row>
                    )}
                  </Form.Item>
                </Form>
              </Space>
            </Spin>
          </Modal>
        </Space>
      </StyledCard>
      <div id="graph" style={{ display: "none" }}></div>
    </Spin>
  )
}