import { Checkbox, Modal, Select, Typography } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EmptyComponent from 'src/components/QualityCheckMetadata/EmptyComponent'
import { antdTheme, AsyncStates } from 'src/constants'
import { qcGetImportDataOptionsRequest, qcImportDataClear, qcImportDataRequest } from 'src/store/actions/qualityCheck'
import { StoreState } from 'src/store/configureStore'
import useTranslate from 'src/utils/useTranslate'
import './importDataModal.scss'

const ImportDataModal = ({ isOpen, setIsOpen, step }: { isOpen: boolean; setIsOpen: (isOpen: boolean) => void; step: string }) => {
  const [t] = useTranslate()
  const dispatch = useDispatch()

  const [profile, setProfile] = React.useState<string | null>(null)
  const [isAllSteps, setIsAllSteps] = React.useState<boolean>(false)

  useEffect(() => {
    if(isOpen) dispatch(qcGetImportDataOptionsRequest())
  }, [dispatch, isOpen])

  const { importDataOptionsResponse, getMetadataResponse, importDataOptionsStatus, getNameDetailsResponse, getNameDetailsStatus } = useSelector(
    (state: StoreState) => state.qualityCheck
  )

  return (
    <Modal
      title={t('common.importData')}
      open={isOpen}
      onOk={() => {
        setIsOpen(false)
        dispatch(
          qcImportDataRequest({
            from_profile_id: profile,
            to_profile_id: getMetadataResponse?.profile_id,
            ...(isAllSteps ? { all_steps: true } : { step })
          })
        )
      }}
      okText={t('common.import')}
      cancelText={t('common.close')}
      okButtonProps={{
        disabled: !profile,
        style: {
          outline: 'none'
        }
      }}
      cancelButtonProps={{
        style: {
          outline: 'none'
        }
      }}
      onCancel={() => {
        setIsOpen(false)
        dispatch(qcImportDataClear())
      }}
      footer={(_, { OkBtn, CancelBtn }) => (
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end'
          }}
        >
          <CancelBtn />
          <OkBtn />
        </div>
      )}
      styles={{
        mask: {
          backgroundColor: 'rgba(0, 0, 0, 0.85)'
        },
        content: {
          padding: '1.5rem'
        }
      }}
      width={'350px'}
      className="qc-metadata-import-modal"
      maskClosable
      destroyOnClose
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          justifyItems: 'start'
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.25rem',
            justifyItems: 'start'
          }}
        >
          <Typography.Text
            style={{
              fontSize: antdTheme.fontSizeSM,
              display: 'flex'
            }}
          >
            <Typography.Text style={{ color: antdTheme.colorError, marginRight: '0.25rem', fontFamily: 'SimSong', fontWeight: 600 }}>
              *
            </Typography.Text>
            {t('common.profile')}
          </Typography.Text>
          <Select
            style={{ width: '100%' }}
            placeholder={`${t('qc.productName')} • ${t('common.Supplier')} • ${t('common.category')}`}
            dropdownStyle={{
              borderRadius: 2
            }}
            className="qc_import_data_select"
            value={profile}
            loading={importDataOptionsStatus === AsyncStates.LOADING || getNameDetailsStatus === AsyncStates.LOADING}
            showSearch
            onChange={(val) => {
              setProfile(val)
            }}
            notFoundContent={<EmptyComponent />}
            optionRender={(op: any) => {
              const { product_name, supplier_name, category_name } = op?.data
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.25rem',
                    height: '100%'
                  }}
                >
                  <Typography.Text
                    ellipsis={{ tooltip: false }}
                    style={{
                      fontWeight: 600,
                      maxWidth: '30%',
                      fontSize: antdTheme.fontSizeSM
                    }}
                  >
                    {product_name}
                  </Typography.Text>

                  <Typography.Text
                    style={{
                      fontWeight: 600,
                      fontSize: antdTheme.fontSizeSM
                    }}
                  >
                    {'•'}
                  </Typography.Text>

                  <Typography.Text
                    ellipsis={{ tooltip: false }}
                    style={{
                      maxWidth: '30%',
                      fontSize: antdTheme.fontSizeSM
                    }}
                  >
                    {supplier_name}
                  </Typography.Text>

                  <Typography.Text
                    style={{
                      fontWeight: 600,
                      fontSize: antdTheme.fontSizeSM
                    }}
                  >
                    {'•'}
                  </Typography.Text>

                  <Typography.Text
                    ellipsis={{ tooltip: false }}
                    style={{
                      maxWidth: '30%',
                      fontSize: antdTheme.fontSizeSM
                    }}
                  >
                    {`${category_name}`}
                  </Typography.Text>
                </div>
              )
            }}
            options={(importDataOptionsResponse || [])
              .filter((metadata) => metadata?.profile_id !== getMetadataResponse?.profile_id)
              .map((opt: any) => {
                const product_name = getNameDetailsResponse?.product?.find((product: any) => product.detail_id === opt.product_id)?.name
                const supplier_name = getNameDetailsResponse?.supplier?.find((supplier: any) => supplier.detail_id === opt.supplier_id)?.name
                const category_name = getNameDetailsResponse?.category?.find((category: any) => category.detail_id === opt.category_id)?.name
                return {
                  ...opt,
                  value: opt.profile_id,
                  label: `${product_name} • ${supplier_name} • ${category_name}`,
                  product_name,
                  supplier_name,
                  category_name
                }
              })}
            filterOption={(input, option) => {
              return option?.label?.toLowerCase().includes(input.toLowerCase())
            }}
          />
        </div>
        <Checkbox
          checked={isAllSteps}
          onChange={(e) => {
            setIsAllSteps(e.target.checked)
          }}
        >
          {t('qc.metadata.importData.importDataForAllSteps')}
        </Checkbox>
      </div>
    </Modal>
  )
}

export default ImportDataModal
