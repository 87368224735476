import { axiosInstance } from "src/utils/sendApi";
import { Headers } from "../interface";

const InventoryService = {
  getIngredients: (payload: any, headers: Headers) =>
    axiosInstance.post(
      process.env.REACT_APP_API_URL + "v1/data/inventory_v2/ingredients_list",
      payload,
      {
        headers,
      }
    ),
  exportIngredients: (payload: any, headers: Headers) =>
    axiosInstance.post(
      process.env.REACT_APP_API_URL + "v1/data/export_ingredient",
      payload,
      {
        headers,
      }
    ),
  validateData: (payload: any, headers: Headers) =>
    axiosInstance.post(
      process.env.REACT_APP_API_URL + "v1/data/inventory_v2/ingredient/validate/tds",
      payload,
      {
        headers,
      }
    ),

  validateBulkEditTdsApi: (payload: any, headers: Headers) =>
    axiosInstance.post(
      process.env.REACT_APP_API_URL + "v1/data/inventory_v2/ingredient/bulk-edit/validate/tds",
      payload,
      {
        headers,
      }
    ),

};

export default InventoryService;
