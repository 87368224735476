import { useEffect, useMemo, useRef, useState } from 'react'
import useTranslate from 'src/utils/useTranslate'
import { Input, InputRef, Space, Typography } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { antdTheme } from 'src/constants'
import EmptyComponent from '../EmptyComponent'

const CustomTable = ({ data, onChange, onRemove }: { data: any[]; onChange: any; onRemove: any }) => {
  const [t] = useTranslate()

  const columns = useMemo(() => {
    return [
      {
        key: 'name',
        title: t('common.Name'),
        width: '49%',
        render: (record: any) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <EditableInput value={record['name']} onChange={(value: string) => onChange(record.detail_id, value)} />
          </div>
        )
      },
      {
        key: 'used_count',
        title: t('common.count'),
        width: '49%',
        render: (record: any) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              width: '100%',
              justifyContent: 'space-between'
            }}
          >
            <Typography.Text style={{ width: '100%', height: '100%', fontWeight: 600, margin: 0, display: 'flex', alignItems: 'center' }}>
              {record['used_count']}
            </Typography.Text>
            {!record?.['used_count'] ? <DeleteOutlined
              style={{
                color: antdTheme.colorError,
                cursor: 'pointer',
                outline: 'none'
              }}
              onClick={() => onRemove(record.detail_id)}
            />: null}
          </div>
        )
      }
    ]
  }, [onChange, onRemove, t])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'auto',
        height: '100%'
      }}
    >
      <TableTile columns={columns} />
      <TableBody data={data} columns={columns} />
    </div>
  )
}

export default CustomTable

const EditableInput = ({ value, onChange }: { value: string; onChange: any }) => {
  const [inputValue, setInputValue] = useState(value)
  const inputRef = useRef<InputRef | null>(null)

  useEffect(()=>{
    setInputValue(value)
  },[value])

  return (
    <Space>
      <Input
        value={inputValue}
        ref={inputRef}
        onChange={(e) => setInputValue(e.target.value)}
        onPressEnter={() => {
          onChange(inputValue)
          inputRef?.current?.blur()
        }}
        onBlur={(e) => {
          onChange(e.target.value)
        }}
        style={{
          width: '100%',
          borderRadius: 2
        }}
      />
    </Space>
  )
}

const TableTile = ({ columns }: { columns: any[] }) => {
  return (
    <div
      style={{
        display: 'flex',
        width: 'max-content',
        minWidth: '100%',
        border: '1px solid rgba(0, 0, 0, 0.06)',
        backgroundColor: '#f5f5f5'
        // For left indent
        // paddingLeft: 24
      }}
    >
      {columns.map((column) => (
        <>
          {/* Actual column name */}
          <div
            key={column.key}
            style={{
              minWidth: column.width,
              width: column.width,
              fontWeight: 600,
              padding: '0.5rem',
              ...column.style
            }}
          >
            {column.title}
          </div>
          {/* Right separator */}
          <div
            style={{
              borderLeft: '1px solid rgba(0, 0, 0, 0.06)',
              margin: '0.25rem 0rem'
            }}
          />
        </>
      ))}
    </div>
  )
}

const TableBody = ({ data, columns }: { data: any[]; columns: any[] }) => {
  return (
    <div
      style={{
        height: 'calc(100% - 2rem)',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
        width: 'max-content',
        minWidth: '100%'
      }}
    >
      {data?.length ? data?.map((row) => (
        <div
          style={{
            display: 'flex',
            width: 'max-content',
            minWidth: '100%',
            borderBottom: '1px solid rgba(0, 0, 0, 0.06)'
          }}
        >
          {columns.map((column) => (
            <div
              key={column.key}
              style={{
                minWidth: column.width,
                width: column.width,
                padding: '0.5rem',
                ...column.style
              }}
            >
              {column.render ? column.render(row) : row[column.key]}
            </div>
          ))}
        </div>
      ))
      : <EmptyComponent/>
    }
    </div>
  )
}
