import { Dropdown, MenuProps, Space, Table, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";
import { InventoryMethod, InventoryProperty } from "../types";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import {
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { InventoryMethodModal } from "./InventoryMethodModal";
import {
  deleteInventoryMethodClear,
  deleteInventoryMethodRequest,
} from "src/store/actions/inventoryV2";
import { InventoryMethodParametersTable } from "./InventoryMethodParametersTable";
import { AsyncStates } from "src/constants";
import { useValue } from "src/utils/useValue";
import { StyledButton } from "src/styled_components/StyledButton";
import useTranslate from "src/utils/useTranslate";

const { Text } = Typography;

type InventoryMethodsTableProps = {
  property: InventoryProperty;
  inventory_id?: string;
  from: string;
  ingredient: any
};

export const InventoryMethodsTable = ({
  property, inventory_id, from, ingredient
}: InventoryMethodsTableProps) => {
  const [t] = useTranslate()
  const dispatch = useDispatch();
  const { getValue } = useValue()
  const deleteMethodResponse = useSelector(
    (state: StoreState) => state.inventoryV2.deleteMethod
  );

  const [mode, setMode] = useState<"create" | "edit">("create");
  const [methodModalOpen, setMethodModalOpen] = useState<boolean>(false);

  const [selectedMethod, setSelectedMethod] = useState<
    InventoryMethod | undefined
  >(undefined);

  const columns: ColumnsType<InventoryMethod> = [
    {
      title: "Name",
      dataIndex: "method_name",
      key: "name",
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
    },
    ...(from !== 'family' ? [{
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (text: any, record: any) => <>{getValue(record.value) ?? getValue(record.value_str)}</>,
    },
    {
      title: "Max Value",
      dataIndex: "value_max",
      key: "value_max",
      render: (text: any, record: any) => getValue(text),
    }] : [])
    // {
    //   title: "Has Range",
    //   dataIndex: "has_range",
    //   key: "has_range",
    // },
  ];

  if (!inventory_id) columns.push({
    title: "Actions",
    key: "actions",
    fixed: "right" as any,
    align: "center" as any,
    width: 200,
    render: (text, record) => (
      <Dropdown menu={menuProps(record)} placement="bottomRight">
        <StyledButton size="small" icon={<MoreOutlined />} />
      </Dropdown>
    ),
  }
  )

  const menuItems: MenuProps["items"] = [
    {
      label: "Edit",
      key: "edit",
      icon: <EditOutlined />,
    },
    {
      label: "Delete",
      key: "delete",
      icon: <DeleteOutlined />,
      danger: true,
    },
  ];

  const handleMenuClick = (e: any, record: InventoryMethod) => {
    switch (e.key) {
      case "edit":
        setMode("edit");
        setSelectedMethod(record);
        setMethodModalOpen(true);
        break;
      case "delete":
        setSelectedMethod(record);
        dispatch(
          deleteInventoryMethodRequest({
            ...(from === 'family' ? { family_id: record?.family_id } : { inventory_id: record?.inventory_id }),
            ...(from === 'family' ? { property_id: record?.property_id } : { property_id: record?.inventory_property_id }),
            method_id: record.method_id,
            from
          })
        );
        break;
    }
  };

  const menuProps = (record: any) => {
    return {
      items: menuItems,
      onClick: (e: any) => handleMenuClick(e, record),
    };
  };

  useEffect(() => {
    if (deleteMethodResponse.status === AsyncStates.SUCCESS) {
      // message.success(`Method deleted!`);
      dispatch(deleteInventoryMethodClear());
      setSelectedMethod(undefined);
    }
  }, [deleteMethodResponse, dispatch, selectedMethod?.method_id]);

  return (
    <div
      style={{
        marginLeft: "20px",
        margin: "5px",
      }}
    >
      <Table
        caption={
          <Space
            style={{
              width: "100%",
              display: "flex",
              // justifyContent: "space-between",
              alignItems: "center",
              padding: "5px",
            }}
          >
            <Text style={{ fontWeight: "bold" }}>
              {t('common.methods')} : {property.name}
            </Text>
            {!inventory_id && <StyledButton
              size="small"
              icon={<PlusOutlined />}
              onClick={() => {
                setMode("create");
                setMethodModalOpen(true);
              }}
            >
              {t('common.method')}
            </StyledButton>}
          </Space>
        }
        size="small"
        columns={columns}
        dataSource={[...property.methods]}
        rowKey={(record) => record.method_id}
        expandable={{
          expandedRowRender: (record) => (
            <InventoryMethodParametersTable method={record} inventory_id={inventory_id} from={from} ingredient={ingredient} />
          ),
        }}
        pagination={false}
      />
      <InventoryMethodModal
        open={methodModalOpen}
        setOpen={setMethodModalOpen}
        property={property}
        mode={mode}
        method={selectedMethod}
        from={from}
        ingredient={ingredient}
      />
    </div>
  );
};
