import { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store/configureStore";
import { FormulationsFilterData } from "../../services/formulation/interface";
import {
  formulationsFilterCleanup,
  formulationsFilterRequest,
} from "../../store/actions/formulationsFilter";
import { compareFormulationsRequest } from "../../store/actions/compareFormulations";
import { antdTheme, AsyncStates, currentPlatform } from "../../constants";
import Spinner from "../Spinner/Spinner";
import {
  Space,
  Row,
  Typography,
  Divider,
  message,
  Menu,
  Switch,
  Popover,
  Tag,
  Tooltip,
  Card,
  Checkbox,
  Image,
} from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import {
  fetchFileListRequest,
  plotsDataRequest,
} from "src/store/actions/workOrderDetails";
import {
  FileExcelOutlined,
  LineChartOutlined,
  EyeInvisibleOutlined,
  ExperimentOutlined,
  CheckOutlined,
  CloseOutlined
} from "@ant-design/icons";
import {
  fetchExperimentRequest,
  fetchLinkedExperimentCleanup,
  fetchLinkedExperimentRequest,
  fetchSimilarFormulationsCleanup,
  // fetchSimilarFormulationsRequest,
} from "src/store/actions/experiment";
import { dataExportRequest } from "../../store/actions/workOrderDetails";
import useTranslate from "src/utils/useTranslate";
import "../WorkOrderDetails/react-datasheet.css";
import mixpanel from "mixpanel-browser";
import { StyledCard } from "src/styled_components/StyledCard";
import { volcano } from "@ant-design/colors";
import { StyledButton } from "src/styled_components/StyledButton";
import { InfiniteTable } from "./InfiniteTable";
import { FormulationDetailsDrawer } from "./FormulationDetailsDrawer";
import { CompareGraphs } from "./ComapreGraphs";
// import { fetchSimilarExperimentsRequest } from "src/store/actions/formulationDetails";
// import { SimilarFormulationsModal } from "./SimilarFormulationsModal";
import { GroupFormulationDetailsDrawer } from "./GroupFormulationDetailsDrawer";
import { history } from "src";
import { updateUserPreferencesRequest, userPreferencesRequest } from "src/store/actions/userPreferences";
import BuildModelIcon from "src/assets/svgs/BuildModelIcon.svg"

const { Text } = Typography;

interface FormulationSearchResultsProps {
  filters: any;
  searchList: any[];
  project_id: string[];
  selectedLabLocations: string[];
  selectedWorkOrderType: string | undefined;
}

export const FormulationSearchResults = ({
  filters,
  searchList,
  project_id,
  selectedLabLocations,
  selectedWorkOrderType
}: FormulationSearchResultsProps) => {
  const [t] = useTranslate();
  const dispatch = useDispatch();

  const configs = useSelector((state: StoreState) => state.configs.features);
  const { current } = useSelector((state: StoreState) => state.projects);
  const apiFilterFormulationsStatus = useSelector<StoreState, AsyncStates>(
    (state) => state.formulationsFilter.status
  );
  const apiFormulationDetailStatus = useSelector<StoreState, AsyncStates>(
    (state) => state.compareFormulations.status
  );
  const apiCompareFormulationStatus = useSelector<StoreState, AsyncStates>(
    (state) => state.formulationDetails.status
  );
  const filterFormulations = useSelector<StoreState, FormulationsFilterData>(
    (state) => state.formulationsFilter.formulationsFilterData
  );
  const { dataExportStatus, archiveFormulationsStatus } = useSelector(
    (state: StoreState) => state.workOrderDetails
  );
  const [showGroupFormulation, setGroupFormulations] = useState(false);
  const [groupSelected, setGroupSelected] = useState(-1)
  const { similarFormulations } = useSelector((state: StoreState) => state.workOrderDetails)
  // const similarExperimentsData = useSelector((state: StoreState) => state.formulationDetails.similarExperimentsData)
  const [details, setDetails] = useState<any>({});
  const [groupDetails, setGroupDetails] = useState<any>(false);
  const [selected, setSelected] = useState<Map<string, boolean>>(new Map());
  const [formulations, setFormulations] = useState<FormulationsFilterData>({
    total_records: 0,
    formulation_data: [],
  });
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [sorting, setSorting] = useState<any>({
    sort_on: null,
    sort_order: null,
  });
  const { formulation_page: columns = {} } = useSelector((state: StoreState) => state.userPreferences.preferences);

  // const [showSimilarExpModal, setShowSimilarExpModal] = useState(false)
  const [selectedFormulation, setSelectedFormulation] = useState("");

  const columnsLabels: any = useMemo(
    () => ({
      application: t("common.application"),
      material: t("common.material"),
      created: t("common.createdOn"),
      modified: t("common.closedOn"),
      work_order: t("common.workOrder"),
      stage_name: t("common.stage"),
      project: t("common.project"),
      lab_location: t("common.labLocation"),
      work_order_type: t("workorder.type")
    }),
    [t]
  );

  useEffect(() => {
    dispatch(userPreferencesRequest())
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(formulationsFilterCleanup());
      dispatch(fetchSimilarFormulationsCleanup())
    };
  }, [dispatch]);

  const workOrdersList = useSelector((state: StoreState) => state.workOrders.data)

  useEffect(() => {
    const updatedFilter = JSON.parse(JSON.stringify(filters))

    const updatedData = updatedFilter?.data?.map((outer: any) => {
      const updatedOuter = outer?.data?.map((inner: any) => {
        if (inner?.parameter_type === 'stage') {
          inner.val = workOrdersList.map((wo: any) => inner?.val.map((id: any) => wo?.stages?.[id]?.identifier)).flatMap((id: any) => id).filter((id: any) => !!id)
        }
        return inner
      })
      return { ...outer, data: updatedOuter }
    })

    dispatch(
      formulationsFilterRequest({
        reset: true,
        project_id,
        ...(!!filters?.selector && { filters: { ...filters, data: updatedData } }),
        ...(searchList.length ? { search_list: searchList } : {}),
        ...(selectedLabLocations.length ? { lab_locations: selectedLabLocations } : {}),
        ...(selectedWorkOrderType ? { work_order_type: selectedWorkOrderType } : {}),
        ...(!!sorting?.sort_on && !!sorting.sort_order && sorting),
        ...(!!selectedFormulation && {
          similar_formulations: selectedFormulation,
        }),
        page_size: 20,
      })
    );
  }, [dispatch, project_id, filters, searchList, sorting, selectedFormulation, workOrdersList, selectedLabLocations, selectedWorkOrderType]);

  useEffect(() => {
    if (apiFilterFormulationsStatus === AsyncStates.SUCCESS) {
      setFormulations(filterFormulations);
    }
  }, [filterFormulations, apiFilterFormulationsStatus]);

  useEffect(() => {
    setSelected(new Map());
  }, [current, project_id, filters]);

  const compareHandler = () => {
    mixpanel.track("Compared formulations");
    const maxSize = Boolean(configs?.characterization_methods) ? 21 : 51;
    if (selected && selected.size > 1 && selected.size < maxSize) {
      dispatch(compareFormulationsRequest([...selected.keys()]));
    } else {
      message.error(
        `Please select more than 1 and less than ${maxSize} formulations`
      );
    }
  };

  function handleSelected(this: string, e: CheckboxChangeEvent) {
    if (e.target.checked) {
      setSelected(new Map(selected.set(this, true)));
    } else {
      const newSelected = new Map(selected);
      newSelected.delete(this);
      setSelected(newSelected);
    }
  }

  const [graphsModalVisible, setGraphsModalVisible] = useState(false);

  // const groupFormulation = () => {
  //   if (showGroupFormulation) {
  //     setGroupFormulations(false)
  //   } else dispatch(fetchSimilarFormulationsRequest({ projects: [] }))
  // }

  useEffect(() => {
    if (similarFormulations?.groups?.length > 0) {
      setGroupFormulations(true)
    }
    // return () => { dispatch(fetchSimilarFormulationsCleanup()) }
  }, [dispatch, similarFormulations?.groups])

  useEffect(() => {
    if (graphsModalVisible && !!selected.size)
      dispatch(
        plotsDataRequest({
          formulation_id: formulations?.formulation_data
            ?.filter((res) =>
              [...selected.keys()].includes(res?.formulation_id)
            )
            ?.map((res: any) => res?.id_set?.formulation_id),
        })
      );
  }, [dispatch, formulations?.formulation_data, selected, graphsModalVisible]);

  useEffect(() => {
    window.onkeyup = (e: any) => {
      if (e.key === "ArrowDown" && e.shiftKey) {
        const data = formulations.formulation_data;
        const lastElement = [...selected.keys()]?.[selected.size - 1] || 0;
        const lastSelectedTrial: any = data?.find(
          (res: any) => res?.formulation_id === lastElement
        );
        const newTrialIndex: any = Number(data?.indexOf(lastSelectedTrial)) + 1;
        const newTrial: any = data?.[newTrialIndex];
        if (newTrial) {
          setSelected(new Map(selected.set(newTrial?.formulation_id, true)));
        }
      } else if (e.key === "ArrowUp" && e.shiftKey) {
        const data = formulations.formulation_data;
        const lastElement = [...selected.keys()]?.[selected.size - 1] || 0;
        const lastSelectedTrial: any = data?.find(
          (res: any) => res?.formulation_id === lastElement
        );
        const newTrialIndex: any = Number(data?.indexOf(lastSelectedTrial)) - 1;
        const newTrial: any = data?.[newTrialIndex];
        if (newTrial) {
          setSelected(new Map(selected.set(newTrial?.formulation_id, true)));
        }
      }
      // else if (e.key === 'ArrowDown' && e.shiftKey) {
      //         const lastElement = [...selected.keys()]?.[[...selected.keys()].length - 1] || 0
      //         const lastSelectedTrial: any = formulations?.formulation_data?.find((res: any) => res?.formulation_id === lastElement)
      //         const newTrialIndex: any = Number(formulations?.formulation_data?.indexOf(lastSelectedTrial)) + 1
      //         const map = new Map()
      //         formulations?.formulation_data?.slice(newTrialIndex)?.forEach((res: any) => {
      //           map.set(res?.formulation_id, true)
      //         })
      //         let count = 0
      //         setSelected((prevState: any) => {
      //           const returnMap: any = new Map([...prevState, ...map])
      //           count = returnMap.size
      //           return returnMap
      //         })
      //       }
      // else if (e.key === 'ArrowUp' && e.shiftKey) {
      //         const lastElement = [...selected.keys()]?.[[...selected.keys()].length - 1] || 0
      //         const lastSelectedTrial: any = formulations?.formulation_data?.find((res: any) => res?.formulation_id === lastElement)
      //         const newTrialIndex: any = Number(formulations?.formulation_data?.indexOf(lastSelectedTrial)) - 1
      //         const map = new Map()
      //         formulations?.formulation_data?.slice(0, newTrialIndex + 1)?.forEach((res: any) => {
      //           map.set(res?.formulation_id, true)
      //         })
      //         let count = 0
      //         setSelected((prevState: any) => {
      //           const returnMap: any = new Map([...prevState, ...map])
      //           count = returnMap.size
      //           return returnMap
      //         })
      //       }
    };
  }, [selected, formulations]);

  const loadMoreItems = useCallback(
    (startIndex: number, stopIndex: number, reset: boolean = false) => {
      const updatedFilter = JSON.parse(JSON.stringify(filters))

      const updatedData = updatedFilter?.data?.map((outer: any) => {
        const updatedOuter = outer?.data?.map((inner: any) => {
          if (inner?.parameter_type === 'stage') {
            inner.val = workOrdersList.map((wo: any) => inner?.val.map((id: any) => wo?.stages?.[id]?.identifier)).flatMap((id: any) => id).filter((id: any) => !!id)
          }
          return inner
        })
        return { ...outer, data: updatedOuter }
      })

      return apiFilterFormulationsStatus === AsyncStates.LOADING
        ? null
        : Promise.resolve(
          dispatch(
            formulationsFilterRequest({
              reset,
              previous_id: formulations.previous_id,
              ...(searchList.length ? { search_list: searchList } : {}),
              ...(!!filters?.selector && { filters: { ...filters, data: updatedData } }),
              ...(!!project_id?.length && { project_id }),
              ...(selectedLabLocations.length ? { lab_locations: selectedLabLocations } : {}),
              ...(selectedWorkOrderType ? { work_order_type: selectedWorkOrderType } : {}),
              ...(sorting.sort_order && sorting.sort_on && sorting),
              ...(!!selectedFormulation && {
                similar_formulations: selectedFormulation,
              }),
              page_size: 20,
            }),
          ),
        );
    },
    [filters, apiFilterFormulationsStatus, dispatch, formulations.previous_id, searchList, project_id, sorting, selectedFormulation, workOrdersList, selectedLabLocations, selectedWorkOrderType],
  );

  const drawerHandle = function (this: any, event: any) {
    if (event?.target?.className !== "ant-checkbox-input") {
      setDetails(this);
      dispatch(
        fetchExperimentRequest({ formulation_id: [this?.formulation_id] })
      );
      dispatch(
        fetchLinkedExperimentRequest({
          work_order_id: [this?.work_order_id],
          access: false,
          linked_stages: true,
        })
      );
      dispatch(fetchFileListRequest({ formulation_id: this?.formulation_id }));
      setDetails(this);
      if (Boolean(configs?.pcm_graphs)) {
        dispatch(
          plotsDataRequest({
            work_order_id: this.work_order_id,
            display_id: [this?.formulation_display_id],
          })
        );
      }
    }
  };

  const groupDrawerHandle = (ids: any[]) => {
    dispatch(fetchLinkedExperimentCleanup());
    setGroupDetails(true)
    dispatch(fetchExperimentRequest({ formulation_id: ids }))
    dispatch(fetchFileListRequest({ formulation_id: ids?.[0] }));
  }

  const dataExport = () => {
    if (selected.size) {
      let payload;
      if (Boolean(configs?.characterization_methods)) {
        const ids = [...selected.keys()];
        payload = {
          formulation_ids: ids, // generateZeonDataExportFormulationPayload(workOrderIds, ids, formulations?.formulation_data),
        };
      } else {
        payload = {
          formulation_ids: [...selected.keys()],
        };
      }
      dispatch(dataExportRequest(payload));
    } else {
      message.error(t("formulations.error.formulationsNotSelected"));
    }
  };

  useEffect(() => {
    if (selectAll) {
      const data = formulations.formulation_data;
      const map = new Map();
      data?.forEach((res: any) => {
        map.set(res?.formulation_id, true);
      });
      setSelected(map);
    }
  }, [formulations.formulation_data, selectAll]);

  const onSelectAll = (e: any) => {
    if (!e.target.checked) {
      setSelected(new Map());
    }
    setSelectAll(e.target.checked);
  };

  const shouldAllowModelBuild = () => {
    if ([...selected.keys()].length < 7) return false;
    if ([...project_id].length > 1) return false;
    if (!isWorkOrdersSameType()) return false;

    return true;
  }

  const isWorkOrdersSameType = () => {
    const selectedFormulationsWorkOrderTypes = getWorkOrderTypes();

    return [...selectedFormulationsWorkOrderTypes].length > 1 ? false : true;
  }

  const getWorkOrderTypes = () => Array.from(new Set(formulations.formulation_data?.filter(f => selected.has(f.formulation_id)).map(f => f.work_order_type) as string[]));

  const getSelectedWorkOrders = () => Array.from(new Set(formulations.formulation_data?.filter(f => selected.has(f.formulation_id)).map(f => f.work_order_id) as string[]));

  const buildCustomModel = () => {
    const selectedWorkOrders = getSelectedWorkOrders();
    const selectedFormulations = [...selected.keys()];
    const isMultiStage = getWorkOrderTypes()[0] === "Multi Stage" ? true : false;
    const project = project_id[0];

    history.push("/ai-engine/new_model/custom_ml", {
      selectedWorkOrders,
      selectedFormulations,
      isMultiStage,
      project,
      redirect: true
    })
  }

  const hideColumnsOverlay = () => {
    return (
      <Menu style={{ padding: 10, width: "max-content" }}>
        {Object.entries(columns).map(([key, value]: any) => (
          <Menu.Item key={key}>
            <Row
              justify="space-between"
              wrap={false}
              style={{ height: "100%", alignItems: "center" }}
            >
              <Text>{columnsLabels?.[key] as any}</Text>
              <Switch
                style={{ marginLeft: 10 }}
                checked={value}
                onChange={(e: boolean) =>
                  dispatch(updateUserPreferencesRequest({ formulation_page: { ...columns, [key]: e } }))
                }
              />
            </Row>
          </Menu.Item>
        ))}
        <Divider style={{ margin: "10px 0" }} />
        <Row justify="space-between">
          <StyledButton
            onClick={() => {
              const updatedObj = Object.fromEntries(
                Object.entries(columns).map(([key, value]) => [key, false])
              );
              dispatch(updateUserPreferencesRequest({ formulation_page: { ...updatedObj } }))
            }}
            type="default"
            size="small"
          >
            {t("common.hideAll")}
          </StyledButton>
          <StyledButton
            onClick={() => {
              const updatedObj = Object.fromEntries(
                Object.entries(columns).map(([key, value]) => [key, true])
              );
              dispatch(updateUserPreferencesRequest({ formulation_page: { ...updatedObj } }))
            }
            }
            type="default"
            size="small"
          >
            {t("common.showAll")}
          </StyledButton>
        </Row>
      </Menu>
    );
  };

  return (
    <>
      {showGroupFormulation && (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Typography.Title level={5} style={{ marginBottom: "0.5rem" }}>{similarFormulations?.groups?.length}{" "}{t('formulations.groupsFound')}</Typography.Title>
          <div style={{ height: "70vh", overflowY: "auto" }}>
            {similarFormulations?.groups?.map((group: any, index: number) => (
              <Card style={{ marginBottom: "0.5rem" }} bodyStyle={{ padding: "12px" }} key={group.key}>
                <Checkbox style={{ fontSize: antdTheme.fontSizeHeading5, fontWeight: 600 }} disabled={groupSelected !== index && groupSelected !== -1} onChange={(e) => {
                  if (e?.target.checked) {
                    setGroupSelected(index)
                    group?.formulation_list_v2.forEach(({ formulation_id }: any) => {
                      setSelected(new Map(selected.set(formulation_id, true)));
                    })
                  } else if (!e?.target.checked) {
                    setSelected(new Map());
                    setGroupSelected(-1);
                  }
                }
                } >{group?.key}({group?.count})</Checkbox>
                {/* <div> <Typography.Title level={5}>Count: </Typography.Title><span>{group?.count}</span></div> */}
                <div onClick={() => groupDrawerHandle(group?.formulation_list_v2.map(({ formulation_id }: any) => formulation_id))}><Typography.Title level={5}>{t('common.formulations')}{":"} </Typography.Title>{group?.formulation_list_v2.map(({ formulation_display_id }: any, index: number) => (<><span className="formulation-list" style={{ cursor: "pointer" }}>{formulation_display_id}</span>{group?.formulation_ids_list.length - 1 === index ? "" : ","} </>))}</div>
              </Card>))}
          </div>
        </Space>)}
      {!showGroupFormulation && <Space size="small" direction="vertical" style={{ width: "100%" }}>
        <StyledCard>
          <Space size="large" direction="vertical" style={{ width: "100%" }}>
            <div style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0.5rem 0rem"
            }}>
              <Space size={"large"}>
                <Text strong>{`${formulations?.total_records} ${t(
                  "formulations.results"
                )}`}</Text>
                {!!selectedFormulation && (
                  <StyledButton
                    className="animated__hover__btn"
                    icon={<CloseOutlined />}
                    type="default"
                    style={{ background: volcano[0], color: "black" }}
                    onClick={() => {
                      setSelected(new Map());
                      setSelectedFormulation("");
                    }}
                    loading={
                      apiCompareFormulationStatus === AsyncStates.LOADING
                    }
                  >
                    <span className="animated__hover__content">{!!formulations?.formulation_data?.length ?
                      `${t("formulations.showingSimilarFormulationsTo")} ${formulations.formulation_data?.find(
                        (res: any) =>
                          res?.formulation_id === selectedFormulation
                      )?.formulation_display_id
                      }`
                      : 'No Similar Formulations Found'
                    }
                    </span>
                  </StyledButton>
                )}
              </Space>
              <Space>

                {/* <StyledButton
  type="primary"
  size="middle"
  onClick={groupFormulation}
>
  {showGroupFormulation ? "Show Formulations " : "Group Formulations"}
</StyledButton> */}
                {!showGroupFormulation && selected.size !== 0 && (
                  <>
                    <Text strong>{`${selected.size} ${t(
                      "formulations.selected"
                    )}`}</Text>
                    <Divider type="vertical" />
                  </>
                )}
                <Tooltip
                  placement="topRight"
                  title={
                    selected.size === 0
                      ? t("formulations.pleaseSelectOneorMoreFormulationsToCompare")
                      : null
                  }
                  trigger={"hover"}
                >
                  <StyledButton
                    type="primary"
                    className="animated__hover__btn"
                    size="middle"
                    onClick={compareHandler}
                    icon={<LineChartOutlined />}
                    disabled={selected.size === 0}
                  >
                    {<span className="animated__hover__content">{t("formulations.compareandrunanalytics")}</span>}
                  </StyledButton>
                </Tooltip>

                <Tooltip
                  placement="topRight"
                  title={
                    selected.size === 0
                      ? t("formulations.pleaseSelectOneorMoreFormulationsToExport")
                      : null
                  }
                  trigger={"hover"}
                >
                  <StyledButton
                    className="animated__hover__btn"
                    icon={<FileExcelOutlined />}
                    onClick={dataExport}
                    loading={dataExportStatus === AsyncStates.LOADING}
                    disabled={selected.size === 0}
                  >
                    {<span className="animated__hover__content">{t("formulations.button.exportResults")}</span>}
                  </StyledButton>
                </Tooltip>
                {currentPlatform !== "connect" &&
                  (!configs?.characterization_methods ||
                    configs?.nestle_configs ||
                    configs?.kuraray_configs) && (
                    <Tooltip
                      placement="topRight"
                      title={
                        ([...selected.keys()].length < 7 && t("formulations.selectSevenTrials"))
                        || ([...project_id].length > 1 && "Build Custom Model by Selecting only 1 Project")
                        || (!isWorkOrdersSameType() && "Build Custom Model by selecting same type of work order")
                      }
                    >
                      <span>
                        <StyledButton
                          className="animated__hover__btn"
                          disabled={!shouldAllowModelBuild()}
                          onClick={buildCustomModel}
                          icon={<Image style={{
                            paddingBottom: 3
                          }} src={BuildModelIcon} alt="Build Custom Model" />}
                        >
                          <span className="animated__hover__content">
                            {t("formulations.buildcustommodel")}
                          </span>
                        </StyledButton>
                      </span>
                    </Tooltip>
                  )}

                <Tooltip
                  title={
                    selected.size !== 1
                      ? t("formulations.pleaseSelectAFormulation")
                      : null
                  }
                  trigger={"hover"}
                  placement="topRight"
                >
                  <StyledButton
                    className="animated__hover__btn"
                    icon={
                      !!selectedFormulation ? (
                        <CheckOutlined />
                      ) : (
                        <ExperimentOutlined />
                      )
                    }
                    onClick={() => {
                      setSelectedFormulation([...selected.keys()][0]);
                    }}
                    loading={apiCompareFormulationStatus === AsyncStates.LOADING}
                    disabled={selected.size !== 1}
                  >
                    <span className="animated__hover__content">{!!selectedFormulation
                      ? t("formulations.showingSimilarFormulations")
                      : t("formulations.showsimilarformulations")}
                    </span>
                  </StyledButton>
                </Tooltip>

                <Popover
                  placement="left"
                  content={hideColumnsOverlay}
                  trigger="click"
                >
                  {Object.values(columns || {}).every((res: any) => res) ? (
                    <StyledButton className="animated__hover__btn bordered" icon={<EyeInvisibleOutlined />} type="text">
                      <Text strong><span className="animated__hover__content">{t("common.hideColumns")}</span></Text>
                    </StyledButton>
                  ) : (
                    <Tag
                      style={{ cursor: "pointer" }}
                      className="animated__hover__btn"
                      icon={<EyeInvisibleOutlined style={{ margin: 0 }} />}
                      color={"blue"}
                    >
                      <span className="animated__hover__content">
                        {`${Object.values(columns || {}).filter((res: any) => !res)
                          .length
                          } ${t("common.hiddenColumns")}`}
                      </span>
                    </Tag>
                  )}
                </Popover>
              </Space>
            </div>
            <InfiniteTable
              formulations={formulations}
              sorting={sorting}
              selectAll={selectAll}
              selected={selected}
              columns={columns}
              columnsLabels={columnsLabels}
              drawerDetails={details}
              selectedFormulation={selectedFormulation}
              callbacks={{
                drawerHandle,
                loadMoreItems,
                handleSelected,
                setSorting,
                onSelectAll,
              }}
            />
          </Space>
        </StyledCard>
      </Space>}
      <FormulationDetailsDrawer
        details={details}
        setDetails={setDetails}
        filters={filters}
      />
      <GroupFormulationDetailsDrawer
        drawerState={[groupDetails, setGroupDetails]}
        filters={filters}
      />
      {/* <SimilarFormulationsModal
				details={details}
				showModal={showSimilarExpModal}
				setShowModal={setShowSimilarExpModal}
				filters={filters}
				formulations={formulations}
				selected={selected}
			/> */}
      <CompareGraphs
        compareGraphsModalvisible={graphsModalVisible}
        setCompareGraphsModalvisible={setGraphsModalVisible}
        selected={selected}
      />
      {(apiFormulationDetailStatus === AsyncStates.LOADING ||
        archiveFormulationsStatus === AsyncStates.LOADING ||
        apiCompareFormulationStatus === AsyncStates.LOADING) && <Spinner />}
    </>
  );
};
