import { Alert, Space, Tooltip, Typography } from 'antd'
import _ from 'lodash';
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { InputConstraints } from './InputConstraintsNew'
import { CategoryInput, Parameter } from '../types'
import { BaseInputConstraints } from '../base-input-constraints'
import { useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { CATEGORY_CONSTRAINTS_ERROR_KEYS, ExludeAllCategoryConstraintsTypes, ExludeAllParameterConstraintsTypes, SelectAllIngredientsInCategoryTypes } from './child'
import { StyledButton } from 'src/styled_components/StyledButton'
import useTranslate from 'src/utils/useTranslate'
import { BuildOutlined } from '@ant-design/icons';

type P = {
  parameterList: Parameter[]
  setParameterList: Dispatch<SetStateAction<{ [key: string]: Parameter[] }>>
  catwiseParameterList: any
  setCatwiseParameterList: Dispatch<SetStateAction<any>>
  modelData: any
  setUnsavedChanges: Dispatch<SetStateAction<boolean>>
  categorialInputList: CategoryInput[]
  setCategorialInputList: Dispatch<
    SetStateAction<{ [key: string]: CategoryInput[] }>
  >
  range: any
  categoricalRange: any
  currentSelectedStage: number
  isMultiStage: boolean
  catConstraintsList: any[]
  setCatConstraintsList: Dispatch<SetStateAction<{ [key: string]: any[] }>>
  selectAllIngredientsInCategory: string[],
  setSelectAllIngredientsInCategory: Dispatch<SetStateAction<SelectAllIngredientsInCategoryTypes>>,
  exludeAllParameterConstraints: any
  setExludeAllParameterConstraints: Dispatch<SetStateAction<ExludeAllParameterConstraintsTypes>>,
  exludeAllCategoryConstraints: any,
  setExludeAllCategoryConstraints: Dispatch<SetStateAction<ExludeAllCategoryConstraintsTypes>>
  isCrossCategorical?: any,
  setIsCrossCategorical?: Dispatch<SetStateAction<ExludeAllCategoryConstraintsTypes>>
  setIsCrossCategoricalModalVisible?: Dispatch<SetStateAction<boolean>>
  isCategoricalConstraintsUseDefault?: any
  setIsCategoricalConstraintsUseDefault?: Dispatch<SetStateAction<boolean>>
  includeMetadata: boolean
}

export type ConstraintsProps = P

export default function Constraints({
  parameterList,
  setParameterList,
  catwiseParameterList,
  setCatwiseParameterList,
  setUnsavedChanges,
  modelData,
  setCategorialInputList,
  categorialInputList,
  range,
  categoricalRange,
  currentSelectedStage,
  isMultiStage,
  catConstraintsList,
  setCatConstraintsList,
  selectAllIngredientsInCategory,
  setSelectAllIngredientsInCategory,
  exludeAllParameterConstraints,
  setExludeAllParameterConstraints,
  exludeAllCategoryConstraints,
  setExludeAllCategoryConstraints,
  isCrossCategorical,
  setIsCrossCategorical,
  setIsCrossCategoricalModalVisible,
  isCategoricalConstraintsUseDefault,
  setIsCategoricalConstraintsUseDefault,
  includeMetadata
}: P) {
  const labels = useSelector(
    (state: StoreState) => state.displayNames.data || {},
  )

  const categorialConstraintsData = useMemo(() => {
    return Object.entries(
      modelData?.inverse_input_constraints ?? modelData?.properties ?? {},
    ).filter(([key, value]) => Array.isArray(value))
  }, [modelData])

  const inputContraintsData = useMemo(() => {
    if (modelData?.properties) {
      const numericalKeys = range.map((r: any) => r?.parameter)
      const categoricalKeys = categoricalRange.map((r: any) => r?.parameter)
      const allKeys = [...numericalKeys, ...categoricalKeys]

      const stages = [
        ...new Set(
          allKeys?.flatMap((key: any) => {
            return Object.keys(modelData?.items_per_property?.[key] ?? {})
          }),
        ),
      ]

      const data = allKeys?.map((key: any) => {
        return modelData.items_per_property?.[key]
      })
      const result = [
        ...new Set(
          stages.flatMap((stage) => data?.flatMap((d: any) => d?.[stage])),
        ),
      ]

      const res = result.flatMap((r: any) => {
        return stages.flatMap((stage) =>
          Array.isArray(modelData?.[stage]?.[r]) && modelData?.[stage]?.[r]
            ? { [r]: modelData?.[stage]?.[r] }
            : { [r]: modelData?.[stage]?.[r] },
        )
      })

      const finalObj = res.reduce((acc: any, curr: any) => {
        if (Object.values(curr)?.[0] !== undefined) {
          return { ...acc, ...curr }
        }
        return acc
      }, {})
      return finalObj
    } else {
      if(includeMetadata) {
        const invInputConstraint = _.cloneDeep(modelData?.inverse_input_constraints);
        const invInputCategoryRanges = modelData?.input_ing_cat_range;
        const metaInputIngredients = modelData?.inputs_ingredients_others;
        const metaIngredientsPerCatgory = modelData?.meta_ings_per_category;

        (metaInputIngredients || []).forEach((ingredient: any) => {
          const ingredientCatgory = Object.keys(metaIngredientsPerCatgory || {}).find((category: any) => metaIngredientsPerCatgory[category]?.includes(ingredient))

          if (ingredientCatgory) {
            invInputConstraint[ingredient] = invInputCategoryRanges?.[ingredientCatgory];
          }
        });

        return invInputConstraint;
      } else return modelData?.inverse_input_constraints;
    }
  }, [modelData, range, categoricalRange, includeMetadata])

  const onLoadDefaultConstraints = useCallback(() => {
    const defaultCategoryConstraints = modelData?.default_category_constraints

    if (Object.keys(defaultCategoryConstraints || {}).length === 0) return


    setCatConstraintsList((prevState: any) => {
      const newState = JSON.parse(JSON.stringify(prevState))
      newState?.[currentSelectedStage]?.forEach((catConstraint: any) => {
        const type = catConstraint?.type
        const category = catConstraint?.category
        const constraintType = catConstraint?.constraint

        const defaultConstraint = defaultCategoryConstraints?.[type]?.[category]?.[constraintType]
        for (const key of Object.keys(defaultConstraint || {})) {
          catConstraint[key] = defaultConstraint[key]
        }

        for (const key of CATEGORY_CONSTRAINTS_ERROR_KEYS) {
          delete catConstraint[key]
        }
      })
      return newState
    })

    setCatwiseParameterList((prevState: any) => {
      const newState = JSON.parse(JSON.stringify(prevState))
      newState[currentSelectedStage] = prevState[currentSelectedStage].map((typeObj: any) => {
        const newTypeObj = {
          ...typeObj,
          categories: typeObj.categories.map((catObj: any) => {
            const exclude = defaultCategoryConstraints?.[typeObj.parameter_type]?.[catObj.category]?.exclude
            const include = defaultCategoryConstraints?.[typeObj.parameter_type]?.[catObj.category]?.include
            return {
              ...catObj,
              include,
              exclude,
            }
          })
        }
        return newTypeObj
      })

      return newState
    })

  }, [currentSelectedStage, modelData?.default_category_constraints, setCatConstraintsList, setCatwiseParameterList])

  return (
    <Space direction="vertical" style={{ width: "100%" }} size={"middle"}>
      <ConstraintsInfo onLoadDefaultConstraints={onLoadDefaultConstraints} />
      {modelData?.version >= 0 ? (
        <BaseInputConstraints labels={labels} modelData={modelData} inputContraintsData={inputContraintsData} />
      ) : null}

      <InputConstraints
        categoricalRange={categorialConstraintsData}
        categorialInputList={categorialInputList}
        setCategorialInputList={setCategorialInputList}
        range={range}
        parameterList={parameterList}
        setParameterList={setParameterList}
        catwiseParameterList={catwiseParameterList}
        setCatwiseParameterList={setCatwiseParameterList}
        modelData={modelData}
        setUnsavedChanges={setUnsavedChanges}
        inputContraintsData={inputContraintsData}
        currentSelectedStage={currentSelectedStage}
        isMultiStage={isMultiStage}
        catConstraintsList={catConstraintsList}
        setCatConstraintsList={setCatConstraintsList}
        selectAllIngredientsInCategory={selectAllIngredientsInCategory}
        setSelectAllIngredientsInCategory={setSelectAllIngredientsInCategory}
        exludeAllParameterConstraints={exludeAllParameterConstraints}
        setExludeAllParameterConstraints={setExludeAllParameterConstraints}
        exludeAllCategoryConstraints={exludeAllCategoryConstraints}
        setExludeAllCategoryConstraints={setExludeAllCategoryConstraints}
        isCrossCategorical={isCrossCategorical}
        setIsCrossCategorical={setIsCrossCategorical}
        setIsCrossCategoricalModalVisible={setIsCrossCategoricalModalVisible}
        isCategoricalConstraintsUseDefault={isCategoricalConstraintsUseDefault}
        setIsCategoricalConstraintsUseDefault={setIsCategoricalConstraintsUseDefault}
        includeMetadata={includeMetadata}
      />
    </Space>
  )
}

const ConstraintsInfo = ({ onLoadDefaultConstraints }: { onLoadDefaultConstraints: any }) => {

  const [t] = useTranslate()
  const [isInfoSectionOpen, setIsInfoSectionOpen] = useState(false)

  return <>
    <Space direction='vertical' style={{ width: '100%' }}>
      <Space style={{ justifyContent: 'space-between', width: '100%' }} >
        <Space style={{ gap: 0 }}>
          <Typography.Text strong>{t('aiEngine.constraints.infoTitle')}</Typography.Text>
          <StyledButton size="small" type="link" onClick={() => setIsInfoSectionOpen(prev => !prev)} style={{ outline: 'none' }}>
            {isInfoSectionOpen ? t('common.hideInfo') : t('common.showInfo')}
          </StyledButton>
        </Space>
        <LoadDefaultConstraintsButton onClick={
          () => {
            onLoadDefaultConstraints()
          }
        } />
      </Space>
      <Alert message={
        <Typography.Paragraph>
          <Typography.Text strong>{t("common.note")}{": "}</Typography.Text>
          <Typography.Text>{t('aiEngine.inverse.defaultConstraintsNote')}</Typography.Text>
        </Typography.Paragraph>
      } type="info" 
      style={{
        border: 'none',
        padding: '1rem',
      }}
      closable/>
    </Space>
    {isInfoSectionOpen && <Alert
      description={
        <ConstraintsInfoContent />
      }
      type="info"
      style={{
        border: "none",
        padding: "0px 16px",
        paddingTop: '16px',
        display: isInfoSectionOpen ? "flex" : "none",
        transition: "display 0.5s ease-in-out",
        width: "100%",
        backgroundColor: "transparent",
      }}
    />}
  </>
}

const ConstraintsInfoContent = () => {
  const [t] = useTranslate()

  return (
    <Space direction='vertical' size='middle'>
      <Typography.Paragraph>
        <Typography.Text strong>
          {t('common.noteThat')}{': '}
        </Typography.Text>
        <Typography.Text>
          {t('aiEngine.constraints.infoNote')}
        </Typography.Text>
      </Typography.Paragraph>

      <Typography.Paragraph>
        <Typography.Text strong>
          {t('aiEngine.inclusionMode')}
        </Typography.Text>

        <ul>
          <li>{t('aiEngine.constraints.infoInclusionModes1')}</li>
          <li>{t('aiEngine.constraints.infoInclusionModes2')}</li>
          <li>{t('aiEngine.constraints.infoInclusionModes3')}</li>
        </ul>
      </Typography.Paragraph>

      <Typography.Paragraph>
        <Typography.Text strong>
          {t('common.categoryConstraints')}
        </Typography.Text>

        <ol>
          <li>{t('aiEngine.constraints.infoCatConstraints1')}</li>
          <li>{t('aiEngine.constraints.infoCatConstraints2')}</li>
          <li>{t('aiEngine.constraints.infoCatConstraints3')}</li>
          <li>{t('aiEngine.constraints.infoCatConstraints4')}</li>
        </ol>
      </Typography.Paragraph>

      <Typography.Paragraph>
        <Typography.Text strong>
          {t('common.parameterConstraints')}
        </Typography.Text>

        <ul>
          <li>{t('aiEngine.constraints.infoParamConstraints1')}</li>
          <li>{t('aiEngine.constraints.infoParamConstraints2')}</li>
        </ul>
      </Typography.Paragraph>

      <Typography.Paragraph>
        <Typography.Text strong>
          {t('aiEngine.inverse.addDefaultConstraints')}
        </Typography.Text>

        <ul>
          <li>{t('aiEngine.inverse.infoDefaultConstraints1')}</li>
          <li>{t('aiEngine.inverse.infoDefaultConstraints2')}</li>
        </ul>
      </Typography.Paragraph>
    </Space>
  )
}

const LoadDefaultConstraintsButton = ({ onClick }: { onClick: any }) => {
  const [t] = useTranslate()
  return (
    <Tooltip title={t('aiEngine.inverse.defaultConstraintsTooltip')} >
      <StyledButton onClick={onClick} type="default" icon={<BuildOutlined />}>
        {`${t('aiEngine.inverse.addDefaultConstraints')}`}
      </StyledButton>
    </Tooltip>
  )
}