import React from 'react'

const EllipseL = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="3" height="6" viewBox="0 0 3 6" fill="none">
      <path d="M3 3C3 6 0 6 0 6V0C0 0 3 0 3 3Z" fill="#1F1F1F" />
    </svg>
  )
}

export default EllipseL
