import { RightOutlined } from "@ant-design/icons"
import { Breadcrumb, Typography } from "antd"
import { ItemType } from "antd/lib/breadcrumb/Breadcrumb"

import './BreadCrumb.scss'

type P = {
  items: ItemType[]
  selectedId?: string
  breadCrumbOnClick?: (route: ItemType) => void
}

const BreadCrumb = ({ items, selectedId, breadCrumbOnClick }: P) => {
  return (
    <Breadcrumb
      items={items}
      style={{ padding: 4, userSelect: 'none' }}
      separator={
        <span style={{ color: '#c1c1c1', padding: '0px 8px' }}>
          <RightOutlined />
        </span>
      }
      itemRender={(route) => {
        return <Typography.Text style={{
          padding: '4px 12px',
          borderRadius: 4,
          cursor: 'pointer',
          color: selectedId === route.key ? '#000' : '#c1c1c1',
          fontWeight: 'bold'
        }}
          className="bread-crumb-text"
          onClick={() => breadCrumbOnClick && breadCrumbOnClick(route)}
        >
          {route.title}
        </Typography.Text>
      }}

    />
  )
}

export default BreadCrumb