import { Descriptions, Input, Space } from 'antd'
import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { antdTheme } from 'src/constants'
import { formulateRequest } from 'src/store/actions/formulate'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import { ModelConfig } from 'src/typings'
import { useQuery } from 'src/utils/useQuery'
import useTranslate from 'src/utils/useTranslate'
import { PARAMETER_NAME, PARAMETER_TYPE, POSSIBLE_PARAMETER_TYPES } from './UploadComponent'

const ReviewAndPredictStep = ({
  rowToParameterMap,
  sheetToStageMap,
  fileJson,
  trialDisplayNameList,
  isMultiStageModel,
  selectedStages
}: {
  rowToParameterMap: any
  sheetToStageMap: any
  fileJson: any
  trialDisplayNameList: any
  isMultiStageModel: boolean
  selectedStages: [number, number]
}) => {
  const [t] = useTranslate()
  const dispatch = useDispatch()

  const [objective, setObjective] = useState('')

  const configData = useSelector((state: StoreState) => state.formulate.configData) as ModelConfig[]
  let query = useQuery()
  let modelVersion = query?.get('version')

  const modelConfigData = useSelector((state: StoreState) => state.formulate.modelConfigData)

  const validMappedSheets = Object.keys(sheetToStageMap).filter(
    (sheetName) => sheetToStageMap[sheetName].stageNumber !== -1 && sheetToStageMap[sheetName].stageNumber !== null
  )

  const sheetWiseTrialKeys = validMappedSheets.reduce((acc: any, sheetName: string) => {
    acc[sheetName] = Object.keys(fileJson[sheetName][0]).filter((key) => key !== PARAMETER_TYPE && key !== PARAMETER_NAME)
    return acc
  }, {})

  const predict = useCallback(() => {
    const intermediatePayload: any = {}

    const config = configData.find((res: any) => res?.version === Number(modelVersion))



    validMappedSheets.forEach((sheetName: string) => {
      const stageName = sheetToStageMap[sheetName].stageName
      const stageNumber = sheetToStageMap[sheetName].stageNumber
      intermediatePayload[stageName] = {
        input_data: sheetWiseTrialKeys[sheetName].map((trialName: any, idx: number) => {
          const stagewiseSheetData = fileJson[sheetName]

          const ingredients: any = {}
          const processing: any = {}

          stagewiseSheetData.forEach((row: any) => {
            const type = row[PARAMETER_TYPE].trim().toLowerCase()
            const parameter = row[PARAMETER_NAME]

            if (POSSIBLE_PARAMETER_TYPES.includes(type)) {
              const parameterIdentifier = rowToParameterMap[sheetName][type][parameter] || parameter

              if (type === 'ingredients' && parameterIdentifier !== 'ignore') {
                ingredients[parameterIdentifier] = row[trialName]
              }

              if (type === 'processing' && parameterIdentifier !== 'ignore') {
                processing[parameterIdentifier] = row[trialName]
              }
            }
          })

          const trial_name = (trialDisplayNameList?.[stageNumber]?.[idx]?.label || `Stage ${stageNumber}: Trial ${idx + 1}`)

          return {
            ...(isMultiStageModel ? { trial_name: trialDisplayNameList?.[stageNumber]?.[idx]?.value } : {}),
            ingredients,
            processing,
            trial_name: isMultiStageModel 
              ? trial_name
              : trial_name.split(':')[1].trim(),
            trial_id: trialDisplayNameList?.[stageNumber]?.[idx]?.value
          }
        }),
        modelNames: config?.model_names?.[stageName],
        base_unit: config?.base_unit?.[stageName]
      }
    })

    let finalPayload: any = {}

    if (isMultiStageModel) {
      const stages = isMultiStageModel ? Array.from({ length: selectedStages[1] - selectedStages[0] + 1 }, (_, i) => selectedStages[0] + i) : [1]
      const updatedStages = stages.length === 1 ? [stages[0], stages[0]] : stages
      const startStage = updatedStages[0]
      const endStage = updatedStages?.[updatedStages.length - 1]
      finalPayload = {
        isMultiStage: isMultiStageModel,
        objective,
        version: modelVersion,
        start_stage: modelConfigData?.[0]?.all_stages?.[startStage - 1] ?? `Stage ${startStage}`,
        end_stage: modelConfigData?.[0]?.all_stages?.[endStage - 1] ?? `Stage ${endStage}`,
        data: intermediatePayload
      }
    } else {
      finalPayload = {
        ...intermediatePayload['Default'],
        objective,
        version: modelVersion,
        modelNames: configData.find((res: any) => res?.version === Number(modelVersion))?.model_names,
        base_unit: configData.find((res: any) => res?.version === Number(modelVersion))?.base_unit
      }
    }

    dispatch(formulateRequest(finalPayload))
  }, [
    configData,
    validMappedSheets,
    isMultiStageModel,
    modelVersion,
    sheetToStageMap,
    sheetWiseTrialKeys,
    fileJson,
    trialDisplayNameList,
    rowToParameterMap,
    selectedStages,
    objective,
    modelConfigData,
    dispatch
  ])

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <div
        style={{
          display: 'flex',
          gap: '1rem'
        }}
      >
        <Input
          value={objective}
          onChange={(e) => setObjective(e.target.value)}
          placeholder={t('aiEngine.briefDescription')}
          style={{ flexGrow: 1 }}
        />
        <StyledButton
          onClick={() => {
            predict()
          }}
          type="primary"
        >
          {t('aiEngine.predict')}
        </StyledButton>
      </div>
      <Descriptions
        bordered
        column={1}
        size="middle"
        items={Object.keys(rowToParameterMap)
          .map((sheetName) => {
            if (validMappedSheets.includes(sheetName)) {
              const totalTrials = sheetWiseTrialKeys[sheetName].length
              const totalIngredients = Object.values(rowToParameterMap[sheetName].ingredients).filter((param) => param !== 'ignore').length
              const totalProcessing = Object.values(rowToParameterMap[sheetName].processing).filter((param) => param !== 'ignore').length

              return {
                label: sheetToStageMap[sheetName].stageName,
                children: (
                  <Space size={'middle'}>
                    <span>
                      {t('common.trials')}: <span style={{ fontWeight: 800 }}>{totalTrials}</span>
                    </span>
                    <span>{'•'}</span>
                    <span>
                      {t('common.ingredients')}: <span style={{ fontWeight: 800 }}>{totalIngredients}</span>
                    </span>
                    <span>{'•'}</span>

                    <span>
                      {t('common.processing')}: <span style={{ fontWeight: 800 }}>{totalProcessing}</span>
                    </span>
                  </Space>
                )
              }
            }
            return null
          })
          .filter((item) => item !== null)}
        labelStyle={{ fontWeight: 'bold', fontSize: antdTheme.fontSize }}
        contentStyle={{ fontSize: antdTheme.fontSize }}
      />
    </div>
  )
}

export default ReviewAndPredictStep
