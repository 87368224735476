import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react"
import { Breadcrumb, Dropdown, notification, Space, Spin, Tabs, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import {
  changeTitleForwardClear,
  formulateCleanup,
  forwardPredListRequest,
  modelsConfigCleanup,
  modelsConfigListRequest,
} from "src/store/actions/formulate"
import {
  changeTitleClear,
  suggestedExperimentsClear,
} from "src/store/actions/suggestedExp"
import { AsyncStates } from "src/constants";
import {
  getPredictionRequest,
  inverseNotificationClick,
} from "src/store/actions/inverseModel"
import { workOrdersRequest } from "../../store/actions/workOrders"
import { ForwardModel } from "./ForwardModel"
import { CustomInsights } from "./CustomInsights/CustomInsights"
import useTranslate from "src/utils/useTranslate"
import { ArrowLeftOutlined, LoadingOutlined, MoreOutlined, PlusOutlined } from "@ant-design/icons"
import { filteredDisplayNamesRequest } from "src/store/actions/displayNames"
import { StyledPageHeader } from "src/styled_components/StyledPageHeader"
import { StyledCard } from "src/styled_components/StyledCard"
import { editWorkOrderClear } from "src/store/actions/workOrderDetails"
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useLocation,
} from "react-router-dom"
import { trackEvent } from "src/analytics"
import { deleteEquationRequest, filterDataRequest } from "src/store/actions/customML"
// import { PolyGPT } from "../../modules/PolyGPT";
import InversePrediction from "./InversePrediction"
import { linkedFormulationsAiEngineClear } from "src/store/actions/common"
import InverseResults from "./InverseResults/InverseResults"
import { Filter } from "./InverseModelNew/catwise/inverse-model"
import InverseResult from "./InverseResults/InverseResult"
import { useQuery } from "src/utils/useQuery"
import { ForwardResults } from "./ForwardModel/ForwardResults"
import ForwardResult from "./ForwardModel/ForwardResult"
import "./AIEngine.scss"
import CustomMLWrapper from "./CustomML/CustomMlWrapper"
import { EquationModel } from "./CustomML/EquationModel"
import { useVariableValue } from "@devcycle/devcycle-react-sdk"
import StyledBadge from "src/styled_components/StyledBadge"
import { EquationModelHistory } from "./CustomML/EquationModelHistory";
import { StyledButton } from "src/styled_components/StyledButton";

type GettabsType = {
  [key: string]: string[]
}

export type FilterForward = {
  pageNum: number
  user_ids: string[]
  model_type: string[]
  status: string[]
  versions: string[]
}

export const AIEngine = () => {
  const [t] = useTranslate()
  const dispatch = useDispatch()
  let query = useQuery()
  const configs = useSelector((state: StoreState) => state.configs.features)
  const ai_engine = useSelector((state: StoreState) => state.configs.ai_engine)
  const { current: currentProject, selectAllProjects } = useSelector(
    (state: StoreState) => state.projects,
  )

  const [experimentsCurrent, setExperimentsCurrent] = useState<any>(1)
  const [selectedObjective, setSelectedObjective] = useState("")
  const [inverseFilters, setInverseFilters] = useState<Filter | null>(null)
  const [forwardFilters, setForwardFilters] = useState<FilterForward | null>(
    null,
  )
  const [inverseCurrent, setInverseCurrent] = useState<any>(1)
  const [forwardCurrent, setForwardCurrent] = useState(1)

  const { state } = useLocation()
  const { push } = useHistory()
  const [tab, setTab] = useState<any>("properties_prediction")
  const [customMlDrawerVisible, setCustomMlDrawerVisible] = useState(false)
  const { configStatus } = useSelector((state: StoreState) => state.formulate)
  const inverseModel = useSelector((state: StoreState) => state.inverseModel)
  const {
    forwardPredList: { forwardData },
  } = useSelector((state: StoreState) => state.formulate)
  const { formulationfilterData } = useSelector(
    (state: StoreState) => state.CustomML,
  )
  const userId = useSelector(
    (state: StoreState) => state.login.loginResponse.user_id,
  )
  const eqModelList = useSelector(
    (state: StoreState) => state.CustomML.equationModelListData,
  )

  const { section, tab: _tab, id } = useParams<{
    section: string
    tab: string
    id: string
  }>()

  const predTitle = useMemo(() => eqModelList.find(model => model.run_id === id)?.title, [eqModelList, id])

  useEffect(() => {
    setTab(_tab ?? "properties_prediction")
  }, [_tab])

  useEffect(() => {
    dispatch(
      workOrdersRequest({
        project_id: selectAllProjects ? null : currentProject,
        closed_stages: true,
      }),
    )
    dispatch(
      filteredDisplayNamesRequest(
        selectAllProjects ? {} : { project_id: currentProject },
      ),
    )
  }, [dispatch, currentProject, selectAllProjects, configs])

  useLayoutEffect(() => {
    dispatch(modelsConfigListRequest())
    return () => {
      dispatch(suggestedExperimentsClear())
      dispatch(formulateCleanup())
      dispatch(editWorkOrderClear())
      dispatch(linkedFormulationsAiEngineClear())
    }
  }, [dispatch])

  useEffect(() => {
    if (inverseModel.inverseNotificationClicked) {
      setTab("formulation_prediction")
      // setDrawerVisible(true)
      setTimeout(
        () => document.getElementById("inversepred-results")?.scrollIntoView(),
        300,
      )
    }
    return () => {
      dispatch(inverseNotificationClick(false))
    }
  }, [inverseModel.inverseNotificationClicked, dispatch])

  useEffect(() => {
    if (
      state &&
      Object.keys(state).includes("formulation_ids") &&
      _tab === "custom_ml_result" &&
      formulationfilterData.dataframe.length === 0
    ) {
      dispatch(
        filterDataRequest({
          payload: state,
          isTrialBased: true,
          redirect: false,
        }),
      )
    }
    return
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showEquationModel = useVariableValue("equation-model", false)

  const gettabs: GettabsType = useMemo(
    () => ({
      predict: ["properties_prediction", "formulation_prediction"],
      history: ["properties_prediction", "formulation_prediction"],
      new_model: [
        ...(!Boolean(configs?.ai_engine_with_methods)
          ? [
            "custom_ml",
            ...(showEquationModel ? ["eq_model"] : []),
            ...(_tab === "custom_ml_result" ||
              (state && Object.keys(state).includes("formulation_ids"))
              ? ["custom_ml_result"]
              : []),
          ]
          : []),
      ],
      insights: [
        ...(Boolean(configs?.custom_insights) ? ["custom_insights"] : []),
      ],
    }),
    [_tab, configs?.ai_engine_with_methods, configs?.custom_insights, showEquationModel, state],
  )

  useEffect(() => {
    if (!gettabs?.[section]?.includes(_tab)) {
      if (section === "new_model" && _tab === "model_analysis") {
        push("/ai-engine/model_analysis")
      } else {
        push("/404")
      }
    }
  }, [gettabs, section, _tab, push])

  const companyId = useSelector(
    (state: StoreState) => state.login.loginResponse.company_id,
  )
  const getTranslatedKey = useCallback(
    (tabname: string) => {
      switch (tabname) {
        case "properties_prediction":
          return t("aiEngine.propertiesPrediction")
        case "formulation_prediction":
          return t("aiEngine.formulationsProcessingsPrediction")
        case "custom_ml":
          return t("aiEngine.tab.custom_ml")
        case "custom_ml_result":
          return "Custom ML Result"
        case "model_analysis":
          return t("aiEngine.tab.model_analysis")
        case "data_summary":
          return t("aiEngine.tab.data_summary")
        case "custom_insights":
          return t("aiEngine.tab.customInsights")
        case "predict":
          return t("aiEngine.predict")
        case "history":
          return t("aiEngine.predictionHistory")
        case "new_model":
          return t("navBar.menuItem.aiEngine.newModel")
        case "eq_model":
          return "Equation Model"
        case "insights":
          return "Insights"
        default:
          return tabname
      }
    },
    [t],
  )
  const getBadge = useCallback(
    (tabname) => {
      if (section === "history") {
        if (tabname === "properties_prediction") {
          return ai_engine?.forward?.total_unread_count > 0 ? (
            <StyledBadge
              count={ai_engine?.forward?.total_unread_count}
              overflowCount={10}
              showZero={false}
            />
          ) : null
        }
        if (tabname === "formulation_prediction") {
          return ai_engine?.inverse?.total_unread_count > 0 ? (
            <StyledBadge
              count={ai_engine?.inverse?.total_unread_count}
              overflowCount={10}
              showZero={false}
            />
          ) : null
        }
      }
    },
    [
      ai_engine?.forward?.total_unread_count,
      ai_engine?.inverse?.total_unread_count,
      section,
    ],
  )

  const getTabs = useMemo(() => {
    return gettabs[section]
      ?.filter((tabname) => {
        if (companyId === "COMP140MGVSEQE2023") {
          if (tabname === "formulation_prediction") {
            return false
          }
        }
        return true
      })
      ?.map((tabname) => (
        <Tabs.TabPane
          tab={
            <div style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                {getTranslatedKey(tabname)} {getBadge(tabname)}
              </span>
              {/* <GuideTooltip
											placement="bottomRight"
											module="properties_prediction"
										/> */}
            </div>
          }
          key={tabname}
        />
      ))
  }, [section, companyId, getTranslatedKey, gettabs, getBadge])

  const [breadCrumbItems, setBreadCrumbItems] = useState<any[]>([])

  const {
    expIdStatus,
    data: { experiments },
  } = useSelector((state: StoreState) => state.suggestedExp)

  const {
    forwardPredResult: { predictions: forwardPredResult = [] },
  } = useSelector((state: StoreState) => state.formulate)

  const { changeTitleResponse, changeTitleStatus } = useSelector(
    (state: StoreState) => state.suggestedExp,
  )

  const {
    changeTitleResponse: changeTitleResponseForward,
    changeTitleStatus: changeTitleStatusForward,
  } = useSelector((state: StoreState) => state.formulate)

  const predId = query?.get("predId")
  const predDetailsInverse = useMemo(() => {
    return inverseModel?.predictionIdsData?.data?.find(
      (pred: any) => pred.prediction_id === predId,
    )
  }, [inverseModel?.predictionIdsData?.data, predId])

  const predDetailsForward = useMemo(() => {
    return forwardPredResult?.find((pred: any) => pred.prediction_id === predId)
  }, [forwardPredResult, predId])

  useEffect(() => {
    setBreadCrumbItems([
      {
        title: getTranslatedKey(section),
      },
      { title: getTranslatedKey(_tab) },
      ...(predId
        ? [
          _tab === "formulation_prediction"
            ? {
              title: (
                <Typography.Text
                  style={{ width: 200 }}
                  ellipsis={{
                    tooltip:
                      changeTitleStatus === AsyncStates.SUCCESS
                        ? changeTitleResponse?.title
                        : predDetailsInverse?.title,
                  }}
                >
                  {changeTitleStatus === AsyncStates.SUCCESS
                    ? changeTitleResponse?.title
                    : predDetailsInverse?.title}
                </Typography.Text>
              ),
            }
            : {
              title: (
                <Typography.Text
                  style={{ width: 200 }}
                  ellipsis={{
                    tooltip:
                      changeTitleStatusForward === AsyncStates.SUCCESS
                        ? changeTitleResponseForward?.title
                        : predDetailsForward?.objective,
                  }}
                >
                  {changeTitleStatusForward === AsyncStates.SUCCESS
                    ? changeTitleResponseForward?.title
                    : predDetailsForward?.objective}
                </Typography.Text>
              ),
            },
        ]
        : []),
    ])
  }, [_tab, section, forwardData.predictions_list, getTranslatedKey, inverseModel.predictionIdsData.data, predId, expIdStatus, experiments, t, predDetailsForward, changeTitleStatus, changeTitleResponse?.title, predDetailsInverse?.title, changeTitleStatusForward, changeTitleResponseForward?.title])

  useEffect(() => {
    return () => {
      dispatch(changeTitleClear())
      dispatch(changeTitleForwardClear())
    }
  }, [dispatch])

  useEffect(() => {
    if (section === "history" && _tab === "formulation_prediction") {
      dispatch(
        getPredictionRequest({
          pageNum: 1,
        }),
      )
    }
  }, [_tab, section, dispatch])

  useEffect(() => {
    if (section === "history" && _tab === "properties_prediction" && !predId) {
      dispatch(forwardPredListRequest({ pageNum: forwardCurrent }))
    }
  }, [_tab, section, dispatch, predId, forwardCurrent])

  useEffect(() => {
    return () => { dispatch(modelsConfigCleanup()) }
  }, [_tab, dispatch])

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const deleteHistory = () => {
    if (!selectedRowKeys.length) {
      notification.warning({
        message: "Please select a model.",
      });
      return;
    }
    dispatch(deleteEquationRequest({ run_ids: selectedRowKeys }))
  }

  return (
    <Space
      direction="vertical"
      size="large"
      className="gap-8"
      style={{ width: "100%" }}
    >
      <StyledPageHeader
        ghost={false}
        title={
          <Space direction="vertical">
            <Space style={{ display: 'flex', alignItems: 'center' }} >
              {((section === "history" && predId) || (id)) && <ArrowLeftOutlined
                onClick={() => {
                  window.history.back()
                }}
                style={{
                  cursor: "pointer",
                  marginRight: 10,
                  outline: "none",
                }}
              />}
              <Typography.Title level={3} style={{ marginBottom: 0 }}>
                {predTitle || getTranslatedKey(section)}
              </Typography.Title>
            </Space>
            <Breadcrumb items={breadCrumbItems} />
          </Space>
        }
        extra={(_tab === "eq_model" && !id) && (
          <Space>
            <StyledButton icon={<PlusOutlined />} size="large" type="primary" onClick={() => { push(`/ai-engine/new_model/eq_model/new`, { work_orders: [] }) }}>{t("navBar.menuItem.aiEngine.newModel")}</StyledButton>
            <Dropdown menu={{
              items: [
                {
                  key: '1',
                  label: t("aiEngine.deleteModels"),
                }],
              onClick: deleteHistory
            }}>
              <StyledButton icon={<MoreOutlined />} size="large"/>
            </Dropdown>
          </Space>
        )}
      />
      <StyledCard bodyStyle={{ padding: "0px" }}>
        <Spin
          indicator={<LoadingOutlined />}
          spinning={configStatus === AsyncStates.LOADING}
          tip={"Loading..."}
          style={{ height: "100%", width: "100%" }}
        >
          {getTabs?.length > 0 && !predId && (
            <Tabs
              onChange={(e) => {
                trackEvent(userId, "AI Engine -> " + e + " was viewed", {
                  tab,
                  currentProject,
                })
                push(`/ai-engine/${section}/${e}`, state)
              }}
              activeKey={_tab}
              size="large"
              style={{ padding: "1rem", paddingTop: "0" }}
            >
              {getTabs}
            </Tabs>
          )}
          <Switch>
            <Route path="/ai-engine/predict/properties_prediction">
              <ForwardModel tab={tab} />
            </Route>
            <Route path="/ai-engine/predict/formulation_prediction">
              <InversePrediction
                tab={tab}
                selectedObjective={selectedObjective}
                setSelectedObjective={setSelectedObjective}
                experimentsCurrent={experimentsCurrent}
                setExperimentsCurrent={setExperimentsCurrent}
                inverseCurrent={inverseCurrent}
                inverseFilters={inverseFilters}
              />
            </Route>
            <Route path="/ai-engine/history/formulation_prediction" exact>
              {predId ? (
                <InverseResult
                  tab={tab}
                  selectedObjective={selectedObjective}
                  experimentsCurrent={experimentsCurrent}
                  setExperimentsCurrent={setExperimentsCurrent}
                  setSelectedObjective={setSelectedObjective}
                />
              ) : (
                <InverseResults
                  selectedObjective={selectedObjective}
                  inverseFilters={inverseFilters}
                  setInverseFilters={setInverseFilters}
                  inverseCurrent={inverseCurrent}
                  setInverseCurrent={setInverseCurrent}
                />
              )}
            </Route>

            <Route path="/ai-engine/history/properties_prediction" exact>
              {predId ? (
                <ForwardResult 
                  tab={tab} 
                  selectedObjective={selectedObjective}
                  experimentsCurrent={experimentsCurrent}
                  setExperimentsCurrent={setExperimentsCurrent}
                  setSelectedObjective={setSelectedObjective}
                />
              ) : (
                <ForwardResults
                  forwardFilters={forwardFilters}
                  setForwardFilters={setForwardFilters}
                  forwardCurrent={forwardCurrent}
                  setForwardCurrent={setForwardCurrent}
                />
              )}
            </Route>

            <Route path="/ai-engine/new_model/eq_model" exact>
              <EquationModelHistory
                customMlDrawerVisible={customMlDrawerVisible}
                setCustomMlDrawerVisible={setCustomMlDrawerVisible}
                onSelectChange={onSelectChange}
                selectedRowKeys={selectedRowKeys}
              />
            </Route>

            <Route path="/ai-engine/new_model/eq_model/:runid">
              <EquationModel
                customMlDrawerVisible={customMlDrawerVisible}
                setCustomMlDrawerVisible={setCustomMlDrawerVisible}
              />
            </Route>

            <Route path="/ai-engine/new_model/custom_ml">
              <CustomMLWrapper
                customMlDrawerVisible={customMlDrawerVisible}
                setCustomMlDrawerVisible={setCustomMlDrawerVisible}
              />
            </Route>
            {/* <Route path="/ai-engine/new_model/model_analysis">
              <ModelAnalysis />
            </Route> */}
            <Route path="/ai-engine/insights/custom_insights">
              <CustomInsights />
            </Route>
          </Switch>
        </Spin>
      </StyledCard>
    </Space>
  )
}
