import React, { useEffect, useState } from "react"
import { Input, Popconfirm, Space, Typography } from "antd"
import { StyledButton } from "src/styled_components/StyledButton"
import useTranslate from "src/utils/useTranslate"
import { useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"
import { COLLAPSED_SIDEBAR_WIDTH, OPEN_SIDEBAR_WIDTH } from "src/router/AppRouter"
import { useQuery } from "src/utils/useQuery"
const { Text } = Typography

type P = {
	clearState: () => void
	onPredictClick: (value: string) => void
}

export const FooterForm = ({
	clearState,
	onPredictClick,
}: P) => {
	const [t] = useTranslate()
	const isSidebarCollapsed = useSelector((state: StoreState) => state.sidebar.collapsed)
	const { current: currentProject } = useSelector((state: StoreState) => state.projects)

	const query = useQuery()
	const modelVersion = query?.get("version")

	const [objective, setObjective] = useState("")

	const perdict = () => {
		onPredictClick(objective)
		setObjective("")
	}

	const clearForm = () => {
		clearState()
		setObjective("")
	}

	useEffect(() => {
		setObjective("")
	}, [modelVersion, currentProject])

	return (
		<div style={{
			display: "flex",
			justifyContent: "space-between",
			padding: '10px 96px 10px 32px',
			marginLeft: isSidebarCollapsed ? COLLAPSED_SIDEBAR_WIDTH : OPEN_SIDEBAR_WIDTH,
			background: '#FAFAFA',
			position: 'fixed',
			bottom: 0,
			right: 0,
			width: '-webkit-fill-available',
			zIndex: 3
		}}>
			<Popconfirm
				okText={t("common.ok")}
				cancelText={t("common.cancel")}
				title={t("common.resetTable")}
				onConfirm={clearForm}
			>
				<StyledButton type="primary" ghost>
					{t("compare.clear")}
				</StyledButton>
			</Popconfirm>

			<Space direction="vertical">
				<Space>
					<Text strong>{t("aiEngine.objective")}: </Text>
					<Input
						onChange={(e) => setObjective(e.target.value)}
						value={objective}
						placeholder={t("aiEngine.briefDescription")}
						style={{ width: 400 }}
					/>
					<StyledButton type="primary" onClick={perdict}>
						{t("aiEngine.predict")}
					</StyledButton>
				</Space>
			</Space>
		</div>
	)
}
