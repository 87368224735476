import {
  sendPost,
  sendGet,
  sendPut,
  sendDelete,
  sendPatch,
} from "../../utils/sendApi";

const inventoryAPIPrefix = "v1/data/inventory_v2";
const familyAPIPrefix = "v1/data/family";

// Suppliers
export const getSuppliersApi = sendGet(`${inventoryAPIPrefix}/suppliers`);

export const createSupplierApi = sendPost(`${inventoryAPIPrefix}/suppliers`);

export const createIngredientFromTrialApi = sendPost(
  `${inventoryAPIPrefix}/ingredients/create_from_trial`
);

export const getSupplierDetailsApi = (params: { supplier_id: string }) =>
  sendPost(`${inventoryAPIPrefix}/suppliers/${params.supplier_id}`);

export const updateSupplierApi = (params: { supplier_id: string }) =>
  sendPut(`${inventoryAPIPrefix}/suppliers/${params.supplier_id}`);

export const deleteSupplierApi = (params: { supplier_id: string }) =>
  sendDelete(`${inventoryAPIPrefix}/suppliers/${params.supplier_id}`);

// Ingredients
export const getIngredientsMeta = sendGet(`${inventoryAPIPrefix}/filters_meta`);

export const getIngredientsApis = sendPost(
  `${inventoryAPIPrefix}/ingredients/filter`
);

export const getIngredientsSearchApis = sendPost(
  `${inventoryAPIPrefix}/ingredients/search`
);

export const getIngredientDetailsApi = (params: { inventory_id: string }) =>
  sendGet(`${inventoryAPIPrefix}/ingredients/${params.inventory_id}`);

export const createIngredientApi = sendPost(
  `${inventoryAPIPrefix}/ingredients`
);

export const updateIngredientApi = (params: { inventory_id: string }) =>
  sendPatch(`${inventoryAPIPrefix}/ingredients/${params.inventory_id}`);

export const deleteIngredientApi = (params: any) => {
  return sendDelete(`${inventoryAPIPrefix}/ingredients/${params.inventory_id}`);
};
// Ingredients
export const getPropertiesApi = (params: { inventory_id?: string, from: string, family_id?: string; }) =>
  sendGet(`${params.from === 'family' ? familyAPIPrefix : inventoryAPIPrefix}/${params.from === 'family' ? params.family_id : params.inventory_id}/properties`);

export const createPropertyApi = (params: { inventory_id?: string, from: string, family_id?: string; }) =>
  sendPost(`${params.from === 'family' ? familyAPIPrefix : inventoryAPIPrefix}/${params.from === 'family' ? params.family_id : params.inventory_id}/property`);

export const updatePropertyApi = (params: {
  inventory_id?: string;
  family_id?: string;
  property_id: string;
  from: string
}) =>
  sendPatch(
    `${params.from === 'family' ? familyAPIPrefix : inventoryAPIPrefix}/${params.from === 'family' ? params.family_id : params.inventory_id}/property/${params.property_id}`
  );

export const deletePropertyApi = (params: {
  inventory_id?: string;
  family_id?: string;
  property_id: string;
  from: string
}) =>
  sendDelete(
    `${params.from === 'family' ? familyAPIPrefix : inventoryAPIPrefix}/${params.from === 'family' ? params.family_id : params.inventory_id}/property/${params.property_id}`
  );

// Methods

export const getInventoryMethodsApi = (params: {
  inventory_id?: string;
  property_id: string;
  family_id?: string;
  from: string
}) =>
  sendGet(
    `${params.from === 'family' ? familyAPIPrefix : inventoryAPIPrefix}/${params.from === 'family' ? params.family_id : params.inventory_id}/${params.property_id}/methods`
  );

export const createInventoryMethodApi = (params: {
  inventory_id?: string;
  family_id?: string;
  property_id: string;
  from: string
}) =>
  sendPost(
    `${params.from === 'family' ? familyAPIPrefix : inventoryAPIPrefix}/${params.from === 'family' ? params.family_id : params.inventory_id}/${params.property_id}/methods`
  );

export const updateInventoryMethodApi = (params: {
  inventory_id?: string;
  family_id?: string;
  property_id: string;
  method_id: string;
  from: string
}) =>
  sendPut(
    `${params.from === 'family' ? familyAPIPrefix : inventoryAPIPrefix}/${params.from === 'family' ? params.family_id : params.inventory_id}/${params.property_id}/methods/${params.method_id}`
  );

export const deleteInventoryMethodApi = (params: {
  inventory_id?: string;
  family_id?: string;
  property_id: string;
  method_id: string;
  from: string
}) =>
  sendDelete(
    `${params.from === 'family' ? familyAPIPrefix : inventoryAPIPrefix}/${params.from === 'family' ? params.family_id : params.inventory_id}/${params.property_id}/methods/${params.method_id}`
  );

export const getInventoryMethodsLookupApi = sendGet(`${inventoryAPIPrefix}/methods/list`);

// Method Parameters
export const getMethodParametersApi = (params: {
  inventory_id?: string;
  family_id?: string;
  property_id: string;
  method_id: string;
  from: string
}) =>
  sendGet(
    `${params.from === 'family' ? familyAPIPrefix : inventoryAPIPrefix}/${params.from === 'family' ? params.family_id : params.inventory_id}/${params.property_id}/${params.method_id}/parameters`
  );

export const createMethodParameterApi = (params: {
  inventory_id?: string;
  family_id?: string;
  property_id: string;
  method_id: string;
  from: string
}) =>
  sendPost(
    `${params.from === 'family' ? familyAPIPrefix : inventoryAPIPrefix}/${params.from === 'family' ? params.family_id : params.inventory_id}/${params.property_id}/${params.method_id}/parameters`
  );

export const updateMethodParameterApi = (params: {
  inventory_id?: string;
  family_id?: string;
  property_id: string;
  method_id: string;
  method_parameter_id: string;
  from: string
}) =>
  sendPut(
    `${params.from === 'family' ? familyAPIPrefix : inventoryAPIPrefix}/${params.from === 'family' ? params.family_id : params.inventory_id}/${params.property_id}/${params.method_id}/parameters/${params.method_parameter_id}`
  );

export const deleteMethodParameterApi = (params: {
  inventory_id?: string;
  family_id?: string;
  property_id: string;
  method_id: string;
  method_parameter_id: string;
  from: string
}) =>
  sendDelete(
    `${params.from === 'family' ? familyAPIPrefix : inventoryAPIPrefix}/${params.from === 'family' ? params.family_id : params.inventory_id}/${params.property_id}/${params.method_id}/parameters/${params.method_parameter_id}`
  );

export const smilesFileUploadApi = (id: string) => sendPost(`v1/data/smiles/get_data?inventory_id=${id}`);

export const resetDataSmilesUploadApi = (id: string) => sendPost(`v1/data/smiles/reset_data?inventory_id=${id}`);

export const viewIngredientUsedInWOApi = (identifier: string) => sendGet(`v1/data/inventory_v2/ingredient/${identifier}/work_orders`);

export const createIngredientFamilyApi = () => sendPost(`v1/data/family`);
export const getIngredientFamilyListApi = () => sendGet(`v1/data/family`);

export const getIngredientFamilyApi = (family_id: string) => sendGet(`v1/data/family/${family_id}`);

export const updateIngredientFamilyApi = (family_id: string) => sendPatch(`v1/data/family/${family_id}`);

export const getIngredientPropertiesDetailsApi = (inventory_id: string) => sendGet(`v1/data/inventory_v2/${inventory_id}/inventory-property`);

export const addPropertyToIngredientApi = (inventory_id: string) => sendPost(`v1/data/inventory_v2/${inventory_id}/inventory-property`);

export const getIngredientPropertyValuesApi = (inventory_id: string, inventory_property_id: string) => sendGet(`v1/data/inventory_v2/${inventory_id}/inventory-property/${inventory_property_id}/inventory-property-value`);

export const getPropertyMethodsListApi = (property_id: string) => sendGet(`v1/data/repo/${property_id}/methods-params`);

export const addPropertyValueToPropertyApi = (inventory_id: string, inventory_property_id: string) => sendPost(`v1/data/inventory_v2/${inventory_id}/inventory-property/${inventory_property_id}/inventory-property-value`);

export const updatePropertyValueToPropertyApi = (inventory_id: string, inventory_property_id: string, inventory_property_value_id: string) => sendPatch(`v1/data/inventory_v2/${inventory_id}/inventory-property/${inventory_property_id}/inventory-property-value/${inventory_property_value_id}`);

export const deleteIngredientFamilyApi = (family_id: string) => sendDelete(`v1/data/family/${family_id}`);

export const getIngredientPropertyApi = (identifier: string) => sendGet(`v1/data/inventory_v2/ingredients/${identifier}/preview`);

export const getPropertyMethodsApi = sendGet(`v1/data/inventory_v2/inventory-property/methods`);

export const deleteIngredientPropertyApi = (inventory_id: string, inventory_property_id: string) => sendDelete(`v1/data/inventory_v2/${inventory_id}/inventory-property/${inventory_property_id}`);

export const deleteIngredientPropertyValueApi = (inventory_id: string, inventory_property_id: string, inventory_property_value_id: string) => sendDelete(`v1/data/inventory_v2/${inventory_id}/inventory-property/${inventory_property_id}/inventory-property-value/${inventory_property_value_id}`);

export const getIngredientAttachmentsApi = (inventory_id: string) => sendGet(`v1/data/inventory_v2/${inventory_id}/attachments`);

export const addIngredientAttachmentApi = (inventory_id: string) => sendPost(`v1/data/inventory_v2/${inventory_id}/attachments`);

export const editIngredientAttachmentApi = (inventory_id: string, attachment_id: string) => sendPut(`v1/data/inventory_v2/${inventory_id}/attachments/${attachment_id}`);

export const deleteIngredientAttachmentApi = (inventory_id: string, attachment_id: string) => sendDelete(`v1/data/inventory_v2/${inventory_id}/attachments/${attachment_id}`);

export const getIngredientGradesApi = sendGet(`v1/data/inventory_v2/ingredients/grade/`);

export const getInventoryPreferencesApi = sendGet(`v1/data/user-preferences/inventory`)

export const updateInventoryPreferencesApi = sendPost(`v1/data/user-preferences/inventory`)

export const validateBulkEditTdsApi = sendPost(`v1/data/inventory_v2/ingredient/bulk-edit/validate/tds`)

export const bulkEditTdsApi = sendPost(`v1/data/inventory_v2/ingredient/bulk-edit/tds`)