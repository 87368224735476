import { Empty, Flex, Modal, Table, Typography } from 'antd'
import React, { useCallback, useEffect, useMemo } from 'react'
import useTranslate from 'src/utils/useTranslate'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { StoreState } from 'src/store/configureStore'
import { antdTheme, AsyncStates } from 'src/constants'
import { qcDeleteMetadataRequest, qcGetMetadataRequest, qcMetadataEditDrawerVisible, qcMetadataListRequest } from 'src/store/actions/qualityCheck'
import TopHeader from './TopHeader'
import PaginationTable from './PaginationTable'
import { AvatarComponent } from 'src/components/DashboardUpdated/Dashboard'
import EmptyImage from 'src/assets/imgs/empty.png'
import { AddSupplierProfileButton } from '../QualityCheckMetadata'
import MetadataDrawer from './MetadataDrawer/MetadataDrawer'
import { StyledButton } from 'src/styled_components/StyledButton'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'

const TEXT_PRIMARY = '#171725'

export const modalProps = {
  okButtonProps: {
    style: {
      outline: 'none',
      fontWeight: 600
    }
  },
  cancelButtonProps: {
    style: {
      outline: 'none',
      fontWeight: 600
    }
  },
  styles: {
    header: {
      fontSize: antdTheme.fontSizeLG,
      fontWeight: 600
    },
    content: {
      fontSize: antdTheme.fontSize
    }
  }
}

const textStyles: React.CSSProperties = {
  color: `${TEXT_PRIMARY}`,
  margin: 0,
  maxWidth: '100%',
  verticalAlign: 'middle'
}

export const StyledText = (text: string) => {
  return (
    <Typography.Text
      style={textStyles}
      ellipsis={{
        tooltip: text
      }}
      strong
    >
      {text}
    </Typography.Text>
  )
}

const MetadataTable = () => {
  const [t] = useTranslate()
  const dispatch = useDispatch()

  const { metadataList, metadataListStatus } = useSelector((state: StoreState) => state.qualityCheck)
  const { getNameDetailsStatus, metadataEditDrawerVisible } = useSelector((state: StoreState) => state.qualityCheck)

  const userList = useSelector((state: StoreState) => state.teams.data)

  const getUserName = useCallback((userId: string) => userList.find((user) => user.user_id === userId)?.user_name || '', [userList])

  useEffect(() => {
    dispatch(qcMetadataListRequest())
  }, [dispatch])

  const columns = useMemo(() => {
    return [
      {
        title: t('qc.productName'),
        dataIndex: 'product_id',
        key: 'product_id',
        width: '20%',
        render: (product_id: any, record: any) => {
          const product_name = record?.product_name
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: 8
              }}
            >
              {StyledText(product_name || '')}
              {record?.is_draft ? (
                <StyledButton
                  style={{
                    fontSize: antdTheme.fontSizeSM,
                    fontWeight: 600,
                    lineHeight: 'normal',
                    color: 'orange',
                    padding: 0,
                    margin: 0,
                    height: 'auto',
                    border: 'none'
                  }}
                  icon={<EditOutlined />}
                  type={'link'}
                  styles={{
                    icon: {
                      marginRight: 4
                    }
                  }}
                >
                  {t('common.Draft')}
                </StyledButton>
              ) : null}
            </div>
          )
        }
      },
      {
        title: t('common.Category'),
        dataIndex: 'category_id',
        key: 'category_id',
        width: '15%',
        render: (category_id: any, record: any) => {
          const category_name = record?.category_name
          return StyledText(category_name || '')
        }
      },
      {
        title: t('common.Supplier'),
        dataIndex: 'supplier_id',
        key: 'supplier_id',
        width: '15%',
        render: (supplier_id: any, record: any) => {
          const supplier_name = record?.supplier_name
          return StyledText(supplier_name || '')
        }
      },
      {
        title: t('common.createdBy'),
        dataIndex: 'created_by',
        key: 'created_by',
        width: '15%',
        render: (created_by: string) => {
          const user_name = getUserName(created_by)
          return user_name ? (
            <Flex
              style={{
                gap: 8,
                alignItems: 'center'
              }}
            >
              <div>
                <AvatarComponent id={created_by} key={created_by} />
              </div>
              {StyledText(user_name)}
            </Flex>
          ) : null
        }
      },
      {
        title: t('common.lastEdited'),
        dataIndex: 'updated',
        key: 'updated',
        width: '15%',
        render: (updated: string) => StyledText(dayjs(updated).format('DD MMM YYYY HH:mm'))
      },
      {
        title: t('common.updatedBy'),
        dataIndex: 'updated_by',
        key: 'updated_by',
        width: '15%',
        render: (updated_by: string) => {
          const user_name = getUserName(updated_by)
          return user_name ? (
            <Flex
              style={{
                gap: 8,
                alignItems: 'center'
              }}
            >
              <div>
                <AvatarComponent id={updated_by} key={updated_by} />
              </div>
              {StyledText(user_name)}
            </Flex>
          ) : null
        }
      },
      {
        dataIndex: 'actions',
        key: 'actions',
        width: '5%',
        render: (value: any, row: any) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <StyledButton
              icon={
                <DeleteOutlined
                  style={{
                    color: antdTheme.colorError
                  }}
                />
              }
              style={{
                outline: 'none',
                border: 'none',
                padding: '0px 5px',
                background: 'transparent'
              }}
              type="default"
              onClick={(e) => {
                e.stopPropagation()
                Modal.confirm({
                  title: t('qc.metadata.deleteModal.title'),
                  content: t('qc.metadata.deleteModal.content'),
                  cancelText: t('common.no'),
                  okText: t('common.yes'),
                  okCancel: true,
                  onOk: () => {
                    dispatch(qcDeleteMetadataRequest({ profile_id: row?.profile_id }))
                    Modal.destroyAll()
                  },
                  onCancel: () => {
                    Modal.destroyAll()
                  },
                  okButtonProps: {
                    style: {
                      outline: 'none',
                      flexGrow: 1
                    }
                  },
                  cancelButtonProps: {
                    style: {
                      outline: 'none',
                      flexGrow: 1
                    }
                  },
                  styles: {
                    mask: {
                      backgroundColor: 'rgba(0, 0, 0, 0.85)'
                    },
                    content: {
                      padding: '1.5rem'
                    }
                  },
                  footer: (_, { OkBtn, CancelBtn }) => (
                    <div
                      style={{
                        display: 'flex',
                        width: '100%'
                      }}
                    >
                      <CancelBtn />
                      <OkBtn />
                    </div>
                  ),
                  width: '350px',
                  maskClosable: true,
                  className: 'qc-metadata-close-modal',
                  icon: null
                })
              }}
              size="small"
            />
          </div>
        )
      }
    ]
  }, [dispatch, getUserName, t])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 12,
        height: '90%',
        overflow: 'hidden',
        padding: '0 16px'
      }}
    >
      <TopHeader />

      {!metadataEditDrawerVisible ? <Table
        columns={columns}
        dataSource={metadataList}
        className="quality-check-files-table"
        loading={metadataListStatus === AsyncStates.LOADING || getNameDetailsStatus === AsyncStates.LOADING}
        tableLayout="fixed"
        pagination={false}
        scroll={{ y: '90%', scrollToFirstRowOnChange: true }}
        style={{
          height: '85%',
          overflowY: 'hidden',
          transition: 'height 0.5s'
        }}
        onRow={(record: any) => {
          return {
            onClick: () => {
              dispatch(qcGetMetadataRequest({ 
                profile_id: record?.profile_id,
              }))
              dispatch(qcMetadataEditDrawerVisible(true))
            },
            style: { cursor: 'pointer' }
          }
        }}
        locale={{
          emptyText: (
            <Empty
              description={
                <Typography.Paragraph
                  style={{
                    width: '300px',
                    fontWeight: 600
                  }}
                >
                  {t('qc.metadata.emptyText')}
                </Typography.Paragraph>
              }
              image={EmptyImage}
              imageStyle={{
                height: 200,
                width: 200
              }}
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 100,
                marginBottom: 100
              }}
              children={<AddSupplierProfileButton />}
            />
          )
        }}
      />:null}

      {metadataList?.length > 0 && <PaginationTable />}

      <MetadataDrawer />
    </div>
  )
}

export default MetadataTable
