import { useState, useMemo, useEffect } from "react";
import { Space, Row, Col, Select, Table, Form, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import Plot from 'react-plotly.js';
import { mapIndex, removeNulls, tableDataForCustomInsights, tableColumnsForCustomInisghts } from "../../../utils/decorator"
import { clearInsights, getCustomInsightsDataRequest, insightsSocketConnect } from "../../../store/actions/insights"
import { AsyncStates } from "src/constants";
import useTranslate from "src/utils/useTranslate";
import { usePlotlyLocale } from "src/utils/switchLocale";
import { LoadingOutlined } from "@ant-design/icons";
import { ProjectSelection } from "../../ProjectSelection";
import { StyledCard } from "src/styled_components/StyledCard";
import { StyledButton } from "src/styled_components/StyledButton";
import { setIsEditing } from "src/store/actions/isEditing";
import { CustomPrompt } from "src/utils/CustomPrompts";
import { CreateProjectModal } from "src/components/Project/CreateProjectModal";
import { useValue } from "src/utils/useValue";
import { useRequiredFieldStar } from "src/components/Common/useRequiredFieldStar";

const { Option } = Select


export const CustomInsights = () => {
  const [t] = useTranslate()
  const requiredFieldStar = useRequiredFieldStar()
  const { convertValue } = useValue()
  const dispatch = useDispatch()
  const plotlyConfig = usePlotlyLocale()
  const { current: project_id } = useSelector((state: StoreState) => state.projects)
  const { data: insightsData, status, customInsightsData, customInsightsStatus } = useSelector((state: StoreState) => state.insights)
  const { data: displayNames } = useSelector((state: StoreState) => state.displayNames)
  const { data: { ingredients, properties } } = useSelector((state: StoreState) => state.displayNames)
  const { data: filteredDisplayNames, filterStatus } = useSelector((state: StoreState) => state.filteredDisplayNames)
  const workOrders = useSelector((state: StoreState) => state.workOrders.data)
  const workOrdersStatus = useSelector((state: StoreState) => state.workOrders.status)
  const [hover, setHover] = useState<any>('')
  const [displayIngredient, setDisplayIngredient] = useState<boolean>(false)
  const [plotType, setPlotType] = useState<any>("line")
  const [category, setCategory] = useState<any>("work_order_name")
  const [selectedProperty, setSelectedProperty] = useState<string>("")
  const isEditing = useSelector((state: StoreState) => state.isEditing)
  const [form] = Form.useForm()
  const { current: currentProject, selectAllProjects } = useSelector(
    (state: StoreState) => state.projects
  )

  useEffect(() => {
    dispatch(getCustomInsightsDataRequest(selectAllProjects ? { project_id: "" } : { project_id: currentProject }))
  }, [currentProject, dispatch, selectAllProjects])

  useEffect(() => {
    form.resetFields()
    dispatch(clearInsights())
    setSelectedProperty("")
  }, [form, project_id, dispatch])

  const filteredIngredientList = useMemo(() => selectedProperty?.length ? customInsightsData?.items_per_property?.[selectedProperty]?.ingredients : customInsightsData.ingredients, [customInsightsData.ingredients, customInsightsData?.items_per_property, selectedProperty])

  const submitForm = (values: any) => {
    const { ingredient, property, work_order_ids, materials, applications, type } = values
    let payload = {}
    if (type === 'properties_v_ingredients') {
      payload = {
        event: "custom_insights",
        action: type,
        data: ingredient.reduce((array: any, element: any) => ([...array, {
          property,
          ingredient: element,
          applications: applications ? [...applications] : [],
          materials: materials ? [...materials] : [],
          work_order_ids: work_order_ids ? [...work_order_ids] : [],
          project_id
        }]), [])
      }
    }
    else if (type === 'regression_stats' || type === 'predicted_v_observed') {
      payload = {
        event: "custom_insights",
        action: type,
        data: [{
          property,
          applications: applications ? [...applications] : [],
          materials: materials ? [...materials] : [],
          work_order_ids: work_order_ids ? [...work_order_ids] : [],
          project_id
        }]
      }
    }
    dispatch(insightsSocketConnect(payload))
    dispatch(setIsEditing(false))
  }

  const plotsData = useMemo(() => {
    let result = []
    if (insightsData?.action === 'properties_v_ingredients' || insightsData?.action === 'predicted_v_observed') {
      result = insightsData?.data?.results.reduce((array: any, element: any) => [...array, ...element?.insights], []) || []
    } else if (insightsData?.action === 'regression_stats') {
      result = insightsData?.data?.results.reduce((array: any, element: any) => [...array, ...element?.insights.filter((res: any) => res?.type === 'plot')], []) || []
    }
    return result
  }, [insightsData])

  const tableData = useMemo(() => {
    if (insightsData?.action === 'regression_stats') {
      return insightsData?.data?.results.reduce((array: any, element: any) => [...array, ...element.insights.filter((res: any) => res?.type === 'table')], []) || []
    } else return []
  }, [insightsData])
  const insightsTableColumns = tableColumnsForCustomInisghts(tableData)
  const insightsRows = tableDataForCustomInsights(tableData)
  const insightsTableRows = removeNulls(insightsRows, 'insights', convertValue)
  mapIndex(insightsTableRows[0], displayNames)

  const plotHover = (data: any) => {
    const arr = plotsData.find((res: any) => res?.data?.x_axis === data.points[0].xaxis.title.text)
    const x = arr.data.x
    const y = arr.data.y
    const displayIds = arr.data.x_display_ids
    const x_labels: string[] = Object.keys(x).filter((key: any) => Number(x[key]) === Number(data.points[0].x))
    const y_labels: string[] = Object.keys(y).filter((key: any) => Number(y[key]) === Number(data.points[0].y))
    const intersection = x_labels.find(x => y_labels.includes(x))
    setHover(intersection ? displayIds?.[intersection] : intersection)
  }

  const [createProjectModal, setCreateProjectModal] = useState<boolean>(false)

  return (
    <Spin
      indicator={<LoadingOutlined />}
      spinning={customInsightsStatus === AsyncStates.LOADING}
    >
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        <CustomPrompt isEditing={(isEditing) as boolean} message={`${t("common.unsavedChangesLost")}!.`} />
        <StyledCard extra={<ProjectSelection selectAll size="middle" createProjectModal={createProjectModal} setCreateProjectModal={setCreateProjectModal} />}>
          <Form onFinish={submitForm} layout="vertical" form={form} onValuesChange={() => dispatch(setIsEditing(true))} requiredMark={false} >
            <Row>
              <Col span={16}>
                <Form.Item
                  name="type"
                  label={t("aiEngine.customInsights.type")}
                  rules={[{ required: true }]}
                  required
                  tooltip={requiredFieldStar}
                >
                  <Select
                    placeholder={t("aiEngine.customInsights.selectType")}
                    allowClear
                    onSelect={(e: any) => {
                      if (e === "properties_v_ingredients") {
                        setDisplayIngredient(false)
                      } else {
                        setDisplayIngredient(true)
                      }
                    }
                    }
                  >
                    <Option value="regression_stats" key="regression_stats">
                      {t("aiEngine.customInsights.regressionStats")}
                    </Option>
                    <Option value="properties_v_ingredients" key="properties_v_ingredients">
                      {t("aiEngine.customInsights.propertiesVIngredients")}
                    </Option>
                    <Option value="predicted_v_observed" key="predicted_v_observed">
                      {t("aiEngine.customInsights.predictedVObserved")}
                    </Option>
                  </Select>
                </Form.Item>
                <Form.Item name="property" label={t("common.property")} rules={[{ required: true }]}
                  required
                  tooltip={requiredFieldStar}
                >
                  <Select placeholder={t("aiEngine.customInsights.selectProperties")} allowClear showSearch value={selectedProperty ?? ""} onChange={(value) => setSelectedProperty(value)}>
                    {customInsightsData?.properties?.map((property: string) => (
                      <Option value={property} key={property}>{properties?.[property]?.name || property} </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item name="ingredient" label={t("common.ingredients")} hidden={displayIngredient} rules={[{ required: !displayIngredient }]}>
                  <Select placeholder={t("aiEngine.customInsights.selectIngredients")} allowClear mode="multiple">
                    {
                      filteredIngredientList?.map((ingredient: string) => (
                        <Option value={ingredient} key={ingredient}>{ingredients?.[ingredient]?.name || customInsightsData?.ingredient_names?.[ingredient] || ingredient}                         </Option>
                      ))
                    }
                  </Select>
                </Form.Item>
                <Form.Item name="work_order_ids" label={t("aiEngine.customInsights.workOrder")}>
                  <Select placeholder={t("aiEngine.customInsights.selectWorkOrder")} allowClear mode="multiple"
                    optionFilterProp="children"
                    loading={workOrdersStatus === AsyncStates.LOADING}>
                    {workOrders?.map((res: any) => (
                      <Option value={res.work_order_id} key={res.work_order_id}>{res.work_order_name}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="materials"
                  label={t("aiEngine.customInsights.materials")}
                >
                  <Select
                    placeholder={t("common.selectMaterial")}
                    allowClear
                    mode="multiple"
                    loading={filterStatus === AsyncStates.LOADING}
                  >
                    {Object.entries(filteredDisplayNames?.material?.data || {}).map(
                      ([key, value]: [any, any]) => (
                        <Option value={key} key={key}>
                          {value}
                        </Option>
                      )
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="applications"
                  label={t("aiEngine.customInsights.applications")}
                >
                  <Select
                    placeholder={t("common.selectApplication")}
                    allowClear
                    mode="multiple"
                    loading={filterStatus === AsyncStates.LOADING}
                  >
                    {Object.entries(filteredDisplayNames?.application?.data || {}).map(
                      ([key, value]: [any, any]) => (
                        <Option value={key} key={key}>
                          {value}
                        </Option>
                      )
                    )}
                  </Select>
                </Form.Item>
                <Row justify="end">
                  <StyledButton disabled={status === AsyncStates.LOADING} type="primary" htmlType="submit" style={{ marginBottom: "20px" }}>
                    {t("aiEngine.customInsights.plot")}
                  </StyledButton>
                </Row>
              </Col>
            </Row>
          </Form>
          <div style={{ textAlign: "center", width: "100%" }}>
            <Spin
              indicator={<LoadingOutlined />}
              spinning={status === AsyncStates.LOADING}
              tip={`${t("customInsight.generatingInsights")}. ${t("customInsight.dontRefreshPage")}`}
            >
            </Spin>
          </div>
          <Space style={{ width: "100%", overflowX: "auto" }} size="large" direction="vertical">
            {insightsData.action === "predicted_v_observed" && (
              <Row justify="center">
                <Form>
                  <Form.Item label={t("aiEngine.customInsights.plotType")}>
                    <Select defaultValue={plotType} placeholder={t("aiEngine.customInsights.selectPlotType")} allowClear onChange={(e) => setPlotType(e)}
                      style={{ width: 200 }}>
                      {plotsData[0]?.data?.plot_type.map((res: any) => (
                        <Option value={res} key={res}>
                          {res}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Form>
              </Row>
            )}
            {insightsData.action === "properties_v_ingredients" && (
              <Row justify="center">
                <Form>
                  <Form.Item label={t("aiEngine.customInsights.categorizeBy")}>
                    <Select defaultValue={category} placeholder={t("aiEngine.customInsights.selectCategory")} allowClear
                      onChange={(e) => setCategory(e)} style={{ width: 200 }}>
                      {plotsData[0]?.data?.categorical_params.map((res: any) => (
                        <Option value={res} key={res}>
                          {res}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Form>
              </Row>
            )}
            <Row justify="center">
              {plotsData.map((plot: any) => (
                <Plot
                  config={plotlyConfig}
                  onHover={plotHover}
                  layout={{
                    xaxis: {
                      title: {
                        text: plot?.data?.x_axis,
                        font: {
                          family: "Courier New, monospace",
                          size: 15,
                          color: "#11111",
                        },
                      },
                      tickangle: -45
                    },
                    yaxis: {
                      title: {
                        text: plot?.data?.y_axis,
                        font: {
                          family: "Courier New, monospace",
                          size: 12,
                          color: "#11111",
                        },
                      },
                    },
                    legend: {
                      ...(insightsData?.action === "properties_v_ingredients" && { xanchor: "right" }),
                      y: -1,
                    },
                    title: plot?.title,
                    margin: { b: 100 },
                    showlegend: true,
                    width: 550,
                    height: 450,
                    hovermode: 'closest'
                  }}
                  data={
                    insightsData?.action === "predicted_v_observed" ? [{
                      x: Object.values(plot?.data?.["x_display_ids"] || plot?.data?.["x"] || {}) as any[],
                      y: Object.values(plot?.data?.["y"] || {}).reduce((array: any, element: any) => [...array, element.Observed], []) as any[],
                      type: plotType,
                      ...(plotType === "line" && { mode: "markers+lines" }),
                      name: "Observed"
                    },
                    {
                      x: Object.values(plot?.data?.["x_display_ids"] || plot?.data?.["x"] || {}) as any[],
                      y: Object.values(plot?.data?.["y"] || {}).reduce((array: any, element: any) => [...array, element.Predicted], []) as any[],
                      type: plotType,
                      ...(plotType === "line" && { mode: "markers+lines" }),
                      name: "Predicted",
                    },
                    ]
                      : insightsData?.action === "regression_stats" ? [{
                        x: plot?.data?.["x"] || [],
                        y: plot?.data?.["y"] || [],
                        type: plot?.data?.plot_type[0],
                        mode: "markers",
                      }]
                        : [...new Set(Object.values(plot?.data?.[category] || {}))].map((res, index: any) => ({
                          x: Object.values(plot?.data?.["x"] || {}).filter((item, index) => Object.values(plot?.data?.[category] || {})[index] === res) as any[],
                          y: Object.values(plot?.data?.["y"] || {}).filter((item, index) => Object.values(plot?.data?.[category] || {})[index] === res) as any[],
                          type: plot?.data?.plot_type[0],
                          mode: "markers",
                          name: String(res),
                          hovertext: hover,
                        }))
                  }
                />
              ))}
            </Row>
            {insightsData?.action === "regression_stats" &&
              tableData.map((res: any, index: any) => (
                <StyledCard title={res?.title}>
                  <Space style={{ width: "100%", overflowX: "auto" }} size="large" direction="vertical">
                    <Table columns={insightsTableColumns[index]} dataSource={insightsTableRows[index]} bordered />
                  </Space>
                </StyledCard>
              ))}
          </Space>
        </StyledCard>
        <CreateProjectModal
          setCreateProjectModal={setCreateProjectModal}
          createProjectModal={createProjectModal}
        />
      </Space>
    </Spin>
  );
}