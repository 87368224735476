import { handleActions } from "redux-actions"
import { GetCustomInsightsDataActionTypes, InsightsActionTypes } from "../actions/insights"
import { AsyncStates } from "../../constants"


export interface insightsReducerState {
    status: AsyncStates
    suggestedExpInsightsStatus?: AsyncStates
    error: string
    data: any
    suggestedExpInsights?: any,
    customInsightsData: any,
    customInsightsStatus: AsyncStates,
    customInsightsError: string
}

const defaultState: insightsReducerState = {
    status: AsyncStates.INITIAL,
    suggestedExpInsightsStatus: AsyncStates.INITIAL,
    error: "",
    data: {},
    suggestedExpInsights: [],
    customInsightsData: {},
    customInsightsStatus: AsyncStates.INITIAL,
    customInsightsError: ""
}

const insightsReducer = handleActions({
    [InsightsActionTypes.INSIGHTS_SOCKET_CONNECT]: (state, action: any) => {
        return {
            ...state,
            ...(['visualize', 'predicted_properties_vs_ingredients', 'predicted_properties_vs_costing', "pareto", "clustering"].includes(action.payload.action)) && { suggestedExpInsightsStatus: AsyncStates.LOADING },
            ...(!['visualize', 'predicted_properties_vs_ingredients', 'predicted_properties_vs_costing', "predicted_properties_vs_processing", "pareto", "clustering"].includes(action.payload.action)) && { status: AsyncStates.LOADING },
            error: ''
        }
    },
    [InsightsActionTypes.INSIGHTS_SUCCESS]: (state, action) => ({
        ...state,
        status: AsyncStates.SUCCESS,
        data: action.payload,
        error: ''
    }),
    [InsightsActionTypes.INSIGHTS_FAILURE]: (state, action) => {
        return {
            ...state,
            suggestedExpInsightsStatus: AsyncStates.ERROR,
            status: AsyncStates.ERROR,
            data: {},
            error: action.payload.error
        }
    },
    [InsightsActionTypes.CLEAR_INSIGHTS]: (state) => ({
        ...state,
        status: AsyncStates.INITIAL,
        data: {},
        error: ""
    }),
    [InsightsActionTypes.SUGGESTED_EXP_INSIGHTS]: (state, action) => ({
        ...state,
        suggestedExpInsightsStatus: AsyncStates.SUCCESS,
        suggestedExpInsights: action.payload,
        error: ''
    }),
    [InsightsActionTypes.CLEAR_SUGGESTED_EXP_INSIGHTS]: (state, action) => ({
        ...state,
        suggestedExpInsightsStatus: AsyncStates.INITIAL,
        suggestedExpInsights: [],
        error: ''
    }),
    [GetCustomInsightsDataActionTypes.REQUEST]: (state) => {
        return ({
            ...state,
            customInsightsStatus: AsyncStates.LOADING,
            customInsightsError: "",
        })
    },
    [GetCustomInsightsDataActionTypes.SUCCESS]: (state, action: any) => {
        const data = action.payload
        return {
            ...state,
            customInsightsStatus: AsyncStates.SUCCESS,
            customInsightsError: "",
            customInsightsData: data,
        };
    },
    [GetCustomInsightsDataActionTypes.FAILURE]: (state, action: any) => ({
        ...state,
        customInsightsStatus: AsyncStates.ERROR,
        customInsightsError: action.payload.error,
    }),
}, defaultState)


export default insightsReducer