import {
    DeleteOutlined,
    FileTextOutlined,
    InfoCircleOutlined,
    LoadingOutlined,
    SendOutlined,
  } from "@ant-design/icons";
  import { Upload, UploadProps, Card, Avatar, Input, message, Tooltip, Typography } from "antd";
  import {
    conversationAskRequest,
    conversationHistoryRequest,
    conversationHistorySuccess,
    createConversationRequest,
    currentConversationSet,
    getConversationsRequest,
    getConversationsSuccess,
  } from "src/store/actions/polyGPT";
  import { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { StoreState } from "src/store/configureStore";
  import { antdTheme, AsyncStates, sendCredsHostnames } from "src/constants";
  import { Unsubscribe, doc, onSnapshot } from "firebase/firestore";
  import { FB_COLLECTION_NAME_TASKERS, firestoreDb } from "src/utils/firebase";
  import axios from "axios";
  import DeleteConfirmationModal from "src/components/DeleteConfirmationModal";
  import useTranslate from "src/utils/useTranslate";
  import jwtManager from "src/utils/jwtManager";
import { GPTConversationStatus } from "../enums";
import { GptLogoV2 } from "..";
import { GPTSummaryModal } from "./GPTSummaryModal";
import { StyledButton } from "src/styled_components/StyledButton";
  
  const { Dragger } = Upload;
  const { Text } = Typography;
  type PropTypes = {
    chatStatus: any[];
  };
  
  export const GPTConversationTab = ({ chatStatus }: PropTypes) => {
    const dispatch = useDispatch();
    const [newChat, setNewChat] = chatStatus;
    const [fileList, setFileList] = useState<any[]>([]);
    const { createConversation, conversationHistory, conversationAsk, getConversations } =
      useSelector((state: StoreState) => state.polyGpt);
    const [summaryModalState, setSummaryModalState] = useState(false);
    const [askQusestion, setAskQuestion] = useState("");
    const [chatTaskStatus, setChatTaskStatus] = useState("")
    const {
      loginResponse: { user_name },
    } = useSelector((state: StoreState) => state.login);
  
    const [t] = useTranslate()
  
    const { currentConversation } = useSelector(
      (state: StoreState) => state.polyGpt
    );
    // const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
    const [deleteChatRecord, setDeleteChatRecord] = useState<any>();
  
    useEffect(() => {
      if (createConversation.status === AsyncStates.SUCCESS)
        dispatch(getConversationsRequest());
    }, [dispatch, createConversation]);
  
    const props: UploadProps = {
      multiple: true,
      beforeUpload: (file: any) => {
        if (file.name.split(".").pop() === "pdf") {
          setFileList((prevState: any) => {
            if (file?.size /
            (1024 * 1024) <= 5) {
              return [...prevState, file]
            } else {
              message.warning(`File ${file.name} Size Greater Than 5MB`);
              return prevState
            }
          })
        } else {
          message.warning(`Invalid file type. Please upload a PDF.`);
        }
      },
      customRequest: ({ file }: any) => {
        const formData = new FormData();
        fileList.forEach((file: any) => {
          formData.append('files', file)
        })
        // formData.append("temperature", "0.3");
        // formData.append("llm_name", "gpt-3.5-turbo-16k");
        // formData.append("chunk_size", "700");
        dispatch(createConversationRequest(formData));
        setFileList([]);
        setNewChat(false);
      },
    };
  
    const handleAskQuestion = () => {
      // if(askQusestion.split(" ").length <= 4){
      //   message.info("Ask question with atleast 4 words")
      //   return;
      // }
      dispatch(
        conversationAskRequest({
          conversation_id: currentConversation?.conversation_id,
          query: askQusestion,
          // temperature: 0.3,
          // llm_name: "gpt-3.5-turbo-16k",
          // chunk_size: 700,
          // chunk_overlap: 15,
          // retrieved_results: 10,
        })
      );
    };
  
    useEffect(() => {
      let unsub: Unsubscribe;
      const listenToFileTrainingTaskStatus = async () => {
        const taskDocRef = doc(
          firestoreDb,
          `${FB_COLLECTION_NAME_TASKERS}/${conversationHistory?.data?.conversation_id}`);
        unsub = onSnapshot(taskDocRef, (doc) => {
          const taskDoc = doc.data();
  
          if (taskDoc) {
            const taskStatus = taskDoc["qa_status"];
            setChatTaskStatus(taskStatus);
            if (currentConversation?.qa_status !== "completed" && taskStatus === "COMPLETED") {
              if (!!currentConversation?.conversation_id) {
                dispatch(currentConversationSet({ ...currentConversation, qa_status: "completed" }))
              }
            }
            const summaryTaskStatus = taskDoc["summary_status"];
            if (currentConversation?.summary_status !== "completed" && summaryTaskStatus === "COMPLETED") {
              if (!!currentConversation?.conversation_id) {
                dispatch(currentConversationSet({ ...currentConversation, summary_status: "completed" }))
              }
            }
          }
        });
      };
  
      if ((conversationHistory?.data?.conversation_id && conversationHistory?.data?.qa_status === "in_progress") || (conversationHistory?.data?.conversation_id && conversationHistory?.data?.summary_status === "in_progress")) {
        listenToFileTrainingTaskStatus();
      }
  
      return () => {
        chatTaskStatus === 'COMPLETED' && unsub && unsub();
      };
    }, [chatTaskStatus, conversationHistory?.data?.conversation_id, conversationHistory?.data.qa_status, conversationHistory?.data?.summary_status, currentConversation, dispatch]);
  
    useEffect(() => {
      if (createConversation.status === AsyncStates.ERROR) setNewChat(true)
    }, [conversationHistory.status, createConversation.status, setNewChat])
  
    const scrollToBottom = () => {
      var objDiv = document.getElementById("conversation-history-container");
      if (objDiv) {
        objDiv!.scrollTop = objDiv?.scrollHeight as number;
      }
    };
  
    useEffect(() => {
      if (conversationHistory.status === AsyncStates.SUCCESS) {
        scrollToBottom();
      }
    }, [conversationHistory?.data?.page_num, conversationHistory?.data?.page_size, conversationHistory?.data.chat, conversationHistory.status, currentConversation]);
  
    useEffect(() => {
      if (conversationAsk.status === AsyncStates.SUCCESS) {
        setAskQuestion("");
        scrollToBottom();
      }
    }, [conversationAsk.status]);
  
    // const openPdfNewTab = async () => {
    //   const response = await axios.get(conversationHistory.data.file_url, { responseType: 'blob' });
    //   const file = new Blob([response?.data as BlobPart], { type: 'application/pdf' });
    //   const fileURL = URL.createObjectURL(file);
    //   window.open(fileURL);
    // }
  
    const handleDeleteQuestion = () => {
      setDeleteChatRecord({ visible: false });
      axios
        .post<any>(
          process.env.REACT_APP_API_URL +
          "v1/data/polygpt/conversations/chat/delete",
          {
            conversation_id: deleteChatRecord?.conversation_id,
            question_id: deleteChatRecord?.question_id,
          },
          {
            headers: { token: `${jwtManager.getToken()}` },
            ...(sendCredsHostnames.includes(window.location.hostname) && {
              withCredentials: true,
            }),
          }
        )
        .then((response) => {
          if (response?.data?.result?.status === "Success") {
            dispatch(
              conversationHistorySuccess({
                ...conversationHistory?.data,
                questions_count: deleteChatRecord?.questions_count - 1,
                chat: conversationHistory?.data?.chat
                  ?.filter(
                    ({ question_id }: any) =>
                      question_id !== deleteChatRecord.question_id
                  )
                  ?.reverse(),
              })
            );
            dispatch(getConversationsSuccess({
              ...getConversations.data,
              conversations: getConversations.data.conversations.map(
                (conversation) => {
                  if (
                    conversation.conversation_id ===
                    currentConversation?.conversation_id
                  ) {
                    return {
                      ...conversation,
                      questions_count: conversation.questions_count! - 1,
                    };
                  }
                  return conversation;
                }
              ),
            }));
            setDeleteChatRecord({ visible: false });
          } else {
            message.info(response?.data?.result?.message);
          }
        })
        .catch((error) => {
          message.info(error);
        });
    };
  
    return (    
      <>
        {newChat ? (
          <div className="upload-container">
            {createConversation?.status === AsyncStates.ERROR && (
              <div
                className=""
                style={{
                  background: " #FFF2F2",
                  padding: "16px",
                  borderRadius: "12px",
                }}
              >
                <span
                  style={{
                    color: "#E02424",
                    padding: "16px",
                    borderRadius: "12px",
                  }}
                >
                  {t('polygpt.tryAgainLater')}
                </span>
              </div>
            )}
            <div>
              <div className="upload-title" style={{ marginBottom: "0.5rem" }}>
                {t("polyGPT.uploadYourFile")}
              </div>
              <div
                style={{
                  width: "30vw",
                  height: "20vh",
                }}
              >
                <Dragger {...props} showUploadList={false}>
                  <p className="ant-upload-text">
                    {t("polyGPT.drag")}
                  </p>
                </Dragger>
              </div>
              <div className="upload-message" style={{ marginTop: "0.5rem" }}>
                {t("polyGPT.format")}
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="chat-container">
              <div className="chat-file-header">
                {(currentConversation?.title) && 
                  <div className="title" style={{display: "flex", justifyContent: "space-between"}}>
                    <span>
                      {currentConversation?.title}{" "}
                    </span>
                    <div>
  
                    <Text style={{
                                  color: "#888888",
                                }}>
                        {
                          currentConversation?.files_folder && `Document Count: ${currentConversation?.files_folder?.length}`
                        }
                      </Text>
                      <StyledButton
                      size="small"
                      style={{ marginLeft: "16px" }}
                      icon={<FileTextOutlined />}
                      onClick={() => setSummaryModalState(true)}
                      disabled={currentConversation?.summary_status  ===
                        GPTConversationStatus.IN_PROGRESS}
                    >
                      {`${currentConversation?.summary_status  ===
                        GPTConversationStatus.IN_PROGRESS ? 'Preparing' : ''} Summary`}
                    </StyledButton>
                      </div>
                  </div>
                }
              </div>
              {createConversation?.status === AsyncStates.LOADING && (
                <div className="file-upload-status">{t("polygpt.FileUploading")}{"..."}</div>
              )}
              {conversationHistory?.status === AsyncStates.LOADING && (
                <div className="file-upload-status">{t("common.loading")}{"..."}</div>
              )}
              {createConversation?.status === AsyncStates.SUCCESS &&
                conversationHistory.status !== AsyncStates.SUCCESS && (
                  <div className="file-upload-status">{t("polygpt.FileUploaded")}</div>
                )}
              {currentConversation?.qa_status === "in_progress" && (
                <div className="file-upload-status">{t("polygpt.FileTrainingInProgress")}</div>
              )}
              {currentConversation?.qa_status === "failed" && (
                <div className="file-upload-status">{t("polygpt.FileTrainingFailed")}</div>
              )}
              <div
                id="conversation-history-container"
                style={{ height: "60vh", overflow: "auto", paddingTop: "10px"}}
              >
                {(conversationHistory.status !== AsyncStates.SUCCESS || !conversationHistory.data.chat.length) && (
                  <div className="ask-polygpt">{currentConversation?.qa_status === 'in_progress' ? "InProgress" : "Ask PolyGPT"}</div>
                )}
                {
                  createConversation.status === AsyncStates.LOADING && <p>{t("polygpt.UploadinProgress!")}</p>
                }
                {conversationHistory.status === AsyncStates.SUCCESS && (
                  <>
                    {conversationHistory?.data?.questions_count >
                      conversationHistory?.data?.chat?.length && (
                        <StyledButton
                          style={{
                            marginTop: "0.5rem",
                            display: "flex",
                            margin: "auto",
                          }}
                          type="link"
                          onClick={() => {
                            dispatch(
                              conversationHistoryRequest({
                                conversation_id:
                                  currentConversation?.conversation_id,
                                page_size: 10,
                                page_num: conversationHistory?.data?.page_num + 1,
                              })
                            );
                          }}
                        >
                          {t('polygpt.More')}{'...'}
                        </StyledButton>
                      )}
                    {conversationHistory?.data?.chat?.map((conversation: any) => (
                      <Card
                        style={{ marginTop: "0.5rem" }}
                        bodyStyle={{ padding: "0" }}
                      >
                        {conversation?.question && <div style={{ display: "flex", padding: "12px 12px 6px 12px" }}>
  
                          <Avatar
                            shape="square"
                            size="large"
                            style={{
                              background: "#9254DE"
                            }}
                          ><Tooltip title={user_name}>{user_name[0].toUpperCase()}</Tooltip></Avatar>
  
                          <div style={{ marginLeft: "1rem" }}>
                            <Text
                              style={{
                                color: "#888888",
                              }}
                            >
                              {new Date(conversation?.created_at)
                                .toLocaleString([], {
                                  month: "short",
                                  day: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hourCycle: "h12",
                                })
                                .split(",")
                                .reverse()
                                .join(",")
                                .toUpperCase()}
                            </Text>
                            <div className="chat-question">{conversation?.question}</div>
                          </div>
                          <DeleteOutlined onClick={() => setDeleteChatRecord({ visible: true, conversation_id: conversationHistory?.data?.conversation_id, question_id: conversation.question_id, questions_count: conversationHistory?.data?.questions_count })} style={{ marginLeft: "auto", color: antdTheme.colorError, fontSize:antdTheme.fontSizeLG, cursor: "pointer" }} />
                        </div>}
                        <div style={{ display: "flex", padding: "6px 12px 12px 12px" }}>
                          <span>
                            <GptLogoV2 />
                          </span>
                          <div style={{ marginLeft: "1rem", width: "100%" }}>
                            <Text
                              style={{
                                color: "#888888",
                              }}
                            >
                              {new Date(conversation?.created_at)
                                .toLocaleString([], {
                                  month: "short",
                                  day: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hourCycle: "h12",
                                })
                                .split(",")
                                .reverse()
                                .join(",")
                                .toUpperCase()}
                            </Text>
                            <div
                              style={{ display: "flex", width: "100%" }}
                              className={
                                conversation?.isError ? "error-conversation" : "chat-conversation"
                              }
                            >
                              <span>{conversation?.answer || (conversation?.isError && "Internal Server Error Please try later")}{" "}</span>
                              {conversation?.isError && <span style={{ marginLeft: "auto" }}>
                                <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10.9984 10.9708V6.13245M10.9984 14.557V14.5995M17.0464 18.6292H4.95051C3.29828 18.6292 1.90396 17.5249 1.46527 16.0142C1.278 15.3693 1.5081 14.6976 1.86005 14.1257L7.90797 3.09773C9.32494 0.795139 12.6719 0.795142 14.0889 3.09773L20.1368 14.1257C20.4888 14.6976 20.7189 15.3693 20.5316 16.0142C20.0929 17.5249 18.6986 18.6292 17.0464 18.6292Z" stroke="#E02424" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                              </span>}
                            </div>
                            
                            
                               <Text
                                style={{
                                  color: "#888888",
                                }}
                            >
                              {
                                conversation?.source ?
                                conversation?.source?.type === 'internet' ?
                                  <>
                                  Source: Internet
                                  </>: 
                                conversation?.source?.metadata?.length ?
                                  <>
                                  Source Document(s): {conversation?.source?.metadata?.map((sourceMeta: any) => `${sourceMeta?.name} (pages: ${sourceMeta?.pages?.toString()})`).toString()} 
                                  {/* | Pages: ({conversation.pages.toString()}) */}
                                  </>:
                                  null: null
                              }
                            </Text>
                          </div>
                        </div>
                      </Card>
                    ))}
                  </>
                )}
              </div>
              {/* <StyledButton
                style={{
                  float: "right",
                  marginTop: "0.5rem",
                }}
                icon={<RedoOutlined />}
              >
                Generate
              </StyledButton> */}
              <div className="chat-input-field">
                {/* "Send a message"||Generate message.. */}
                <Input
                  prefix={
                    <>
                    {
                      conversationAsk.status === AsyncStates.LOADING && (
                        <LoadingOutlined />
                      )
                    }
                    <Tooltip title="Ask question with atleast 4 words for better results">
                      <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                    </Tooltip>
                    </>
                  }
                  style={{ lineHeight: "25px" }}
                  value={askQusestion}
                  disabled={
                    conversationAsk.status === AsyncStates.LOADING ||
                    currentConversation?.qa_status !==
                    GPTConversationStatus.COMPLETED
                    
                  }
                  // onPressEnter={handleAskQuestion}
                  addonAfter={
                    conversationAsk.status !== AsyncStates.LOADING && (
                      <span onClick={askQusestion !== "" ? handleAskQuestion : undefined} style={{ cursor: "pointer" }}>
                        <SendOutlined />
                        <span style={{ marginLeft: "0.5rem" }}>{t("polygpt.Ask")}</span>
                      </span>
                    )
                  }
                  placeholder="Ask questions from your pdf"
                  onChange={(e) => setAskQuestion(e?.target?.value)}
                  className="ask-questions-input"
                />
                {/* <Text></Text> */}
              </div>
            </div>
          </>
        )}
        <GPTSummaryModal modalState={[summaryModalState, setSummaryModalState]} />
        <DeleteConfirmationModal
          title={`${t("common.confirmDelete")}?`}
          description={
            <Text>
              {t("common.deleteConfirmation")}{" "}
              ? {t("common.cannotUndoOperation")}
            </Text>
          }
          strict={false}
          onCancel={() => setDeleteChatRecord({ visible: false })}
          visible={deleteChatRecord?.visible}
          onConfirm={handleDeleteQuestion}
        />
      </>
    );
  };
  