import React from 'react'
import useTranslate from 'src/utils/useTranslate'

const PredictionsTable = () => {
  const [t] = useTranslate()
  return (
    <div>{t("predsTable")}</div>
  )
}

export default PredictionsTable