import React from 'react'

const EllipseR = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="3" height="6" viewBox="0 0 3 6" fill="none">
<path d="M0 3C0 6 3 6 3 6V0C3 0 0 0 0 3Z" fill="#1F1F1F"/>
</svg>
  )
}

export default EllipseR