import { CloseOutlined, DeleteOutlined, DownOutlined } from '@ant-design/icons'
import { Drawer, Dropdown, message, Modal, Space, Spin, Steps, Typography } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AsyncStates } from 'src/constants'
import {
  qcAddDetails,
  qcCreateMetadataRequest,
  qcGetMetadataClear,
  qcMetadataEditDrawerVisible,
  qcMetadataListRequest,
  qcMetadataStepErrors,
  qcMetadataUpdate,
  qcUpdateMetadataRequest
} from 'src/store/actions/qualityCheck'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import useTranslate from 'src/utils/useTranslate'
import DetailsBar from './DetailsBar'
import StepContent from './StepContent/StepContent'
import _ from 'lodash'
import './MetadataDrawer.scss'
import { initialMetadataStepErrorsState } from 'src/store/reducers/qualityCheck'

export const stepMapping: Record<string, number> = {
  add_details: 0,
  metadata_mapping: 1,
  test_title_mapping: 2,
  input_criteria: 3
}
export const stepReverseMapping: Record<number, string> = {
  0: 'add_details',
  1: 'metadata_mapping',
  2: 'test_title_mapping',
  3: 'input_criteria'
}

const MetadataDrawer = () => {
  const dispatch = useDispatch()
  const [t] = useTranslate()

  const { metadataEditDrawerVisible, getMetadataResponse, getMetadataStatus, getNameDetailsStatus, addDetails, metadataStepErrors } = useSelector(
    (state: StoreState) => state.qualityCheck
  )

  const [currentStep, setCurrentStep] = useState<number>(getMetadataResponse?.current_step ? stepMapping[getMetadataResponse.current_step] : 0)
  
  useEffect(() => {
    setCurrentStep(getMetadataResponse?.current_step ? stepMapping[getMetadataResponse.current_step] : 0)
  }, [getMetadataResponse?.current_step])

  const isProductSelected = useMemo(() => currentStep > 0, [currentStep])

  const validateCreateStep = useCallback(() => {
    let isPassed = true
    const validatedResponse = _.cloneDeep(addDetails)

    Object.keys(validatedResponse).forEach((key) => {
      if (!validatedResponse[key]) {
        isPassed = false
        validatedResponse[`error_${key}`] = t('common.pleaseEnterValue')
      }
    })
    dispatch(qcAddDetails(validatedResponse))

    dispatch(qcMetadataStepErrors({ add_details: !isPassed }))

    return isPassed
  }, [addDetails, dispatch, t])

  const validateAllSteps = useCallback(() => {
    let metadataMappingStepPassed = true
    let testTitleMappingStepPassed = true
    let inputCriteriaStepPassed = true

    // validate metadata mapping

    const metadataMapping = _.cloneDeep(getMetadataResponse?.metadata_mapping)

    Object.keys(metadataMapping).forEach((key) => {
      if (metadataMapping[key].length === 0) {
        metadataMappingStepPassed = false
        metadataMapping[`error_${key}`] = t('qc.metadata.mapMetadata.errorString')
      }
    })

    if(Object.keys(metadataMapping).length === 0) {
      metadataMappingStepPassed = false
    }

    dispatch(qcMetadataUpdate({ metadata_mapping: metadataMapping }))

    // validate test title mapping

    const testTitleMapping = _.cloneDeep(getMetadataResponse?.test_title_mapping)

    Object.keys(testTitleMapping).forEach((key) => {
      if (testTitleMapping[key].length === 0) {
        testTitleMappingStepPassed = false
        testTitleMapping[`error_${key}`] = t('qc.metadata.mapTestColumns.errorString')
      }
    })

    if(Object.keys(testTitleMapping).length === 0) {
      testTitleMappingStepPassed = false
    }

    dispatch(qcMetadataUpdate({ test_title_mapping: testTitleMapping }))

    // validate input criteria

    const inputCriteria = _.cloneDeep(getMetadataResponse?.input_criteria)

    inputCriteria.forEach((criteria: any, idx: number) => {
      Object.keys(criteria).forEach((key) => {
        const invalidValue = (val:any)=>[null, undefined, ''].includes(val)
        const isTestField = key === 'test'
        const isValueOptional = criteria?.optional
        const isRequiredField = key !== 'method' && key !== 'unit'
        const isValueValid =
          (invalidValue(criteria[key])) ||
          (Array.isArray(criteria[key]) && criteria[key].length === 0 && !criteria[key].every((item: any) => invalidValue(item)))

        if (isTestField && isValueValid) {
          inputCriteriaStepPassed = false
          inputCriteria[idx][`error_${key}`] = true
        }

        if (!isValueOptional && isRequiredField && isValueValid) {
          inputCriteriaStepPassed = false
          inputCriteria[idx][`error_${key}`] = true
        }
      })
    })

    if(inputCriteria.length === 0) {
      inputCriteriaStepPassed = false
    }

    dispatch(qcMetadataUpdate({ input_criteria: inputCriteria }))

    dispatch(
      qcMetadataStepErrors({
        metadata_mapping: !metadataMappingStepPassed,
        test_title_mapping: !testTitleMappingStepPassed,
        input_criteria: !inputCriteriaStepPassed
      })
    )

    return metadataMappingStepPassed && testTitleMappingStepPassed && inputCriteriaStepPassed
  }, [dispatch, getMetadataResponse?.input_criteria, getMetadataResponse?.metadata_mapping, getMetadataResponse?.test_title_mapping, t])

  const onCloseActions = useCallback(() => {
    dispatch(qcMetadataEditDrawerVisible(false))
    if (isProductSelected) {
      dispatch(qcMetadataListRequest())
    }
    dispatch(
      qcAddDetails({
        supplier_id: '',
        product_id: '',
        category_id: ''
      })
    )
    dispatch(
      qcMetadataStepErrors({
        ...initialMetadataStepErrorsState
      })
    )
    dispatch(qcGetMetadataClear())
  }, [dispatch, isProductSelected])

  const onDiscardClick = useCallback(() => {
    const onClose = () => {
      Modal.confirm({
        title: t('qc.metadata.closeModal.title'),
        content: t('qc.metadata.closeModal.content'),
        cancelText: t('common.no'),
        okText: t('common.yes'),
        okCancel: true,
        onOk: () => {
          onCloseActions()
          Modal.destroyAll()
        },
        onCancel: () => {
          Modal.destroyAll()
        },
        okButtonProps: {
          style: {
            outline: 'none',
            flexGrow: 1
          }
        },
        cancelButtonProps: {
          style: {
            outline: 'none',
            flexGrow: 1
          }
        },
        styles: {
          mask: {
            backgroundColor: 'rgba(0, 0, 0, 0.85)'
          },
          content: {
            padding: '1.5rem'
          }
        },
        footer: (_, { OkBtn, CancelBtn }) => (
          <div
            style={{
              display: 'flex',
              width: '100%'
            }}
          >
            <CancelBtn />
            <OkBtn />
          </div>
        ),
        width: '350px',
        maskClosable: true,
        className: 'qc-metadata-close-modal',
        icon: null
      })
    }
    onClose()
  }, [onCloseActions, t])

  return (
    <Drawer
      placement="bottom"
      open={metadataEditDrawerVisible}
      onClose={() => {
        onDiscardClick()
      }}
      height={'95%'}
      destroyOnClose={true}
      title={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '2rem'
          }}
        >
          <Typography.Title level={4}>{t('qc.metadata.add')}</Typography.Title>
          <Steps
            size="small"
            current={currentStep}
            onChange={(step: number) => {
              if (currentStep < step) {
                if (currentStep === 0) {
                  validateCreateStep() && dispatch(qcCreateMetadataRequest())
                } else {
                  setCurrentStep(step)
                  dispatch(qcMetadataUpdate({ current_step: stepReverseMapping[step] }))
                }
              } else if (currentStep > step) {
                setCurrentStep(step)
                dispatch(qcMetadataUpdate({ current_step: stepReverseMapping[step] }))
              }
            }}
            style={{
              width: '60%'
            }}
            items={[
              {
                title: t('qc.metadata.step1'),
                disabled: isProductSelected,
                status: metadataStepErrors.add_details ? 'error' : currentStep === 0 ? 'process' : 'finish'
              },
              {
                title: t('qc.metadata.step2'),
                status: metadataStepErrors.metadata_mapping ? 'error' : currentStep === 1 ? 'process' : 'finish'
              },
              {
                title: t('qc.metadata.step3'),
                disabled: !isProductSelected,
                status: metadataStepErrors.test_title_mapping ? 'error' : currentStep === 2 ? 'process' : 'finish'
              },
              {
                title: t('qc.metadata.step4'),
                disabled: !isProductSelected,
                status: metadataStepErrors.input_criteria ? 'error' : currentStep === 3 ? 'process' : 'finish'
              }
            ]}
          />
        </div>
      }
      closeIcon={null}
      styles={{
        header: {
          border: 'none',
          padding: '1.5rem'
        },
        body: {
          padding: 0
        },
        footer: {
          padding: '1rem 1.5rem',
          paddingRight: '6rem',
          backgroundColor: '#fbfbfb',
          border: 'none'
        }
      }}
      extra={
        <Space>
          {/* Mark it as completed feature */}
          {isProductSelected ? (
            <Space>
              <StyledButton
                type="primary"
                style={{
                  fontSize: '0.75rem',
                  fontWeight: 600
                }}
                danger
                icon={<DeleteOutlined />}
                onClick={() => {
                  onDiscardClick()
                }}
              >
                {`${t('common.discard')} ${t('common.and')} ${t('common.close')}`}
              </StyledButton>
              <Dropdown
                overlayStyle={{
                  borderRadius: 2
                }}
                menu={{
                  items: [
                    {
                      key: 'draft',
                      onClick: () => {
                        const updatedMetadata = _.cloneDeep(getMetadataResponse)

                        const error_keys_metadata_mapping = Object.keys(updatedMetadata?.metadata_mapping || {}).filter((key) =>
                          key.startsWith('error_')
                        )
                        error_keys_metadata_mapping.forEach((key) => {
                          delete updatedMetadata?.metadata_mapping?.[key]
                        })

                        const error_keys_test_title_mapping = Object.keys(updatedMetadata?.test_title_mapping || {}).filter((key) =>
                          key.startsWith('error_')
                        )
                        error_keys_test_title_mapping.forEach((key) => {
                          delete updatedMetadata?.test_title_mapping?.[key]
                        })

                        updatedMetadata?.input_criteria?.forEach((criteria: any) => {
                          const error_keys_input_criteria = Object.keys(criteria).filter((key) => key.startsWith('error_'))
                          error_keys_input_criteria.forEach((key) => {
                            delete criteria[key]
                          })
                        })

                        dispatch(
                          qcUpdateMetadataRequest({
                            profile_id: getMetadataResponse?.profile_id,
                            ...updatedMetadata,
                            is_draft: true
                          })
                        )
                        onCloseActions()
                      },
                      label: `${t('common.Draft')} ${t('common.and')} ${t('common.close')}`,
                      style: {
                        outline: 'none',
                        fontSize: '0.75rem',
                      }
                    },
                    {
                      key: 'completed',
                      onClick: () => {
                        if (validateAllSteps()) {
                          dispatch(
                            qcUpdateMetadataRequest({
                              profile_id: getMetadataResponse?.profile_id,
                              ...getMetadataResponse,
                              is_draft: false
                            })
                          )
                          onCloseActions()
                        } else {
                          message.error(t('qc.metadata.invalidMessage'))
                        }
                      },
                      label: `${t('common.completed')} ${t('common.and')} ${t('common.close')}`,
                      style: {
                        outline: 'none',
                        fontSize: '0.75rem',
                      }
                    }
                  ]
                }}
                placement="bottomRight"
                overlayClassName='qc-metadata-save-as-dropdown'
                trigger={['click', 'hover']}
              >
                <StyledButton
                  type="primary"
                  style={{
                    fontSize: '0.75rem',
                    fontWeight: 600
                  }}
                >
                  <Space>
                    {t('common.saveAs')}
                    <DownOutlined
                      style={{
                        outline: 'none'
                      }}
                    />
                  </Space>
                </StyledButton>
              </Dropdown>
            </Space>
          ) : (
            <CloseOutlined
              onClick={() => {
                onDiscardClick()
              }}
              style={{
                outline: 'none',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            />
          )}
        </Space>
      }
      footer={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          {currentStep > 1 ? (
            <StyledButton
              onClick={() => {
                setCurrentStep(currentStep - 1)
              }}
            >
              {t('common.back')}
            </StyledButton>
          ) : (
            <div />
          )}
          {currentStep === 3 ? (
            <div />
          ) : (
            <StyledButton
              type="primary"
              onClick={() => {
                if (currentStep === 0) {
                  validateCreateStep() && dispatch(qcCreateMetadataRequest())
                } else {
                  setCurrentStep(currentStep + 1)
                }
              }}
            >
              {t('common.continue')}
            </StyledButton>
          )}
        </div>
      }
    >
      {getMetadataStatus === AsyncStates.LOADING || getNameDetailsStatus === AsyncStates.LOADING ? (
        <Spin
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%'
          }}
        >
          {isProductSelected ? <DetailsBar /> : null}
          <StepContent step={currentStep} />
        </div>
      )}
    </Drawer>
  )
}

export default MetadataDrawer
