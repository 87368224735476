import React from 'react'
import { Typography, Space } from 'antd'
import useTranslate from 'src/utils/useTranslate'

const { Link, Title, Text } = Typography


export const UnsecuredPageWarning = () => {
    const [t] = useTranslate()
    return (
        <Space direction="vertical" style={{ width: "100%", textAlign: "center", marginTop: 20, padding: 100 }} size="large">
            <Title level={4}>{t('unsecuredPage.sentence1')}</Title>
            <Text>{t('unsecuredPage.sentence2')}</Text>
            <Text>{t('common.click')} <Link href={window.self.location.href} title='Web Application' target='blank'>{t('common.here')}</Link>{t('unsecuredPage.sentence3')}</Text>
        </Space>
    )
}
