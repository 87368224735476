import { useEffect } from 'react'
import { Layout, Space } from 'antd';
import Title from 'antd/lib/typography/Title';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { StoreState } from 'src/store/configureStore';
import LeftSideOnboarding from "../SignUp/LeftSideOnboarding";
import AllRightsReserved from '../SignUp/AllRightsReserved';
import useTranslate from 'src/utils/useTranslate';


const { Content: LayoutContent } = Layout

export const UserVerified = () => {
  const [t] = useTranslate()
  const { state: userInfo }: any = useLocation()
  const history = useHistory()
  const userData = useSelector((store: StoreState) => store.login.loginResponse)

  useEffect(() => {
    if (userData?.user_verified && userData?.user_access) {
      history.push("/formulations")
    }
  }, [history, userData?.user_access, userData?.user_verified])

  return (
    <>
      <Layout
        style={{
          textAlign: "center",
          minHeight: "100%",
          background: "white",
        }}
        className="fontWorkSans"
      >
        <LayoutContent style={{ overflow: "auto", position: "relative" }}>
          <Space
            style={{ width: "100%" }}
            direction="horizontal"
            size="large"
          >
            <LeftSideOnboarding minWidth="55vw" />
            <Space direction="vertical"  style={{ maxWidth: "40vw" }}>
            <Space direction="vertical" style={{ margin: "48px", textAlign: "left" }} size={'large'}>
                <Title
                  level={1}
                  style={{
                    fontSize: "2.5rem",
                    fontWeight: 500,
                    color: "#1753E2",
                    marginBottom: 40,
                  }}
                >
                {t('userVerified.sentence1')}{" "}
                {userInfo?.user_name?.replace("+", " ") ||
                  userData?.user_name}
              </Title>
                <Title level={5}>
                  {t('userVerified.sentence2')}
                  <strong>
                    {userInfo?.user_email || userData?.user_email}
                  </strong>
                  {t('userVerified.sentence3')}
                </Title>
                <Title level={5}>
                  {t('userVerified.sentence4')}
                </Title>

                <Space
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Title
                    level={5}
                    style={{
                      textAlign: "left",
                      display: "block",
                    }}
                  >
                    <Link to="/login">{t('common.backToLogin')}</Link>
                  </Title>
                  <Title
                    level={5}
                    style={{
                      textAlign: "left",
                      display: "block",
                    }}
                  >
                    {t('userVerified.needHelp')}
                    <a href="mailto:contact@polymerize.io">
                      {t("common.contactSupport")}
                    </a>
                  </Title>
                </Space>
                </Space>
              <AllRightsReserved />
            </Space>
          </Space>
        </LayoutContent>
      </Layout>
    </>
  );
}
