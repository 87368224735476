import { EyeInvisibleOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import { Cascader, Col, Form, Input, InputNumber, Row, Select, Typography } from 'antd';
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from 'src/store/configureStore';
import { StyledButton } from 'src/styled_components/StyledButton';
import { StyledCard } from 'src/styled_components/StyledCard';
import useTranslate from 'src/utils/useTranslate';
import { OPS } from './AnalyticsRecipeDistribution';
import { analyticsRecipeDistributionRequest } from 'src/store/actions/dataSummary';
import { recipeDistributionColorCodes } from 'src/constants';
import { useQuery } from 'src/utils/useQuery';
import { isValidNumber } from 'src/utils/decorator';
import { useRequiredFieldStar } from 'src/components/Common/useRequiredFieldStar';
import StyledDeleteIcon from 'src/styled_components/StyledDeleteIcon';
import { useValue } from 'src/utils/useValue';


type ReciepeDistributionFilterFormProps = any

export const operatorConstantsMapper: { [key: string]: string } = {
  "<": "lt",
  ">": "gt",
  "<=": "lte",
  ">=": "gte",
  "=": "eq",
  "range": "range",
  "exists": "exists",
}

const { Text } = Typography

export const AnalyticsReciepeDistributionFilterForm = ({ reciepeExperiments, setVisibilityArray, visibilityArray, filterValues, setFilterValues, recipeDistributionFrom, selectedExperiments, workOrderIds, Xaxis, Yaxis, Zaxis, selectedFormulations }: ReciepeDistributionFilterFormProps) => {
  const [reciepeDistributionFilterForm] = Form.useForm()
  const [t] = useTranslate()
  const dispatch = useDispatch()
  const requiredFieldStar = useRequiredFieldStar()
  const configs = useSelector((state: StoreState) => state.configs.features)
  const displayNames = useSelector((state: StoreState) => state.displayNames.data)
  const { formatter, parser } = useValue()

  const query = useQuery();
  const isFromCompareFormulation = Boolean(query?.get("source") === "compare-formulation")

  const workOrders = useSelector((state: StoreState) => state.workOrders.data)

  const filteredWorkOrdersList = useMemo(() => workOrders.filter((workOrder) => workOrderIds.includes(workOrder.work_order_id)), [workOrderIds, workOrders])

  const { plotOptions, plotOptionsFormulationDisplayNames } = useSelector((state: StoreState) => state.dataSummary.dataSummaryPlotOptions)

  const getCascaderOptions = useCallback((parameter: string) => {
    const recipeData: any[] = plotOptions?.[parameter] ?? []
    if (['properties', 'processing'].includes(parameter) && Boolean(configs?.nestle_configs)) {
      if (parameter === "properties") {
        const variations = plotOptions?.variation_data?.properties || {}
        return Object.keys(variations || {}).map((variation) => ({
          value: variation,
          label: variations?.[variation]?.variation_name,
          children: variations?.[variation]?.properties?.map((property: any) => ({
            label: plotOptionsFormulationDisplayNames?.[property] || displayNames['properties'][property]?.name,
            value: property
          }))
        }))
      } else {
        const processingProfiles = plotOptions?.variation_data?.processing || {}
        return [{
          value: "profile",
          label: "Profile",
          children: Object.keys(processingProfiles || {}).map((profile: any) => ({
            label: processingProfiles?.[profile] ?? profile,
            value: profile
          }))
        }]
      }

      // if (parameter === 'processing' && Boolean(configs?.nestle_configs) && recipeDistributionFrom === "inverse-prediction") {
      //   return recipeData.map((processingParameter) => ({
      //     label: processingParameter,
      //     value: processingParameter,
      //     children: [...new Set(reciepeExperiments.map((reciepeExperiment: any) => reciepeExperiment.processing[processingParameter].value))].map((processingName: any) => ({
      //       label: processingName,
      //       value: processingName
      //     }))
      //   }))
      // }

      // return parameter === 'processing' ? [...new Set(recipeDistributionData?.flatMap(value => value.processing.flatMap((value: any) => value?.meta?.profile_id)))].map(value => ({ value, label: processingProfilesList.find(({ profile_id }: any) => profile_id === value).name }))
      //   : data.length > 0 ? variation_ids.map((var_id: any) => ({
      //     value: var_id,
      //     label: displayNames?.characterization_methods[var_id]?.name,
      //     children: Object.keys(getstruct(data.filter(((recipe: any) => recipe?.variation_id)).map(({ data }: any) => data))).map(pro_id => ({ value: pro_id, label: displayNames?.properties[pro_id].name })
      //     )
      //   })
      //   ) : []
    } else if (parameter === 'costing') {
      return [
        { value: 'total_cost', label: 'Formulation Cost' },
        ...plotOptions?.["ingredients"].map((value: any) => ({ value, label: displayNames?.ingredients[value as any]?.name + " Cost" }))
      ]
    } else {
      return [...recipeData?.map(value => ({ value: value, label: displayNames.hasOwnProperty(parameter) ? displayNames[parameter][value]?.name || plotOptionsFormulationDisplayNames?.[value] || value : displayNames['properties'][value]?.name }))]
    }
  }, [configs?.nestle_configs, displayNames, plotOptions, plotOptionsFormulationDisplayNames])

  const options: any = useMemo(() => ([
    {
      ...(!!plotOptions?.["ingredients"]?.length && {
        value: 'ingredients',
        label: t('common.ingredients'),
        children: [...getCascaderOptions('ingredients')]
      }),
    },
    {
      ...(!!plotOptions?.["properties"]?.length && {
        value: 'properties',
        label: t('common.properties'),
        children: [...getCascaderOptions('properties')],
      }),
    },
    {
      ...(!!plotOptions?.["processing"]?.length && !Boolean(configs?.nestle_configs) && {
        value: 'processing',
        label: t('common.processing'),
        children: [...getCascaderOptions('processing')]
      })
    },
    {
      ...(Boolean(configs?.work_order_costing) && !!plotOptions?.["costing"]?.length && !Boolean(configs?.nestle_configs) && ({
        value: 'costing',
        label: t('inventory.costing'),
        children: [...getCascaderOptions('cost')]
      }))
    },
    ...(isFromCompareFormulation ? [] : ['work_order_id'].map(value => ({ label: t('common.workOrder'), value }))),
    ...(isFromCompareFormulation ? [] : filteredWorkOrdersList?.some((wo: any) => wo.material) ? ['material'] : []).map(value => ({ label: t('common.material'), value })),
    ...(isFromCompareFormulation ? [] : filteredWorkOrdersList?.some((wo: any) => wo.grade) ? ['grade'] : []).map(value => ({ label: t('common.grade'), value }))
  ].filter(values => JSON.stringify(values) !== '{}') ?? []), [isFromCompareFormulation, plotOptions, t, getCascaderOptions, configs?.nestle_configs, configs?.work_order_costing, filteredWorkOrdersList])

  const displayRender = (labels: string[]) => labels.join('/')

  useEffect(() => {
    reciepeDistributionFilterForm.setFieldsValue({
      recipeFilters: [{
        op: "",
        param: [],
        val: null
      }]
    })

  }, [reciepeDistributionFilterForm])

  useEffect(() => {
    reciepeDistributionFilterForm.setFieldsValue({
      recipeFilters: filterValues
    })
  }, [reciepeDistributionFilterForm, filterValues])

  const getOperators = (values: any) => {
    const valueType = [...new Set(reciepeExperiments?.map((value: any) => value?.[values?.[0]]?.[values?.[1]]?.value))]?.find((value: any) => value !== '')
    const isCategorical = typeof (Number(valueType) || valueType) === "string"
    if (isCategorical) {
      return ["="]
    } else if (Boolean(configs?.nestle_configs) && values?.[0] === 'processing') {
      return ['exists']
    } else if (values?.[0] === 'cost') {
      return ['=', '<', '<=', '>', '>=']
    } else {
      return OPS
    }
  }

  const getOptions = (type: string, index: number) => {
    if (type === "work_order_id") {
      const selectedWoIds = filterValues.filter((filterElement: any) => filterElement?.param?.[0] === type).map((workOrder: any) => workOrder.type)
      const options = Array.from(new Set(filteredWorkOrdersList.map((wo) => wo.work_order_id))).map((woId: any) => ({ label: filteredWorkOrdersList.find((wo: any) => wo.work_order_id === woId).work_order_name ?? woId, value: woId })).filter((workOrder) => !selectedWoIds.includes(workOrder.value))
      return options
    } else if (type === "grade") {
      const selectedGrades = filterValues.filter((filterElement: any) => filterElement?.param?.[0] === type).map((grade: any) => grade.type)
      const options = Array.from(new Set(filteredWorkOrdersList.map((wo) => wo.grade))).map((grade: any) => ({ label: filteredWorkOrdersList.find((exp: any) => exp.grade === grade).grade ?? grade, value: grade })).filter((grades) => !selectedGrades.includes(grades.value))
      return options
    } else if (type === "material") {
      const selectedMaterials = filterValues.filter((filterElement: any) => filterElement?.param?.[0] === type).map((material: any) => material.type)
      const options = Array.from(new Set(filteredWorkOrdersList.map((wo) => wo.material))).map((material: any) => ({ label: displayNames?.material?.[material]?.name ?? material, value: material })).filter((materials) => !selectedMaterials.includes(materials.value))
      return options
    }
    return []
  }


  const getFormItemBasedOnOperators = (field: any, operatorType: string, name: number) => {
    if (operatorType === "range") {
      const { recipeFilters } = reciepeDistributionFilterForm.getFieldsValue()
      return (
        <div style={{ display: "flex", gap: "4px", alignItems: "baseline" }}>
          <Form.Item
            rules={[
              {
                transform: (val: any) => val?.trim(),
              },
            ]}
            {...field}
            name={[name, "val"]}
          >
            <InputNumber style={{ minWidth: 70 }} placeholder={t("common.minimum")}
              formatter={formatter} parser={parser}
              onChange={(value) => {
                setFilterValues((prevState: any) => {
                  const values = [...prevState]
                  values[name].val = value
                  return values
                })
              }}
            />
          </Form.Item>
          <Text>{"~"}</Text>
          <Form.Item
            {...field}
            rules={[
              {
                transform: (val: any) => val?.trim(),
              },
            ]}
            validateStatus={((recipeFilters[name].val && recipeFilters[name].val2 && recipeFilters[name].val > recipeFilters[name].val2) || (recipeFilters[name].val && recipeFilters[name].val2 === 0 && recipeFilters[name].val > recipeFilters[name].val2) || (recipeFilters[name].val === 0 && recipeFilters[name].val2 && recipeFilters[name].val > recipeFilters[name].val2)) && "error"}
            help={((recipeFilters[name].val && recipeFilters[name].val2 && recipeFilters[name].val > recipeFilters[name].val2) || (recipeFilters[name].val && recipeFilters[name].val2 === 0 && recipeFilters[name].val > recipeFilters[name].val2) || (recipeFilters[name].val === 0 && recipeFilters[name].val2 && recipeFilters[name].val > recipeFilters[name].val2)) && "Max should be greater than Min!"}
            name={[name, "val2"]}
          >
            <InputNumber style={{ minWidth: 70 }} placeholder={t("formulations.placeholder.maximum")}
              formatter={formatter} parser={parser}
              onChange={(value) => {
                setFilterValues((prevState: any) => {
                  const values = [...prevState]
                  values[name].val2 = value
                  return values
                })
              }}
            />
          </Form.Item>
        </div>
      )
    } else if (operatorType === "exists") {
      return (
        <Form.Item
          rules={[{ required: true, message: t("common.required") }]}
          {...field}
          name={[name, "val"]}
          required
          tooltip={requiredFieldStar}
        >
          <Select style={{ minWidth: 200 }} onChange={(value) => {
            setFilterValues((prevState: any) => {
              const values = [...prevState]
              values[name].val = value
              return values
            })
          }}>
            <Select.Option value={"yes"}>{t("common.yes")}</Select.Option>
            <Select.Option value={"no"}>{t("common.no")}</Select.Option>
          </Select>
        </Form.Item>
      )
    } else {
      return (
        <Form.Item
          {...field}
          name={[name, 'val']}
        >
          <Input placeholder={t('common.enterValue')}
            onChange={(e) => {
              setFilterValues((prevState: any) => {
                const values = [...prevState]
                values[name].val = e.target.value
                return values
              })
            }}
          />
        </Form.Item>
      )
    }
  }

  const handleApplyFilter = (deleteIndex: number | undefined = undefined) => {
    const selectedFilters = isValidNumber(deleteIndex) ? filterValues?.filter((_: any, index: number) => deleteIndex !== index) : filterValues
    const filters = selectedFilters.reduce((acc: any[], filter: any, index: number) => {
      if ((filter?.param?.length > 0) && ((!!filter?.op?.length) ? (filter.op === "range" ? ((filter.val2 !== null && filter.val2 !== "") && (filter.val !== null && filter.val !== ""))
        : (["work_order_id", "material", "grade"].includes(filter?.param[0]) ? !!filter?.[filter?.param?.[0]]?.length : (filter?.val !== null && filter?.val !== ""))) : false) && visibilityArray[index]) {
        acc.push({
          "val": ["work_order_id", "material", "grade"].includes(filter?.param?.[0]) ? filter?.[filter?.param?.[0]] : filter?.val,
          "max": filter?.val2,
          "parameter": ["work_order_id", "material", "grade"].includes(filter?.param?.[0]) ? filter?.param?.[0] : filter?.param?.[1],
          "parameter_type": filter?.param?.[0],
          "operator": operatorConstantsMapper[filter?.op] ?? filter?.op
        })
      }
      return acc
    }, [])

    const paramTypes = ["properties", "processing"]
    dispatch(analyticsRecipeDistributionRequest({
      x: { "type": Xaxis[0], "value": Xaxis.at(-1), ...((Boolean(configs?.nestle_configs) && (paramTypes.includes(Xaxis[0]))) && { category: Xaxis[1] }) },
      y: { "type": Yaxis[0], "value": Yaxis.at(-1), ...((Boolean(configs?.nestle_configs) && (paramTypes.includes(Yaxis[0]))) && { category: Yaxis[1] }) },
      ...(Zaxis?.length && { z: { "type": Zaxis[0], "value": Zaxis.at(-1), ...((Boolean(configs?.nestle_configs) && (paramTypes.includes(Zaxis[0]))) && { category: Zaxis[1] }) } }),
      ...(!!filters?.length && { filters }),
      ...(!!selectedFormulations.length ? { formulation_ids: selectedFormulations } : { experiment_ids: selectedExperiments }),
      work_order_id: workOrderIds
    }))
  }

  return (
    <>
      <Form
        name="recipe-filter-form"
        form={reciepeDistributionFilterForm}
        style={{ width: "100%" }}
        autoComplete="off"
        requiredMark={false}
        onFinish={handleApplyFilter}
      >
        <Form.List name="recipeFilters">
          {(fields, { add, remove }) => (
            <>
              <Form.Item>
                <StyledButton
                  disabled={recipeDistributionColorCodes.length === filterValues.length}
                  type="primary"
                  style={{ width: "8rem" }}
                  onClick={() => {
                    add();
                    setFilterValues((prev: any) => [
                      ...prev,
                      { param: [], op: "", val: null },
                    ]);
                    setVisibilityArray((prev: string[]) => [...prev, true])
                  }}
                  block
                  icon={<PlusOutlined />}
                >
                  {t("common.addFilters")}
                </StyledButton>
              </Form.Item>
              {fields.map(({ key, name, ...restField }, index) => (
                <StyledCard
                  style={{
                    padding: "0.5rem",
                    borderLeft: `8px solid ${recipeDistributionColorCodes[index]}`,
                  }}
                >
                  <Row style={{ alignItems: "baseline" }}>
                    <Col span={1}>
                      {visibilityArray[index] ? (
                        <EyeOutlined
                          onClick={(e) => {
                            e.stopPropagation();
                            setVisibilityArray((arr: any) =>
                              arr.map((val: any, idx: number) =>
                                idx === index ? false : val
                              )
                            );
                          }}
                        />
                      ) : (
                        <EyeInvisibleOutlined
                          onClick={(e) => {
                            e.stopPropagation();
                            setVisibilityArray((arr: any) =>
                              arr.map((val: any, idx: number) =>
                                idx === index ? true : val
                              )
                            );
                          }}
                        />
                      )}
                    </Col>
                    <Col
                      span={
                        filterValues[name]?.param?.length === 1 &&
                          ["work_order_id", "material", "grade"].includes(filterValues[name]?.param?.[0]) ? 11 : 8
                      }
                      style={{ paddingInline: "0.5rem" }}
                    >
                      <Form.Item {...restField} name={[name, "param"]}>
                        <Cascader
                          options={options}
                          displayRender={displayRender}
                          showSearch
                          onChange={(value) => {
                            const { recipeFilters } =
                              reciepeDistributionFilterForm.getFieldsValue();
                            if (Boolean(configs?.nestle_configs) && value && value[0] === 'processing') {
                              setFilterValues((prevState: any) => {
                                const values = [...prevState];
                                values[name] = recipeFilters[name];
                                values[name].op = "exists";
                                values[name].val = "";
                                return values;
                              });
                            } else {
                              setFilterValues((prevState: any) => {
                                const values = [...prevState];
                                values[name] = recipeFilters[name];
                                values[name].op = "";
                                values[name].val = "";
                                return values;
                              });
                            }
                          }}
                          placeholder={t("common.pleaseSelect")}
                        />
                      </Form.Item>
                    </Col>
                    {filterValues[name]?.param?.length === 1 &&
                      ["work_order_id", "material", "grade"].includes(
                        filterValues[name]?.param?.[0]
                      ) ? (
                      <Col span={11} style={{ paddingInline: "0.5rem" }}>
                        <Form.Item {...restField} name={[name, ["work_order_id", "material", "grade"].includes(filterValues[name]?.param?.[0]) ? filterValues[name]?.param?.[0] : "val"]}>
                          <Select
                            optionFilterProp="children"
                            onChange={(value) => {
                              setFilterValues((prevState: any) => {
                                const values = [...prevState];
                                values[name][filterValues[name]?.param?.[0]] = value;
                                if (filterValues[name]?.param?.[0] === "work_order_id") {
                                  values[name][value] = filteredWorkOrdersList.find((wo: any) => wo.work_order_id === value).work_order_name
                                }
                                values[name][value] = value
                                values[name].op = "=";
                                return values;
                              });
                            }}
                            placeholder="Select From Options"
                            options={getOptions(
                              filterValues[name]?.param?.[0],
                              name
                            )}
                          />
                        </Form.Item>
                      </Col>
                    ) : (
                      <>
                        <Col span={7} style={{ paddingInline: "0.5rem" }}>
                          <Form.Item {...restField} name={[name, "op"]}>
                            <Select
                              onChange={(value) => {
                                const { recipeFilters } =
                                  reciepeDistributionFilterForm.getFieldsValue();

                                setFilterValues((prevState: any) => {
                                  const values = [...prevState];
                                  values[name].op = value;
                                  values[name].val = "";
                                  if (
                                    values[name].op !== "range" &&
                                    values[name].hasOwnProperty("val2")
                                  ) {
                                    delete values[name].val2;
                                  }
                                  return values;
                                });
                                if (recipeFilters?.[key]) {
                                  Object.assign(recipeFilters?.[key], {
                                    val: "",
                                    val2: "",
                                  });
                                }
                                reciepeDistributionFilterForm.setFieldsValue({
                                  recipeFilters,
                                });
                              }}
                              optionFilterProp="children"
                              placeholder={t("common.selectOperator")}
                              options={getOperators(
                                filterValues[name]?.param
                              )?.map((opValue: any) => ({
                                value: opValue,
                                label: opValue === 'exists' ? t('common.exists') : opValue === 'range' ? t('common.range') : opValue,
                              }))}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={7} style={{ paddingInline: "0.5rem" }}>
                          {getFormItemBasedOnOperators(
                            restField,
                            filterValues?.[name]?.op,
                            name
                          )}
                        </Col>
                      </>
                    )}
                    <Col span={1} style={{ textAlign: "end" }}>
                      <StyledDeleteIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setFilterValues((prevState: any) => prevState.filter((item: any, index: number) => index !== name));
                          setVisibilityArray((prev: string[]) => prev.filter((item: any, index: number) => index !== name))
                          remove(name);
                          if (!!Xaxis?.length && !!Yaxis?.length) {
                            handleApplyFilter(name)
                          }
                        }}
                      />
                    </Col>
                  </Row>
                </StyledCard>
              ))}
            </>
          )}
        </Form.List>
        <div style={{ display: "flex", justifyContent: "flex-end", padding: "1rem 0rem" }}>
          {!!filterValues.length && !!Xaxis.length && !!Yaxis?.length &&
            <StyledButton disabled={!Xaxis.length && !Yaxis?.length} onClick={() => handleApplyFilter()}>{t("common.applyFilter")}</StyledButton>
          }
        </div>
      </Form>
    </>
  );
}
