import { Checkbox, Select, Space, Tag, Tooltip, Typography } from "antd";
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import { ModelConfig } from "src/typings";
import useTranslate from "src/utils/useTranslate";
import { getTranslatedIngredientsAndProcessing } from "../../utils";
import { ParameterTooltip } from "./ParameterTooltip";
import { DownOutlined } from "@ant-design/icons";

const { Text } = Typography;
const { Option, OptGroup } = Select;

type IngredientsSelectProps = {
  index: number;
  versionData: ModelConfig | undefined;
  ingredientsInputs: any;
  ingredientsParameterList: string[];
  ingredientsDataList: any[];
  ingredientsCategories: any;
  groupedIngredientOptions: any;
  setIngredientsDataList: Dispatch<SetStateAction<{ [key: number]: any[] }>>;
  setIngredientsParameterList: Dispatch<SetStateAction<{ [key: number]: string[] }>>;
  setUnsavedChanges: Dispatch<SetStateAction<boolean>>;
  currentSelectedStage: number,
  trialDisplayNameList: any
};

const IngredientsSelect = ({
  index,
  versionData,
  ingredientsCategories,
  ingredientsDataList,
  ingredientsParameterList,
  ingredientsInputs,
  groupedIngredientOptions,
  setIngredientsDataList,
  setIngredientsParameterList,
  setUnsavedChanges,
  currentSelectedStage,
  trialDisplayNameList
}: IngredientsSelectProps) => {
  const [t] = useTranslate();

  const displayNames = useSelector(
    (state: StoreState) => state.displayNames.data ?? {}
  );

  const hasCategories = useMemo(
    () => Object.keys(ingredientsCategories || {}).length !== 0,
    [ingredientsCategories]
  );

  const configs = useSelector((state: StoreState) => state.configs.features);
  const [tagMode, setTagMode] = useState(true);

  // const getIngredientCategory = (ingredient: string) => {
  //   for (const group of groupedIngredientOptions) {
  //     const option = group.options.find((opt) => opt.value === ingredient);
  //     if (option) {
  //       return group.label;
  //     }
  //   }
  //   return null;
  // };

  // const disableIngredientOption = (ingredientOptionValue: string) => {
  //   const optionCategory = getIngredientCategory(ingredientOptionValue);
  //   const currentIngredientCategory = getIngredientCategory(
  //     ingredientsParameterList[index]
  //   );
  //   const isAlreadySelectedFromCategory = ingredientsParameterList?.find(
  //     (selectedIngredient) =>
  //       getIngredientCategory(selectedIngredient) === optionCategory
  //   );
  //   return (
  //     isAlreadySelectedFromCategory &&
  //     optionCategory !== currentIngredientCategory
  //   );
  // };

  const modelConfigData = useSelector((state: StoreState) => state.formulate.modelConfigData);

  const getIngredientCategoryName = useCallback((ingredientName: string) => {
    if (!modelConfigData?.[currentSelectedStage - 1]) return null
    const categoryList = modelConfigData?.[currentSelectedStage - 1]?.categories?.ingredients?.[ingredientName]
    if (!categoryList?.length) return null
    return categoryList.join(", ")
  }, [currentSelectedStage, modelConfigData])

  const onOptionSelect = (optValue: any) => {
    const { min, max } = ingredientsInputs[optValue];
    if (!!min && !!max && min === max && !configs?.no_autofill) {
      setIngredientsDataList((prev) => ({
        ...prev,
        [currentSelectedStage]: ingredientsDataList.map((ingredientRow) => ({
          ...ingredientRow,
          [optValue]: max,
        }))
      }))
    }
    setIngredientsParameterList((prevState: any) => {
      const ingredients = { ...prevState }
      const data = [...ingredients[currentSelectedStage]]
      data.splice(index, 1, optValue);
      return {
        ...prevState,
        [currentSelectedStage]: data
      };
    })
    setUnsavedChanges(true);
  }

  const metaInputIngredients = useMemo(() => {
    const currentModelConfigData = modelConfigData?.[currentSelectedStage - 1];

    return currentModelConfigData?.inputs_ingredients_others || [];
  }, [modelConfigData, currentSelectedStage]);

  const ingredientValue = trialDisplayNameList?.[currentSelectedStage - 1]?.find((ele: any) => ele?.value === ingredientsParameterList?.[index])?.label || modelConfigData?.[currentSelectedStage - 1]?.display_names?.ingredients?.[ingredientsParameterList[index]] || versionData?.display_names?.ingredients?.[ingredientsParameterList[index]] ||
    displayNames?.ingredients?.[ingredientsParameterList?.[index]]?.name ||
    ingredientsParameterList[index];

  return (
    <div style={{
      position: 'relative',
      width: "100%",
      height: "32px",
    }}>
      {tagMode && (<div style={{
        padding: '0px 10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 1,
        width: "100%",
        backgroundColor: 'white',
        height: "32px",
        pointerEvents: 'none'
      }}>
        <div>
          {String(ingredientValue || "").length > 24 ? <Tooltip title={ingredientValue}>{String(ingredientValue).substring(0, 24)}...</Tooltip> : ingredientValue}
          &nbsp;&nbsp;{metaInputIngredients.includes(ingredientsParameterList[index]) ? <Tag style={{ padding: '2px 6px', fontSize: '10px', lineHeight: "12px" }} color="processing">{t("common.New")}</Tag> : null}
        </div>
        <div><DownOutlined style={{ color: "#d9d9d9" }} /></div>
      </div>)}
      <Select
        value={ingredientValue}
        filterOption={(input, option: any) => { console.log(input, option);
          if (!option) return false;

          const value: string = (
            (option?.children?.props?.value ?? "") as string
          ).toLowerCase();

          const replacedValue = value
            .trim()
            .replaceAll("_", " ")
            .replaceAll("  ", " ");

          return replacedValue.includes(input.toLowerCase());
        }}
        bordered={false}
        showSearch
        placeholder={t("aiEngine.selectInputs")}
        onFocus={() => setTagMode(false)}
        onBlur={() => setTagMode(true)}
        style={{
          flexGrow: 1,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          verticalAlign: "bottom",
          width: '100%',
          position: "absolute",
          height: "32px",
          top: 0,
          left: 0   
        }}
        optionRender={(option) => (
          <div>{option.label}&nbsp;&nbsp;{metaInputIngredients.includes(option?.value) ? <Tag style={{ padding: '2px 6px', fontSize: '10px', lineHeight: "12px" }} color="processing">{t("common.New")}</Tag> : null}</div>
        )}
        notFoundContent={
          !!Object.keys(ingredientsInputs || {}).length ? (
            <Space style={{ background: "whitesmoke", width: "100%" }}>
              <Text>{t("common.noSuchParameter")}</Text>
            </Space>
          ) : (
            <Space style={{ background: "whitesmoke", width: "100%" }}>
              <Text>{t("noIngrsFound")}</Text>
            </Space>
          )
        }
        dropdownRender={(menu) => {
          return (
            <>
              {!!Object.keys(ingredientsInputs || {}).length && !hasCategories && (
                <Checkbox
                  style={{ padding: 10 }}
                  checked={
                    ingredientsParameterList.every((res: any) => !!res) &&
                    ingredientsParameterList.length ===
                    Object.keys(ingredientsInputs || {}).length
                  }
                  onChange={(e: any) => {
                    if (e.target.checked) {
                      setIngredientsParameterList((prev) => ({
                        ...prev,
                        [currentSelectedStage]: Object.keys(ingredientsInputs || {})
                      }));
                      setIngredientsDataList((prev) => ({
                        ...prev,
                        [currentSelectedStage]: Object.keys(ingredientsDataList || {}).map(() =>
                          Object.entries(ingredientsInputs).reduce(
                            (acc, [ingredient, { min, max }]: any) => ({
                              ...acc,
                              [ingredient]: (min === max && !configs?.no_autofill) ? max : "",
                            }),
                            {}
                          )
                        )
                      })
                      );
                    } else {
                      setIngredientsParameterList((prev) => ({
                        ...prev,
                        [currentSelectedStage]: [""]
                      }));
                    }
                  }}
                >{`${t("common.selectAll")} ${getTranslatedIngredientsAndProcessing('ingredients', t)
                  ?.charAt(0)
                  ?.toUpperCase() +
                  getTranslatedIngredientsAndProcessing('ingredients', t)?.slice(1)}`}</Checkbox>
              )}
              {menu}
            </>
          );
        }}
      >
        {hasCategories ? (
          <>
            {groupedIngredientOptions.map((group: any) => (
              <OptGroup key={group.label} label={group.label}>
                {group.options
                  .filter(
                    (res: any) => !ingredientsParameterList.includes(res.value)
                  )
                  .map((option: any) => {
                    const multstageIngName = trialDisplayNameList?.[currentSelectedStage - 1]?.find((ele: any) => ele?.value === option.value)?.label || modelConfigData?.[currentSelectedStage - 1]?.display_names?.ingredients?.[option.value]
                    return (
                      <Option
                        key={option.value}
                        value={option.value}
                      // disabled={disableIngredientOption(option.value)}
                        onMouseDown={() => onOptionSelect(option.value)}
                      >
                        {/* {option.label} */}
                        <ParameterTooltip
                          value={
                            multstageIngName ?? versionData?.display_names?.ingredients?.[
                            option.value
                            ] ?? option.value
                          }
                          category={option.category}
                        />
                      </Option>
                    )
                  })}
              </OptGroup>
            ))}
          </>
        ) : (
          <>
            {Object.keys(ingredientsInputs ?? {})
              .filter((res: any) => !ingredientsParameterList.includes(res))
              .map((value: any) => {
                const multstageIngName = trialDisplayNameList?.[currentSelectedStage - 1]?.find((ele: any) => ele?.value === value)?.label || modelConfigData?.[currentSelectedStage - 1]?.display_names?.ingredients?.[value]
                return <Option value={value} key={value} onMouseDown={() => onOptionSelect(value)}>
                  <ParameterTooltip
                    value={
                      multstageIngName ?? versionData?.display_names?.ingredients?.[value] ?? value
                    }
                    category={getIngredientCategoryName(value)}
                  />
                </Option>
              })}
          </>
        )}
      </Select>
    </div>
  );
};

export default IngredientsSelect;
