import { EditOutlined, EnterOutlined } from "@ant-design/icons"
import { Row, Descriptions, Tag, Typography, Space, Input } from "antd"
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import { AsyncStates } from "src/constants"
import { changeTitleRequest } from "src/store/actions/suggestedExp"
import { StoreState } from "src/store/configureStore"
import {
  generateVersionName,
  predictionStatusColorText,
  predictionStatusIcon,
  predictionStatus,
} from "src/utils/decorator"
import useTranslate from "src/utils/useTranslate"

const { Text } = Typography

const ModelDetails = ({ data }: any) => {
  const [t] = useTranslate()
  const dispatch = useDispatch()
  // let query = useQuery()
  const { configData } = useSelector((state: StoreState) => state.formulate)
  const { changeTitleStatus } = useSelector(
    (state: StoreState) => state.suggestedExp,
  )
  // const inverseModel = useSelector((state: StoreState) => state.inverseModel)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [title, setTitle] = useState<string>("")

  // const predId = query?.get("predId")
  // const predDetailsInverse = useMemo(() => {
  //   return inverseModel?.predictionIdsData?.data?.find(
  //     (pred: any) => pred.prediction_id === predId,
  //   )
  // }, [inverseModel?.predictionIdsData?.data, predId])

  useEffect(() => {
    setTitle(data?.title)
  }, [data?.title])

  useEffect(() => {
    if (changeTitleStatus === AsyncStates.ERROR)
      setTitle(data?.title)
  }, [changeTitleStatus, data?.title])

  const submitTitle = useCallback(() => {
    setIsEditing(false)
    dispatch(
      changeTitleRequest({
        prediction_id: data?.prediction_id,
        title,
        type: "inverse",
      }),
    )
  }, [data?.prediction_id, dispatch, title])

  return (
    <Row
      style={{
        padding: "16px",
        borderRadius: "8px",
        backgroundColor: "#FAFAFA",
      }}
    >
      <Descriptions
        layout="vertical"
        colon={false}
        size="small"
        className="suggested-exp-description"
        column={5}
        bordered={true}
      >
        <Descriptions.Item
          label={`${t("aiEngine.objective")} / ${t("common.title")}`}
        >
          <Space style={{ width: "100%" }}>
            {isEditing ? (
              <Input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                onBlur={() => submitTitle()}
                autoFocus
                size="small"
                style={{ flexGrow: 1 }}
                onPressEnter={() => submitTitle()}
              />
            ) : (
                <Text
                  style={{ maxWidth: "300px" }}
                  ellipsis={{ tooltip: title }}
                  strong
                >
                  {title}
                </Text>
            )}
            {isEditing ? (
              <EnterOutlined
                onClick={() => submitTitle()}
                style={{ outline: "none" }}
              />
            ) : (
                <EditOutlined
                  onClick={() => setIsEditing(true)}
                  style={{ outline: "none" }}
                />
            )}
          </Space>
        </Descriptions.Item>
        <Descriptions.Item label={t("common.model")}>
          <Text
            style={{ maxWidth: "300px" }}
            ellipsis={{
              tooltip: generateVersionName(
                data?.version,
                configData,
              ),
            }}
            strong
          >
            {data?.version !== "undefined" || data?.version !== "null"
              ? generateVersionName(data?.version, configData)
              : t("common.loading")}
          </Text>
        </Descriptions.Item>
        <Descriptions.Item label={t("common.status")}>
          <Tag
            color={predictionStatusColorText[data?.status]}
            icon={predictionStatusIcon[data?.status]}
            style={{
              textTransform: "capitalize",
              border: "1px solid",
              fontWeight: "bold",
            }}
          >
            {data?.status
              ? predictionStatus[data?.status]
              : t("common.loading")}
          </Tag>
        </Descriptions.Item>
      </Descriptions>
    </Row>
  )
}

export default ModelDetails
