import { LoadingOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { AutoComplete, Divider, Form, Input, Modal, Radio, Row, Select, Space, Spin } from 'antd'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRequiredFieldStar } from 'src/components/Common/useRequiredFieldStar'
import { AsyncStates } from 'src/constants'
import { createMetadataTemplateRequest, editMetadataTemplateRequest } from 'src/store/actions/inventory'
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import { StyledCard } from 'src/styled_components/StyledCard'
import useTranslate from 'src/utils/useTranslate'

const { OptGroup, Option } = Select

export const AddTemplateModal = ({ visible, setVisible, templateFields, setTemplateFields, filterCategories, filterSubCategories, setMetadataModalVisible, currentType }: any) => {
    const [t] = useTranslate()
    const [form] = Form.useForm()
    const requiredFieldStar = useRequiredFieldStar()
    const dispatch = useDispatch()
    const unitsList = useSelector((state: StoreState) => state.conversion.unitList)
    const inventoryData = useSelector((state: StoreState) => state.displayNames.data)
    const templateCreateStatus = useSelector((state: StoreState) => state.inventory.templateCreateStatus)
    const templateUpdateStatus = useSelector((state: StoreState) => state.inventory.templateUpdateStatus)

    const closeModal = useCallback(() => {
        form.resetFields()
        setTemplateFields([])
        setVisible(false)
    }, [setTemplateFields, setVisible, form])

    useEffect(() => {
        if (templateCreateStatus === AsyncStates.SUCCESS || templateUpdateStatus === AsyncStates.SUCCESS) {
            closeModal()
        }
    }, [closeModal, templateCreateStatus, templateUpdateStatus])

    useEffect(() => {
        form.setFieldsValue({
            name: templateFields?.name ?? "",
            ...(!!templateFields?.template_id && { type: templateFields?.type }),
            category: templateFields?.category,
            sub_category: templateFields?.sub_category,
            properties: templateFields?.properties
        })
    }, [form, templateFields])

    const formSubmit = (values: any) => {
        if (!!templateFields?.template_id) {
            dispatch(editMetadataTemplateRequest({
                template_id: templateFields?.template_id,
                type: values?.type,
                name: values?.name,
                category: values?.category,
                sub_category: values?.sub_category,
                data: values?.properties?.map((res: any) => ({ identifier: res?.identifier, unit: res?.unit })),
                currentType
            }))
        } else {
            dispatch(createMetadataTemplateRequest({
                name: values?.name,
                type: values?.type,
                category: values?.category,
                sub_category: values?.sub_category,
                data: values?.properties?.map((res: any) => ({ identifier: res?.identifier, unit: res?.unit }))
            }))
        }
    }


    return (
        <Modal
            title={`${templateFields?.template_id ? "View/Edit" : "Save"} properties directory in a template`}
            zIndex={999}
            maskClosable={false}
            footer={null}
            visible={visible}
            width={800}
            onCancel={closeModal}
        >
            <Spin spinning={templateCreateStatus === AsyncStates.LOADING || templateUpdateStatus === AsyncStates.LOADING} indicator={<LoadingOutlined />}>
                <Space direction="vertical" size="large" style={{ width: "100%", marginTop: 20 }}>
                    <Form requiredMark={false} form={form} layout="vertical" onFinish={formSubmit} initialValues={{ type: "company" }}>
                        <Form.Item label={t("common.name")} name={"name"} required tooltip={requiredFieldStar}
                            rules={[{ required: true, transform: (value) => value?.trim() }]}>
                            <Input placeholder={t("common.name")} />
                        </Form.Item>
                        <Form.Item label={t('project.saveNewTemplate')} required tooltip={requiredFieldStar}
                            rules={[{ required: true, transform: (value) => value?.trim() }]} name="type">
                            <Radio.Group disabled={!!templateFields?.template_id && templateFields?.type === "company"}>
                                <Radio value={"company"}>{t("inventory.companyDirectory")}</Radio>
                                <Radio value={"personal"}>{t("inventory.personalDirectory")}</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label={t("common.category")} required tooltip={requiredFieldStar}
                            rules={[{ required: true, transform: (value) => value?.trim() }]} name="category">
                            <AutoComplete
                                showSearch
                                placeholder={t("common.selectCategory")}
                                filterOption={true}
                                options={filterCategories.map((res: any) => ({ label: res, value: res }))}
                            />
                        </Form.Item>
                        <Form.Item label={t("inventory.subCategory")}
                            rules={[{ required: false, transform: (value) => value?.trim() }]} name="sub_category">
                            <AutoComplete
                                showSearch
                                placeholder={t("common.selectCategory")}
                                filterOption={true}
                                options={filterSubCategories.map((res: any) => ({ label: res, value: res }))}
                            />
                        </Form.Item>
                        <StyledCard title={t("formulations.type.properties")}>
                            <Form.List name="properties">
                                {(fields, { add, remove }, { errors }) => (
                                    <>
                                        {fields.map(({ key, name, }, index: number) => (
                                            <Space direction="horizontal">
                                                <Form.Item
                                                    name={[name, 'category']}
                                                    fieldKey={[key, 'category']}
                                                    rules={[{ required: true, transform: (value) => value?.trim() }]}
                                                    required tooltip={requiredFieldStar}
                                                >
                                                    <Input disabled />
                                                </Form.Item>
                                                <Form.Item name={[name, 'identifier']} fieldKey={[key, 'identifier']} required tooltip={requiredFieldStar} rules={[{ required: true, message: t("common.required"), transform: (value) => value?.trim() }]}>
                                                    <Select
                                                        showSearch
                                                        style={{ width: 220 }}
                                                        filterOption={true}
                                                        placeholder={t("common.selectProperties")}
                                                        onChange={e => {
                                                            const currentFormField = {
                                                                category: inventoryData?.metadata_properties[e]?.category, identifier: e,
                                                                unit: inventoryData?.metadata_properties[e]?.unit?.[0], value: ""
                                                            }
                                                            const formProperties = form.getFieldValue("properties")
                                                            formProperties[index] = currentFormField
                                                            form.setFieldsValue({ "properties": formProperties })
                                                        }}
                                                        dropdownRender={(menu) => (
                                                            <div>
                                                                {menu}
                                                                <Divider style={{ margin: "4px 0" }} />
                                                                {/* <CustomSelectionDropdown {...props} /> */}
                                                                <StyledButton
                                                                    onClick={() => setMetadataModalVisible(true)}
                                                                    type="link"
                                                                >
                                                                    {t("inventory.AddNewproperty")}
                                                                </StyledButton>
                                                            </div>
                                                        )}
                                                    >
                                                        {Object.entries(inventoryData?.metadata_properties || {}).map(([key, value]: any) => (
                                                            <Option key={key} value={key}>{value?.name}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item name={[name, 'unit']} fieldKey={[key, 'unit']} required tooltip={requiredFieldStar} rules={[{ required: true, message: t("common.required") }]} >
                                                    <Select showSearch
                                                        placeholder={t("common.selectUnit")}>
                                                        {Array.from(new Set(unitsList.map((res: any) => res.category.toLowerCase()))).map((category: any) =>
                                                            <OptGroup label={String(category).charAt(0).toLocaleUpperCase() + String(category).slice(1)}>
                                                                {unitsList.filter((res: any) => res.category.toLowerCase() === category).map((res: any, index: any) => (
                                                                    <Option value={res.name} key={res.name + index}>{res.name}</Option>
                                                                ))}
                                                            </OptGroup>
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                                <MinusCircleOutlined
                                                    className="dynamic-delete-button"
                                                    onClick={() => remove(name)}
                                                />
                                            </Space>
                                        ))}
                                        <Form.Item>
                                            <Space>
                                                <StyledButton
                                                    type="dashed"
                                                    style={{ width: "100%" }}
                                                    onClick={() => add()}
                                                    icon={<PlusOutlined />}
                                                >
                                                    {t("inventory.addProperty")}
                                                </StyledButton>
                                            </Space>
                                            <Form.ErrorList errors={errors} />
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </StyledCard>
                        <Row justify={"end"} style={{ marginTop: 40 }}>
                            <Space>
                                <StyledButton onClick={closeModal}>
                                    {t("common.cancel")}
                                </StyledButton>
                                <StyledButton type={"primary"} htmlType="submit">
                                    {!!templateFields?.template_id ? t("common.edit") : t("common.save")}
                                </StyledButton>
                            </Space>
                        </Row>
                    </Form>
                </Space>
            </Spin>
        </Modal>
    )
}
