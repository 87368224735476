import { FilterOutlined } from '@ant-design/icons'
import {
  Typography,
  Popover,
  Col,
  Row,
  Space,
  DatePicker,
  Input,
  Form,
  Select,
  Checkbox,
  Badge,
} from 'antd';
import { useForm } from 'antd/es/form/Form'
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import {
  qcMetadataSetFilters,
  qcMetadataListRequest,
  qcMetadataSetSearchTerm,
} from 'src/store/actions/qualityCheck';
import { StoreState } from 'src/store/configureStore'
import { StyledButton } from 'src/styled_components/StyledButton'
import { toTitleCase } from 'src/utils/general'
import useTranslate from 'src/utils/useTranslate'

import './TopHeader.scss'
import _ from 'lodash';

const initialFilters = {
  categories: [],
  suppliers: [],
  product_name: [],
  updated_at: null,
  operator: 'and',
  is_draft: null
}


const TopHeader = () => {
  const [t] = useTranslate()
  const dispatch = useDispatch()

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false)

  const [enableFiltersApply, setEnableFiltersApply] = useState<boolean>(false)
  const [enableFiltersClear, setEnableFiltersClear] = useState<boolean>(false)

  const [searchTerm, setSearchTerm] = useState<string>('')

  const {
    metadataListTotal,
    metadataFilters,
    metadataSearchTerm
  } = useSelector((state: StoreState) => state.qualityCheck)

  useEffect(() => {
    if(metadataSearchTerm) (setSearchTerm(metadataSearchTerm))
  }, [metadataSearchTerm])

  const [filterSelections, setFilterSelections] = useState<{ [key: string]: any }>(
    metadataFilters
  )

  useEffect(() => {
    const noChange = _.isEqual(metadataFilters, initialFilters)
    if (!noChange) {
      setEnableFiltersClear(true)
    }
  }, [metadataFilters])

  return (
    <div
      style={{ height: '5%', display: 'flex', gap: 12, alignItems: 'center' }}
      className="quality-check-files-table-header"
    >
      <Typography.Title level={5}>
        {`${t('common.items')} (${metadataListTotal})`}
      </Typography.Title>

      <Popover
        content={
          <Space direction="vertical" size={'middle'} style={{
            width: 400,
            padding: 12
          }} >
            <FilterForm
              filterSelections={filterSelections}
              setFilterSelections={setFilterSelections}
              enableButtons={() => {
                setEnableFiltersApply(true)
                setEnableFiltersClear(true)
              }}
            />
            <Row justify="end" gutter={12}>
              <Col>
                <StyledButton
                  type="primary"
                  onClick={() => {
                    dispatch(qcMetadataSetFilters(filterSelections))
                    dispatch(qcMetadataListRequest({ page_num: 1 }))
                    setEnableFiltersApply(false)
                  }}
                  size='small'
                  disabled={!enableFiltersApply}
                >
                  {t('aiEngine.button.apply')}
                </StyledButton>
              </Col>
              <Col>
                <StyledButton
                  onClick={() => {
                    setFilterSelections(initialFilters)
                    dispatch(qcMetadataSetFilters(initialFilters))
                    dispatch(qcMetadataListRequest({ page_num: 1 }))
                    setEnableFiltersClear(false)
                    setEnableFiltersApply(false)
                  }}
                  size='small'
                  disabled={!enableFiltersClear}
                >
                  {t('common.clear')}
                </StyledButton>
              </Col>
            </Row>
          </Space>
        }
        placement="bottomLeft"
        trigger={'click'}
        open={isFilterOpen}
        onOpenChange={(visible) => setIsFilterOpen(visible)}
      >
        <Badge dot={enableFiltersClear} >
          <StyledButton
            icon={<FilterOutlined />}
            className={isFilterOpen ? 'action-button-active' : ''}
          >
            {t('common.filters')}
          </StyledButton>
        </Badge>
      </Popover>

      <Badge dot={metadataSearchTerm !== ''}>
        <Input.Search
          placeholder={t('common.search')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onSearch={(value) => {
            dispatch(qcMetadataSetSearchTerm(value))
            dispatch(qcMetadataListRequest({ page_num: 1 }))
          }}
          style={{ width: 200, justifySelf: 'flex-end' }}
          allowClear
        />
      </Badge>
    </div>
  )
}

export default TopHeader

const FilterForm = ({ setFilterSelections, filterSelections, enableButtons }: any) => {
  const [form] = useForm()
  const [t] = useTranslate()

  const [isUpdatedAtDate, setIsUpdatedAtDate] = useState<boolean>(
    !Array.isArray(filterSelections['updated_at'])
  )

  const { getNameDetailsResponse } = useSelector((state: StoreState) => state.qualityCheck)

  useEffect(() => {
    form.setFieldsValue(filterSelections)
  }, [filterSelections, form])

  return <Form form={form}
    onFieldsChange={() => {
      setFilterSelections(form.getFieldsValue())
      enableButtons()
    }}
    labelAlign='left'
    labelCol={{ span: 8 }}
    wrapperCol={{ span: 16 }}
    size='small'
    style={{
      display: 'flex',
      flexDirection: 'column',
      gap: 12,
    }}
    className='qc-files-table-filter-form'
    initialValues={filterSelections}
  >
    <Form.Item name="operator" label={t('common.operator')} >
      <Select
        options={[
          { value: 'and', label: toTitleCase(t('common.and')) },
          { value: 'or', label: toTitleCase(t('common.or')) }
        ]}
      ></Select>
    </Form.Item>
    <Form.Item name="categories" label={t('common.Categories')} >
      <Select
        mode="multiple"
        placeholder={t('common.Categories')}
        allowClear
        showSearch
        maxTagCount={'responsive'}
        optionFilterProp='label'
        options={
          (getNameDetailsResponse?.category || [])?.map((category: any) => {
            return {
              value: category.detail_id,
              label: category.name
            }
          })
        }
      />
    </Form.Item>

    <Form.Item name="suppliers" label={t('common.Supplier')} >
      <Select
        mode="multiple"
        placeholder={t('common.Supplier')}
        allowClear
        showSearch
        optionFilterProp='label'
        maxTagCount={'responsive'}
        options={
          (getNameDetailsResponse?.supplier || [])?.map((supplier: any) => {
            return {
              value: supplier.detail_id,
              label: supplier.name
            }
          })
        }
      />
    </Form.Item>

    <Form.Item name="product_name" label={t('qc.productName')} >
      <Select
        mode="multiple"
        placeholder={t('qc.productName')}
        allowClear
        showSearch
        maxTagCount={'responsive'}
        optionFilterProp='label'
        options={
          (getNameDetailsResponse?.product || [])?.map((product: any) => {
            return {
              value: product.detail_id,
              label: product.name
            }
          })
        }
      />
    </Form.Item>

    <Form.Item name='is_draft' label={t('common.status')} >
      <Select
        placeholder={t('common.status')}
        allowClear
        showSearch
        optionFilterProp='label'
        options={
            [
              { label: t('common.all'), value: null },
              { label: t('common.Draft'), value: true },
              { label: t('common.completed'), value: false },
            ]
        }
      />
    </Form.Item>

    <Form.Item label={t('common.lastEdited')} >
      <div style={{
        display: 'flex',
        gap: 12,
        alignItems: 'center'
      }} >
        <Checkbox
          checked={isUpdatedAtDate}
          onChange={(e) => {
            setIsUpdatedAtDate(e.target.checked)
            form.setFieldValue('updated_at', null)
            setFilterSelections(form.getFieldsValue())
            enableButtons()
          }}
        >
          {t('common.date')}
        </Checkbox>
        <Form.Item name='updated_at' style={{
          marginBottom: 0,
          flexGrow: 1
        }}  >
          {
            isUpdatedAtDate ? <DatePicker
              format={'DD-MM-YYYY'}
              style={{
                width: '100%'
              }}
            /> :
              <DatePicker.RangePicker
                format={'DD-MM-YYYY'}
              />
          }
        </Form.Item>
      </div>
    </Form.Item>
  </Form>
}