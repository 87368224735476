import { Col, Collapse, Empty, Row, Select, Space, Typography } from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "src/store/configureStore";
import useTranslate from "src/utils/useTranslate";


export const CelsureCompareGraph = () => {
    const { ceslurePlotsData }: any = useSelector((state: StoreState) => state.celsureWo)
    const [type, setType] = useState<any>("actual")
    const [selectedTrials, setSelectedTrials] = useState<any>()
    const [plotData, setPlotData] = useState<any>()
    const [t] = useTranslate()

    useEffect(() => {
        setPlotData(ceslurePlotsData?.filter((ele: any) => selectedTrials?.includes(ele?.trial_id)))
    }, [ceslurePlotsData, selectedTrials])

    const setExpType = useCallback((value: any) => {
        setType(value)
        setSelectedTrials([])
    }, [])

    return (
        <Collapse>
            <Collapse.Panel header={"Compare Graphs"} key="compare_plots">
                <Space direction="vertical" size={"large"} style={{ width: "100%", maxHeight: 800, overflowY: "auto" }}>
                    <Space>
                        <Typography>{t("common.selectTrialsToCompare")} :</Typography>
                        <Select allowClear onChange={setExpType} style={{ width: 300 }} defaultValue={type} placeholder={`${t('common.select')} ${t('compareGraph.actual')}/${t('compareGraph.stimulation')}`}>
                            <Select.Option value={"actual"}>{t("compareGraph.actual")}</Select.Option>
                            <Select.Option value={"theoretical"}>{"Simulation"}</Select.Option>
                        </Select>
                        <Select mode="multiple" allowClear style={{ width: 400 }} value={selectedTrials} onChange={setSelectedTrials} placeholder="Select Trial">
                            {
                                (ceslurePlotsData || [])?.filter((res: any) => res?.[type] && JSON.stringify(res?.[type]) !== '{}')?.map((res: any) => (
                                    <Select.Option value={res.trial_id}>{res.trial_display_id}</Select.Option>
                                ))
                            }
                        </Select>
                    </Space>
                </Space>
                <Space>
                    {
                        (!!plotData?.length) &&
                        <Row>
                            {plotData?.map((trial: any) => (
                                <Col span={(plotData?.length >= 2) ? 12 : 24}>
                                    {
                                        (!!plotData.length && !!trial?.[type]) ?
                                            <HighchartsReact highcharts={Highcharts}
                                                options={{
                                                    chart: {
                                                        width: 550
                                                    },
                                                    reflow: true,
                                                    title: {
                                                        text: trial?.trial_display_id
                                                    }, credits: {
                                                        enabled: false
                                                    },
                                                    xAxis: {
                                                        title: {
                                                            text: "Time in minutes"
                                                        },
                                                        categories: trial?.[type]?.["time"] || trial?.[type]?.["Time"] || []
                                                    },
                                                    yAxis: {
                                                        title: {
                                                            text: 'Temperature in ᵒC'
                                                        }
                                                    },
                                                    series: [
                                                        {
                                                            data: trial?.[type]?.["trial_data"] || trial?.[type]?.[trial?.trial_display_id] as any,
                                                            type: 'line',
                                                            name: 'Ambient Temperature',
                                                        },
                                                        ...(Object.keys(trial?.[type] || {}).filter((res: any) => !["time", "Time", "trial_data", trial?.trial_display_id].includes(res)).map((res: any) => ({
                                                            data: trial?.[type]?.[res] || [] as any,
                                                            type: 'line',
                                                            name: res.toUpperCase(),
                                                        })))
                                                    ]
                                                }}
                                            /> : <Empty description={`No data available for ${type === "theoretical" ? "Simulation" : "Actual Experiment"} for ${trial?.trial_display_id}`} />
                                    }
                                </Col>
                            ))}
                        </Row>
                    }
                </Space>
            </Collapse.Panel>
        </Collapse>
    )
}