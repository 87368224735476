import { useCallback } from "react"
import { useSelector } from "react-redux"
import { StoreState } from "src/store/configureStore"

import { LanguageUnion } from "src/utils/useTranslate"

type Messages = {
  [key in LanguageUnion]: {
    internal_server_error: string
    scroll_down_to_see_reports: string
    work_order_created: string
    trial_not_found_given_inputs: string
    ai_engine_no_matching_models: string
    no_trained_model_found: string
    could_not_fetch_results: string
    ai_engine_inverse_started: string
    custom_insights_results: string
    ai_engine_inverse_results_ready: string
    ai_engine_inverse_results_desc: string
    work_order_created_from_selected_trials: string
    formulation_empty_data_error: string
    properties_empty_data_error: string
    update_profile_error: string
    newUnitAdded:string
    newUnitAddedConversionAlert:string,
    alreadyExists: string,
    modelConfigFailedToLoad: string,
    noResultFound: string,
    inventoryFilterSaved: string
  }
}

export const messages: Messages = {
  en: {
    internal_server_error:
      "There was an internal server error, please try again later.",
    scroll_down_to_see_reports:
      "Scroll to the bottom of the page to see a list of all previously saved reports.",
    work_order_created: "Work order created",
    trial_not_found_given_inputs: "Trials not found with the given inputs",
    ai_engine_no_matching_models:
      "AI Engine (No matching models found for ingredient set)",
    no_trained_model_found: "No trained model found for the given inputs",
    could_not_fetch_results:
      "Could not fetch the results with the provided inputs",
    custom_insights_results: "Results of Custom Insights are ready",
    ai_engine_inverse_results_ready:
      "AI inverse prediction results are ready!",
    ai_engine_inverse_started:
      "Inverse prediction Run in Progress. Please check History for status and results.",
    ai_engine_inverse_results_desc:
      "If it is not displayed, refresh the page and access the prediction card from the 'Predicted Experiments History' button.",
    work_order_created_from_selected_trials:
      "Work Order has been created from selected trials!",
    formulation_empty_data_error:
      "Empty data in trials - formulation stage. Add data or delete the trial",
    properties_empty_data_error:
      "Empty data in trials - properties stage. Add data or delete the trial",
    update_profile_error: "Invalid user update fields",
    newUnitAdded: "New Unit added successfully",
    newUnitAddedConversionAlert: "Your conversion may not be available immediately",
    alreadyExists: "Unit already Exists",
    modelConfigFailedToLoad: "Model configuration failed to load. Please refresh the page and try again. If problem persists, please contact",
    noResultFound: "No Result Found",
    inventoryFilterSaved: "Inventory filter saved successfully"
  },
  de: {
    internal_server_error:
      "Es ist ein interner Serverfehler aufgetreten, bitte versuchen Sie es später noch einmal.",
    scroll_down_to_see_reports:
      "Scrollen Sie zum Ende der Seite, um eine Liste aller zuvor gespeicherten Berichte anzuzeigen.",
    work_order_created: "Arbeitsauftrag erstellt",
    trial_not_found_given_inputs: "Versuche, die mit den angegebenen Eingaben nicht gefunden wurden",
    ai_engine_no_matching_models:
      "AI Engine (Keine passenden Modelle für Zutatenset gefunden)",
    no_trained_model_found: "Für die angegebenen Eingaben wurde kein trainiertes Modell gefunden",
    could_not_fetch_results:
      "Die Ergebnisse konnten mit den bereitgestellten Eingaben nicht abgerufen werden",
    custom_insights_results: "Die Ergebnisse von Custom Insights sind fertig",
    ai_engine_inverse_results_ready:
      "Die Ergebnisse der inversen KI-Vorhersage sind fertig!",
      ai_engine_inverse_started:
      "Inverse prediction Run in Progress. Please check History for status and results.",
    ai_engine_inverse_results_desc:
      "Wenn sie nicht angezeigt wird, aktualisieren Sie die Seite und greifen Sie über die Schaltfläche 'Verlauf der vorhergesagten Experimente' auf die Vorhersagekarte zu.",
    work_order_created_from_selected_trials:
      "Der Arbeitsauftrag wurde aus ausgewählten Testversionen erstellt!",
    formulation_empty_data_error:
      "Leere Daten in Studien - Formulierungsphase. Hinzufügen von Daten oder Löschen der Testversion",
    properties_empty_data_error:
      "Leere Daten in Testversionen – Eigenschaftenphase. Hinzufügen von Daten oder Löschen der Testversion",
    update_profile_error: "Ungültige Felder für die Benutzeraktualisierung",
    newUnitAdded: "Neue Einheit erfolgreich hinzugefügt",
    newUnitAddedConversionAlert: "Ihre Conversion ist möglicherweise nicht sofort verfügbar",
    alreadyExists: "Einheit existiert bereits",
    modelConfigFailedToLoad: "Model configuration failed to load. Please refresh the page and try again. If problem persists, please contact",
    noResultFound: "No Result Found",
    inventoryFilterSaved: "Inventory filter saved successfully"
  },
  es: {
    internal_server_error:
      "Hubo un error interno del servidor, intente de nuevo más tarde.",
    scroll_down_to_see_reports:
      "Scroll to the bottom of the page to see a list of all previously saved reports.",
    work_order_created: "Work order created",
    trial_not_found_given_inputs: "Trials not found with the given inputs",
    ai_engine_no_matching_models:
      "AI Engine (No matching models found for ingredient set)",
    no_trained_model_found: "No trained model found for the given inputs",
    could_not_fetch_results:
      "Could not fetch the results with the provided inputs",
    custom_insights_results: "Results of Custom Insights are ready",
    ai_engine_inverse_results_ready:
      "AI inverse prediction results are ready!",
      ai_engine_inverse_started:
      "Inverse prediction Run in Progress. Please check History for status and results.",
    ai_engine_inverse_results_desc:
      "If it is not displayed, refresh the page and access the prediction card from the 'Predicted Experiments History' button.",
    work_order_created_from_selected_trials:
      "Work Order has been created from selected trials!",
    formulation_empty_data_error:
      "Empty data in trials - formulation stage. Add data or delete the trial",
    properties_empty_data_error:
      "Empty data in trials - properties stage. Add data or delete the trial",
    update_profile_error: "Invalid user update fields",
    newUnitAdded: "New Unit added successfully",
    newUnitAddedConversionAlert: "Your conversion may not be available immediately",
    alreadyExists: "Unit already Exists",
    modelConfigFailedToLoad: "Model configuration failed to load. Please refresh the page and try again. If problem persists, please contact",
    noResultFound: "No Result Found",
    inventoryFilterSaved: "Inventory filter saved successfully"
  },
  fr: {
    internal_server_error:
      "Il y avait une erreur interne du serveur, veuillez réessayer",
    scroll_down_to_see_reports:
      "Faites défiler jusqu'au bas de la page pour voir la liste de tous les rapports précédemment enregistrés.",
    work_order_created: "Bon de travail créé",
    trial_not_found_given_inputs: "Trials not found with the given inputs",
    ai_engine_no_matching_models:
      "AI Engine (No matching models found for ingredient set)",
    no_trained_model_found: "No trained model found for the given inputs",
    could_not_fetch_results:
      "Could not fetch the results with the provided inputs",
    custom_insights_results: "Results of Custom Insights are ready",
    ai_engine_inverse_results_ready:
      "AI inverse prediction results are ready!",
      ai_engine_inverse_started:
      "Inverse prediction Run in Progress. Please check History for status and results.",
    ai_engine_inverse_results_desc:
      "If it is not displayed, refresh the page and access the prediction card from the 'Predicted Experiments History' button.",
    work_order_created_from_selected_trials:
      "Work Order has been created from selected trials!",
    formulation_empty_data_error:
      "Empty data in trials - formulation stage. Add data or delete the trial",
    properties_empty_data_error:
      "Empty data in trials - properties stage. Add data or delete the trial",
    update_profile_error: "Invalid user update fields",
    newUnitAdded: "New Unit added successfully",
    newUnitAddedConversionAlert: "Your conversion may not be available immediately",
    alreadyExists: "Unit already Exists",
    modelConfigFailedToLoad: "Model configuration failed to load. Please refresh the page and try again. If problem persists, please contact",
    noResultFound: "No Result Found",
    inventoryFilterSaved: "Inventory filter saved successfully"
  },
  ja: {
    internal_server_error:
      "サーバー内部エラーが発生しました。後でもう一度お試しください。",
    scroll_down_to_see_reports:
      "ページの一番下までスクロールすると、過去に保存されたすべてのレポートのリストが表示されます。",
    work_order_created: "ワークオーダーが作成された。",
    trial_not_found_given_inputs: "指定された入力で試行が見つからない",
    ai_engine_no_matching_models:
      "AI エンジン (成分セットに一致するモデルが見つかりません)",
    no_trained_model_found:
      "指定された入力に対してトレーニング済みのモデルが見つかりません",
    could_not_fetch_results:
      "指定された入力で結果をフェッチできませんでした",
    custom_insights_results: "カスタムインサイトの結果の準備ができました",
    ai_engine_inverse_results_ready: "AI逆予測結果の準備ができました!",
    ai_engine_inverse_started:
      "Inverse prediction Run in Progress. Please check History for status and results.",
    ai_engine_inverse_results_desc:
      "表示されない場合は、ページを更新し、[予測された実験履歴]ボタンから予測カードにアクセスします。",
    work_order_created_from_selected_trials:
      "選択したトライアルからワークオーダーが作成されました!",
    formulation_empty_data_error:
      "試験中の空のデータ - 製剤段階。データの追加または試用版の削除",
    properties_empty_data_error:
      "トライアルの空のデータ - プロパティ ステージ。データの追加または試用版の削除",
    update_profile_error: "Invalid user update fields",
    newUnitAdded: "新しいユニットが正常に追加されました",
    newUnitAddedConversionAlert: "変換はすぐに利用できない場合があります",
    alreadyExists: "ユニットはすでに存在します",
    modelConfigFailedToLoad: "Model configuration failed to load. Please refresh the page and try again. If problem persists, please contact",
    noResultFound: "No Result Found",
    inventoryFilterSaved: "Inventory filter saved successfully"
  },
  pt: {
    internal_server_error:
      "Houve um erro interno do servidor, tente novamente mais tarde.",
    scroll_down_to_see_reports:
      "Scroll to the bottom of the page to see a list of all previously saved reports.",
    work_order_created: "Work order created",
    trial_not_found_given_inputs: "Trials not found with the given inputs",
    ai_engine_no_matching_models:
      "AI Engine (No matching models found for ingredient set)",
    no_trained_model_found: "No trained model found for the given inputs",
    could_not_fetch_results:
      "Could not fetch the results with the provided inputs",
    custom_insights_results: "Results of Custom Insights are ready",
    ai_engine_inverse_results_ready:
      "AI inverse prediction results are ready!",
      ai_engine_inverse_started:
      "Inverse prediction Run in Progress. Please check History for status and results.",
    ai_engine_inverse_results_desc:
      "If it is not displayed, refresh the page and access the prediction card from the 'Predicted Experiments History' button.",
    work_order_created_from_selected_trials:
      "Work Order has been created from selected trials!",
    formulation_empty_data_error:
      "Empty data in trials - formulation stage. Add data or delete the trial",
    properties_empty_data_error:
      "Empty data in trials - properties stage. Add data or delete the trial",
    update_profile_error: "Invalid user update fields",
    newUnitAdded: "New Unit added successfully",
    newUnitAddedConversionAlert: "Your conversion may not be available immediately",
    alreadyExists: "Unit already Exists",
    modelConfigFailedToLoad: "Model configuration failed to load. Please refresh the page and try again. If problem persists, please contact",
    noResultFound: "No Result Found",
    inventoryFilterSaved: "Inventory filter saved successfully"
  },
  zh: {
    internal_server_error: "服务器内部错误，请稍后再试。",
    scroll_down_to_see_reports:
      "Scroll to the bottom of the page to see a list of all previously saved reports.",
    work_order_created: "Work order created",
    trial_not_found_given_inputs: "Trials not found with the given inputs",
    ai_engine_no_matching_models:
      "AI Engine (No matching models found for ingredient set)",
    no_trained_model_found: "No trained model found for the given inputs",
    could_not_fetch_results:
      "Could not fetch the results with the provided inputs",
    custom_insights_results: "Results of Custom Insights are ready",
    ai_engine_inverse_results_ready:
      "AI inverse prediction results are ready!",
      ai_engine_inverse_started:
      "Inverse Run in Progress. Please check History for status and results.",
    ai_engine_inverse_results_desc:
      "If it is not displayed, refresh the page and access the prediction card from the 'Predicted Experiments History' button.",
    work_order_created_from_selected_trials:
      "Work Order has been created from selected trials!",
    formulation_empty_data_error:
      "Empty data in trials - formulation stage. Add data or delete the trial",
    properties_empty_data_error:
      "Empty data in trials - properties stage. Add data or delete the trial",
    update_profile_error: "Invalid user update fields",
    newUnitAdded: "New Unit added successfully",
    newUnitAddedConversionAlert: "Your conversion may not be available immediately",
    alreadyExists: "Unit already Exists",
    modelConfigFailedToLoad: "Model configuration failed to load. Please refresh the page and try again. If problem persists, please contact",
    noResultFound: "No Result Found",
    inventoryFilterSaved: "Inventory filter saved successfully"
  },
  ko: {
    internal_server_error:
      "There was an internal server error, please try again later.",
    scroll_down_to_see_reports:
      "Scroll to the bottom of the page to see a list of all previously saved reports.",
    work_order_created: "Work order created",
    trial_not_found_given_inputs: "Trials not found with the given inputs",
    ai_engine_no_matching_models:
      "AI Engine (No matching models found for ingredient set)",
    no_trained_model_found: "No trained model found for the given inputs",
    could_not_fetch_results:
      "Could not fetch the results with the provided inputs",
    custom_insights_results: "Results of Custom Insights are ready",
    ai_engine_inverse_results_ready:
      "AI inverse prediction results are ready!",
    ai_engine_inverse_started:
      "역 예측이 진행 중입니다. 상태와 결과는 예측 기록을 확인하세요.",
    ai_engine_inverse_results_desc:
      "If it is not displayed, refresh the page and access the prediction card from the 'Predicted Experiments History' button.",
    work_order_created_from_selected_trials:
      "Work Order has been created from selected trials!",
    formulation_empty_data_error:
      "Empty data in trials - formulation stage. Add data or delete the trial",
    properties_empty_data_error:
      "Empty data in trials - properties stage. Add data or delete the trial",
    update_profile_error: "Invalid user update fields",
    newUnitAdded: "New Unit added successfully",
    newUnitAddedConversionAlert: "Your conversion may not be available immediately",
    alreadyExists: "Unit already Exists",
    modelConfigFailedToLoad: "Model configuration failed to load. Please refresh the page and try again. If problem persists, please contact",
    noResultFound: "No Result Found",
    inventoryFilterSaved: "Inventory filter saved successfully"
  },
}

export const useMesssage = () => {
	const language = useSelector((state: StoreState) => state.language.current)

	/**
	 * This function returns all the common messages used across the application in the current language
	 *
	 * @example
	 * const { m } = useMessage()
	 * m('internal_server_error') // returns the message in the current language
	 */
	const m = useCallback(
		(key: keyof Messages["en"]) => {
			return messages[language][key]
		},
		[language]
	)

	return { m }
}
