import { Tag, Tooltip, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux'
import { AsyncStates } from 'src/constants'
import { updateSubscriptionRequest } from 'src/store/actions/settings'
import { StoreState } from 'src/store/configureStore'
import dayjs from "dayjs"
import { InfoCircleFilled } from '@ant-design/icons'
import { toTitleCase } from 'src/utils/general'
import useTranslate from 'src/utils/useTranslate'
import { StyledButton } from 'src/styled_components/StyledButton';

const { Title, Text } = Typography

export const BillingCurrentPlan = ({ subscription }: any) => {
    const [t] = useTranslate();
    const userInfo = useSelector((state: StoreState) => state.login.loginResponse)
    const { updateSubscriptionStatus } = useSelector((state: StoreState) => state.settings)
    const dispatch = useDispatch()

    const handleCurrentPlan = () => {
        if (subscription?.cancel_at_period_end || userInfo?.subscription_status === "canceled") {
            const payload = {
                resume_subscription: true
            }
            dispatch(updateSubscriptionRequest(payload))
        } else {
            const payload = {
                cancel_at_period_end: true
            }
            dispatch(updateSubscriptionRequest(payload))
        }
    }

    return (
        <div className="billing__currentplan__container">
            <Title level={4} className={"common__billing__title"}>{t("billing.currentPlan")} {" "}
                <Tooltip title={`${t('common.standard')} ${toTitleCase(subscription?.interval + "ly")} : ${subscription?.currency.toUpperCase()} ${subscription?.amount} per member/${subscription?.interval}`}>
                    <InfoCircleFilled />
                </Tooltip>  {" "}
                {!!subscription?.trial_end_date &&
                    <Tag style={{ backgroundColor: "#91c9ff" }}>{`${t("billing.trialEnds")} ${dayjs(subscription?.trial_end_date).format(`DD MMM YYYY`)}`}</Tag>
                }
            </Title>

            <div className='current__plan__info'>
                <Text type="secondary">{t('common.standard')} {toTitleCase(subscription?.interval + "ly")} * {subscription?.quantity}</Text>
                <Text type="secondary">{subscription?.currency.toUpperCase()} {subscription?.amount * subscription?.quantity} {t('common.per')} {subscription?.interval} - {(subscription?.cancel_at_period_end || userInfo?.subscription_status === "canceled") ? t('billing.willBeCancelledFrom') : t('billing.renewsOn')} {dayjs(subscription?.license_period_end).format("MMMM D, YYYY")}.</Text>
            </div>
            <div className='update__plan__action'>
                {(subscription?.cancel_at_period_end || userInfo?.subscription_status === "canceled") ?
                    <StyledButton loading={updateSubscriptionStatus === AsyncStates.LOADING} onClick={() => handleCurrentPlan()}>{t("billing.resumeSubs")}</StyledButton>
                    : <StyledButton loading={updateSubscriptionStatus === AsyncStates.LOADING} danger type='primary' onClick={() => handleCurrentPlan()}>{t("billing.cancelPlan")}</StyledButton>}
            </div>
        </div>
    )
}
