import { InfoCircleFilled, LoadingOutlined } from "@ant-design/icons"
import { Form, Input, Modal, Select, Space, Spin, Switch, Tooltip, Typography } from "antd"
import { useDispatch, useSelector } from "react-redux";
import { useRequiredFieldStar } from "src/components/Common/useRequiredFieldStar";
import { AsyncStates } from "src/constants";
import { setIsEditing } from "src/store/actions/isEditing";
import { StoreState } from "src/store/configureStore";
import { StyledButton } from "src/styled_components/StyledButton"
import useTranslate from "src/utils/useTranslate";
const { Text } = Typography;
const { Option } = Select;

const CreateModelModal = ({ modalVisible, setModalVisible, customDataTrain, isMultiStage, form }: any) => {
    const [t] = useTranslate();
    const dispatch = useDispatch();
    const requiredFieldStar = useRequiredFieldStar()

    const { customDataTrainStatus } = useSelector(
        (state: StoreState) => state.CustomML
    );

    return (
        <Modal
            okText={t("common.ok")}
            cancelText={t("common.cancel")}
            open={modalVisible}
            onCancel={() => setModalVisible(false)}
            footer={null}
        >
            <Space style={{ width: "100%" }} direction="vertical">
                <Spin
                    spinning={customDataTrainStatus === AsyncStates.LOADING}
                    indicator={<LoadingOutlined />}
                >
                    <Text type="secondary" strong>
                        {t("aiEngine.customML.modal.text")}
                    </Text>
                    <Form
                        style={{ marginTop: 30 }}
                        onFinish={customDataTrain}
                        layout="vertical"
                        form={form}
                        onValuesChange={() => dispatch(setIsEditing(true))}
                        requiredMark={false}
                    >
                        <Form.Item
                            name="objective"
                            label={t("common.objective")}
                            rules={[{ required: true }]}
                            required
                            tooltip={requiredFieldStar}
                        >
                            <Input placeholder={t("aiEngine.placeholder.objective")} />
                        </Form.Item>
                        <Form.Item
                            name="comment"
                            label={t("common.comment")}
                            rules={[{ required: true }]}
                            required
                            tooltip={requiredFieldStar}
                        >
                            <Input placeholder={t("aiEngine.placeholder.comment")} />
                        </Form.Item>
                        {!isMultiStage && <Form.Item
                            name="input_types"
                            label={
                                <Space>
                                    <Text>{t("common.modelInputs")}</Text>
                                    <Tooltip
                                        title={t("common.selectInputTypesToTrainTheModel")}
                                    >
                                        <InfoCircleFilled />
                                    </Tooltip>
                                </Space>
                            }
                            rules={[{ required: true }]}
                            initialValue={["ingredients"]}
                            required
                            tooltip={requiredFieldStar}
                        >
                            <Select mode="multiple" defaultValue={["ingredients"]}>
                                <Option value="ingredients" key="ingredients" disabled>
                                    {t("common.ingredients")}
                                </Option>
                                <Option value="processing" key="processing">
                                    {t("common.processing")}
                                </Option>
                            </Select>
                        </Form.Item>}
                        <Form.Item
                            name='use_metadata'
                            label={t("common.useMetadata")}
                            initialValue={false}
                            rules={[{ required: true }]}
                            required
                            tooltip={requiredFieldStar}
                            style={{ marginBottom: "8px" }}
                        >
                            <Switch
                                checkedChildren={t("common.yes")}
                                unCheckedChildren={t("common.no")}
                            />

                        </Form.Item>
                        <div style={{ marginBottom: "20px" }}>
                            <Text type="secondary">
                                {t("aiEngine.newModelUsemetadataNote")}
                            </Text>
                        </div>
                        <StyledButton
                            htmlType="submit"
                            type="primary"
                            style={{ float: "right", marginTop: 20 }}
                            disabled={customDataTrainStatus === AsyncStates.LOADING}
                            loading={customDataTrainStatus === AsyncStates.LOADING}
                        >
                            {t("common.submit")}
                        </StyledButton>
                    </Form>
                </Spin>
            </Space>
        </Modal>
    )
}

export default CreateModelModal;